import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import store from '../../../Data/Store';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import TextField from "@material-ui/core/TextField/TextField";

const style = store.pageStyle;
const styles = theme => ({
    root: {
        width: `calc(100% - ${theme.spacing(2)}px)`,
        height: `100%`,
        overflow: 'hidden',
        display: 'flex',
        padding: `0 ${theme.spacing(1)}px`,
    },
    textField: {
        flex: 1,
        flexBasis: 200,
        margin: `0 ${theme.spacing(1)}px`,
        '& div': {
            color: style.text,
            '& div': {
                '& svg': {color: style.text}
            }
        }
    },
    cssRoot: {
        color: style.text,
        '&$cssFocused': {color: theme.palette.primary.main},
    },
    cssFilled: {color: theme.palette.primary.main},
    cssFocused: {},
    cssUnderline: {
        '&:before': {borderBottom: `1px solid ${style.text}`},
        '&:after': {borderBottomColor: theme.palette.primary.main},
    },
});

class AdditionalTerm extends Component {
    state = {
        needRefresh: false,
        openGameAction: false,
        openStrikeEffect: false,
        openSpinKind: false,
    };

    UNSAFE_componentWillMount() {
        store.register({strikeAdditional: this});
    }

    componentWillUnmount() {
        store.unregister({strikeAdditional: this});
    }

    handleOpen = key => e => {
        if (e.button !== undefined)
            this.setState({[`open${key}`]: true});
    };

    handleClose = key => e => {
        this.setState({[`open${key}`]: false});
    };

    render() {
        const {classes} = this.props;
        const data = store.getData("AdditionalTerm");

        return <div className={classes.root}>
            <TextField
              select
              className={classes.textField}
              label={store.languageSwitch('GameAction')}
              value={data.GameAction.value}
              InputLabelProps={{
                  classes: {
                      root: classes.cssRoot,
                      filled: classes.cssFilled,
                      focused: classes.cssFocused,
                  }
              }}
              SelectProps={{
                  open: this.state.openGameAction,
                  onOpen: this.handleOpen('GameAction'),
                  onClose: this.handleClose('GameAction'),
              }}
              InputProps={{
                  classes: {
                      underline: classes.cssUnderline,
                  }
              }}
              onChange={store.handleChange("GameAction")}
            >
                {data.GameAction.possible.map(option => (
                  <MenuItem key={option} value={option}>
                      {store.languageSwitch(option)}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              select
              className={classes.textField}
              label={store.languageSwitch('StrikeEffect')}
              value={data.StrikeEffect.value}
              InputLabelProps={{
                  classes: {
                      root: classes.cssRoot,
                      filled: classes.cssFilled,
                      focused: classes.cssFocused,
                  }
              }}
              SelectProps={{
                  open: this.state.openStrikeEffect,
                  onOpen: this.handleOpen('StrikeEffect'),
                  onClose: this.handleClose('StrikeEffect'),
              }}
              InputProps={{
                  classes: {
                      underline: classes.cssUnderline,
                  }
              }}
              onChange={store.handleChange("StrikeEffect")}
            >
                {data.StrikeEffect.possible.map(option => (
                  <MenuItem key={option} value={option}>
                      {store.languageSwitch('L' + option)}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              select
              className={classes.textField}
              label={store.languageSwitch('SpinKind')}
              value={data.SpinKind.value}
              InputLabelProps={{
                  classes: {
                      root: classes.cssRoot,
                      filled: classes.cssFilled,
                      focused: classes.cssFocused,
                  }
              }}
              SelectProps={{
                  open: this.state.openSpinKind,
                  onOpen: this.handleOpen('SpinKind'),
                  onClose: this.handleClose('SpinKind'),
              }}
              InputProps={{
                  classes: {
                      underline: classes.cssUnderline,
                  }
              }}
              onChange={store.handleChange("SpinKind")}
            >
                {data.SpinKind.possible.map(option => (
                  <MenuItem key={option} value={option}>
                      {store.languageSwitch(option)}
                  </MenuItem>
                ))}
            </TextField>
        </div>;
    }
}

export default withStyles(styles)(AdditionalTerm);
