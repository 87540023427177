import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core';
import store from '../../../Data/Store';

const styles = theme => ({
    root: {display: 'block'},
    rowRoot: {display: 'flex'},
    table: {
        margin: 25,
        border: `2px solid ${theme.palette.primary.light}`,
        borderCollapse: 'collapse',
        '& td': {
            padding: 5,
            border: `2px solid ${theme.palette.primary.light}`,
            textAlign: 'center',
            minWidth: 35,
        },
        '& th': {
            padding: 5,
            fontWeight: 'normal',
            border: `2px solid ${theme.palette.primary.light}`,
            minWidth: 70
        },
    },
    win: {background: 'rgb(189,215,238)'},
    lose: {background: 'rgb(248,203,173)'},
    emptyCell: {opacity: 0.2},
    selectable: {
        cursor: 'pointer',
        '&:hover': {
            border: `3px solid ${theme.palette.primary.dark}`,
        },
    },
});

const div = (a, b) => !b ? 0 : parseInt((a / b).toString(), 10);

const tableFrame = [
    [
        [1, 1, 0, 1, 0, 0, 0, 0],
        [0, 0, 1, 0, 0, 1, 0, 1],
        [1, 1, 0, 1, 0, 0, 0, 0],
        [0, 0, 1, 0, 0, 1, 0, 1],
        [0, 0, 0, 0, 1, 1, 1, 1],
        [0, 0, 0, 0, 1, 1, 1, 1],
    ],
    [
        [1, 1, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 1, 0, 0, 0, 0],
        [1, 1, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 1, 0, 0, 0, 0],
        [0, 0, 1, 0, 1, 1, 1, 1],
        [0, 0, 1, 0, 1, 1, 1, 1],
    ],
];
const isEmpty = (tableType, rid, cid) => {
    return tableFrame[tableType][rid][cid] === 0;
};

class Multi8Table extends React.Component {
    players = store.getData('PlayerNames');
    data = [...new Array(4)]
        .map(() => [...new Array(6)]
            .map(() => [...new Array(8)]
                .map(() => 0)));

    calData = () => {
        const {rallies} = this.props;
        this.data.forEach(t => t.forEach(r => r.forEach((_, i) => r[i] = 0)));

        rallies.forEach((rally, rId) => {
            const {tid, rid, cid} = this.toCell(rally);
            if (tid >= 0 && tid < 4 && rid >= 0 && rid < 6 && cid >= 0 && cid < 8) {
                if (isEmpty(tid % 2, rid, cid)) console.log(rId, rally, tid, rid, cid);
                this.data[tid][rid][cid] += 1;
            }
        });
    };

    getPlayerName = (side, pId) => {
        const {player} = this.props;
        return this.players[(side + player) % 2][pId];
    };

    toCell = rally => {
        let tid = -1, rid = -1, cid = -1;
        const {length, lose, win} = rally;
        const {player} = this.props;

        if (!!lose && !!win) {
            if (+lose[0] === player) {
                if (+lose[1] === 0) {
                    // 00输球
                    tid = (length + 3) % 4;
                    cid = (length <= 4 ? 0 : 1) * 4 + (tid < 2 ? 0 : 1) * 2 + 1;
                } else {
                    // 01输球
                    tid = (length + 1) % 4;
                    cid = (length <= 4 ? 0 : 1) * 4 + (tid < 2 ? 1 : 0) * 2 + 1;
                }
                if (length <= 3) rid = (+win[1] === 0) ? 0 : 2;
                else if (length <= 5) rid = (+win[1] === 0) ? 3 : 1;
                else rid = (+win[1] === 0) ? 4 : 5;
            } else {
                if (+win[1] === 0) {
                    // 00赢球
                    tid = length === 1 ? length % 4 : (length + 2) % 4;
                    cid = (length <= 5 ? 0 : 1) * 4 + (tid < 2 ? 0 : 1) * 2;
                } else {
                    // 01赢球
                    tid = length === 1 ? (length + 2) % 4 : length % 4;
                    cid = (length <= 5 ? 0 : 1) * 4 + (tid < 2 ? 1 : 0) * 2;
                }
                if (length <= 2) rid = (+lose[1] === 0) ? 0 : 2;
                else if (length <= 4) rid = (+lose[1] === 0) ? 3 : 1;
                else rid = (+lose[1] === 0) ? 4 : 5;
            }
        }
        return {tid, rid, cid};
    };

    handleClick = (tid, rIds, cIds) => {
        this.props.clickCell('add-list', (game, rally, rallyResult) => {
            const location = this.toCell(rallyResult);
            return tid === location.tid && rIds.includes(location.rid) && cIds.includes(location.cid);
        });
    };

    renderCell(option, span = [1, 1]) {
        const {classes} = this.props;
        const {winLose, tid, location: [rIds, cIds], ...others} = option;
        let value = 0;
        let empty = true;
        rIds.forEach(rid => {
            cIds.forEach(cid => {
                if (isEmpty(tid % 2, rid, cid)) return;
                empty = false;
                value += this.data[tid][rid][cid];
            });
        });
        const valueStr = empty ? '' : (value === 0 ? '-' : value.toString());
        return <td
            className={clsx(classes[winLose], {
                [classes.emptyCell]: empty,
                [classes.selectable]: value > 0 && !empty
            })}
            rowSpan={span[0]} colSpan={span[1]}
            onClick={() => {
                if (value > 0 && !empty) this.handleClick(tid, rIds, cIds)
            }} {...others}>{valueStr}</td>
    }

    renderOrder(order, side, pId, span = [1, 1]) {
        let name = '';
        switch (order) {
            case 0:
                return <td rowSpan={span[0]} colSpan={span[1]}>{store.languageSwitch('total')}</td>;
            case 1:
                name = store.languageSwitch('Serve');
                break;
            case 2:
                name = store.languageSwitch('Receive');
                break;
            case 3:
                name = store.languageSwitch('StrikeNum')(3);
                break;
            case 4:
                name = store.languageSwitch('StrikeNum')(4);
                break;
            default:
                name = store.languageSwitch('Stalemate');
                break;
        }
        return <th rowSpan={span[0]} colSpan={span[1]}>
            {this.getPlayerName(side, pId)}<br/>
            {name}
        </th>
    }

    renderTable(tid) {
        const {classes} = this.props;
        return <table className={classes.table}>
            <tbody>
            <tr>
                <td rowSpan={2}>{tid}</td>
                {this.renderOrder(tid % 2 + 1, 0, div(tid, 2), [1, 2])}
                {this.renderOrder(3 + tid % 2, 0, 1 - div(tid, 2), [1, 2])}
                {this.renderOrder(5, 0, div(tid, 2), [1, 2])}
                {this.renderOrder(5, 0, 1 - div(tid, 2), [1, 2])}
                {this.renderOrder(0, 0, 0, [1, 2])}
            </tr>
            <tr>
                {[...new Array(4)].map((_, i) => <React.Fragment key={i}>
                    <td className={classes.win}>{store.languageSwitch('win')}</td>
                    <td className={classes.lose}>{store.languageSwitch('lose')}</td>
                </React.Fragment>)}
                {this.renderCell({
                    winLose: 'win',
                    tid,
                    location: [[0, 1, 2, 3, 4, 5], [0, 2, 4, 6]],
                }, [8, 1])}
                {this.renderCell({
                    winLose: 'lose',
                    tid,
                    location: [[0, 1, 2, 3, 4, 5], [1, 3, 5, 7]],
                }, [8, 1])}
            </tr>
            {[...new Array(6)].map((_, rid) => {
                return <tr key={rid}>
                    {this.renderOrder(
                        2 - tid % 2 + 2 * (rid % 2) + (3 + tid % 2 - 2 * (rid % 2)) * div(rid, 4),
                        1,
                        (rid % 2 + (rid % 4 - rid % 2) / 2) % 2
                    )}
                    {[...new Array(8)].map((_, cid) => {
                        return this.renderCell({
                            winLose: cid % 2 ? 'lose' : 'win',
                            tid,
                            location: [[rid], [cid]],
                            key: cid,
                        })
                    })}
                </tr>
            })}
            <tr>
                {this.renderOrder(0)}
                {[...new Array(8)].map((_, cid) => {
                    return this.renderCell({
                        winLose: cid % 2 ? 'lose' : 'win',
                        tid,
                        location: [[0, 1, 2, 3, 4, 5], [cid]],
                        key: cid,
                    })
                })}
            </tr>
            </tbody>
        </table>;
    }

    render() {
        const {classes} = this.props;
        this.calData();

        return <div className={classes.root}>
            <div className={classes.rowRoot}>
                {this.renderTable(0)}
                {this.renderTable(2)}
            </div>
            <div className={classes.rowRoot}>
                {this.renderTable(1)}
                {this.renderTable(3)}
            </div>
        </div>;
    }
}

Multi8Table.propTypes = {
    rallies: PropTypes.array.isRequired,
    player: PropTypes.number.isRequired,
    clickCell: PropTypes.func.isRequired,
};

export default withStyles(styles)(Multi8Table);
