import React, {useCallback, useRef, useState} from 'react';
import {Button, makeStyles} from "@material-ui/core";
import GameStruct from "./GameStruct";
import {Add, Clear} from "@material-ui/icons";
import {useSize} from "ahooks";
import InsertionPlaceholder from "./InsertionPlaceholder";
import interleave from "../../Utils/interleave";
import {dataActions} from "../../Utils/dataActions";

const useStyles = makeStyles(theme => ({
    rootGameStructure: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.primary.main}`
    },
    gameList: {
        flex: 1,
        overflow: 'hidden',
    },
    actions: {
        flex: '0 0 50px',
        height: 50,
        display: 'flex',
    },
    action: {
        margin: theme.spacing(1),
        flex: 1
    }
}));

function GameStructure({focus, setFocus, data, onChange}) {
    const classes = useStyles();

    //region insertion select
    const [selectedInsertion, setInsertion] = useState(-1);
    const [selectedRallyInsertion, setRallyInsertion] = useState(-1);
    const handleClickAnywhere = e => {
        if (e.target !== list.current) return;
        if (focus.gId === -1) {
            setInsertion(data.length);
        } else {
            setRallyInsertion(data[focus.gId].list.length);
        }
    };
    //endregion

    //region focus
    const handleFocusGame = gId => {
        setFocus(focus => ({gId: (gId === focus.gId) ? -1 : gId, rId: -1}));
        setInsertion(-1);
        setRallyInsertion(-1);
    }
    const handleFocusRally = rId => {
        setFocus(focus => ({...focus, rId}));
        setInsertion(-1);
        setRallyInsertion(-1);
    }
    //endregion

    //region action
    const handleAdd = () => {
        if (focus.gId === -1) { // add game
            let insertGameIdx = data.length; // append to the tail by default
            if (selectedInsertion !== -1) insertGameIdx = selectedInsertion;
            onChange({
                type: dataActions.AddGame,
                payload: {
                    gId: insertGameIdx
                },
            })
            if (selectedInsertion !== -1) setInsertion(d => d + 1);
        } else { // add rally
            const game = data[focus.gId];
            let insertRallyIdx = game.list.length; // append by default
            if (focus.rId !== -1) insertRallyIdx = focus.rId + 1; // insert to next
            if (selectedRallyInsertion !== -1) insertRallyIdx = selectedRallyInsertion;
            onChange({
                type: dataActions.AddRally,
                payload: {
                    gId: focus.gId,
                    rId: insertRallyIdx,
                }
            })
            if (selectedRallyInsertion !== -1) setRallyInsertion(d => d + 1);
        }
    };
    const handleRemove = () => {
        if (focus.gId === -1) { // remove game
            let removeGameIdx = data.length - 1; // remove last
            if (selectedInsertion !== -1) removeGameIdx = Math.max(selectedInsertion - 1, 0);
            onChange({
                type: dataActions.RemoveGame,
                payload: {
                    gId: removeGameIdx,
                }
            })
            if (selectedInsertion !== -1) setInsertion(removeGameIdx);
        } else { // remove rally
            const game = data[focus.gId];
            let removeRallyIdx = game.list.length - 1; // remove last
            if (focus.rId !== -1) removeRallyIdx = focus.rId; // remove current
            onChange({
                type: dataActions.RemoveRally,
                payload: {
                    gId: focus.gId,
                    rId: removeRallyIdx,
                }
            })
            if (selectedRallyInsertion !== -1) setRallyInsertion(-1);
        }
    };
    //endregion

    //region list height
    const list = useRef(null);
    const {height} = useSize(list);
    const gameStructMaxHeight = useCallback((titleHeight) => {
        if (!height) return 100;
        return height
          - (data.length + 1)
          - titleHeight * data.length;
    }, [height, data.length]);
    //endregion

    return <div className={classes.rootGameStructure}>
        <div className={classes.gameList}
             ref={list}
             onClick={handleClickAnywhere}>
            {interleave(
              data.map((game, gId) => (
                <GameStruct key={`game-${gId}`}
                            idx={gId}
                            game={game}
                            focus={focus}
                            onFocusGame={handleFocusGame}
                            onFocusRally={handleFocusRally}
                            insertion={selectedRallyInsertion}
                            onSetInsertion={setRallyInsertion}
                            maxHeight={gameStructMaxHeight}/>
              )),
              (idx) => (
                <InsertionPlaceholder key={`pad-${idx}`}
                                      idx={idx}
                                      shrunk={focus.gId !== -1}
                                      activate={selectedInsertion === idx}
                                      onSelect={setInsertion}/>
              ))}
        </div>
        <div className={classes.actions}>
            <Button color={'primary'}
                    variant={'contained'}
                    className={classes.action}
                    onClick={handleAdd}>
                <Add/>
            </Button>
            <Button color={'secondary'}
                    variant={'contained'}
                    className={classes.action}
                    onClick={handleRemove}>
                <Clear/>
            </Button>
        </div>
    </div>
}

export default GameStructure;
