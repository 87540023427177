import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import store from '../../Data/Store';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';

const style = store.pageStyle;
const styles = theme => ({
    root: {
        position: 'reletive',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    title: {
        position: 'absolute',
        width: '100%',
        height: '50px',
        '& span': {
            '& p': {
                fontSize: '20px',
            }
        },
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.palette.primary.main + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        "&:hover": {
            backgroundColor: theme.palette.primary.main + ' !important',
            color: theme.palette.primary.contrastText + ' !important',
        }
    },
    list: {
        position: 'absolute',
        width: '100%',
        top: '50px',
        overflow: 'auto',
    },
    listItem: {
        display: 'flex',
    },
    listItem0: {
        flex: 5,
        flexWrap: 'noWrap',
        textAlign: 'center'
    },
    listItem1: {
        flex: 2,
        flexWrap: 'noWrap',
        textAlign: 'center'
    },
    listItem2: {
        flex: 1,
        flexWrap: 'noWrap',
        textAlign: 'center'
    },
    listItem3: {
        flex: 2,
        flexWrap: 'noWrap',
        textAlign: 'center'
    },
    textCompleteRoot: {
        color: style.text + ' !important',
        backgroundColor: theme.palette.primary.contrastText + ' !important',
        '&:hover': {
            color: theme.palette.primary.contrastText + ' !important',
            backgroundColor: style.text + ' !important',
        },
    },
    textWarningRoot: {
        color: style.warning + ' !important',
        backgroundColor: theme.palette.primary.contrastText + ' !important',
        '&:hover': {
            color: theme.palette.primary.contrastText + ' !important',
            backgroundColor: style.warning + ' !important',
        },
    },
    textErrorRoot: {
        color: style.error + ' !important',
        backgroundColor: theme.palette.primary.contrastText + ' !important',
        '&:hover': {
            color: theme.palette.primary.contrastText + ' !important',
            backgroundColor: style.error + ' !important',
        },
    },
    outlinedCompleteRoot: {
        color: style.text + ' !important',
        backgroundColor: theme.palette.primary.contrastText + ' !important',
        border: `1px solid ${style.text}`,
        '&:hover': {
            color: theme.palette.primary.contrastText + ' !important',
            backgroundColor: style.text + ' !important',
            border: `1px solid ${style.text}`,
        },
    },
    outlinedWarningRoot: {
        color: style.warning + ' !important',
        backgroundColor: theme.palette.primary.contrastText + ' !important',
        border: `1px solid ${style.warning}`,
        '&:hover': {
            color: theme.palette.primary.contrastText + ' !important',
            backgroundColor: style.warning + ' !important',
            border: `1px solid ${style.warning}`,
        },
    },
    outlinedErrorRoot: {
        color: style.error + ' !important',
        border: `1px solid ${style.error}`,
        backgroundColor: theme.palette.primary.contrastText + ' !important',
        '&:hover': {
            color: theme.palette.primary.contrastText + ' !important',
            backgroundColor: style.error + ' !important',
            border: `1px solid ${style.error}`,
        },
    },
    buttonList: {
        position: "absolute",
        bottom: 0,
        height: '50px',
        width: "100%",
        display: "flex",
    },
    button: {
        flex: 1,
        height: '50px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '0.3vw',
        borderBottomRightRadius: 0,
        backgroundColor: theme.palette.primary.main + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        "&:hover": {
            backgroundColor: theme.palette.primary.main + ' !important',
            color: theme.palette.primary.contrastText + ' !important',
        },
        '&~button': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: '0.3vw',
        }
    },
    icon: {
        width: "25px",
        height: "25px"
    },
});

class MatchTree extends Component {
    state = {
        needRefresh: false,
    };

    UNSAFE_componentWillMount() {
        store.register({matchTree: this});
    }

    componentWillUnmount() {
        store.unregister({matchTree: this});
    }

    renderLabel(label) {
        const {classes} = this.props;
        let arr = label.split(' ');
        return arr.map((text, i) => <Typography className={classes[`listItem${i}`]} key={i}
                                                color={"inherit"}>{text}</Typography>)
    }

    render() {
        const {classes} = this.props;
        const canGameEnd = score => {
            if (score[0] <= 9 || score[1] <= 9) return score[0] === 11 || score[1] === 11;
            else return score[0] - score[1] === 2 || score[1] - score[0] === 2;
        };
        const result = store.focus.stage === "match" ? store.getData("MatchResult") : store.getData("GameResult");
        const showResult = store.focus.stage === "match" || canGameEnd(result);

        return <div className={classes.root}>
            <Button className={classes.title} onClick={store.handleChange("FocusItemBack")}>
                {
                    this.renderLabel((store.focus.stage === "match" ? store.languageSwitch('Match') : store.languageSwitch('GameNum')(store.focus.game + 1)) + (showResult ? ` ${result[0]} : ${result[1]}` : ''))
                }
            </Button>
            <div className={classes.list}
                 style={{bottom: this.props.editable ? '50px' : '0'}}>
                {
                    store.getData("FocusList").map((item, i) => {
                        let variant = 'text';
                        if (store.focus.stage === 'match' && i === store.focus.game) variant = 'outlined';
                        if (store.focus.stage === 'game' && i === store.focus.rally) variant = 'outlined';
                        return <Button variant={variant} key={i} fullWidth onClick={store.handleChange(`FocusItem${i}`)}
                                       className={clsx(classes[`${variant}${this.props.editable ? item.structureLevel : item.termLevel}Root`], classes.listItem)}>
                            {this.renderLabel(item.label)}
                        </Button>
                    })
                }
            </div>
            {
                this.props.editable &&
                <div className={classes.buttonList}>
                    <Button className={classes.button} onClick={store.handleChange("MatchTreeAdd")}>
                        <Add className={classes.icon}/>
                    </Button>
                    <Button className={classes.button} onClick={store.handleChange("MatchTreeDelete")}>
                        <Remove className={classes.icon}/>
                    </Button>
                </div>
            }
        </div>;
    }
}

export default withStyles(styles)(MatchTree);
