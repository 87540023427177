import React from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    IconButton,
    makeStyles,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import store from "../../../../Data/Store";
import {Refresh} from "@material-ui/icons";
import useChangeData from "../../Utils/useChangeData";

const style = store.pageStyle;
const useStyles = makeStyles(theme => ({
    rootRallyInfo: {
        width: '100%',
        height: '100%',
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.primary.main}`,
        overflow: 'hidden'
    },
    scoreArea: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '50%',
        overflow: 'hidden',
    },
    playerName: {
        flex: 1,
        height: '100%',
        fontSize: '40px',
        lineHeight: '150px',
        textAlign: 'center',
    },
    scorePadding: {
        margin: theme.spacing(0, 1),
        height: '100%',
    },
    bigScoreCard: {
        height: '100%',
        width: '100px',
        fontSize: '70px',
        lineHeight: '150px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    smallScoreCard: {
        height: '50%',
        width: '50px',
        fontSize: '35px',
        lineHeight: '75px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    editPanel: {
        width: '100%',
        height: '50%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
    },
    formStack: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 4),
        justifyContent: 'center',
    },
    timeButton: {
        height: '42px',
        lineHeight: '42px',
    },
    sideSelect: {
        height: '100%',
        flex: 1,
        display: 'flex',
    },
    colorSwitchBase: {
        color: theme.palette.primary.main,
        '& + $colorBar': {backgroundColor: style.text},
        '&$colorChecked': {
            color: theme.palette.primary.main,
            '& + $colorBar': {backgroundColor: style.text},
        },
    },
    colorBar: {},
    colorChecked: {},
    switchButton: {
        margin: '50px 0',
        lineHeight: '50px',
    },
    switchChoice: {
        margin: '50px 0',
        flex: 1,
        lineHeight: '50px',
        textAlign: 'center',
        color: style.text,
    },
}));

const timeFormat = (second) => {
    const hour = Math.floor(second / 3600).toFixed(0);
    const min = Math.floor((second % 3600) / 60).toFixed(0);
    const sec = Math.floor(second % 60).toFixed(0);

    return `${hour} : ${min.padStart(2, '0')} : ${sec.padStart(2, '0')}`;
};

const emptyGame = {
    score: [0, 0],
    list: []
};
const emptyRally = {
    score: [0, 0],
    rallyLength: 0,
    startTime: 0,
    endTime: 0,
    startSide: 0,
    winSide: 0,
};

function RallyInfo({focus, data, onChange, getCurrentTime}) {
    const classes = useStyles();
    const players = store.getData("PlayerNames");
    const game = data[focus.gId] || emptyGame;
    const rally = game.list[focus.rId] || emptyRally;

    const {
        handleChangeStartTime,
        handleChangeStarter,
        handleChangeRallyLength,
        handleReverseScore,
        handleSetGameScore,
        handleSetRallyScore,
        handleChangeEndTime
    } = useChangeData(onChange);

    const handleSetGameScoreOfOneSide = side => () => {
        const newScore = window.prompt(store.languageSwitch("InputGameScore")(players[side].join('/')));
        const score = [game.score[0], game.score[1]];
        score[side] = newScore;
        handleSetGameScore({gId: focus.gId, score});
    }
    const handleSetRallyScoreOfOneSide = side => () => {
        const newScore = parseInt(window.prompt(store.languageSwitch("InputRallyScore")(players[side].join('/'))));
        if (isNaN(newScore)) return;
        const score = [rally.score[0], rally.score[1]];
        score[side] = newScore;
        handleSetRallyScore({gId: focus.gId, rId: focus.rId, score});
    }
    const handleChangeStarterTriggeredBySwitch = evt => {
        const startSide = evt.target.checked ? 1 : 0;
        const {gId, rId} = focus;
        handleChangeStarter({gId, rId, startSide});
    }
    const handleInputRallyLength = evt => {
        const rallyLength = parseInt(evt.target.value);
        const {gId, rId} = focus;
        handleChangeRallyLength({gId, rId, rallyLength});
    }
    const handleRecordStartTime = () => {
        const {gId, rId} = focus;
        const startTime = getCurrentTime();
        handleChangeStartTime({gId, rId, startTime});
    }
    const handleRecordEndTime = () => {
        const {gId, rId} = focus;
        const endTime = getCurrentTime();
        handleChangeEndTime({gId, rId, endTime});
    }

    return <div className={classes.rootRallyInfo}>
        <div className={classes.scoreArea}>
            <div className={classes.playerName}>{players[0].join('/')}</div>
            <div className={classes.scorePadding}/>
            <div className={classes.bigScoreCard}
                 onClick={handleSetRallyScoreOfOneSide(0)}>
                {Math.floor(rally.score[0] / 10).toFixed(0)}
            </div>
            <div className={classes.scorePadding}/>
            <div className={classes.bigScoreCard}
                 onClick={handleSetRallyScoreOfOneSide(0)}>
                {rally.score[0] % 10}
            </div>
            <div className={classes.scorePadding}/>
            <div className={classes.smallScoreCard}
                 onClick={handleSetGameScoreOfOneSide(0)}>
                {game.score[0]}
            </div>
            <div className={classes.scorePadding}>
                <Tooltip title={store.languageSwitch("ReverseScore")}>
                    <IconButton onClick={handleReverseScore}><Refresh/></IconButton>
                </Tooltip>
            </div>
            <div className={classes.smallScoreCard}
                 onClick={handleSetGameScoreOfOneSide(1)}>
                {game.score[1]}
            </div>
            <div className={classes.scorePadding}/>
            <div className={classes.bigScoreCard}
                 onClick={handleSetRallyScoreOfOneSide(1)}>
                {Math.floor(rally.score[1] / 10).toFixed(0)}
            </div>
            <div className={classes.scorePadding}/>
            <div className={classes.bigScoreCard}
                 onClick={handleSetRallyScoreOfOneSide(1)}>
                {rally.score[1] % 10}
            </div>
            <div className={classes.scorePadding}/>
            <div className={classes.playerName}>{players[1].join('/')}</div>
        </div>
        <div className={classes.editPanel}>
            <div className={classes.formStack}>
                <div className={classes.sideSelect}>
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyStarterA')}</Typography>
                    <Switch
                      checked={rally.startSide === 1}
                      className={classes.switchButton}
                      classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          track: classes.colorBar,
                      }}
                      onClick={handleChangeStarterTriggeredBySwitch}
                    />
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyStarterB')}</Typography>
                </div>
            </div>

            <div className={classes.formStack}>
                <FormControl component={'fieldset'}>
                    <FormLabel component={'legend'}>{store.languageSwitch("StartTime")}</FormLabel>
                    <Button className={classes.timeButton} color={'inherit'}
                            onClick={handleRecordStartTime}>
                        {timeFormat(rally.startTime)}
                    </Button>
                </FormControl>
            </div>

            <div className={classes.formStack}>
                <FormControl component={'fieldset'}>
                    <FormLabel component={'legend'}>{store.languageSwitch("RallyLength")}</FormLabel>
                    <TextField variant={'standard'} sx={{height: 50}} value={rally.rallyLength}
                               onChange={handleInputRallyLength}/>
                </FormControl>
            </div>

            <div className={classes.formStack}>
                <FormControl component={'fieldset'}>
                    <FormLabel component={'legend'}>{store.languageSwitch("EndTime")}</FormLabel>
                    <Button className={classes.timeButton} color={'inherit'}
                            onClick={handleRecordEndTime}>
                        {timeFormat(rally.endTime)}
                    </Button>
                </FormControl>
            </div>

            <div className={classes.formStack}>
                <div className={classes.sideSelect}>
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyWinnerA')}</Typography>
                    <Switch
                      checked={rally.winSide === 1}
                      className={classes.switchButton}
                      classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          track: classes.colorBar,
                      }}
                    />
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyWinnerB')}</Typography>
                </div>
            </div>
        </div>
    </div>;
}

export default RallyInfo;
