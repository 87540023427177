import rules from './TermRules';
import Papa from "papaparse/papaparse";
import wordDict from "./WordDict";
import {Component} from "react";
import FindPlayers from './Players';
import pages from "./Pages";
import {
    allFaceTypes,
    allMatchCompetitions,
    allMatchRounds,
    allMatchTypes,
    oldMatchCompetitions, oldMatchRounds,
    oldMatchTypes
} from "./static";

class Store {
    constructor() {
        this.pageStyle = {
            background: "rgb(222, 225, 230)",
            foreground: "rgb(48, 138, 194)",
            text: "rgb(36, 40, 41)",
            warning: "rgb(254, 153, 48)",
            error: "rgb(219, 52, 149)",
            disabled: "rgb(180, 180, 180)",
            recommended: "rgb(219, 149, 52)",
            chosen: "rgb(49, 219, 72)",
        };
        this.theme = {
            primary: {
                main: "#37474f",
                contrastText: '#ffffff',
            },
            secondary: {
                main: "#ff5722",
            },
        };
        this.styles = [
            {
                __style_name__: '素雅墨',
                primary: {
                    main: "rgb(68, 72, 89)",
                    // light: "rgb(218, 220, 230)",
                    dark: "rgb(28, 30, 37)",
                    // main: "rgb(22, 25, 57)",
                    contrastText: "#ffffff"
                },
                secondary: {
                    main: "#ff5722"
                }
            },
            {
                __style_name__: '清澈蓝',
                primary: {
                    main: "#308ac2",
                    light: "#b6e5ff",
                    dark: "#0185de",
                    contrastText: "#ffffff"
                },
                secondary: {
                    main: "#ff5722"
                }
            },
            {
                __style_name__: '深邃蓝',
                primary: {
                    main: "#007acc",
                    contrastText: "#292930"
                },
                secondary: {
                    main: "#ff5722"
                }
            },
            {
                __style_name__: '活泼粉',
                primary: {
                    main: "#f06292",
                    contrastText: "#ffffff"
                },
                secondary: {
                    main: "#d50000"
                }
            }
        ];
        this.refreshList = {
            app: null,
            matchInfo: null,
            matchTree: null,
            rallyInfo: null,
            strikePosition: null,
            strikeTech: null,
            strikeAdditional: null,
            video: null,
            videoSrc: null,
            matchList: null,
            videoList: null,
            statistics: null,
            autoDetection: null,
        };
        this.necessaryFiles = ['collect_project.json', 'video.mp4'];
        this.focus = {
            stage: 'match',
            game: -1,
            rally: -1,
            strike: -1,
        };
        this.userEventCollections = [];
        this.videoSrc = null;
        this.videoFile = null;
        this.lang = 'cn';
        this.service = "http://127.0.0.1:5000";
        this.show = false;
        this.hasModified = false;
        this.setPosition = false;
        this.videoList = [];
        this.updates = '';
        this.timeLock = false;
        this.statisticVideoList = [];
        this.autoDetectionState = {
            activeStep: 0,
            videoName: "",
            md5: "",
            canSkipUploadVideo: false,
            canSkipUploadAnnotation: false,
            canSkipSegment: false,
            scoreboard: null,
            table: null,
            net: null,
            fps: 24,
            segmentData: [],
        };

        this.initData();

        fetch(`${this.service}/sports_platform_service_confirm`)
            .then(res => res.text())
            .then(res => {
                if (res === 'Running') {
                    this.show = true;
                    this.refreshPage('app');
                }
            });


        fetch(`${this.service}/settings`)
            .then(res => res.json())
            .then(res => {
                if (res.styleChosen < this.styles.length) this.theme = this.styles[res.styleChosen];
                else this.theme = res.customStyle;
                this.refreshPage('app');

                const interval = res.autoSave || 5000;
                setInterval(() => {
                    if (this.refreshList.app !== null && [1, 2, 3].includes(this.refreshList.app.state.page))
                        this.SaveTemp();
                }, interval);
            });

        fetch(`${this.service}/updates/ttdc`)
            .then(res => res.text())
            .then(res => {
                this.updates = res;
            });

        document.oncontextmenu = e => e.preventDefault();
        document.ondragleave = e => e.preventDefault();
        document.ondrop = e => e.preventDefault();
        document.ondragenter = e => e.preventDefault();
        document.ondragover = e => e.preventDefault();
    }

    NewMatch() {
        this.initData();
        if (this.refreshList.app !== null)
            this.refreshList.app.setState({
                page: pages.INFO,
            });
    }

    static loadFile() {
        let fileImporter = document.getElementById("file-importer");
        if (!fileImporter.value) return;
        return fileImporter.files[0];
    }

    initData() {
        this.data = {
            match: {
                video: null,
                time: ["", ""],
                location: "",
                type: "",
                competition: "",
                round: "",
            },
            player: [[], []],
            record: {
                MatchNo: '',
                result: [0, 0],
                list: [],
            }
        };
        this.focus = {
            stage: 'match',
            game: -1,
            rally: -1,
            strike: -1,
        };
        this.userEventCollections = [];
        if (this.videoSrc !== null) URL.revokeObjectURL(this.videoSrc);
        this.videoSrc = null;
    }

    //region recalculate system
    recalculateStructureLevel() {
        const match = this.data.record;
        let lastRally = {endTime: 0};
        match.list.forEach(game => {
            let isEnd = false;
            game.structureLevel = 0;
            game.list.forEach(rally => {
                rally.structureLevel = 0;
                isEnd = isEnd || this.getData('CanGameEnd')(rally.score);
                if (isEnd) rally.structureLevel = 2;
                else {
                    if (rally.rallyLength === 0) rally.structureLevel = 2;
                    else if (rally.endTime <= rally.startTime) rally.structureLevel = 2;
                    else if (rally.startTime < lastRally.endTime) rally.structureLevel = 1;
                }
                lastRally = rally;
            });
            if (isEnd) game.structureLevel = 2;
            else {
                if (!this.getData('CanGameEnd')(game.result)) game.structureLevel = 2;
                else
                    for (let i = 0; i < game.list.length; i++) {
                        if (game.list[i].structureLevel > game.structureLevel) game.structureLevel = game.list[i].structureLevel;
                        if (game.structureLevel === 2) break;
                    }
            }
        });
    }

    recalculateStartSide() {
        const match = this.data.record;
        let whoToReceiveIsNormal = true;
        let firstToFive = true;
        match.list.forEach((game, gameId) => {
            game.list.forEach((rally, rallyId) => {
                let startSide = -1;
                if (rallyId === 0) {
                    if (gameId !== 0) {
                        const lastGame = match.list[gameId - 1];
                        if (lastGame.list.length !== 0) {
                            const firstRally = lastGame.list[0];
                            if (firstRally.startSide === 1) startSide = 0;
                            else startSide = 1;
                        } else startSide = 0;
                    } else startSide = rally.startSide;
                } else {
                    const lastRally = game.list[rallyId - 1];
                    const isSame = rallyId < 20 ? (rallyId % 2 === 1) : false;
                    if (isSame) startSide = lastRally.startSide === 1 ? 1 : 0;
                    else startSide = lastRally.startSide === 1 ? 0 : 1;
                }
                rally.startSide = startSide;
                rally.list.forEach((strike, strikeId) => {
                    let side = rally.startSide;
                    if (side === -1) side = 0;
                    side = (side + strikeId) % 2;

                    if (this.data.player[0].length !== 2) strike.HitPlayer = `${side}0`;
                    else {
                        // 决定接发球次序是顺序还是逆序
                        if (strikeId === 0)
                            if (rallyId === 0 && gameId !== 0) whoToReceiveIsNormal = !whoToReceiveIsNormal;
                        if (strikeId === 1)
                            if (gameId === this.data.record.list.length - 1 && gameId % 2 === 0 && game.score[0] === game.score[1])
                                if ((rally.score[0] === 5 || rally.score[1] === 5) && firstToFive) {
                                    firstToFive = false;
                                    whoToReceiveIsNormal = !whoToReceiveIsNormal;
                                }

                        let player = '0';
                        if (strikeId === 0) { // 每一回合的发球员
                            if (rallyId === 0) { // 每一局的第一发球员（用户指定）
                                player = strike.HitPlayer[1];
                                if (player !== '0' && player !== '1') player = '0';
                            } else { // 除每局第一回合外，每一回合的发球员（系统计算）
                                const preStarter = game.list[rallyId - 1].list[0].HitPlayer;
                                if (side === +preStarter[0]) player = preStarter[1];
                                else player = this.getData('WhoToReceive')(preStarter, whoToReceiveIsNormal)[1];
                            }
                        } else if (strikeId === 1) { // 每一回合的接发员
                            if (gameId === 0 && rallyId === 0) { // 第一局的第一接发员（用户指定）
                                player = strike.HitPlayer[1];
                                if (player !== '0' && player !== '1') player = '0';
                                whoToReceiveIsNormal = this.getData('IsNormalOrderToReceive')(rally.list[0].HitPlayer, `${side}${player}`);
                            } else { // 除第一局第一回合，每一回合的接发员（系统计算）
                                player = this.getData('WhoToReceive')(rally.list[0].HitPlayer, whoToReceiveIsNormal)[1];
                            }
                        } else if (strikeId > 1) { // 每一回合交替击球（系统计算）
                            player = rally.list[strikeId % 2].HitPlayer[1];
                            if ((strikeId - strikeId % 2) % 4 === 2) player = 1 - player;
                        }

                        strike.HitPlayer = `${side}${player}`;
                    }
                });
            });
        });
    }

    recalculateWinSide() {
        const match = this.data.record;
        match.result = [0, 0];
        match.list.forEach(game => {
            game.score[0] = match.result[0];
            game.score[1] = match.result[1];
            game.result = [0, 0];
            game.list.forEach(rally => {
                rally.score[0] = game.result[0];
                rally.score[1] = game.result[1];

                let startSide = rally.startSide;
                if (startSide === -1) startSide = 0;
                if (rally.rallyLength % 2 === 0) rally.winSide = startSide;
                else rally.winSide = 1 - startSide;

                game.result[rally.winSide]++;
            });

            if (this.getData('CanGameEnd')(game.result))
                if (game.result[0] > game.result[1]) match.result[0]++;
                else match.result[1]++;
        });
    }

    recalculateTerm() {
        const game = this.data.record.list[this.focus.game];
        if (game === undefined) return;
        const rally = game.list[this.focus.rally];
        if (rally === undefined) return;

        // init
        this.data.record.list.forEach(game => {
            game.list.forEach(rally => {
                if (rally.rallyLength > rally.list.length)
                    while (rally.rallyLength !== rally.list.length)
                        rally.list.push(null);
                else if (rally.rallyLength < rally.list.length) rally.list.splice(rally.rallyLength);
                rally.list.forEach((strike, strikeId) => {
                    if (strike === null)
                        rally.list[strikeId] = this.getData('NewStrike')(strikeId)
                });
            });
        });
        this.recalculatePlayer();

        if (rally.list.length === 0) return;

        rally.list.forEach((strike, strikeId, rallyList) => {
            const strikeTuple = {
                pre: strikeId === 0 ? null : rallyList[strikeId - 1],
                cur: strike,
                nxt: strikeId === rallyList.length - 1 ? null : rallyList[strikeId + 1],
                nnt: strikeId >= rallyList.length - 2 ? null : rallyList[strikeId + 2],
            };
            const indexTuple = {
                pre: strikeId === 0 ? null : strikeId - 1,
                cur: strikeId,
                nxt: strikeId === rallyList.length - 1 ? null : strikeId + 1,
                nnt: strikeId >= rallyList.length - 2 ? null : strikeId + 2,
            };
            const faceType = {}, techType = {};
            ['pre', 'cur', 'nxt', 'nnt'].forEach(relPos => {
                const getFaceType = strike => {
                    if (!strike) return null;
                    const playerId = strike.HitPlayer;
                    const player = this.data.player[playerId[0]][playerId[1]];
                    const fore_back = (player.rightHand ? ['F', 'P'] : ['B', 'T']).includes(strike.StrikePosition.value) ? 1 : 0;
                    let face = player.face[fore_back];
                    if (['S1', 'S2'].includes(strike.StrikePosition.value)) face = 1;
                    return face;
                };
                const getTechType = strike => {
                    if (!strike) return 'None';
                    switch (strike.StrikeTech.value) {
                        case 'Pendulum':
                        case 'Reverse':
                        case 'Tomahawk':
                            return 'Serve';
                        case 'Topspin':
                        case 'Attack':
                        case 'Smash':
                        case 'Flick':
                        case 'Twist':
                            return 'Attack';
                        case 'Block':
                        case 'Lob':
                            return 'Defend';
                        case 'Push':
                        case 'Short':
                        case 'Slide':
                            return 'Control';
                        case 'Others':
                            return 'Others';
                        case 'Chopping':
                            return 'Chopping';
                        case 'PimpleTech':
                            return 'PimpleTech';
                        default:
                            return '';
                    }
                };
                faceType[relPos] = getFaceType(strikeTuple[relPos]);
                techType[relPos] = getTechType(strikeTuple[relPos]);
            });
            [
                'BallPosition', 'StrikePosition', 'StrikeTech', 'GameAction', 'StrikeEffect', 'SpinKind',
                // 'PlayerPositionAl0', 'PlayerPositionAl1', 'PlayerPositionOp0', 'PlayerPositionOp1'
            ]
                .filter((key, key_id) => key_id < 2 + 4 * this.getData('PlayerNum'))
                .forEach(key => {
                    let possible = this.getData(`All${key}`);
                    let impossible = new Set();
                    let defaultVal = '';
                    //region generate impossible tuple
                    rules[key].forEach(rule => {
                        const matchRule = () => {
                            let allMatch = true;
                            ['pre', 'cur', 'nxt', 'nnt'].forEach(relPos => {
                                if (!allMatch) return;
                                const relPosRule = rule.match[relPos];
                                if (relPosRule) {
                                    const relPosStrike = strikeTuple[relPos];
                                    const relPosFace = faceType[relPos];
                                    const relPosTech = techType[relPos];
                                    const relPosIndex = indexTuple[relPos];
                                    ['BallPosition', 'StrikePosition', 'StrikeTech', 'GameAction', 'StrikeEffect',
                                        'SpinKind', 'Index', 'FaceType', 'TechType', 'notBallPosition', 'notStrikePosition',
                                        'notStrikeTech', 'notGameAction', 'notStrikeEffect', 'notSpinKind', 'notIndex',
                                        'notFaceType', 'notTechType', 'PlayerNum'].forEach(matchKey => {
                                        if (!allMatch) return;
                                        const keyRule = relPosRule[matchKey];
                                        if (keyRule) {
                                            switch (matchKey) {
                                                case 'BallPosition':
                                                case 'StrikePosition':
                                                case 'StrikeTech':
                                                case 'GameAction':
                                                case 'StrikeEffect':
                                                case 'SpinKind':
                                                    if (relPosStrike === null || !keyRule.includes(relPosStrike[matchKey].value)) allMatch = false;
                                                    break;
                                                case 'Index':
                                                    if (keyRule.filter(i => {
                                                        if (i === null || i >= 0) return i === relPosIndex;
                                                        else return i + rallyList.length === relPosIndex;
                                                    }).length === 0) allMatch = false;
                                                    break;
                                                case 'FaceType':
                                                    if (!keyRule.includes(relPosFace)) allMatch = false;
                                                    break;
                                                case 'TechType':
                                                    if (!keyRule.includes(relPosTech)) allMatch = false;
                                                    break;
                                                case 'notBallPosition':
                                                case 'notStrikePosition':
                                                case 'notStrikeTech':
                                                case 'notGameAction':
                                                case 'notStrikeEffect':
                                                case 'notSpinKind':
                                                    const matchKeyWithoutPrefix = matchKey.substring(3);
                                                    if (relPosStrike === null || keyRule.includes(relPosStrike[matchKeyWithoutPrefix].value)) allMatch = false;
                                                    break;
                                                case 'notIndex':
                                                    if (keyRule.filter(i => {
                                                        if (i === null || i >= 0) return i === relPosIndex;
                                                        else return i + rallyList.length === relPosIndex;
                                                    }).length !== 0) allMatch = false;
                                                    break;
                                                case 'notFaceType':
                                                    if (keyRule.includes(relPosFace)) allMatch = false;
                                                    break;
                                                case 'notTechType':
                                                    if (keyRule.includes(relPosTech)) allMatch = false;
                                                    break;
                                                case 'PlayerNum':
                                                    if (!keyRule.includes(this.data.player[0].length)) allMatch = false;
                                                    break;
                                                default:
                                                    allMatch = false;
                                            }
                                        }
                                    });
                                }
                            });
                            return allMatch;
                        };
                        const updateOption = () => {
                            if (rule.possible) {
                                possible = possible.filter(val => rule.possible.includes(val));
                                this.getData(`All${key}`).forEach(val => {
                                    if (!rule.possible.includes(val)) impossible.add(val);
                                });
                            }
                            if (rule.impossible) {
                                possible = possible.filter(val => !rule.impossible.includes(val));
                                this.getData(`All${key}`).forEach(val => {
                                    if (rule.impossible.includes(val)) impossible.add(val);
                                });
                            }
                            if (rule.addPossible) {
                                rule.addPossible.forEach(add => {
                                    if (!possible.includes(add)) possible.push(add);
                                    if (impossible.has(add)) impossible.delete(add);
                                })
                            }
                            if (rule.delImpossible) {
                                rule.delImpossible.forEach(del => {
                                    if (!possible.includes(del)) possible.push(del);
                                    if (impossible.has(del)) impossible.delete(del);
                                });
                            }
                            if (rule.default) {
                                if (defaultVal === '') defaultVal = rule.default;
                                else defaultVal = null;
                            }
                        };

                        const isMatch = matchRule();
                        if (isMatch) updateOption();
                        // if (key === 'GameAction') console.log(isMatch, JSON.stringify(possible));
                    });
                    //endregion
                    //region calculate term level and imply default value
                    // if (key === 'GameAction') console.log(strikeId, possible, [...impossible], defaultVal);
                    strike[key].impossible = [...impossible];

                    if (strike[key].value !== '') {
                        if (possible.length === 1) {
                            strike[key].value = possible[0];
                            strike[key].termLevel = 0;
                        } else if (possible.includes(strike[key].value)) {
                            if (strike[key].value !== defaultVal && possible.includes(defaultVal))
                                strike[key].termLevel = 1;
                            else strike[key].termLevel = 0;
                        } else {
                            strike[key].termLevel = 2;
                        }
                    } else {
                        if (possible.length === 1 || (possible.length === 2 && possible.includes('WT'))) {
                            strike[key].value = possible[0];
                            strike[key].termLevel = 0;
                        } else if (possible.includes(defaultVal)) {
                            strike[key].value = defaultVal;
                            strike[key].termLevel = 0;
                        } else {
                            strike[key].termLevel = 2;
                        }
                    }
                    //endregion
                });
        });

        // refresh term level
        let termLevel = 0;
        rally.list.forEach(strike => {
            strike.termLevel = 0;
            ['BallPosition', 'StrikePosition', 'StrikeTech', 'GameAction', 'StrikeEffect', 'SpinKind'].forEach(key => {
                if (strike.termLevel < strike[key].termLevel) strike.termLevel = strike[key].termLevel;
            });
            if (strike.HitPlayer.length === 0) strike.termLevel = 2;
            if (termLevel < strike.termLevel) termLevel = strike.termLevel;
        });

        if (termLevel === rally.termLevel) return;
        const tempTermLevel = rally.termLevel;
        rally.termLevel = termLevel;

        if (rally.termLevel === game.termLevel) return;
        if (rally.termLevel > game.termLevel) game.termLevel = rally.termLevel;
        else if (tempTermLevel === game.termLevel) {
            termLevel = 0;
            game.list.forEach(rally => {
                if (termLevel < rally.termLevel) {
                    termLevel = rally.termLevel;
                }
            });
            game.termLevel = termLevel;
        }
    }

    recalculatePlayer() {
        const game = this.data.record.list[this.focus.game];
        if (game === undefined) return;
        const rally = game.list[this.focus.rally];
        if (rally === undefined) return;

        if (this.data.player[0].length !== 2) {
            rally.list.forEach((strike, strikeId) => {
                let side = rally.startSide;
                if (side === -1) side = 0;
                side = (side + strikeId) % 2;

                strike.HitPlayer = `${side}0`;
            });
        } else {
            let whoToReceiveIsNormal = true;
            let firstToFive = true;
            this.data.record.list.forEach((game, gameId) => {
                game.list.forEach((rally, rallyId) => {
                    rally.list.forEach((strike, strikeId) => {
                        let side = rally.startSide;
                        if (side === -1) side = 0;
                        side = (side + strikeId) % 2;

                        // 决定接发球次序是顺序还是逆序
                        if (strikeId === 0)
                            if (rallyId === 0 && gameId !== 0) whoToReceiveIsNormal = !whoToReceiveIsNormal;
                        if (strikeId === 1)
                            if (gameId === this.data.record.list.length - 1 && gameId % 2 === 0 && game.score[0] === game.score[1])
                                if ((rally.score[0] === 5 || rally.score[1] === 5) && firstToFive) {
                                    firstToFive = false;
                                    whoToReceiveIsNormal = !whoToReceiveIsNormal;
                                }

                        let player = '0';
                        if (strikeId === 0) { // 每一回合的发球员
                            if (rallyId === 0) { // 每一局的第一发球员（用户指定）
                                player = strike.HitPlayer[1];
                                if (player !== '0' && player !== '1') player = '0';
                            } else { // 除每局第一回合外，每一回合的发球员（系统计算）
                                const preStarter = game.list[rallyId - 1].list[0].HitPlayer;
                                if (side === +preStarter[0]) player = preStarter[1];
                                else player = this.getData('WhoToReceive')(preStarter, whoToReceiveIsNormal)[1];
                            }
                        } else if (strikeId === 1) { // 每一回合的接发员
                            if (gameId === 0 && rallyId === 0) { // 第一局的第一接发员（用户指定）
                                player = strike.HitPlayer[1];
                                if (player !== '0' && player !== '1') player = '0';
                                whoToReceiveIsNormal = this.getData('IsNormalOrderToReceive')(rally.list[0].HitPlayer, `${side}${player}`);
                            } else { // 除第一局第一回合，每一回合的接发员（系统计算）
                                player = this.getData('WhoToReceive')(rally.list[0].HitPlayer, whoToReceiveIsNormal)[1];
                            }
                        } else if (strikeId > 1) { // 每一回合交替击球（系统计算）
                            player = rally.list[strikeId % 2].HitPlayer[1];
                            if ((strikeId - strikeId % 2) % 4 === 2) player = 1 - player;
                        }

                        strike.HitPlayer = `${side}${player}`;
                    });
                });
            });
        }
    }

    //endregion

    //region Export system
    exportCheck() {
        let isReady = true;
        this.data.record.list.forEach(game => {
            if (game.structureLevel === 2) isReady = false;
            if (game.termLevel === 2) isReady = false;
        });
        return isReady;
    }

    exportCsvData() {
        // filename
        let filename = this.data.match.video;
        const index = filename.lastIndexOf('.');
        filename = filename.substr(0, index);
        // convert to string
        let fields = [
            "MatchNo",
            "GameNo",
            "RallyNo",
            "StrikeNo",
            "GameScoreA",
            "GameScoreB",
            "RallyScoreA",
            "RallyScoreB",
            "HitPlayer",
            "BallPosition",
            "NextBallPosition",
            "StrikeTech",
            "NextStrikeTech",
            "StrikePosition",
            "NextStrikePosition",
        ];
        if (this.getData('PlayerNum') === 2)
            fields = fields.concat([
                "PlayerPositionAl0",
                "NextPlayerPositionAl0",
                "PlayerPositionAl1",
                "NextPlayerPositionAl1",
                "PlayerPositionOp0",
                "NextPlayerPositionOp0",
                "PlayerPositionOp1",
                "NextPlayerPositionOp1"
            ])
        fields = fields.concat([
            "GameAction",
            "NextGameAction",
            "StrikeEffect",
            "NextStrikeEffect",
            "SpinKind",
            "NextSpinKind",
            "StartTime",
            "EndTime",
            "LeftHand"
        ]);
        let tempData = {
            fields,
            data: [],
        };
        this.data.record.list.forEach((game, gameId) => {
            game.list.forEach((rally, rallyId) => {
                rally.list.forEach((strike, strikeId) => {
                    const next = strikeId === rally.list.length - 1 ? null : rally.list[strikeId + 1];
                    let bp = strike.BallPosition.value, sp = strike.StrikePosition.value;
                    let player = this.getData(`Player${strike.HitPlayer}`);
                    if (!player.rightHand) {
                        if (bp.startsWith('B')) bp = `F${bp[1]}`;
                        else if (bp.startsWith('F')) bp = `B${bp[1]}`;
                        else if (bp === 'S1') bp = 'S2';
                        else if (bp === 'S2') bp = 'S1';

                        switch (sp) {
                            case 'F':
                                sp = 'B';
                                break;
                            case 'B':
                                sp = 'F';
                                break;
                            case 'P':
                                sp = 'T';
                                break;
                            case 'T':
                                sp = 'P';
                                break;
                            case 'S1':
                                sp = 'S2';
                                break;
                            case 'S2':
                                sp = 'S1';
                                break;
                            default:
                        }
                    }
                    let nbp = next === null ? "SC" : next.BallPosition.value,
                        nsp = next === null ? "SC" : next.StrikePosition.value;
                    if (next !== null) {
                        player = this.getData(`Player${next.HitPlayer}`);
                        if (!player.rightHand) {
                            if (nbp.startsWith('B')) nbp = `F${nbp[1]}`;
                            else if (nbp.startsWith('F')) nbp = `B${nbp[1]}`;
                            else if (nbp === 'S1') nbp = 'S2';
                            else if (nbp === 'S2') nbp = 'S1';

                            switch (nsp) {
                                case 'F':
                                    nsp = 'B';
                                    break;
                                case 'B':
                                    nsp = 'F';
                                    break;
                                case 'P':
                                    nsp = 'T';
                                    break;
                                case 'T':
                                    nsp = 'P';
                                    break;
                                case 'S1':
                                    nsp = 'S2';
                                    break;
                                case 'S2':
                                    nsp = 'S1';
                                    break;
                                default:
                            }
                        }
                    }

                    const d = {
                        "MatchNo": filename,
                        "GameNo": gameId + 1,
                        "RallyNo": rallyId + 1,
                        "StrikeNo": strikeId + 1,
                        "GameScoreA": game.score[0],
                        "GameScoreB": game.score[1],
                        "RallyScoreA": rally.score[0],
                        "RallyScoreB": rally.score[1],
                        "HitPlayer": this.data.player[strike.HitPlayer[0]][strike.HitPlayer[1]].name,
                        "BallPosition": this.getData("BallPositionCode")(bp, strike.HitPlayer[0]),
                        "StrikePosition": this.getData("StrikePositionCode")(sp, strike.HitPlayer[0]),
                        "StrikeTech": this.getData("StrikeTechCode")(strike.StrikeTech.value, strike.HitPlayer[0]),
                        "GameAction": this.getData("GameActionCode")(strike.GameAction.value, strike.HitPlayer[0]),
                        "StrikeEffect": this.getData("StrikeEffectCode")(strike.StrikeEffect.value, strike.HitPlayer[0]),
                        "SpinKind": this.getData("SpinKindCode")(strike.SpinKind.value, strike.HitPlayer[0]),
                        "NextBallPosition": this.getData("BallPositionCode")(nbp, 1 - strike.HitPlayer[0]),
                        "NextStrikePosition": this.getData("StrikePositionCode")(nsp, 1 - strike.HitPlayer[0]),
                        "NextStrikeTech": this.getData("StrikeTechCode")(next === null ? "SC" : next.StrikeTech.value, 1 - strike.HitPlayer[0]),
                        "NextGameAction": this.getData("GameActionCode")(next === null ? "SC" : next.GameAction.value, 1 - strike.HitPlayer[0]),
                        "NextStrikeEffect": this.getData("StrikeEffectCode")(next === null ? "SC" : next.StrikeEffect.value, 1 - strike.HitPlayer[0]),
                        "NextSpinKind": this.getData("SpinKindCode")(next === null ? "SC" : next.SpinKind.value, 1 - strike.HitPlayer[0]),
                        "StartTime": rally.startTime,
                        "EndTime": rally.endTime,
                        "LeftHand": !this.data.player[strike.HitPlayer[0]][strike.HitPlayer[1]].rightHand,
                    };
                    if (this.getData('PlayerNum') === 2)
                        Object.assign(d, {
                            // TODO: player position info
                        });
                    tempData.data.push(d);
                });
            });
        });
        const text = Papa.unparse(tempData);
        // generate blob
        fetch(`${this.service}/data/ttdc/${filename}/data.csv`, {
            method: 'POST',
            body: text,
        }).then(res => res.text())
            .then(res => alert('导出CSV数据：' + res));
    }

    exportJsonData() {
        // filename
        let filename = this.data.match.video;
        const index = filename.lastIndexOf('.');
        filename = this.data.record.MatchNo || filename.substr(0, index);
        // convert to string
        const text = JSON.stringify({
            match: this.data.match,
            player: this.data.player,
            record: {
                MatchNo: filename,
                result: this.data.record.result,
                list: this.data.record.list.map((game, gameId) => {
                    return {
                        GameNo: gameId + 1,
                        result: game.result,
                        score: game.score,
                        list: game.list.map((rally, rallyId) => {
                            return {
                                RallyNo: rallyId + 1,
                                startTime: rally.startTime,
                                endTime: rally.endTime,
                                startSide: rally.startSide !== 1 ? 0 : 1,
                                winSide: rally.winSide,
                                score: rally.score,
                                list: rally.list.map((strike, strikeId) => {
                                    const value = {
                                        PatTime: strike.PatTime,
                                        PatPos: strike.PatPos,
                                        PatVisible: strike.PatVisible,
                                        StrikeNo: strikeId + 1,
                                        BallPosition: strike.BallPosition.value,
                                        StrikePosition: strike.StrikePosition.value,
                                        StrikeTech: strike.StrikeTech.value,
                                        GameAction: strike.GameAction.value,
                                        StrikeEffect: [1, 2, 3, 4, 5].includes(strike.StrikeEffect.value) ? `L${strike.StrikeEffect.value}` : strike.StrikeEffect.value,
                                        SpinKind: strike.SpinKind.value,
                                        HitPlayer: strike.HitPlayer,
                                    };
                                    if (this.getData('PlayerNum') === 2)
                                        Object.assign(value, {
                                            PlayerPositionAl0: strike.PlayerPositionAl0.value,
                                            PlayerPositionAl1: strike.PlayerPositionAl1.value,
                                            PlayerPositionOp0: strike.PlayerPositionOp0.value,
                                            PlayerPositionOp1: strike.PlayerPositionOp1.value,
                                        });
                                    return value;
                                })
                            };
                        })
                    };
                })
            }
        });
        // generate blob
        fetch(`${this.service}/data/ttdc/${filename}/data.json`, {
            method: 'POST',
            body: text,
        }).then(res => res.text())
            .then(res => alert('导出JSON数据：' + res));
    }

    //endregion

    //region Player
    initPlayer(num) {
        this.data.player = [[], []];
        for (let side = 0; side < 2; side++)
            for (let i = 0; i < num; i++) {
                this.data.player[side][i] = {
                    name: "",
                    country: "",
                    rank: "",
                    rightHand: true,
                    isStraight: false,
                    face: [1, 1],
                }
            }
    }

    //endregion

    //region Video
    parseVideoName(name) {
        const parseWord = (str, start, step) => {
            const split = [" ", "\t"];
            let i = start;
            while (i >= 0 && i < str.length && split.indexOf(str[i]) !== -1) i += step;
            const id1 = i;
            while (i >= 0 && i < str.length && split.indexOf(str[i]) === -1) i += step;
            const id2 = i - step;
            const min = id1 < id2 ? id1 : id2;

            return str.substring(min, id1 + id2 - min + 1);
        };

        const year = parseInt(name.substr(0, 4), 10);
        const month = parseInt(name.substr(4, 2), 10);
        if (!isNaN(year)) this.handleChange("MatchYear")({target: {value: year}});
        if (!isNaN(month) && month >= 1 && month <= 12) this.handleChange("MatchMonth")({target: {value: month}});

        const types = this.getData("MatchTypes");
        const competitions = this.getData("MatchCompetitions");
        const rounds = this.getData("MatchRounds");
        [types, rounds].forEach(arr => arr.splice(arr.length - 1));
        for (const type of types) {
            const index = name.indexOf(this.languageSwitch(type));
            if (index !== -1) {
                this.handleChange("MatchLocation")({target: {value: parseWord(name, index - 1, -1)}});
                this.handleChange("MatchType")({target: {value: type}});
                break;
            }
        }
        for (const [i, competition] of competitions.entries()) {
            if (name.indexOf(this.languageSwitch(competition)) !== -1) {
                this.handleChange("MatchCompetition")({target: {value: competition}});
            }
        }
        for (const [i, round] of rounds.entries()) {
            if (name.indexOf(this.languageSwitch(round)) !== -1)
                this.handleChange("MatchRound")({target: {value: round}});
        }
    }

    handleVideo(video) {
        this.initData();

        this.data.match.video = video.name;
        this.data.record.MatchNo = video.name.substr(0, video.name.lastIndexOf('.'));
        if (this.videoSrc !== null) URL.revokeObjectURL(this.videoSrc);
        this.videoSrc = URL.createObjectURL(video);
        this.videoFile = video;
        fetch(`${this.service}/data/ttdc/${video.name.substring(0, video.name.lastIndexOf('.'))}/video.mp4`, {
            method: 'POST',
            body: video
        })
            .then(res => res.text())
            .then(res => console.log(res))
            .catch(e => console.error(e));
        this.parseVideoName(video.name);
        this.refreshPage("matchInfo");
        document.getElementById('video-player').load();
    }

    dropVideo(e) {
        e.preventDefault();
        var fileList = e.dataTransfer.files;

        if (fileList.length === 0) return;

        if (fileList[0].type.indexOf('video') === -1) {
            alert("您拖的不是视频！");
            return;
        }

        this.handleVideo(fileList[0]);
    }

    chooseVideo() {
        document.getElementById("file-importer").onchange = () => {
            const file = Store.loadFile();
            if (!file)
                return;

            if (file.type.indexOf('video') === -1) {
                alert("您选择的不是视频！");
                return;
            }

            this.handleVideo(file);
        };
        document.getElementById("file-importer").click();
    }

    setOnlineVideoSrc = (videoSrc) => {
        this.videoSrc = videoSrc;
        this.refreshPage('video');
        this.refreshPage('videoSrc');
    }
    //endregion

    //region Refresh System
    refreshPage(key) {
        const component = this.refreshList[key];
        if (!!component) {
            component.setState({
                needRefresh: !component.state.needRefresh,
            });
        }
    }

    register(refresh) {
        [
            "app", "matchInfo", "matchTree", "rallyInfo", "strikePosition", "strikeTech",
            "strikeAdditional", 'video', 'videoSrc', 'matchList', 'videoList', 'statistics',
            "autoDetection"
        ].forEach(key => {
            if (refresh.hasOwnProperty(key) && refresh[key] instanceof Component) {
                this.refreshList[key] = refresh[key] || this.refreshList[key];
                const component = this.refreshList[key];
                if (component !== null) {
                    component.setState({
                        needRefresh: false,
                    });
                }
            }
        });
    }

    unregister(refresh) {
        [
            "app", "matchInfo", "matchTree", "rallyInfo", "strikePosition", "strikeTech",
            "strikeAdditional", 'video', 'videoSrc', 'matchList', 'videoList', 'statistics',
            "autoDetection",
        ].forEach(key => {
            if (this.refreshList[key] === refresh[key]) this.refreshList[key] = null;
        });
    }

    //endregion

    //region Temp System
    LoadTemp() {
        if (this.refreshList.matchList)
            this.refreshList.matchList.handleOpen();
    }

    openTemp(match) {
        fetch(`${this.service}/data/ttdc/${match}/collect_project.json`)
            .then(res => res.json())
            .then(res => {
                this.handleChange('LoadProject')({res, match});
            })
            .catch(e => {
                console.error(e);
                alert(this.languageSwitch('FailOpenProject'));
            });
        fetch(`${this.service}/data/ttdc/${match}/video.mp4`)
            .then(res => res.blob())
            .then(video => {
                if (video.type === 'text/html') throw new Error('FailOpen');
                this.videoFile = new File([video], `${match}.mp4`);
                if (this.videoSrc !== null) URL.revokeObjectURL(this.videoSrc);
                this.videoSrc = URL.createObjectURL(video);
                this.refreshPage('videoSrc');
                this.refreshPage('autoDetection');
                const videoPlayer = document.getElementById('video-player');
                videoPlayer && videoPlayer.load();
            });
    }

    SaveTemp() {
        if (!this.hasModified) return;
        // filename
        let filename = this.data.record.MatchNo;
        if (filename === null) return;
        // convert to string
        const text = JSON.stringify({
            data: this.data,
            focus: this.focus,
            page: this.refreshList.app !== null ? this.refreshList.app.state.lastPage : 1,
            lang: this.lang,
            userEventCollections: this.userEventCollections,
        });
        // save
        fetch(`${this.service}/data/ttdc/${filename}/collect_project.json`, {
            method: 'POST',
            body: text,
        }).then(res => res.text())
            .then(res => {
                console.log(res);
                this.hasModified = false;
            })
            .catch(e => {
                console.error(e);
            });
    }

    //endregion

    handleChange = key => event => {
        switch (key) {
            case "LoadProject": {
                const {res, match} = event;
                this.data = res.data;
                if (!isNaN(this.data.match.type))
                    this.data.match.type = oldMatchTypes[this.data.match.type];
                if (!isNaN(this.data.match.competition))
                    this.data.match.competition = oldMatchCompetitions[this.data.match.competition];
                if (!isNaN(this.data.match.round))
                    this.data.match.round = oldMatchRounds[this.data.match.round];
                this.data.record.list.forEach(game => {
                    game.list.forEach(rally => {
                        rally.list.forEach(strike => {
                            if (strike.hasOwnProperty('PatTime')) return;
                            strike.PatTime = -1;
                            strike.PatPos = [0, 0];
                            strike.PatVisible = false;
                        })
                    })
                });
                if (this.data.player[0].length === 2) {
                    this.data.record.list.forEach(game => {
                        game.list.forEach(rally => {
                            rally.list.forEach(strike => {
                                if (strike.hasOwnProperty('PlayerPositionAl0')) return;
                                strike.PlayerPositionAl0 = this.getData('InitPlayerPosition');
                                strike.PlayerPositionAl1 = this.getData('InitPlayerPosition');
                                strike.PlayerPositionOp0 = this.getData('InitPlayerPosition');
                                strike.PlayerPositionOp1 = this.getData('InitPlayerPosition');
                            })
                        })
                    })
                }
                this.data.record.MatchNo = match;
                this.focus = res.focus;
                this.lang = !res.lang ? 'en' : res.lang;
                if (this.refreshList.app !== null) this.refreshList.app.setState({
                    page: (res.page !== pages.AUTO_DETECTION) ? res.page : pages.INFO,
                });
                if (res.userEventCollections) this.userEventCollections = res.userEventCollections;
                else this.userEventCollections = [];
                this.hasModified = false;
                this.setPosition = false;
                this.videoList = [];
                this.statisticVideoList = [];
                break;
            }
            case "MergeAutoDetectionData": {
                const games = event;
                this.data.record.list = games;
                const lastGame = games[games.length - 1];
                this.data.record.result = [...lastGame.score];
                this.data.record.result[(lastGame.result[0] > lastGame.result[1]) ? 0 : 1]++;
                if (this.refreshList.app !== null) this.refreshList.app.setState({
                    page: pages.LOCATE,
                });
                return;
            }
            case "ChangeLanguage": {
                if (this.getData('SupportedLanguages').includes(event))
                    this.lang = event;
                break;
            }
            case "MatchYear": {
                this.data.match.time[0] = event.target.value;
                this.refreshPage("matchInfo");
                this.hasModified = true;
                break;
            }
            case "MatchMonth": {
                this.data.match.time[1] = event.target.value;
                this.refreshPage("matchInfo");
                this.hasModified = true;
                break;
            }
            case "MatchLocation": {
                this.data.match.location = event.target.value;
                this.refreshPage("matchInfo");
                this.hasModified = true;
                break;
            }
            case "MatchType": {
                this.data.match.type = event.target.value;
                this.refreshPage("matchInfo");
                this.hasModified = true;
                break;
            }
            case "MatchCompetition": {
                let value = event.target.value;
                if (!isNaN(value)) value = this.getData("MatchCompetitions")[value];
                this.data.match.competition = value;
                let playerNum = 1;
                if (value.endsWith('D')) playerNum = 2;
                this.initPlayer(playerNum);
                this.refreshPage("matchInfo");
                this.hasModified = true;
                break;
            }
            case "MatchRound": {
                this.data.match.round = event.target.value;
                this.refreshPage("matchInfo");
                this.hasModified = true;
                break;
            }
            case "MatchTreeAdd": {
                if (this.focus.stage === 'match') {
                    this.data.record.list.push(this.getData('NewGame'));
                    this.focus.game = this.data.record.list.length - 1;
                    this.focus.rally = -1;
                    this.focus.strike = -1;
                } else {
                    this.recalculateTerm();
                    this.data.record.list[this.focus.game].list.push(this.getData('NewRally'));
                    this.focus.rally = this.data.record.list[this.focus.game].list.length - 1;
                    this.focus.strike = -1;
                }
                this.recalculateStartSide();
                this.recalculateWinSide();
                this.recalculateStructureLevel();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                break;
            }
            case "MatchTreeDelete": {
                if (this.focus.stage === 'match') {
                    this.data.record.list.pop();
                    if (this.focus.game >= this.data.record.list.length) {
                        this.focus.game = this.data.record.list.length - 1;
                        this.focus.rally = -1;
                        this.focus.strike = -1;
                    }
                } else {
                    this.data.record.list[this.focus.game].list.pop();
                    if (this.focus.rally >= this.data.record.list[this.focus.game].list.length) {
                        this.focus.rally = this.data.record.list[this.focus.game].list.length - 1;
                        this.focus.strike = -1;
                    }
                }
                this.recalculateStartSide();
                this.recalculateWinSide();
                this.recalculateStructureLevel();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                break;
            }
            case "NextRally": {
                this.recalculateTerm();
                this.focus.stage = 'game';
                if (this.focus.game === -1) this.focus.game = this.data.record.list.length - 1;
                if (this.focus.game === -1 || this.getData('CanGameEnd')(this.data.record.list[this.focus.game].result)) {
                    if (this.focus.game !== -1 && this.focus.rally < this.data.record.list[this.focus.game].list.length - 1) {
                        this.focus.rally++;
                    } else {
                        if (this.focus.game === this.data.record.list.length - 1) {
                            this.data.record.list.push(this.getData('NewGame'));
                            this.focus.game = this.data.record.list.length - 1;
                        } else this.focus.game++;

                        if (this.data.record.list[this.focus.game].list.length === 0)
                            this.data.record.list[this.focus.game].list.push(this.getData('NewRally'));
                        this.focus.rally = 0;
                    }
                } else {
                    const game = this.data.record.list[this.focus.game];
                    if (this.focus.rally === game.list.length - 1) {
                        game.list.push({
                            startTime: 0,
                            endTime: 0,
                            rallyLength: 0,
                            startSide: -1,
                            winSide: -1,
                            score: [0, 0],
                            structureLevel: 2,
                            termLevel: 2,
                            list: [],
                        });
                        this.focus.rally = game.list.length - 1;
                    } else this.focus.rally++;
                }
                this.focus.strike = -1;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'next_rally',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
                this.recalculateStartSide();
                this.recalculateWinSide();
                this.recalculateStructureLevel();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                break;
            }
            case "RallyStarter": {
                if (this.focus.stage === 'game')
                    if (this.focus.game === 0 && this.focus.rally === 0) {
                        this.data.record.list[0].list[0].startSide = event.target.checked ? 1 : 0;
                        this.recalculateStartSide();
                        this.recalculateWinSide();
                        this.recalculateStructureLevel();
                        this.refreshPage("matchTree");
                        this.refreshPage("rallyInfo");
                    }
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'rally_starter',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
                break;
            }
            case "RallyLength": {
                if (this.focus.stage === 'game') {
                    const rally = this.data.record.list[this.focus.game].list[this.focus.rally];
                    if (!rally) return;
                    const newValue = parseInt(event.target.value, 10);
                    if (!isNaN(newValue)) {
                        rally.rallyLength = newValue;
                        if (rally.rallyLength > rally.list.length)
                            while (rally.rallyLength !== rally.list.length)
                                rally.list.push(null);
                        else if (rally.rallyLength < rally.list.length) rally.list.splice(rally.rallyLength);
                        rally.list.forEach((strike, strikeId) => {
                            if (strike === null)
                                rally.list[strikeId] = this.getData('NewStrike')(strikeId)
                        });
                        this.recalculateWinSide();
                        this.recalculateStructureLevel();
                        this.refreshPage("matchTree");
                        this.refreshPage("rallyInfo");
                    }
                }
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'rally_length',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
                break;
            }
            case "RallyLengthAdd": {
                if (this.focus.stage === 'game') {
                    const rally = this.data.record.list[this.focus.game].list[this.focus.rally];
                    if (!rally) return;
                    rally.rallyLength = rally.rallyLength * 10 + event.target.value;
                    if (rally.rallyLength > rally.list.length)
                        while (rally.rallyLength !== rally.list.length)
                            rally.list.push(null);
                    else if (rally.rallyLength < rally.list.length) rally.list.splice(rally.rallyLength);
                    rally.list.forEach((strike, strikeId) => {
                        if (strike === null)
                            rally.list[strikeId] = this.getData('NewStrike')(strikeId)
                    });
                    this.recalculateWinSide();
                    this.recalculateStructureLevel();
                    this.refreshPage("matchTree");
                    this.refreshPage("rallyInfo");
                }
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'rally_length',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
                break;
            }
            case "RallyLengthBackspace": {
                if (this.focus.stage === 'game') {
                    const rally = this.data.record.list[this.focus.game].list[this.focus.rally];
                    if (!rally) return;
                    rally.rallyLength = parseInt(rally.rallyLength / 10, 10);
                    if (rally.rallyLength > rally.list.length)
                        while (rally.rallyLength !== rally.list.length)
                            rally.list.push(null);
                    else if (rally.rallyLength < rally.list.length) rally.list.splice(rally.rallyLength);
                    rally.list.forEach((strike, strikeId) => {
                        if (strike === null)
                            rally.list[strikeId] = this.getData('NewStrike')(strikeId)
                    });
                    this.recalculateWinSide();
                    this.recalculateStructureLevel();
                    this.refreshPage("matchTree");
                    this.refreshPage("rallyInfo");
                }
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'rally_length',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
                break;
            }
            case "TimeLock":
                this.timeLock = event;
                this.refreshPage("rallyInfo");
                break;
            case "RallyStart": {
                const video = document.getElementById('video-player');
                if (!video) return;
                if (this.focus.stage !== 'game') return;
                const focusedRally = this.data.record.list[this.focus.game].list[this.focus.rally];
                if (!focusedRally) return;

                const offsetTime = video.currentTime - focusedRally.startTime;
                focusedRally.startTime = video.currentTime;
                console.log(this.timeLock);
                if (this.timeLock) {
                    focusedRally.startTime -= offsetTime;
                    for (let gId = this.focus.game; gId < this.data.record.list.length; gId++) {
                        const game = this.data.record.list[gId];
                        for (let rId = 0; rId < game.list.length; rId++) {
                            if (gId === this.focus.game && rId < this.focus.rally) continue;
                            const rally = game.list[rId];
                            rally.startTime += offsetTime;
                            rally.endTime += offsetTime;
                        }
                    }
                }

                this.recalculateStructureLevel();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'rally_start',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
                this.hasModified = true;
            }
                break;
            case "RallyEnd": {
                const video = document.getElementById('video-player');
                if (!video) return;
                if (this.focus.stage !== 'game') return;
                const focusedRally = this.data.record.list[this.focus.game].list[this.focus.rally];
                if (!focusedRally) return;

                const offsetTime = video.currentTime - focusedRally.endTime;
                focusedRally.endTime = video.currentTime;
                if (this.timeLock) {
                    for (let gId = this.focus.game; gId < this.data.record.list.length; gId++) {
                        const game = this.data.record.list[gId];
                        for (let rId = 0; rId < game.list.length; rId++) {
                            if (gId === this.focus.game && rId <= this.focus.rally) continue;
                            const rally = game.list[rId];
                            rally.startTime += offsetTime;
                            rally.endTime += offsetTime;
                        }
                    }
                }

                this.recalculateStructureLevel();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'rally_end',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
            }
                break;
            case "VideoPlay": {
                const video = document.getElementById('video-player');
                if (!video) return;
                if (video.paused) {
                    video.play();
                    if (this.refreshList.video !== null) this.refreshList.video.setState({play: true});
                }
            }
                break;
            case "VideoPause": {
                const video = document.getElementById('video-player');
                if (!video) return;
                if (!video.paused) {
                    video.pause();
                    if (this.refreshList.video !== null) this.refreshList.video.setState({play: false});
                }
            }
                break;
            case "VideoPlayPause": {
                const video = document.getElementById('video-player');
                if (!video) return;
                if (video.paused) {
                    video.play();
                    if (this.refreshList.video !== null) this.refreshList.video.setState({play: true});
                } else {
                    video.pause();
                    if (this.refreshList.video !== null) this.refreshList.video.setState({play: false});
                }
            }
                break;
            case "VideoPlayBack": {
                const video = document.getElementById('video-player');
                if (!video) return;
                video.currentTime = video.currentTime - 3;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'video_back',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
            }
                break;
            case "VideoPlayFront": {
                const video = document.getElementById('video-player');
                if (!video) return;
                video.currentTime = video.currentTime + 3;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'video_front',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
            }
                break;
            case "VideoPlayPreFrame": {
                const video = document.getElementById('video-player');
                if (!video) return;
                video.pause();
                video.currentTime = video.currentTime - 1 / 25;
                if (this.refreshList.video !== null) this.refreshList.video.setState({play: false});
                this.refreshPage("videoSrc");
            }
                break;
            case "VideoPlayNextFrame": {
                const video = document.getElementById('video-player');
                if (!video) return;
                video.pause();
                video.currentTime = video.currentTime + 1 / 25;
                if (this.refreshList.video !== null) this.refreshList.video.setState({play: false});
                this.refreshPage("videoSrc");
            }
                break;
            case "VideoSpeedUp": {
                const video = document.getElementById('video-player');
                if (!video) return;
                const rate = (video.playbackRate + 0.2).toFixed(1);
                if (rate < 3) {
                    video.playbackRate = rate;
                    this.refreshList.video.setState({rate: rate});
                }
            }
                break;
            case "VideoSpeedDown": {
                const video = document.getElementById('video-player');
                if (!video) return;
                const rate = (video.playbackRate - 0.2).toFixed(1);
                if (rate > 0) {
                    video.playbackRate = rate;
                    this.refreshList.video.setState({rate: rate});
                }
            }
                break;
            case "VideoReplay": {
                const video = document.getElementById('video-player');
                if (!video) return;
                let time = 0;
                const gameId = this.focus.game;
                if (gameId > -1 && gameId < this.data.record.list.length) {
                    const game = this.data.record.list[gameId];
                    const rallyId = this.focus.rally;
                    if (rallyId > -1 && rallyId < game.list.length) {
                        const rally = game.list[rallyId];
                        if (rally.startTime !== 0) time = rally.startTime;
                        else {
                            const lastGameId = rallyId === 0 ? gameId - 1 : gameId;
                            if (lastGameId > -1 && lastGameId < this.data.record.list.length) {
                                const lastGame = this.data.record.list[lastGameId];
                                const lastRallyId = rallyId === 0 ? lastGame.list.length - 1 : rallyId - 1;
                                if (lastRallyId > -1 && lastRallyId < lastGame.list.length) {
                                    const lastRally = lastGame.list[lastRallyId];
                                    if (lastRally.endTime !== 0) time = lastRally.endTime;
                                }
                            }
                        }
                    }
                }
                video.currentTime = time;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'video_replay',
                    game: this.focus.game,
                    rally: this.focus.rally,
                });
            }
                break;
            case "VideoPlayStrike": {
                const video = document.getElementById('video-player');
                if (!video) return;
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                video.currentTime = strike.PatTime;
                break;
            }
            case "SetPat": {
                if (event.x < 0 || event.x >= 1 || event.y < 0 || event.y >= 1) return;
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                if (strike.PatTime !== -1)
                    if (!window.confirm("此拍已记录时间与位置，是否清除？")) break;
                strike.PatPos = [event.x, event.y];
                strike.PatTime = event.time;
                strike.PatVisible = event.visible;
                this.handleChange('StrikeNext')();
                this.hasModified = true;
                break;
            }
            case "BallPosition": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                strike.BallPosition.value = event;
                if (event === "S1" || event === "S2") strike.StrikePosition.value = event;
                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'ball_position',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "StrikePosition": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                strike.StrikePosition.value = event;
                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'strike_position',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "StrikeTech": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                strike.StrikeTech.value = event;
                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'strike_tech',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "GameAction": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                if (this.getData("AllGameAction").includes(event.target.value) && !strike.GameAction.impossible.includes(event.target.value)) {
                    strike.GameAction.value = event.target.value;
                    strike.GameAction.termLevel = 1;
                }
                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'game_action',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "StrikeEffect": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                if (this.getData("AllStrikeEffect").includes(event.target.value) && !strike.StrikeEffect.impossible.includes(event.target.value)) {
                    strike.StrikeEffect.value = event.target.value;
                    strike.StrikeEffect.termLevel = 1;
                }
                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'strike_effect',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "SpinKind": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                if (this.getData("AllSpinKind").includes(event.target.value) && !strike.SpinKind.impossible.includes(event.target.value)) {
                    strike.SpinKind.value = event.target.value;
                    strike.SpinKind.termLevel = 1;
                }
                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'spin_kind',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "PlayerPosition": {
                const {side, position} = event;
                if (side !== 'Al' && side !== 'Op') return;
                if (!this.getData("AllPlayerPosition").includes(position)) return;

                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;

                const pp = [strike[`PlayerPosition${side}0`], strike[`PlayerPosition${side}1`]];
                if (pp[0].value === position) {
                    pp[0].value = '';
                    pp[1].value = position;
                } else if (pp[1].value === position) {
                    pp[1].value = '';
                } else {
                    if (!pp[0].value) pp[0].value = position;
                    else if (!pp[1].value) pp[1].value = position;
                    else pp[0].value = position;
                }

                this.recalculateTerm();
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'player_position',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
                break;
            }
            case "StrikePlayer": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return;
                strike.HitPlayer = `${strike.HitPlayer[0]}${event}`;
                this.recalculatePlayer();
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.hasModified = true;
                this.userEventCollections.push({
                    time: new Date().getTime(),
                    event: 'strike_player',
                    game: this.focus.game,
                    rally: this.focus.rally,
                    strike: this.focus.strike,
                });
            }
                break;
            case "StrikeBack": {
                if (this.focus.game !== -1 && this.focus.rally !== -1) {
                    this.focus.strike--;
                    if (this.focus.strike < 0) {
                        this.recalculateTerm();
                        this.focus.rally--;
                        if (this.focus.rally < 0) {
                            this.focus.game--;
                            if (this.focus.game < 0) {
                                this.focus.game = 0;
                                this.focus.rally = 0;
                                this.focus.strike = 0;
                                return;
                            }
                            this.focus.rally = this.data.record.list[this.focus.game].list.length - 1;
                        }
                        this.focus.strike = this.data.record.list[this.focus.game].list[this.focus.rally].list.length - 1;
                    }
                }
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.refreshPage("videoSrc");
                this.hasModified = true;
                break;
            }
            case "StrikeNext": {
                if (this.focus.game !== -1 && this.focus.rally !== -1) {
                    this.focus.strike++;
                    if (this.focus.strike >= this.data.record.list[this.focus.game].list[this.focus.rally].list.length) {
                        this.recalculateTerm();
                        this.focus.rally++;
                        if (this.focus.rally >= this.data.record.list[this.focus.game].list.length) {
                            this.focus.game++;
                            if (this.focus.game >= this.data.record.list.length) {
                                this.focus.game = this.data.record.list.length - 1;
                                this.focus.rally = this.data.record.list[this.focus.game].list.length - 1;
                                this.focus.strike = this.data.record.list[this.focus.game].list[this.focus.rally].list.length;
                                return;
                            }
                            if (this.data.record.list[this.focus.game].list.length > 0) this.focus.rally = 0;
                            else {
                                this.focus.rally = -1;
                                this.focus.strike = -1;
                                return;
                            }
                        }
                        if (this.data.record.list[this.focus.game].list[this.focus.rally].list.length > 0) this.focus.strike = 0;
                        else this.focus.strike = -1;
                    }
                }
                this.refreshPage("matchTree");
                this.refreshPage("rallyInfo");
                this.refreshPage("strikePosition");
                this.refreshPage("strikeTech");
                this.refreshPage("strikeAdditional");
                this.refreshPage("videoSrc");
                this.hasModified = true;
                break;
            }
            case "VideoList": {
                this.videoList = event;
                this.refreshPage('videoList');
                break;
            }
            case "VideoIndex": {
                const isValidIdx = idx => {
                    return idx >= 0 && idx < this.videoList.length;
                };
                const {from: oriIdx, to: newIdx} = event;
                if (isValidIdx(oriIdx) && isValidIdx(newIdx)) {
                    const val = this.videoList[oriIdx];
                    this.videoList.splice(oriIdx, 1);
                    this.videoList.splice(newIdx, 0, val);
                }
                this.refreshPage('videoList');
                break;
            }
            case "DelExportVideo": {
                if (event >= 0 && event < this.videoList.length)
                    this.videoList.splice(event, 1);
                this.refreshPage('videoList');
                break;
            }
            case "ClearExportVideos": {
                this.videoList = [];
                this.refreshPage('videoList');
                break;
            }
            case "PlayAllExportVideos": {
                this.handleChange('StatisticsVideoList')(this.videoList.map(d => d));
                break;
            }
            case "PlayExportVideo": {
                this.handleChange('StatisticsVideoList')([this.videoList[event]]);
                break;
            }
            case "ResortExportVideos": {
                this.videoList.sort((a, b) => (a[0] === b[0]) ? (a[1] - b[1]) : (a[0] - b[0]));
                this.refreshPage('videoList');
                break;
            }
            case "ExportVideos": {
                let taskToken = null;
                const {callback, name, buffer_time} = event;
                const timer = setInterval(() => {
                    if (taskToken !== null)
                        fetch(`${this.service}/task_progress/${taskToken}`)
                            .then(res => res.json())
                            .then(res => {
                                if (callback instanceof Function)
                                    callback({
                                        status: res.status,
                                        info: res.info,
                                    });
                                if (res.status !== 'processing')
                                    clearInterval(timer);
                            })
                            .catch(() => {
                                if (callback instanceof Function)
                                    callback({
                                        status: 'failed',
                                        info: '无法连接到本地服务！',
                                    });
                                clearInterval(timer);
                            })
                }, 500);
                fetch(`${this.service}/task/ttdc/clipVideo`, {
                    method: 'POST',
                    body: JSON.stringify({
                        name,
                        list: this.videoList.map(idx => {
                            const rally = this.getData('RallyInfo')(idx);
                            if (rally)
                                return {
                                    Match_No: this.data.record.MatchNo,
                                    GameScore: rally.gameScore,
                                    RallyScore: rally.rallyScore,
                                    start: rally.startTime,
                                    end: rally.endTime,
                                };
                            else return null;
                        }).filter(d => d !== null),
                        buffer_time,
                    })
                }).then(res => res.json())
                    .then(res => {
                        if (res.status === 'failed') {
                            if (callback instanceof Function)
                                callback({
                                    status: 'failed',
                                    info: res.info,
                                });
                            clearInterval(timer);
                        } else if (res.status === 'succeed')
                            taskToken = res.info;
                    })
                    .catch(() => {
                        if (callback instanceof Function)
                            callback({
                                status: 'failed',
                                info: '无法连接到本地服务！',
                            })
                    });
                break;
            }
            case 'StatisticsVideoList': {
                this.statisticVideoList = event;
                this.refreshPage('statistics');
                break;
            }
            case "SwitchView": {
                if (this.getData('PlayerNum') === 2) {
                    this.setPosition = !this.setPosition;
                    this.refreshPage("strikePosition");
                }
                break;
            }
            case "LockPage": {
                if (!this.refreshList['app']) return;
                this.refreshList['app'].setState({lockedPage: event});
                break;
            }
            default:
                if (key.startsWith("Player")) {
                    const playerId = key.substr(6, 2);
                    const player = this.data.player[playerId[0]][playerId[1]];
                    if (key.endsWith("Name")) {
                        player.name = event.target.value;
                        const data = FindPlayers(player.name);
                        if (!!data) {
                            ['country', 'rightHand', 'isStraight', 'face'].forEach(key => {
                                if (data.hasOwnProperty(key))
                                    player[key] = data[key];
                            })
                        }
                    } else if (key.endsWith("Country")) player.country = event.target.value;
                    else if (key.endsWith("Rank")) player.rank = event.target.value;
                    else if (key.endsWith("Hand")) player.rightHand = event.target.checked;
                    else if (key.endsWith("Pad")) player.isStraight = event.target.checked;
                    else if (key.endsWith("BackFace")) player.face[0] = event.target.value;
                    else if (key.endsWith("ForeFace")) player.face[1] = event.target.value;
                    this.refreshPage("matchInfo");
                } else if (key.startsWith("FocusItem")) {
                    const itemId = parseInt(key.substring(9), 10);
                    if (isNaN(itemId)) {
                        if (key.substring(9) === 'Back') {
                            if (this.focus.stage === 'game') {
                                this.recalculateTerm();
                                this.focus.stage = 'match';
                                this.focus.rally = -1;
                                this.focus.strike = -1;
                            }
                        }
                    } else {
                        if (this.focus.stage === 'match') {
                            this.focus.stage = 'game';
                            this.focus.game = itemId;
                            this.focus.rally = -1;
                            this.focus.strike = -1;
                        } else if (this.focus.stage === 'game') {
                            this.recalculateTerm();
                            this.focus.rally = itemId;
                            this.focus.strike = -1;
                        }
                    }
                    this.refreshPage("matchTree");
                    this.refreshPage("rallyInfo");
                    this.refreshPage("strikePosition");
                    this.refreshPage("strikeTech");
                    this.refreshPage("strikeAdditional");
                } else if (key.startsWith("FocusStrike")) {
                    const itemId = parseInt(key.substring(11), 10);
                    if (!isNaN(itemId)) {
                        this.focus.strike = itemId;
                        this.refreshPage("rallyInfo");
                        this.refreshPage("strikePosition");
                        this.refreshPage("strikeTech");
                        this.refreshPage("strikeAdditional");
                    }
                }
                this.hasModified = true;
        }
    };

    getData = key => {
        switch (key) {
            case "InitPlayerPosition":
                return this.getData('PlayerNum') === 2 ? {
                    value: '',
                    termLevel: 2,
                    impossible: [],
                } : undefined;
            case "SupportedLanguages":
                return ['cn', 'en'];
            case "CurrentLanguage":
                return this.lang;
            case "MatchTypes":
                return allMatchTypes;
            case "MatchCompetitions":
                return allMatchCompetitions;
            case "MatchRounds":
                return allMatchRounds;
            case "FaceTypes":
                return allFaceTypes;
            case "VideoFile":
                return this.videoFile;
            case "Video":
                return this.videoSrc;
            case "PlayerNum":
                return this.data.player[0].length;
            case "Player00":
                return this.data.player[0][0];
            case "Player01":
                return this.data.player[0][1];
            case "Player10":
                return this.data.player[1][0];
            case "Player11":
                return this.data.player[1][1];
            case "PlayerNames":
                return this.data.player.map(side => side.map(player => player.name));
            case "CanSwitchPlayer": {
                if (this.data.player[0].length < 2) return false;

                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return false;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return false;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return false;
                if (this.focus.strike > 1) return false;
                if (this.focus.strike === 1) return this.focus.game === 0 && this.focus.rally === 0;
                else if (this.focus.strike === 0) return this.focus.rally === 0;
                else return false;
            }
            case "CanGameEnd":
                return score => {
                    if (score[0] <= 9 || score[1] <= 9) return score[0] === 11 || score[1] === 11;
                    else return score[0] - score[1] === 2 || score[1] - score[0] === 2;
                };
            case "NewGame":
                return {
                    result: [0, 0],
                    score: [0, 0],
                    structureLevel: 2,
                    termLevel: 2,
                    list: [],
                };
            case 'NewRally':
                return {
                    startTime: 0,
                    endTime: 0,
                    rallyLength: 0,
                    startSide: -1,
                    winSide: -1,
                    score: [0, 0],
                    structureLevel: 2,
                    termLevel: 2,
                    list: [],
                };
            case 'NewStrike':
                return strikeId => ({
                    index: strikeId,
                    termLevel: 2,
                    BallPosition: {
                        value: '',
                        termLevel: 2,
                        impossible: [],
                    },
                    StrikePosition: {
                        value: '',
                        termLevel: 2,
                        impossible: [],
                    },
                    StrikeTech: {
                        value: '',
                        termLevel: 2,
                        impossible: [],
                    },
                    GameAction: {
                        value: '',
                        termLevel: 2,
                        impossible: [],
                    },
                    StrikeEffect: {
                        value: '',
                        termLevel: 2,
                        impossible: [],
                    },
                    SpinKind: {
                        value: '',
                        termLevel: 2,
                        impossible: [],
                    },
                    PatTime: -1,
                    PatPos: [0, 0],
                    PatVisible: false,
                    PlayerPositionAl0: this.getData('InitPlayerPosition'),
                    PlayerPositionAl1: this.getData('InitPlayerPosition'),
                    PlayerPositionOp0: this.getData('InitPlayerPosition'),
                    PlayerPositionOp1: this.getData('InitPlayerPosition'),
                    HitPlayer: "",
                });
            case "IsNormalOrderToReceive":
                return (pre, next) => {
                    switch (pre) {
                        case '00':
                            return next === '10';
                        case '10':
                            return next === '01';
                        case '01':
                            return next === '11';
                        case '11':
                            return next === '00';
                        default:
                    }
                };
            case "WhoToReceive":
                return (pre, isNormal) => {
                    switch (pre) {
                        case '00':
                            return isNormal ? '10' : '11';
                        case '10':
                            return isNormal ? '01' : '00';
                        case '01':
                            return isNormal ? '11' : '10';
                        case '11':
                            return isNormal ? '00' : '01';
                        default:
                    }
                };
            case "CurrentPlayerID": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return "99";
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return "99";
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return "99";
                return strike.HitPlayer;
            }
            case "FocusList": {
                if (this.focus.stage === 'match')
                    return this.data.record.list.map((game, i) => {
                        return {
                            label: `${this.languageSwitch('GameNum')(i + 1)} ${game.score[0]} : ${game.score[1]}`,
                            structureLevel: game.structureLevel === 2 ? "Error" : (game.structureLevel === 1 ? "Warning" : "Complete"),
                            termLevel: game.termLevel === 2 ? "Error" : (game.termLevel === 1 ? "Warning" : "Complete"),
                        };
                    });
                else
                    return this.data.record.list[this.focus.game].list.map((rally, i) => {
                        return {
                            label: `${this.languageSwitch('RallyNum')(i + 1)} ${rally.score[0]} : ${rally.score[1]}`,
                            structureLevel: rally.structureLevel === 2 ? "Error" : (rally.structureLevel === 1 ? "Warning" : "Complete"),
                            termLevel: rally.termLevel === 2 ? "Error" : (rally.termLevel === 1 ? "Warning" : "Complete"),
                        }
                    });
            }
            case "StrikeList": {
                if (this.focus.game === -1 || this.focus.rally === -1) return null;
                const rally = this.data.record.list[this.focus.game].list[this.focus.rally];
                this.recalculateTerm();
                return rally.list.map(strike => {
                    const player = this.getData(`Player${strike.HitPlayer}`);
                    let ballPosition = strike.BallPosition.value;
                    let strikePosition = strike.StrikePosition.value;
                    if (!player.rightHand) {
                        if (ballPosition.startsWith('B')) ballPosition = `F${ballPosition[1]}`;
                        else if (ballPosition.startsWith('F')) ballPosition = `B${ballPosition[1]}`;
                        else if (ballPosition === 'S1') ballPosition = 'S2';
                        else if (ballPosition === 'S2') ballPosition = 'S1';

                        switch (strikePosition) {
                            case 'F':
                                strikePosition = 'B';
                                break;
                            case 'B':
                                strikePosition = 'F';
                                break;
                            case 'P':
                                strikePosition = 'T';
                                break;
                            case 'T':
                                strikePosition = 'P';
                                break;
                            case 'S1':
                                strikePosition = 'S2';
                                break;
                            case 'S2':
                                strikePosition = 'S1';
                                break;
                            default:
                        }
                    }
                    return {
                        termLevel: strike.termLevel === 2 ? "Error" : (strike.termLevel === 1 ? "Warning" : "Complete"),
                        BallPosition: {
                            label: this.languageSwitch(ballPosition),
                            level: strike.BallPosition.termLevel === 2 ? "Error" : (strike.BallPosition.termLevel === 1 ? "Warning" : "Complete")
                        },
                        StrikeTech: {
                            label: this.languageSwitch(strike.StrikeTech.value),
                            level: strike.StrikeTech.termLevel === 2 ? "Error" : (strike.StrikeTech.termLevel === 1 ? "Warning" : "Complete")
                        },
                        StrikePosition: {
                            label: this.languageSwitch(strikePosition),
                            level: strike.StrikePosition.termLevel === 2 ? "Error" : (strike.StrikePosition.termLevel === 1 ? "Warning" : "Complete")
                        },
                        GameAction: {
                            label: this.languageSwitch(strike.GameAction.value),
                            level: strike.GameAction.termLevel === 2 ? "Error" : (strike.GameAction.termLevel === 1 ? "Warning" : "Complete")
                        },
                        StrikeEffect: {
                            label: this.languageSwitch('L' + strike.StrikeEffect.value),
                            level: strike.StrikeEffect.termLevel === 2 ? "Error" : (strike.StrikeEffect.termLevel === 1 ? "Warning" : "Complete")
                        },
                        SpinKind: {
                            label: this.languageSwitch(strike.SpinKind.value),
                            level: strike.SpinKind.termLevel === 2 ? "Error" : (strike.SpinKind.termLevel === 1 ? "Warning" : "Complete")
                        },
                        PatTime: strike.PatTime,
                        HitPlayer: {
                            label: player.name
                        },
                    };
                });
            }
            case "FocusRally": {
                if (this.focus.stage === 'game' && this.focus.rally >= 0 && this.focus.rally < this.data.record.list[this.focus.game].list.length) {
                    const game = this.data.record.list[this.focus.game];
                    const rally = game.list[this.focus.rally];
                    return {
                        player: this.data.player.map(side => side.map(player => player.name).join(" / ")),
                        gameScore: game.score,
                        startTime: rally.startTime,
                        endTime: rally.endTime,
                        rallyScore: rally.score,
                        startSide: rally.startSide,
                        winSide: rally.winSide,
                        rallyLength: rally.rallyLength,
                        list: rally.list,
                    }
                } else return null;
            }
            case "MatchResult": {
                return this.data.record.result;
            }
            case "GameResult": {
                const game = this.data.record.list[this.focus.game];
                if (!game) return [0, 0];
                else return game.result;
            }
            case "AdditionalTerm": {
                const initReturn = {
                    GameAction: {
                        value: "",
                        possible: []
                    },
                    StrikeEffect: {
                        value: "",
                        possible: []
                    },
                    SpinKind: {
                        value: "",
                        possible: []
                    },
                };
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return initReturn;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return initReturn;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return initReturn;
                ["GameAction", "StrikeEffect", "SpinKind"].forEach(key => {
                    initReturn[key].possible = this.getData(`All${key}`).filter(value => !strike[key].impossible.includes(value));
                    initReturn[key].value = strike[key].value;
                    if (!initReturn[key].possible.includes(initReturn[key].value)) initReturn[key].value = "";
                });
                return initReturn;
            }
            case "CurrentBallPosition": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return null;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return null;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return null;
                return strike.BallPosition.value;
            }
            case "CurrentStrikePosition": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return null;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return null;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return null;
                return strike.StrikePosition.value;
            }
            case "CurrentStrikeTech": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return null;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return null;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return null;
                return strike.StrikeTech.value;
            }
            case "CurrentPlayerPosition": {
                const defaultValue = [[null, null], [null, null]];
                if (this.getData('PlayerNum') !== 2)
                    return defaultValue;
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return defaultValue;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return defaultValue;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return defaultValue;
                return [[
                    strike.PlayerPositionAl0.value,
                    strike.PlayerPositionAl1.value,
                ], [
                    strike.PlayerPositionOp0.value,
                    strike.PlayerPositionOp1.value,
                ]];
            }
            case "PatPos": {
                const player = document.getElementById('video-player');
                if (!player) return null;
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return null;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return null;

                const sameFrame = (t1, t2) => Math.abs(t1 - t2) < 1 / 20;

                for (const strike of rally.list) {
                    if (sameFrame(player.currentTime, strike.PatTime)) return {
                        x: strike.PatPos[0],
                        y: strike.PatPos[1],
                        visible: strike.PatVisible,
                    };
                }
                return null;
            }
            case "NextPlayerName": {
                return param => {
                    const {gId, rId, pId} = param;
                    if (this.getData('PlayerNum') !== 2) return pId[0] + '0';
                    const game = this.data.record.list[gId];
                    if (!game) return '';
                    const rally = game.list[rId];
                    if (!rally) return '';
                    const pattern = [];
                    for (let rally of game.list) {
                        if (rally.list.length >= 1) {
                            pattern.push(rally.list[0].HitPlayer);
                            pattern.push(rally.list[1].HitPlayer);
                            break;
                        }
                    }
                    if (pattern.length === 0) return '';
                    for (let i = 0; i < 2; i++)
                        pattern.push(`${pattern[i][0]}${1 - pattern[i][1]}`);
                    if (gId === 6 && (rally.score[0] >= 5 || rally.score[1] >= 5)) pattern.reverse();
                    for (let i = 0; i < 4; i++)
                        if (pattern[i] === pId)
                            return pattern[(i + 1) % 4];
                };
            }
            case 'PlayerNameByPlayerPosition': {
                return param => {
                    const {side, player, strike: redirect} = param;
                    if (side !== 0 && side !== 1) return '';
                    if (player !== 0 && player !== 1) return '';
                    const game = this.data.record.list[!redirect ? this.focus.game : redirect[0]];
                    if (game === undefined) return '';
                    const rally = game.list[!redirect ? this.focus.rally : redirect[1]];
                    if (rally === undefined) return '';
                    if (rally.list.length <= 0) return '';
                    const strike = rally.list[!redirect ? this.focus.strike : redirect[2]];
                    if (strike === undefined) return '';
                    if (!strike.HitPlayer) return '';
                    const currentPlayer = strike.HitPlayer;
                    let pId = '';
                    if (side === 0)
                        pId = `${currentPlayer[0]}${player === 0 ? currentPlayer[1] : (1 - currentPlayer[1])}`;
                    else
                        pId = this.getData('NextPlayerName')({
                            gId: !redirect ? this.focus.game : redirect[0],
                            rId: !redirect ? this.focus.rally : redirect[1],
                            pId: `${currentPlayer[0]}${player === 0 ? currentPlayer[1] : (1 - currentPlayer[1])}`
                        });
                    return this.getData(`Player${pId}`).name;
                };
            }
            case "ExportCheck": {
                return {
                    title: this.languageSwitch('Match'),
                    subtitle: this.data.match.video ? this.data.match.video.substr(0, this.data.match.video.lastIndexOf('.')) : '',
                    level: this.data.record.list.filter(game => game.structureLevel === 2 || game.termLevel === 2).length > 0 ? 2 :
                        (this.data.record.list.filter(game => game.structureLevel === 1 || game.termLevel === 1).length > 0 ? 1 : 0),
                    list: this.data.record.list.map((game, gameId) => {
                        return {
                            title: this.languageSwitch('GameNum')(gameId + 1),
                            subtitle: game.result.join(" : "),
                            level: game.termLevel > game.structureLevel ? game.termLevel : game.structureLevel,
                            list: game.list.map((rally, rallyId) => {
                                return {
                                    title: this.languageSwitch('RallyNum')(rallyId + 1),
                                    subtitle: rally.score.join(" : "),
                                    level: rally.termLevel > rally.structureLevel ? rally.termLevel : rally.structureLevel,
                                    list: rally.list.map((strike, strikeId) => {
                                        if (!strike) return {
                                            title: this.languageSwitch('StrikeNum')(strikeId + 1),
                                            subtitle: this.languageSwitch('EmptyStrike'),
                                            level: 2,
                                        };
                                        else {
                                            const player = this.getData(`Player${strike.HitPlayer}`);
                                            let ballPosition = strike.BallPosition.value;
                                            let strikePosition = strike.StrikePosition.value;
                                            if (!player.rightHand) {
                                                if (ballPosition.startsWith('B')) ballPosition = `F${ballPosition[1]}`;
                                                else if (ballPosition.startsWith('F')) ballPosition = `B${ballPosition[1]}`;
                                                else if (ballPosition === 'S1') ballPosition = 'S2';
                                                else if (ballPosition === 'S2') ballPosition = 'S1';

                                                switch (strikePosition) {
                                                    case 'F':
                                                        strikePosition = 'B';
                                                        break;
                                                    case 'B':
                                                        strikePosition = 'F';
                                                        break;
                                                    case 'P':
                                                        strikePosition = 'T';
                                                        break;
                                                    case 'T':
                                                        strikePosition = 'P';
                                                        break;
                                                    case 'S1':
                                                        strikePosition = 'S2';
                                                        break;
                                                    case 'S2':
                                                        strikePosition = 'S1';
                                                        break;
                                                    default:
                                                }
                                            }
                                            return {
                                                title: this.languageSwitch('StrikeNum')(strikeId + 1),
                                                subtitle: player.name,
                                                level: strike.termLevel,
                                                list: [
                                                    {
                                                        title: this.languageSwitch('BallPosition'),
                                                        subtitle: this.languageSwitch(ballPosition),
                                                        level: strike.BallPosition.termLevel,
                                                    }, {
                                                        title: this.languageSwitch('StrikePosition'),
                                                        subtitle: this.languageSwitch(strikePosition),
                                                        level: strike.StrikePosition.termLevel,
                                                    }, {
                                                        title: this.languageSwitch('StrikeTech'),
                                                        subtitle: this.languageSwitch(strike.StrikeTech.value),
                                                        level: strike.StrikeTech.termLevel,
                                                    }, {
                                                        title: this.languageSwitch('GameAction'),
                                                        subtitle: this.languageSwitch(strike.GameAction.value),
                                                        level: strike.GameAction.termLevel,
                                                    }, {
                                                        title: this.languageSwitch('StrikeEffect'),
                                                        subtitle: this.languageSwitch('L' + strike.StrikeEffect.value),
                                                        level: strike.StrikeEffect.termLevel,
                                                    }, {
                                                        title: this.languageSwitch('SpinKind'),
                                                        subtitle: this.languageSwitch(strike.SpinKind.value),
                                                        level: strike.SpinKind.termLevel,
                                                    },
                                                ]
                                            };
                                        }
                                    })
                                };
                            })
                        };
                    })
                };
            }
            case "AllBallPosition": {
                return ['S1', 'S2', 'BS', 'BH', 'BL', 'MS', 'MH', 'ML', 'FS', 'FH', 'FL', 'SP'];
            }
            case "AllStrikePosition": {
                return ['S1', 'S2', 'B', 'F', 'P', 'T'];
            }
            case "AllStrikeTech": {
                return ['Pendulum', 'Reverse', 'Tomahawk', 'Topspin', 'Attack', 'Smash', 'Flick', 'Twist', 'Push', 'Short', 'Slide', 'Block', 'Lob', 'Chopping', 'PimpleTech', 'Others'];
            }
            case "AllGameAction": {
                return ['Serve', 'Receive', 'Stalemate', 'Offensive', 'Defensive', 'Controlled'];
            }
            case "AllStrikeEffect": {
                return [1, 2, 3, 4, 5];
            }
            case "AllSpinKind": {
                return ['ST', 'NT', 'NS', 'ND', 'SD', 'SK', 'WT'];
            }
            case "AllPlayerPosition":
            case "AllPlayerPositionAl0":
            case "AllPlayerPositionAl1":
            case "AllPlayerPositionOp0":
            case "AllPlayerPositionOp1": {
                return [
                    'FN', 'MFN', 'MBN', 'BN',
                    'FM', 'MFM', 'MBM', 'BM',
                    'FF', 'MFF', 'MBF', 'BF'
                ];
            }
            case "ImpossibleBallPosition": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return this.getData("AllBallPosition");
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return this.getData("AllBallPosition");
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return this.getData("AllBallPosition");
                return strike.BallPosition.impossible;
            }
            case "ImpossibleStrikePosition": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return this.getData("AllStrikePosition");
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return this.getData("AllStrikePosition");
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return this.getData("AllStrikePosition");
                return strike.StrikePosition.impossible;
            }
            case "ImpossiblePlayerPosition": {
                const defaultImpossible = [[this.getData('AllPlayerPosition'), this.getData('AllPlayerPosition')], [this.getData('AllPlayerPosition'), this.getData('AllPlayerPosition')]];
                if (this.getData('PlayerNum') !== 2)
                    return defaultImpossible;
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return defaultImpossible;
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return defaultImpossible;
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return defaultImpossible;
                return [[
                    strike.PlayerPositionAl0.impossible,
                    strike.PlayerPositionAl1.impossible,
                ], [
                    strike.PlayerPositionOp0.impossible,
                    strike.PlayerPositionOp1.impossible,
                ]];
            }
            case "ImpossibleStrikeTech": {
                const game = this.data.record.list[this.focus.game];
                if (game === undefined) return this.getData("AllStrikeTech");
                const rally = game.list[this.focus.rally];
                if (rally === undefined) return this.getData("AllStrikeTech");
                const strike = rally.list[this.focus.strike];
                if (strike === undefined) return this.getData("AllStrikeTech");
                return strike.StrikeTech.impossible;
            }
            case "BallPositionName": {
                return value => {
                    switch (value) {
                        case 'S1':
                            return '反手区发球';
                        case 'S2':
                            return '正手区发球';
                        case 'BL':
                            return '反手长球';
                        case 'ML':
                            return '中路长球';
                        case 'FL':
                            return '正手长球';
                        case 'BH':
                            return '反手半长球';
                        case 'MH':
                            return '中路半长球';
                        case 'FH':
                            return '正手半长球';
                        case 'BS':
                            return '反手短球';
                        case 'MS':
                            return '中路短球';
                        case 'FS':
                            return '正手短球';
                        case 'SP':
                            return '特殊球';
                        default:
                            return '';
                    }
                };
            }
            case "StrikePositionName": {
                return value => {
                    switch (value) {
                        case 'S1':
                            return '反手区发球';
                        case 'S2':
                            return '正手区发球';
                        case 'F':
                            return '正手位';
                        case 'B':
                            return '反手位';
                        case 'P':
                            return '反侧身';
                        case 'T':
                            return '侧身位';
                        default:
                            return '';
                    }
                };
            }
            case "GameActionName": {
                return value => {
                    switch (value) {
                        case 'Serve':
                            return '发球';
                        case 'Receive':
                            return '接发球';
                        case 'Stalemate':
                            return '相持';
                        case 'Offensive':
                            return '进攻';
                        case 'Defensive':
                            return '防守';
                        case 'Controlled':
                            return '控制';
                        default:
                            return '';
                    }
                };
            }
            case "StrikeEffectName": {
                return value => {
                    switch (value) {
                        case 1:
                            return '一级';
                        case 2:
                            return '二级';
                        case 3:
                            return '三级';
                        case 4:
                            return '四级';
                        case 5:
                            return '五级';
                        default:
                            return '';
                    }
                };
            }
            case "SpinKindName": {
                return value => {
                    switch (value) {
                        case 'ST':
                            return '强上旋';
                        case 'NT':
                            return '中上旋';
                        case 'NS':
                            return '不转球';
                        case 'ND':
                            return '中下旋';
                        case 'SD':
                            return '强下旋';
                        case 'SK':
                            return '下沉';
                        case 'WT':
                            return '未触球';
                        default:
                            return '';
                    }
                };
            }
            case "PlayerPositionName": {
                return value => {
                    if (value.length !== 2 && value.length !== 3) return "";
                    let part1 = value.substr(0, value.length - 1);
                    let part2 = value.substr(value.length - 1, 1);
                    switch (part1) {
                        case "F":
                            part1 = "正手";
                            break;
                        case "MF":
                            part1 = "中偏正手";
                            break;
                        case "MB":
                            part1 = "中偏反手";
                            break;
                        case "B":
                            part1 = "反手";
                            break;
                        default:
                            return "";
                    }
                    switch (part2) {
                        case "N":
                            part1 = "近台";
                            break;
                        case "M":
                            part1 = "中台";
                            break;
                        case "F":
                            part1 = "远台";
                            break;
                        default:
                            return "";
                    }
                    return part1 + part2;
                };
            }
            case "BallPositionCode": {
                return (value, side) => {
                    side = +side;
                    switch (value) {
                        case 'S1':
                            return 1 + side;
                        case 'S2':
                            return 3 + side;
                        case 'BL':
                            return 22 - side;
                        case 'ML':
                            return 16 - side;
                        case 'FL':
                            return 10 - side;
                        case 'BH':
                            return 20 - side;
                        case 'MH':
                            return 14 - side;
                        case 'FH':
                            return 8 - side;
                        case 'BS':
                            return 18 - side;
                        case 'MS':
                            return 12 - side;
                        case 'FS':
                            return 6 - side;
                        case 'SP':
                            return 24 - side;
                        case 'SC':
                            return 25 + side;
                        default:
                            return 0;
                    }
                };
            }
            case "StrikePositionCode": {
                return (value, side) => {
                    side = +side;
                    switch (value) {
                        case 'S1':
                            return side + 1;
                        case 'S2':
                            return side + 3;
                        case 'F':
                            return side + 5;
                        case 'B':
                            return side + 7;
                        case 'T':
                            return side + 9;
                        case 'P':
                            return side + 11;
                        case 'SC':
                            return side + 13;
                        default:
                            return 0;
                    }
                };
            }
            case "StrikeTechCode": {
                return (key, side) => {
                    side = +side;
                    switch (key) {
                        case "Pendulum":
                            return side + 1;
                        case "Reverse":
                            return side + 3;
                        case "Tomahawk":
                            return side + 5;
                        case "Topspin":
                            return side + 7;
                        case "Attack":
                            return side + 9;
                        case "Smash":
                            return side + 11;
                        case "Flick":
                            return side + 13;
                        case "Twist":
                            return side + 15;
                        case "Push":
                            return side + 17;
                        case "Short":
                            return side + 19;
                        case "Slide":
                            return side + 21;
                        case "Block":
                            return side + 23;
                        case "Lob":
                            return side + 25;
                        case 'Chopping':
                            return side + 31;
                        case 'PimpleTech':
                            return side + 33;
                        case "Others":
                            return side + 27;
                        case "SC":
                            return side + 29;
                        default:
                            return 0;
                    }
                };
            }
            case "GameActionCode": {
                return (value, side) => {
                    side = +side;
                    switch (value) {
                        case 'Serve':
                            return side + 1;
                        case 'Receive':
                            return side + 3;
                        case 'Stalemate':
                            return side + 5;
                        case 'Offensive':
                            return side + 7;
                        case 'Defensive':
                            return side + 9;
                        case 'Controlled':
                            return side + 11;
                        case 'SC':
                            return side + 13;
                        default:
                            return 0;
                    }
                };
            }
            case "StrikeEffectCode": {
                return (value, side) => {
                    side = +side;
                    switch (value) {
                        case 1:
                            return side + 1;
                        case 2:
                            return side + 3;
                        case 3:
                            return side + 5;
                        case 4:
                            return side + 7;
                        case 5:
                            return side + 9;
                        default:
                            return 0;
                    }
                };
            }
            case "SpinKindCode": {
                return (value, side) => {
                    side = +side;
                    switch (value) {
                        case 'ST':
                            return side + 1;
                        case 'NT':
                            return side + 3;
                        case 'NS':
                            return side + 5;
                        case 'ND':
                            return side + 7;
                        case 'SD':
                            return side + 9;
                        case 'SK':
                            return side + 11;
                        case 'SC':
                            return side + 13;
                        default:
                            return 0;
                    }
                };
            }
            case "PlayerPositionCode": {
                return (value, side, player) => {
                    side = +side;
                    player = +player;
                    if (value.length !== 2 && value.length !== 3) return 0;
                    let part1 = value.substr(0, value.length - 1);
                    let part2 = value.substr(value.length - 1, 1);
                    let code = 0;
                    switch (part1) {
                        case "F":
                            code += 0;
                            break;
                        case "MF":
                            code += 12;
                            break;
                        case "MB":
                            code += 24;
                            break;
                        case "B":
                            code += 36;
                            break;
                        default:
                            return 0;
                    }
                    switch (part2) {
                        case "N":
                            code += 0;
                            break;
                        case "M":
                            code += 4;
                            break;
                        case "F":
                            code += 8;
                            break;
                        default:
                            return 0;
                    }
                    code += side * 2 + player;
                    return code + 1;
                };
            }
            case "MatchList": {
                return fetch(`${store.service}/available_packages/${this.necessaryFiles.join("&&")}`)
                    .then(res => res.json());
            }
            case 'RallyInfo': {
                return redirect => {
                    const game = this.data.record.list[redirect[0]];
                    if (!game) return null;
                    const rally = game.list[redirect[1]];
                    if (!rally) return null;

                    return {
                        name: this.getData('PlayerNames'),
                        startSide: rally.startSide === 1 ? 1 : 0,
                        winSide: rally.winSide === 1 ? 1 : 0,
                        rallyScore: rally.score,
                        gameScore: game.score,
                        startTime: rally.startTime,
                        endTime: rally.endTime,
                    }
                };
            }
            case 'RallyResult': {
                return this.data.record.list.map((game, gId) => {
                    return game.list.map((rally, rId) => {
                        let loseStrike = rally.list[rally.rallyLength - 1];
                        let winStrike = rally.list[rally.rallyLength - 2];

                        const playerNum = this.getData('PlayerNum');

                        if (!winStrike) { // infer winStrike when rally.list.length < 2
                            if (!loseStrike) { // infer loseStrike when the strike list is not initialized
                                if (playerNum === 1)
                                    loseStrike = {HitPlayer: `${1 - rally.winSide}0`};
                                // no need for checking doubles match because collectors must choose receivers in the record page so that the list must be initialized
                            }

                            if (playerNum === 1) winStrike = {HitPlayer: `${1 - loseStrike.HitPlayer[0]}0`};
                            else winStrike = {HitPlayer: this.getData('FindWinnerWhenOneStrokeInMultiGame')(gId, rId)};
                        }

                        return {
                            length: rally.rallyLength,
                            score: rally.score,
                            win: !!winStrike ? winStrike.HitPlayer : null,
                            lose: !!loseStrike ? loseStrike.HitPlayer : null,
                        };
                    });
                });
            }
            case 'Updates': {
                return this.updates;
            }
            case 'FindOrderInRallyOfMultiGame':
                return (gId, rId) => {
                    const game = this.data.record.list[gId];
                    if (!game) return null;
                    const rally = game.list[rId];
                    if (!rally) return null;
                    if (rally.list.length <= 1) return null;
                    const p1 = rally.list[0].HitPlayer;
                    const p2 = rally.list[1].HitPlayer;
                    const p1_idx = ['00', '10', '01', '11'].indexOf(p1);
                    const p2_idx = ['00', '10', '01', '11'].indexOf(p2);
                    if ((p1_idx + 1) % 4 === p2_idx) return 0;
                    else if (p1_idx === (p2_idx + 1) % 4) return 1;
                    else return null;
                };
            case 'FindWinnerWhenOneStrokeInMultiGame':
                return (gId, rId) => {
                    let after00 = null;
                    for (let gi = 0; after00 === null && gi < this.data.record.list.length; gi++)
                        for (let ri = 0; after00 === null && ri < this.data.record.list[gi].list.length; ri++) {
                            const order = this.getData('FindOrderInRallyOfMultiGame')(gi, ri);
                            if (order !== null) {
                                after00 = order + Math.abs(gId - gi);
                                if (gId > 3 && gId % 2 === 0 && gId === this.data.record.list.length - 1) {
                                    const game = this.data.record.list[gId];
                                    if (game.score[0] === game.score[1]) {
                                        const rally = game.list[rId];
                                        if (rally.score[0] >= 5 || rally.score[1] >= 5) after00 += 1;
                                    }
                                }
                                after00 %= 2;
                            }
                        }
                    if (after00 === null) return null;
                    const server = this.data.record.list[gId].list[rId].list[0].HitPlayer;
                    const order = ['00', '10', '01', '11'];
                    const server_idx = order.indexOf(server);
                    return order[(server_idx + (after00 === 1 ? 3 : 1)) % 4];
                };
            default:
                return null;
        }
    };

    languageSwitch = content => {
        if (wordDict[content] === undefined) return content;
        else if (wordDict[content][this.lang] === undefined) return content;
        else return wordDict[content][this.lang];
    };
}

const store = new Store();

export default store;
