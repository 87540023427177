import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, InputBase, withStyles} from '@material-ui/core';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {Search} from '@material-ui/icons';
import store from '../../../Data/Store';
import ImportProject from "./ImportProject";

const styles = theme => ({
    root: {},
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.primary.main, 0.85),
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.75),
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    searchIcon: {
        width: 45,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.contrastText,
    },
    buttonText: {
        display: 'block',
        textAlign: 'left',
    },
    inputRoot: {
        color: theme.palette.primary.contrastText,
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 45,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
});

class MatchList extends Component {
    state = {
        open: false,
        openImport: false,
        matches: [],
        chosen: '',
        search: '',
    };

    handleSearch = (e) => {
        this.setState({search: e.target.value});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleConfirm = (match) => {
        if (match)
            store.openTemp(match);
        else if (this.state.chosen !== '')
            store.openTemp(this.state.chosen);
        this.handleClose();
    };

    handleImport = () => {
        this.setState({openImport: true});
    };

    handleChoose = match => () => {
        this.setState({chosen: match});
    };

    UNSAFE_componentWillMount() {
        store.register({matchList: this, chosen: ''});

        this.updateList();
    }

    handleOpen = () => {
        this.setState({open: true});
        this.updateList();
    };

    componentWillUnmount() {
        store.unregister({matchList: this});
    }

    updateList() {
        store.getData('MatchList')
          .then(res => {
              res.sort((a, b) => -a.localeCompare(b))
              this.setState({
                  matches: res,
              })
          })
    }

    render() {
        const {classes, ...others} = this.props;

        return <Dialog open={this.state.open} onClose={this.handleClose} {...others}>
            <ImportProject open={this.state.openImport}
                           onClose={() => this.setState({openImport: false})}
                           onSubmit={(videoSrc, data, filename) => {
                               store.handleChange('LoadProject')({
                                   res: data,
                                   match: filename,
                               });
                               store.setOnlineVideoSrc(videoSrc);
                               this.setState({open: false});
                           }}/>
            <DialogTitle>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <Search/>
                    </div>
                    <InputBase
                      placeholder="搜索比赛名"
                      value={this.state.search}
                      onChange={this.handleSearch}
                      classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                      }}
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                {
                    this.state.matches
                      .filter(match => {
                          let allMatch = true;
                          this.state.search.split(' ').forEach(key => {
                              allMatch = allMatch && match.includes(key);
                          });
                          return allMatch;
                      })
                      .map(match => {
                          return <Button key={match}
                                         fullWidth
                                         classes={{
                                             label: classes.buttonText,
                                         }}
                                         variant={this.state.chosen === match ? 'contained' : 'text'}
                                         onClick={this.handleChoose(match)}
                                         onDoubleClick={() => this.handleConfirm(match)}>
                              {match}
                          </Button>;
                      })
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.handleImport()}>导入</Button>
                <Button onClick={() => this.handleConfirm()}>确认</Button>
                <Button onClick={() => this.handleClose()}>取消</Button>
            </DialogActions>
        </Dialog>;
    }
}

export default withStyles(styles)(MatchList);

// edited by WuJiang5521 on 2019/5/27
