import React from 'react';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    rootInsertionPlaceholder: {
        width: '100%',
        padding: '4px 0',
        transition: 'all .3s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    shrunk: {
        height: 1,
        padding: 0,
    },
    insertionBar: {
        height: 2,
        margin: theme.spacing(0, 4),
        backgroundColor: theme.palette.primary.main,
        animation: "$blink 1s linear infinite",
    },
    "@keyframes blink": {
        "0%": {
            opacity: 1,
        },
        "49%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0,
        },
        "99%": {
            opacity: 0,
        },
    }
}));

function InsertionPlaceholder({
                                  idx,
                                  shrunk = false,
                                  activate,
                                  onSelect,
                              }) {
    const classes = useStyles();

    return <div className={clsx(classes.rootInsertionPlaceholder, {
        [classes.shrunk]: shrunk,
    })} onClick={() => onSelect(idx)}>
        {activate && <div className={classes.insertionBar}/>}
    </div>
}

export default InsertionPlaceholder;
