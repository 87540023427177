import React, {Component} from 'react';
import {fade, IconButton, withStyles} from '@material-ui/core';
import store from '../../../Data/Store';
import clsx from 'clsx';
import VideoController from "./VideoController";
import {ViewModule} from "@material-ui/icons";
import ReferenceTable from "./ReferenceTable";

const styles = theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    video: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        minWidth: '75%',
    },
    topArea: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '20%',
        '&:hover > $pluginBar': {
            display: 'flex',
        }
    },
    pluginBar: {
        position: 'absolute',
        top: 0,
        height: '48px',
        left: 0,
        right: 0,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        display: 'none',
    },
    activatePluginBar: {
        display: 'flex',
    }
});

class Video extends Component {
    state = {
        activatePlugin: 'VideoController',
        videoSize: [0, 0],
        duration: 0,
    };

    pluginsWithButton = ['ReferenceTable'];

    UNSAFE_componentWillMount() {
        store.register({videoSrc: this});
    }

    componentWillUnmount() {
        store.unregister({videoSrc: this});
    }

    activatePlugin = key => {
        if (this.state.activatePlugin === key) this.setState({activatePlugin: 'VideoController'});
        else this.setState({activatePlugin: key});
    };

    renderPluginButton(pluginName) {
        switch (pluginName) {
            case "ReferenceTable":
                return <IconButton key={pluginName}
                                   onClick={() => this.activatePlugin(pluginName)}
                                   color={this.state.activatePlugin === 'ReferenceTable' ? 'primary' : 'default'}>
                    <ViewModule/>
                </IconButton>;
            default:
                return null;
        }
    }

    renderPlugin(pluginName, props) {
        switch (pluginName) {
            case "VideoController":
                return <VideoController key={pluginName}
                                        activate={this.state.activatePlugin === pluginName}
                                        {...props}/>;
            case "ReferenceTable":
                return <ReferenceTable key={pluginName}
                                       activate={this.state.activatePlugin === pluginName}
                                       completeAnnotate={() => this.activatePlugin(pluginName)}
                                       {...props}/>;
            default:
                return null;
        }
    }

    handleLoadedMetaData = e => {
        this.setState({
            videoSize: [e.target.videoWidth, e.target.videoHeight],
            duration: e.target.duration,
        });
    }

    render() {
        let {classes, autoPlay, plugins, ...others} = this.props;
        if (!plugins) plugins = ['VideoController'];
        else if (!plugins.includes('VideoController')) plugins.push('VideoController');
        const src = store.getData("Video");
        const pluginsButtons = this.pluginsWithButton.filter(pName => plugins.includes(pName));
        return <div className={classes.root}>
            <video id={"video-player"} className={classes.video} autoPlay={!!autoPlay}
                   onLoadedMetadata={this.handleLoadedMetaData}>
                {
                    src !== null &&
                    <source src={src}/>
                }
            </video>
            {src !== null && plugins.map(pluginName => this.renderPlugin(pluginName, others))}
            {src !== null && pluginsButtons.length > 0 &&
            <div className={classes.topArea}>
                <div className={clsx(classes.pluginBar, {
                    [classes.activatePluginBar]: pluginsButtons.includes(this.state.activatePlugin),
                })}>
                    {pluginsButtons.map(pluginName => this.renderPluginButton(pluginName))}
                </div>
            </div>}
        </div>;
    }
}

export default withStyles(styles)(Video);
