import React, {Component} from 'react';
import hotkey from 'react-shortcut-key';
import {Button, IconButton, Radio, Typography, SvgIcon, withStyles} from '@material-ui/core';
import clsx from 'clsx';
import store from '../../../Data/Store';
import {PlayArrow, ImportExport, Pause, FlipCameraAndroid} from '@material-ui/icons';
import {fade} from "@material-ui/core/styles/colorManipulator";

const componentOnTab = e => {
    e.preventDefault();
    store.handleChange("SwitchView")();
};
const keymap = {
    'tab': componentOnTab,
};

const style = store.pageStyle;
const totalWidth = '((100vh - 154px) / 2.025)';
const totalHeight = `(${totalWidth} * 7 / 5)`;
const tableHeight = `(${totalHeight} * 2.9 / 5)`;
const tableWidth = `(${tableHeight} / 27.4 * 15.25)`;
const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    svg: {
        width: '100%',
        height: '100%',
    },
    table: {
        position: 'absolute',
        top: `calc((${totalHeight} - ${tableHeight}) / 2)`,
        bottom: `calc((${totalHeight} - ${tableHeight}) / 2)`,
        left: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        right: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableButton: {
        minWidth: 0,
        minHeight: 0,
        width: `calc(${100 / 3}% - 2px)`,
        height: `calc(${100 / 6}% - 2px)`,
        margin: 1,
        borderRadius: 0,
    },
    impossible: {
        cursor: 'not-allowed',
        backgroundColor: `rgba${style.disabled.substring(3, style.disabled.length - 1)}, 0.2)`,
        '&:hover': {
            backgroundColor: `rgba${style.disabled.substring(3, style.disabled.length - 1)}, 0.2)`,
        }
    },
    unchosen: {
        backgroundColor: fade(theme.palette.primary.light, 0.25),
        color: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    chosen: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        }
    },
    serveButton: {
        minWidth: 0,
        minHeight: 0,
        position: 'absolute',
        width: `calc(50% - ${theme.spacing(1)}px)`,
        height: `calc(${tableHeight} / 8 - ${theme.spacing(1)}px)`,
        margin: theme.spacing(0.5),
        border: `1px solid ${fade(theme.palette.primary.light, 0.25)}`,
        borderRadius: '0.3vw',
    },
    topServe: {
        top: 0,
    },
    bottomServe: {
        bottom: 0,
    },
    leftServe: {
        left: 0,
    },
    rightServe: {
        right: 0,
    },
    specialButton: {
        minWidth: 0,
        minHeight: 0,
        position: 'absolute',
        width: `calc((${totalWidth} - ${tableWidth}) / 6)`,
        height: '15%',
        border: `1px solid ${fade(theme.palette.primary.light, 0.25)}`,
        borderRadius: '0.3vw',
        right: 0,
        top: '50%',
        transform: 'translate(150%, -50%)',
    },
    switchViewButton: {
        minWidth: 0,
        minHeight: 0,
        position: 'absolute',
        width: `calc((${totalWidth} - ${tableWidth}) / 6)`,
        height: '15%',
        right: 0,
        top: '50%',
        transform: 'translate(270%, -50%)',
    },
    topPlaceT: {
        position: 'absolute',
        top: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        left: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        transform: `translate(-${3.414 / 4 * 100}%, -${3.414 / 4 * 100}%)`
    },
    topPlaceF: {
        position: 'absolute',
        top: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        left: `calc(${totalWidth} / 2 - ${tableWidth} / 4)`,
        transform: `translate(-50%, -100%)`
    },
    topPlaceB: {
        position: 'absolute',
        top: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        right: `calc(${totalWidth} / 2 - ${tableWidth} / 4)`,
        transform: `translate(50%, -100%)`
    },
    topPlaceP: {
        position: 'absolute',
        top: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        right: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        transform: `translate(${3.414 / 4 * 100}%, -${3.414 / 4 * 100}%)`
    },
    bottomPlaceP: {
        position: 'absolute',
        bottom: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        left: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        transform: `translate(-${3.414 / 4 * 100}%, ${3.414 / 4 * 100}%)`
    },
    bottomPlaceB: {
        position: 'absolute',
        bottom: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        left: `calc(${totalWidth} / 2 - ${tableWidth} / 4)`,
        transform: `translate(-50%, 100%)`
    },
    bottomPlaceF: {
        position: 'absolute',
        bottom: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        right: `calc(${totalWidth} / 2 - ${tableWidth} / 4)`,
        transform: `translate(50%, 100%)`
    },
    bottomPlaceT: {
        position: 'absolute',
        bottom: `calc((${totalHeight} - ${tableHeight}) / 2 - 8px)`,
        right: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        transform: `translate(${3.414 / 4 * 100}%, ${3.414 / 4 * 100}%)`
    },
    placeButton: {
        border: `1px dotted ${style.text}`,
        minWidth: 0,
        minHeight: 0,
        width: `calc((${totalHeight} - ${tableHeight}) / 4)`,
        height: `calc((${totalHeight} - ${tableHeight}) / 4)`,
        borderRadius: `calc((${totalHeight} - ${tableHeight}) / 8)`,
        padding: 0,
    },
    sideSwitch: {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translate(0, -50%)',
        width: `calc((${totalWidth} - ${tableWidth}) / 2)`,
        height: `calc(${tableHeight})`,
    },
    switchSideButton: {
        position: 'absolute',
        width: '50%',
        height: '48px',
        bottom: 'calc(50% - 24px)'
    },
    playButton: {
        position: 'absolute',
        left: '50%',
        width: '50%',
        height: '48px',
        bottom: 'calc(50% - 24px)'
    },
    side: {
        position: 'absolute',
        left: 0,
        width: '100%',
        maxHeight: 'calc(50% - 24px)',
        display: 'block'
    },
    player: {
        width: '100%',
        display: 'flex',
        height: '50px',
    },
    playerRadio: {
        flex: 1,
        padding: `0 ${theme.spacing(1)}px`,
    },
    cssRoot: {
        color: theme.palette.primary.main,
        '&$cssChecked': {
            color: theme.palette.primary.main,
        },
    },
    cssChecked: {},
    playerName: {
        color: style.text,
        flex: 6,
        textAlign: 'center',
        lineHeight: '50px',
        cursor: 'pointer',
    },
    animation: {
        transition: 'transform 550ms cubic-bezier(0.08, 0.82, 0.17, 1)'
    },
    currentStrikePositionIcon: {
        stroke: theme.palette.primary.contrastText,
    },
    strikePositionIcon: {
        stroke: theme.palette.primary.dark,
        '&:hover': {
            stroke: theme.palette.primary.contrastText,
        }
    },
    PlayerPositionTop: {
        position: 'absolute',
        bottom: '90%',
        left: '50%',
        width: '95%',
        height: '20%',
        transform: 'translate(-50%, 50%)',
        display: 'flex',
        flexDirection: 'row',
        '& > $PlayerPositionX': {
            display: 'flex',
            flexDirection: 'column-reverse',
        }
    },
    PlayerPositionBottom: {
        position: 'absolute',
        top: '90%',
        left: '50%',
        width: '95%',
        height: '20%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& > $PlayerPositionX': {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    PlayerPositionX: {
        flex: 1
    },
    PlayerPositionY: {
        flex: 1
    },
    PlayerPosition: {
        color: theme.palette.primary.dark,
        backgroundColor: fade(theme.palette.primary.light, 0.25),
        border: `1px solid ${fade(theme.palette.primary.light, 0.25)}`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        margin: theme.spacing(0.5),
        minWidth: 0,
    },
    PlayerPositionChosen: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
        },
        margin: theme.spacing(0.5),
        minWidth: 0,
    },
});

class PositionCollector extends Component {
    state = {
        needRefresh: false,
        reverse: false,
        play: false,
        animation: -1,
    };
    interval = null;

    UNSAFE_componentWillMount() {
        store.register({strikePosition: this});
        window.onresize = () => this.setState({needRefresh: !this.state.needRefresh});
    }

    componentWillUnmount() {
        store.unregister({strikePosition: this});
    }

    renderShow() {
        const {classes, theme} = this.props;
        const totalWidth = (window.innerHeight - 154) / 2.025;
        const totalHeight = totalWidth * 7 / 5;
        const tableHeight = totalHeight * 2.9 / 5;
        const tableWidth = tableHeight / 27.4 * 15.25;
        const rally = store.getData("FocusRally");
        const calPos = (pos, side) => {
            let x = 0, y = 0;
            if (pos[0] === 'S') {
                if (pos[1] === 'P') {
                    x = tableWidth / 2 + (totalWidth - tableWidth) / 3 * 0.7;
                    y = 0;
                } else {
                    if (pos[1] === '1') x = -tableWidth / 4;
                    else if (pos[1] === '2') x = tableWidth / 4;
                    y = tableHeight / 12 * 7;
                }
            } else {
                if (pos[0] === 'B') x = -tableWidth / 3;
                else if (pos[0] === 'M') x = 0;
                else if (pos[0] === 'F') x = tableWidth / 3;
                if (pos[1] === 'L') y = tableHeight / 12 * 5;
                else if (pos[1] === 'H') y = tableHeight / 12 * 3;
                else if (pos[1] === 'S') y = tableHeight / 12;
            }
            if (x === 0 && y === 0) return null;
            if (side === 0 && pos !== 'SP') return [-x, -y];
            else return [x, y];
        };
        const id = {
            p: this.state.animation - 1,
            c: this.state.animation,
            n: this.state.animation + 1,
        };
        const strike = {};
        const pos = {};
        const xy = {};

        ['p', 'c', 'n'].forEach(key => {
            strike[key] = rally.list[id[key]];
            pos[key] = !strike[key] ? '' : strike[key].BallPosition.value;
            xy[key] = calPos(pos[key], (rally.startSide + id[key]) % 2);
        });
        return <g
            transform={`translate(${totalWidth / 2}, ${totalHeight / 2}) scale(${this.state.reverse ? -1 : 1}, ${this.state.reverse ? -1 : 1})`}>
            {
                xy.p !== null && xy.c !== null &&
                <line x1={xy.p[0]} y1={xy.p[1]} x2={xy.c[0]} y2={xy.c[1]} stroke={style.text} strokeWidth={'1px'}/>
            }
            {
                xy.c !== null && xy.n !== null &&
                <line x1={xy.c[0]} y1={xy.c[1]} x2={xy.n[0]} y2={xy.n[1]} stroke={style.text} strokeWidth={'1px'}
                      markerEnd="url(#triangleMarker)"/>
            }
            {
                xy.c !== null &&
                <g transform={`translate(${xy.c[0]}, ${xy.c[1]})`} className={classes.animation}>
                    <circle cx={0} cy={0} r={`${tableHeight / 20}px`} strokeWidth={0} fill={style.text}/>
                    <text x={0} y={0} alignmentBaseline={'middle'} textAnchor={'middle'}
                          fill={theme.palette.primary.contrastText}>{this.state.animation + 1}</text>
                </g>
            }
        </g>
    }

    render() {
        const {classes, theme} = this.props;
        const totalWidth = (window.innerHeight - 154) / 2.025;
        const totalHeight = totalWidth * 7 / 5;
        const tableHeight = totalHeight * 2.9 / 5;
        const tableWidth = tableHeight / 27.4 * 15.25;
        const playerNames = store.getData("PlayerNames");
        const currentPlayer = store.getData("CurrentPlayerID");
        const currentSide = this.state.reverse ? 1 - currentPlayer[0] : +currentPlayer[0];
        const player = store.getData(`Player${currentPlayer}`);
        const rightHand = player !== null ? player.rightHand : true;
        const impossibleBallPosition = store.getData("ImpossibleBallPosition");
        const impossibleStrikePosition = store.getData("ImpossibleStrikePosition");
        // const impossiblePlayerPosition = store.getData("ImpossiblePlayerPosition");
        const canSwitchPlayer = store.getData('CanSwitchPlayer');
        const curBP = store.getData('CurrentBallPosition');
        const curSP = store.getData('CurrentStrikePosition');
        const curPP = store.getData('CurrentPlayerPosition');

        return <div className={classes.root}>
            <svg className={classes.svg}>
                <g transform={`translate(${totalWidth / 2}, ${totalHeight / 2})`}>
                    <rect x={-tableWidth / 2} y={-tableHeight / 2} width={tableWidth} height={tableHeight}
                          fill={theme.palette.primary.contrastText}
                          stroke={theme.palette.primary.main} strokeWidth={'2px'}/>
                    <line x1={-tableWidth / 2 * 1.1} y1={0} x2={tableWidth / 2 * 1.1} y2={0}
                          fill={theme.palette.primary.contrastText}
                          stroke={theme.palette.primary.main} strokeWidth={'2px'}/>
                </g>
                {
                    this.state.play && this.renderShow()
                }
            </svg>
            <div>
                <div className={classes.table}>
                    {["FL", "ML", "BL", "FH", "MH", "BH", "FS", "MS", "BS"].map((pos, i) => {
                        return <Button
                            key={i}
                            className={clsx(classes.tableButton, impossibleBallPosition.includes(pos) || currentSide !== 0 ? classes.impossible : (curBP === pos ? classes.chosen : classes.unchosen))}
                            onClick={() => {
                                if (!impossibleBallPosition.includes(pos)) store.handleChange("BallPosition")(pos)
                            }}>{""}</Button>
                    })}
                    {["BS", "MS", "FS", "BH", "MH", "FH", "BL", "ML", "FL"].map((pos, i) => {
                        return <Button
                            key={i}
                            className={clsx(classes.tableButton, impossibleBallPosition.includes(pos) || currentSide !== 1 ? classes.impossible : (curBP === pos ? classes.chosen : classes.unchosen))}
                            onClick={() => {
                                if (!impossibleBallPosition.includes(pos)) store.handleChange("BallPosition")(pos)
                            }}>{""}</Button>
                    })}
                    <Button
                        className={clsx(classes.specialButton, impossibleBallPosition.includes("SP") ? classes.impossible : (curBP === 'SP' ? classes.chosen : classes.unchosen))}
                        onClick={() => {
                            if (!impossibleBallPosition.includes("SP")) store.handleChange("BallPosition")("SP")
                        }}>{""}</Button>
                    {store.getData('PlayerNum') === 2 &&
                    <IconButton className={classes.switchViewButton}
                                onClick={store.handleChange("SwitchView")}>
                        <FlipCameraAndroid/>
                    </IconButton>
                    }
                </div>
                {
                    !this.state.play && !store.setPosition &&
                    <Button
                        className={clsx(classes.serveButton, classes.topServe, classes.leftServe, impossibleBallPosition.includes("S2") || currentSide !== 0 ? classes.impossible : (curBP === "S2" ? classes.chosen : classes.unchosen))}
                        onClick={() => {
                            if (!impossibleBallPosition.includes("S2")) store.handleChange("BallPosition")("S2")
                        }}>{currentSide === 0 && !impossibleBallPosition.includes("S2") ? (rightHand ? store.languageSwitch('S2') : store.languageSwitch('S1')) : ""}</Button>
                }
                {
                    !this.state.play && !store.setPosition &&
                    <Button
                        className={clsx(classes.serveButton, classes.topServe, classes.rightServe, impossibleBallPosition.includes("S1") || currentSide !== 0 ? classes.impossible : (curBP === "S1" ? classes.chosen : classes.unchosen))}
                        onClick={() => {
                            if (!impossibleBallPosition.includes("S1")) store.handleChange("BallPosition")("S1")
                        }}>{currentSide === 0 && !impossibleBallPosition.includes("S1") ? (rightHand ? store.languageSwitch('S1') : store.languageSwitch('S2')) : ""}</Button>
                }
                {
                    !this.state.play && !store.setPosition &&
                    <Button
                        className={clsx(classes.serveButton, classes.bottomServe, classes.leftServe, impossibleBallPosition.includes("S1") || currentSide !== 1 ? classes.impossible : (curBP === "S1" ? classes.chosen : classes.unchosen))}
                        onClick={() => {
                            if (!impossibleBallPosition.includes("S1")) store.handleChange("BallPosition")("S1")
                        }}>{currentSide === 1 && !impossibleBallPosition.includes("S1") ? (rightHand ? store.languageSwitch('S1') : store.languageSwitch('S2')) : ""}</Button>
                }
                {
                    !this.state.play && !store.setPosition &&
                    <Button
                        className={clsx(classes.serveButton, classes.bottomServe, classes.rightServe, impossibleBallPosition.includes("S2") || currentSide !== 1 ? classes.impossible : (curBP === "S2" ? classes.chosen : classes.unchosen))}
                        onClick={() => {
                            if (!impossibleBallPosition.includes("S2")) store.handleChange("BallPosition")("S2")
                        }}>{currentSide === 1 && !impossibleBallPosition.includes("S2") ? (rightHand ? store.languageSwitch('S2') : store.languageSwitch('S1')) : ""}</Button>
                }
                {
                    !this.state.play && !store.setPosition &&
                    ["T", "F", "B", "P"].map((pos, i) => {
                        return <Button
                            key={i}
                            className={clsx(classes[`topPlace${pos}`], classes.placeButton, impossibleStrikePosition.includes(pos) || currentSide !== 0 ? classes.impossible : (curSP === pos ? classes.chosen : classes.unchosen))}
                            onClick={() => {
                                if (!impossibleStrikePosition.includes(pos)) store.handleChange("StrikePosition")(pos)
                            }}>
                            {
                                currentSide === 0 && !impossibleStrikePosition.includes(pos) &&
                                this.getIcon(pos, rightHand, 'top', curSP === pos)
                            }
                        </Button>
                    })
                }
                {
                    !this.state.play && !store.setPosition &&
                    ["P", "B", "F", "T"].map((pos, i) => {
                        return <Button
                            key={i}
                            className={clsx(classes[`bottomPlace${pos}`], classes.placeButton, impossibleStrikePosition.includes(pos) || currentSide !== 1 ? classes.impossible : (curSP === pos ? classes.chosen : classes.unchosen))}
                            onClick={() => {
                                if (!impossibleStrikePosition.includes(pos)) store.handleChange("StrikePosition")(pos)
                            }}>
                            {
                                currentSide === 1 && !impossibleStrikePosition.includes(pos) &&
                                this.getIcon(pos, rightHand, 'bottom', curSP === pos)
                            }
                        </Button>;
                    })
                }
                {
                    !this.state.play && store.setPosition &&
                    ['Top', 'Bottom'].map((side, sId) => {
                        const toHit = currentSide === sId ? 0 : 1;
                        return <div key={side} className={classes[`PlayerPosition${side}`]}>
                            {['F', 'MF', 'MB', 'B'].map((x, xId) => {
                                return <div key={x} className={classes.PlayerPositionX}>
                                    {['N', 'M', 'F'].map((y, yId) => {
                                        let playerName = "";
                                        for (let pId of [0, 1])
                                            if (x + y === curPP[toHit][pId])
                                                playerName = store.getData('PlayerNameByPlayerPosition')({
                                                    side: toHit,
                                                    player: pId,
                                                });
                                        return <Button key={`${sId}${xId}${yId}`}
                                                       className={clsx(
                                                           classes.PlayerPositionY,
                                                           classes.PlayerPosition,
                                                           {
                                                               [classes.PlayerPositionChosen]: playerName !== ''
                                                           })}
                                                       onClick={() => store.handleChange('PlayerPosition')({
                                                           side: currentSide === sId ? 'Al' : 'Op',
                                                           position: x + y
                                                       })}>
                                            {playerName}
                                        </Button>
                                    })}
                                </div>
                            })}
                        </div>
                    })
                }
                <div className={classes.sideSwitch}>
                    <div className={classes.side} style={{bottom: 'calc(50% + 24px)'}}>
                        {
                            playerNames[this.state.reverse ? 1 : 0].map((player, playerID) => {
                                const isCurrent = `${this.state.reverse ? 1 : 0}${playerID}` === currentPlayer;
                                return <div className={classes.player} key={player}>
                                    <Radio className={classes.playerRadio}
                                           classes={{root: classes.cssRoot, checked: classes.cssChecked}}
                                           checked={isCurrent} disabled={currentSide !== 0 || !canSwitchPlayer}
                                           onClick={() => store.handleChange('StrikePlayer')(playerID)}/>
                                    <Typography className={classes.playerName} noWrap
                                                onClick={() => store.handleChange('StrikePlayer')(playerID)}>{player}</Typography>
                                </div>;
                            })
                        }
                    </div>
                    <IconButton className={classes.switchSideButton}
                                onClick={this.handleReverse}><ImportExport/></IconButton>
                    <IconButton className={classes.playButton}
                                onClick={this.state.play ? this.handlePause : this.handlePlay}>
                        {
                            this.state.play ? <Pause/> : <PlayArrow/>
                        }
                    </IconButton>
                    <div className={classes.side} style={{top: 'calc(50% + 24px)'}}>
                        {
                            playerNames[this.state.reverse ? 0 : 1].map((player, playerID) => {
                                const isCurrent = `${this.state.reverse ? 0 : 1}${playerID}` === currentPlayer;
                                return <div className={classes.player} key={player}>
                                    <Radio className={classes.playerRadio}
                                           classes={{root: classes.cssRoot, checked: classes.cssChecked}}
                                           checked={isCurrent} disabled={currentSide !== 1 || !canSwitchPlayer}
                                           onClick={() => store.handleChange('StrikePlayer')(playerID)}/>
                                    <Typography className={classes.playerName} noWrap
                                                onClick={() => store.handleChange('StrikePlayer')(playerID)}>{player}</Typography>
                                </div>;
                            })
                        }
                    </div>
                </div>
            </div>
        </div>;
    }

    getIcon = (pos, rightHand, tb, current) => {
        const {classes} = this.props;
        const size = 24;
        if (rightHand === ['F', 'P'].includes(pos))
            return <SvgIcon className={current ? classes.currentStrikePositionIcon : classes.strikePositionIcon}
                            style={{
                                width: '100%',
                                height: '100%',
                                transform: `rotate(${(tb === 'bottom' ? 180 : 0) + (['P', 'T'].includes(pos) ? (rightHand ? 45 : -45) : 0)}deg) scale(${rightHand ? 1 : -1}, 1)`
                            }}>
                <line x1={size / 2} y1={size * 0.45}
                      x2={size * 0.9} y2={size * 0.45}
                      style={{
                          strokeWidth: size * 0.1,
                          fill: 'transparent'
                      }}/>
                <path d={`M${size / 2} ${size * 0.45}L${size * 0.3} ${size * 0.4}L${size * 0.1} ${size * 0.37}`}
                      style={{
                          strokeWidth: '1px',
                          fill: 'transparent'
                      }}/>
                <path
                    d={`M${size * 0.1} ${size / 2} A${size * 0.4} ${size * 0.4} 0 0 0 ${size * 0.2172} ${size * 0.7828} L${size * 0.18} ${size * 0.65}`}
                    style={{
                        strokeWidth: '1px',
                        fill: 'transparent'
                    }}/>
            </SvgIcon>;
        else
            return <SvgIcon className={current ? classes.currentStrikePositionIcon : classes.strikePositionIcon}
                            style={{
                                width: '100%',
                                height: '100%',
                                transform: `rotate(${(tb === 'bottom' ? 180 : 0) + (['P', 'T'].includes(pos) ? (rightHand ? -45 : 45) : 0)}deg) scale(${rightHand ? 1 : -1}, 1)`
                            }}>
                <line x1={size * 0.3} y1={size * 0.25}
                      x2={size * 0.7} y2={size * 0.25}
                      style={{
                          strokeWidth: size * 0.1,
                          fill: 'transparent'
                      }}/>
                <path d={`M${size * 0.3} ${size * 0.25}L${size * 0.28} ${size * 0.45}L${size * 0.6} ${size * 0.4}`}
                      style={{
                          strokeWidth: '1px',
                          fill: 'transparent'
                      }}/>
                <path
                    d={`M${size * 0.6} ${size / 2} A${size * 0.4} ${size * 0.4} 0 0 1 ${size * 0.5} ${size * 0.8} L${size * 0.53} ${size * 0.67}`}
                    style={{
                        strokeWidth: '1px',
                        fill: 'transparent'
                    }}/>
            </SvgIcon>;
    };

    handleReverse = () => this.setState({
        reverse: !this.state.reverse
    });

    handlePlay = () => {
        const rally = store.getData("FocusRally");
        if (rally.list.filter(strike => strike.BallPosition.value.length > 0).length !== rally.rallyLength) return;
        this.setState({
            play: true,
            animation: 0
        });
        this.interval = setInterval(this.handleAnimation, 600);
    };

    handlePause = () => {
        this.setState({
            play: false,
            animation: -1,
        });
        clearInterval(this.interval);
        this.interval = null;
    };

    handleAnimation = () => {
        const rally = store.getData("FocusRally");
        if (this.state.animation >= rally.rallyLength - 1) this.handlePause();
        else
            this.setState({
                animation: this.state.animation + 1,
            })
    }
}

export default hotkey(keymap)(withStyles(styles, {withTheme: true})(PositionCollector));
