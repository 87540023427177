const pages = {
    WELCOME: 0,
    INFO: 1,
    LOCATE: 2,
    RECORD: 3,
    EXPORT: 4,
    AUTO_DETECTION: 5,
}

pages.sort = [
    pages.WELCOME,
    pages.INFO,
    pages.AUTO_DETECTION,
    pages.LOCATE,
    pages.RECORD,
    pages.EXPORT,
]

export default pages;
