import {dataActions} from "./dataActions";

const useChangeData = onChange => {
    const handleReverseScore = () => onChange({
        type: dataActions.ReverseScore,
        payload: null,
    });

    const handleSetGameScore = ({gId, score}) => onChange({
        type: dataActions.SetGameInfo,
        payload: {gId, gameInfo: {score}}
    });

    const handleSetRallyScore = ({gId, rId, score}) => {
        onChange({
            type: dataActions.SetRallyInfo,
            payload: {gId, rId, rallyInfo: {score}}
        })
        onChange({
            type: dataActions.SetRallyInfo,
            payload: {gId, rId: rId - 1, rallyInfo: {result: score}}
        })
    };

    const handleChangeStarter = ({gId, rId, startSide}) => onChange({
        type: dataActions.SetRallyInfo,
        payload: {
            gId, rId,
            rallyInfo: {startSide}
        }
    });

    const handleChangeStartTime = ({gId, rId, startTime}) => onChange({
        type: dataActions.SetRallyInfo,
        payload: {
            gId, rId,
            rallyInfo: {startTime}
        }
    });

    const handleChangeEndTime = ({gId, rId, endTime}) => onChange({
        type: dataActions.SetRallyInfo,
        payload: {
            gId, rId,
            rallyInfo: {endTime}
        }
    });

    const handleChangeRallyLength = ({gId, rId, rallyLength}) => onChange({
        type: dataActions.SetRallyInfo,
        payload: {
            gId, rId,
            rallyInfo: {rallyLength}
        }
    });

    return {
        handleChangeRallyLength,
        handleChangeStartTime,
        handleChangeEndTime,
        handleChangeStarter,
        handleSetRallyScore,
        handleSetGameScore,
        handleReverseScore,
    }
}

export default useChangeData;
