import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import store from '../../../Data/Store';

const styles = () => ({
    root: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        height: 'calc(100% - 50px)',
    },
    activate: {
        zIndex: 100,
    }
});

const resort = points => {
    let lt = 0, rt = 0, rb = 0, lb = 0;
    for (let i = 0; i < 4; i++) {
        if (points[lt][1] - points[lt][0] < points[i][1] - points[i][0]) lt = i;
        if (points[rt][1] + points[rt][0] < points[i][1] + points[i][0]) rt = i;
        if (points[rb][1] - points[rb][0] > points[i][1] - points[i][0]) rb = i;
        if (points[lb][1] + points[lb][0] > points[i][1] + points[i][0]) lb = i;
    }
    return [points[lt], points[rt], points[rb], points[lb]];
};

const getPos = e => {
    const boundingRect = e.target.getBoundingClientRect();
    return {
        x: e.clientX - boundingRect.x,
        y: e.clientY - boundingRect.y,
    }
};

class ReferenceTable extends Component {
    state = {
        corners: [],
        size: 5,
        startDrag: null,
    };
    svgRef = React.createRef();

    setPat = (e, visible) => {
        // const player = document.getElementById('video-player');
        // const box = player.getBoundingClientRect();
        // const {clientX: x, clientY: y} = e;
        // store.handleChange('SetPat')({
        //     x: (x - box.x) / box.width,
        //     y: (y - box.y) / box.height,
        //     time: player.currentTime,
        //     visible,
        // });
    };

    handleClick = e => {
        if (this.props.activate) {
            let {corners} = this.state;
            if (corners.length === 4) return;
            const boundingRect = e.target.getBoundingClientRect();
            const {x, y} = getPos(e);
            corners.push([x, y]);
            if (corners.length === 4) {
                corners = resort(corners);
                const {completeAnnotate: complete} = this.props;
                complete();
            }
            this.setState({
                corners,
                size: Math.min(boundingRect.width, boundingRect.height) * 0.01,
            });
        } else this.setPat(e, true);
    };

    handleClear = e => {
        e.preventDefault();
        if (this.props.activate) {
            this.setState({corners: []});
        } else this.setPat(e, false);
    };

    mouseDown = e => {
        if (!this.props.activate) return;
        e.preventDefault();
        const {x, y} = getPos(e);
        this.setState({startDrag: [x, y]});
    };

    mouseUp = e => {
        if (!this.props.activate) return;
        e.preventDefault();
        this.setState({startDrag: null});
    };

    mouseMove = e => {
        if (!this.props.activate) return;
        e.preventDefault();
        if (this.state.startDrag === null) return;
        const {x, y} = getPos(e);
        const {corners, startDrag} = this.state;
        corners.forEach(corner => {
            corner[0] += x - startDrag[0];
            corner[1] += y - startDrag[1];
        });
        this.setState({corners, startDrag: [x, y]});
    };

    renderReferenceLine() {
        const {theme} = this.props;
        const {corners} = this.state;
        const lines = [{
            x1: (corners[0][0] + corners[3][0]) / 2,
            y1: (corners[0][1] + corners[3][1]) / 2,
            x2: (corners[1][0] + corners[2][0]) / 2,
            y2: (corners[1][1] + corners[2][1]) / 2,
            main: true,
        }];
        for (let i = 0; i < 4; i++) {
            const a = corners[i], b = corners[(i + 1) % 4], c = corners[(i + 2) % 4], d = corners[(i + 3) % 4];
            lines.push({
                x1: a[0],
                y1: a[1],
                x2: b[0],
                y2: b[1],
                main: true,
            });
            if (i % 2 !== 0)
                lines.push({
                x1: a[0] * 2 / 3 + d[0] / 3,
                y1: a[1] * 2 / 3 + d[1] / 3,
                x2: b[0] * 2 / 3 + c[0] / 3,
                y2: b[1] * 2 / 3 + c[1] / 3,
                main: false,
            });
            // if (i % 2 === 0)
            //     lines.push({
            //         x1: a[0] * 5 / 6 + d[0] / 6,
            //         y1: a[1] * 5 / 6 + d[1] / 6,
            //         x2: b[0] * 5 / 6 + c[0] / 6,
            //         y2: b[1] * 5 / 6 + c[1] / 6,
            //         main: false,
            //     });
        }
        return <g>
            {lines.map((line, lId) => <line key={lId}
                                            x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2}
                                            stroke={theme.palette.secondary.main}
                                            strokeWidth={line.main ? 2 : 1}
                                            strokeDasharray={line.main ? null : [5, 4]}/>)}
        </g>
    }

    transXY = pos => {
        const video = document.getElementById('video-player').getBoundingClientRect();
        const svg = this.svgRef.current.getBoundingClientRect();
        const x = pos.x * video.width + video.x - svg.x;
        const y = pos.y * video.height + video.y - svg.y;
        return {x, y};
    };

    renderPatPos(pos) {
        const {theme} = this.props;
        if (pos === null) return null;
        else {
            const transPos = this.transXY(pos);
            return <circle cx={transPos.x} cy={transPos.y} r={this.state.size}
                           stroke={theme.palette.secondary.main}
                           strokeDasharray={pos.visible ? 'none' : '2 2'}
                           fill={pos.visible ? theme.palette.secondary.main : 'none'}/>
        }
    }

    render() {
        const {classes, activate, theme} = this.props;
        const {corners, size} = this.state;
        const patPos = store.getData('PatPos');

        return <div className={clsx(classes.root, {
            [classes.activate]: activate,
        })}>
            <svg width={'100%'} height={'100%'}
                 ref={this.svgRef}
                 onMouseDown={this.mouseDown}
                 onMouseUp={this.mouseUp}
                 onMouseMove={this.mouseMove}
                 onClick={this.handleClick}
                 onContextMenu={this.handleClear}>
                {corners.length < 4 &&
                corners.map((corner, cId) => {
                    return <circle key={cId}
                                   cx={corner[0]} cy={corner[1]} r={size}
                                   fill={theme.palette.secondary.main}/>
                })}
                {corners.length === 4 && this.renderReferenceLine()}
                {this.renderPatPos(patPos)}
            </svg>
        </div>;
    }
}

export default withStyles(styles, {withTheme: true})(ReferenceTable);

// edited by WuJiang5521 on 2019/11/4
