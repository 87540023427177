import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import Pause from "@material-ui/icons/Pause";
import PlayArrow from "@material-ui/icons/PlayArrow";
import {fade} from "@material-ui/core/styles/colorManipulator";
import store from "../../../Data/Store";

const style = store.pageStyle;
const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
    },
    video: {
        width: '100%',
    },
    controlPanel: {
        position: 'absolute',
        bottom: -49,
        height: 48,
        left: 0,
        right: 0,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        display: 'flex',
    },
    timelineAll: {
        flex: 5,
        position: 'relative',
        height: '100%',
    },
    progress: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    playedPart: {
        position: 'absolute',
        left: '10px',
        top: '10px',
        height: '10px',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
    },
    toPlayPart: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        height: '10px',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        backgroundColor: fade(theme.palette.primary.main, 0),
    },
    playedText: {
        position: 'absolute',
        left: '10px',
        top: '29px',
        bottom: '3px',
        lineHeight: '16px',
        fontSize: '14px',
        color: style.text,
    },
    toPlayText: {
        position: 'absolute',
        right: '10px',
        top: '29px',
        bottom: '3px',
        lineHeight: '16px',
        fontSize: '14px',
        color: style.text,
    },
    currentPot: {
        position: 'absolute',
        top: '5px',
        width: '20px',
        height: '20px',
        borderRadius: '10px',
        border: `2px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.main,
    },
});

const Num2Time = num => {
    let txt = '';
    if (num < 0) {
        txt = '-';
        num = -num;
    }
    num = parseInt(num, 10);
    const min = parseInt(num / 60, 10), sec = num % 60;
    txt += `${min} : ${sec < 10 ? '0' : ''}${sec}`;
    return txt;
};

class SimpleVideoPlayer extends Component {
    state = {
        play: false,
        duration: 0,
        progress: 0,
    };

    videoEle = React.createRef();
    cmpPosition = 0;
    totalLength = 1;

    //region Interactive

    handleVideo = ele => {
        this.videoEle.current = ele;
        this.props.videoRef && (this.props.videoRef.current = ele);
        if (ele === null) return;
        ele.ontimeupdate = e => {
            this.setState({progress: e.target.currentTime})
        }
    }

    handleMouseDown = key => e => {
        this.slideOnControl = key;
        const dom = document.getElementById(`${key}SlideSimplePlayer`);
        const rect = dom.getBoundingClientRect();
        switch (key) {
            case 'total':
                this.cmpPosition = rect.left;
                this.totalLength = dom.clientWidth - 32;
                break;
            default:
                this.cmpPosition = 0;
                this.totalLength = 1;
        }
        this.handleMouseMove(e);
    };

    handleMouseMove = e => {
        if (this.slideOnControl !== '') {
            switch (this.slideOnControl) {
                case 'total': {
                    const videoEle = this.videoEle.current;
                    let offset = e.clientX - this.cmpPosition - 16;
                    if (offset < 0) offset = 0;
                    if (offset > this.totalLength) offset = this.totalLength;
                    const rate = offset / this.totalLength;
                    videoEle.currentTime = videoEle.duration * rate;
                    this.setState({
                        progress: videoEle.duration * rate,
                    });
                }
                    break;
                default:
                    break;
            }
        }
        if (!this.state.hover) this.setState({
            hover: true
        });
    };

    handleMouseUp = e => {
        this.slideOnControl = '';
        this.cmpPosition = 0;
        this.totalLength = 1;
        this.handleMouseMove(e);
    };

    handleTogglePlay = () => {
        this.setState(({play}) => {
            if (this.videoEle.current) {
                if (!play) this.videoEle.current.play();
                else this.videoEle.current.pause();
            }
            return {
                play: !play,
            }
        })
    }

    //endregion

    renderProgress(start, end, key) {
        const {classes} = this.props;
        if (isNaN(start) || isNaN(end) || end <= start) return <div/>;
        const progress = this.state.progress < start ? start : (this.state.progress > end ? end : this.state.progress);
        const playedLength = progress - start, toPlayLength = end - progress;
        const playedText = Num2Time(this.state.progress - start), toPlayText = Num2Time(end - this.state.progress);
        const playedRate = playedLength / (end - start), toPlayRate = toPlayLength / (end - start);
        return <div id={key + 'SlideSimplePlayer'} className={classes.progress}
                    onMouseDown={this.handleMouseDown(key)}>
            <div className={classes.playedPart}
                 style={{width: `calc(${100 * playedRate}% - ${32 * playedRate - 5}px)`}}/>
            <div className={classes.toPlayPart}
                 style={{width: `calc(${100 * toPlayRate}% - ${32 * toPlayRate - 5}px)`}}/>
            <div className={classes.currentPot}
                 style={{left: `calc(${100 * playedRate}% - ${32 * playedRate - 4}px)`}}/>
            <div className={classes.playedText}>{playedText}</div>
            <div className={classes.toPlayText}>{toPlayText}</div>
        </div>
    }

    handleLoadedMetaData = (e) => {
        this.setState({
            duration: e.target.duration,
        })
    }

    render() {
        const {classes, src, onLoadedMetaData} = this.props;

        return <div className={classes.root}>
            <video className={classes.video}
                   src={src}
                   ref={this.handleVideo}
                   onLoadedMetadata={e => {
                       this.handleLoadedMetaData(e);
                       onLoadedMetaData && onLoadedMetaData(e);
                   }}/>
            <div className={classes.controlPanel}
                 onMouseUp={this.handleMouseUp} onMouseMove={this.handleMouseMove}>
                <IconButton onClick={this.handleTogglePlay}>{this.state.play ? <Pause/> :
                  <PlayArrow/>}</IconButton>
                <div className={classes.timelineAll}>
                    {this.renderProgress(0, this.state.duration, 'total')}
                </div>
            </div>
        </div>;
    }
}

export default withStyles(styles)(SimpleVideoPlayer);

// edited by WuJiang5521 on 2021/5/24
