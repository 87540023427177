import React, {Component} from 'react';
import {Step, StepLabel, Stepper, withStyles} from '@material-ui/core';
import store from "../../Data/Store";
import Preprocessor from "./Steps/Preprocessor";
import ScoreboardAnnotator from "./Steps/ScoreboardAnnotator";
import TableAnnotator from "./Steps/TableAnnotator";
import NetAnnotator from "./Steps/NetAnnotator";
import BackendProcessor from "./Steps/BackendProcessor";
import DataChecker from "./Steps/DataChecker";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${theme.spacing(10)}px)`,
        height: `calc(100% - ${theme.spacing(10)}px)`,
        margin: theme.spacing(5),
        overflow: "hidden",
        backgroundColor: theme.palette.primary.contrastText,
    },
    contentContainer: {
        width: '100%',
        flex: '1 1 0',
        height: 'auto',
        overflow: 'hidden',
    },
    contentWrapper: {
        width: 'fit-content',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        transition: 'transform .3s',
    },
    singleContent: {}
});

class AutoDetection extends Component {
    state = {
        contentSize: [0, 0]
    }
    getState = () => ({
        ...store.autoDetectionState,
        ...this.state,
    });
    handleSetState = newState => {
        if (newState instanceof Function)
            newState = newState(this.getState());
        store.autoDetectionState = {
            ...store.autoDetectionState,
            ...newState,
        }
        store.refreshPage("autoDetection")
    }

    //region steps
    steps = [
        {
            key: "dataPreprocess",
            title: store.languageSwitch("dataPreprocess"),
            component: this.renderDataPreprocess.bind(this),
        },
        {
            key: "annotateScore",
            title: store.languageSwitch("annotateScore"),
            component: this.renderAnnotateScore.bind(this),
        },
        {
            key: "annotateTable",
            title: store.languageSwitch("annotateTable"),
            component: this.renderAnnotateTable.bind(this),
        },
        {
            key: "annotateNet",
            title: store.languageSwitch("annotateNet"),
            component: this.renderAnnotateNet.bind(this),
        },
        {
            key: "backendProcess",
            title: store.languageSwitch("backendProcess"),
            component: this.renderBackendProcess.bind(this),
        },
        {
            key: "dataCheck",
            title: store.languageSwitch("dataCheck"),
            component: this.renderDataCheck.bind(this),
        },
    ]

    onPreStep = () => {
        this.handleSetState(state => ({
            activeStep: Math.max(state.activeStep - 1, 0),
        }))
    }
    onNextStep = () => {
        this.handleSetState(state => ({
            activeStep: Math.min(state.activeStep + 1, this.steps.length - 1),
        }))
    }
    //endregion

    //region size
    ref = React.createRef()

    tryResize = () => {
        if (!this.ref.current) return;
        const box = this.ref.current.getBoundingClientRect();
        if (box.width !== this.getState().contentSize[0] || box.height !== this.getState().contentSize[1])
            this.setState({contentSize: [box.width, box.height]});
    }
    //endregion

    //region life cycle
    componentDidMount() {
        store.register({"autoDetection": this});
        this.tryResize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.tryResize();
    }

    componentWillUnmount() {
        store.unregister({"autoDetection": this});
    }

    //endregion

    handleLockPage = (lockReason) => {
        store.handleChange("LockPage")(lockReason);
    }
    handleDisableLockPage = () => {
        store.handleChange("LockPage")(null);
    }

    //region render
    renderDataPreprocess(activate) {
        return <Preprocessor activate={activate}
                             videoFile={store.getData('VideoFile')}
                             onProcessEnd={(md5, config) => this.handleSetState({md5, ...config})}
                             onNext={this.onNextStep}/>
    }

    handleSetScoreboard = scoreboard => this.handleSetState({
        scoreboard,
        canSkipUploadAnnotation: false,
        canSkipSegment: false
    });

    renderAnnotateScore(activate) {
        return <ScoreboardAnnotator activate={activate}
                                    scoreboard={this.getState().scoreboard}
                                    onSetScoreboard={this.handleSetScoreboard}
                                    onPre={this.onPreStep}
                                    onNext={this.onNextStep}/>
    }

    handleSetTable = table => this.handleSetState({
        table,
        canSkipUploadAnnotation: false,
        canSkipSegment: false
    });

    renderAnnotateTable(activate) {
        return <TableAnnotator activate={activate}
                               table={this.getState().table}
                               onSetTable={this.handleSetTable}
                               onPre={this.onPreStep}
                               onNext={this.onNextStep}/>
    }

    handleSetNet = net => this.handleSetState({
        net,
        canSkipUploadAnnotation: false,
        canSkipSegment: false
    });

    renderAnnotateNet(activate) {
        return <NetAnnotator activate={activate}
                             net={this.getState().net}
                             onSetNet={this.handleSetNet}
                             onPre={this.onPreStep}
                             onNext={this.onNextStep}/>
    }

    renderBackendProcess(activate) {
        const state = this.getState();
        const videoFile = store.getData('VideoFile');
        return <BackendProcessor activate={activate}
                                 videoFile={videoFile}
                                 videoName={videoFile.name}
                                 md5={state.md5}
                                 canSkipUploadVideo={state.canSkipUploadVideo}
                                 scoreboard={state.scoreboard}
                                 table={state.table}
                                 net={state.net}
                                 canSkipUploadAnnotation={state.canSkipUploadAnnotation}
                                 onProcessEnd={gameStructure => this.handleSetState(gameStructure)}
                                 canSkipSegment={state.canSkipSegment}
                                 onPre={this.onPreStep}
                                 onNext={this.onNextStep}
                                 onStartBackendProcess={() => this.handleLockPage("后端正在运算中，请勿切换页面！")}
                                 onEndBackendProcess={() => this.handleDisableLockPage()}/>
    }

    renderDataCheck(activate) {
        return <DataChecker activate={activate}
                            src={store.getData("Video")}
                            fps={this.getState().fps}
                            segmentData={this.getState().segmentData}
                            onPre={this.onPreStep}
                            onProcessEnd={(systemData, backendData) => {
                                store.handleChange("MergeAutoDetectionData")(systemData);
                                // TODO: send back backendData
                            }}
                            onStartProcess={() => this.handleLockPage("切换页面将导致临时修改丢失，请勿切换页面！")}
                            onEndProcess={() => this.handleDisableLockPage()}/>
    }

    render() {
        const {classes} = this.props;
        const {activeStep, contentSize} = this.getState();

        return <div className={classes.root}>
            <Stepper activeStep={activeStep}>
                {this.steps.map(step => {
                    return <Step key={step.key}>
                        <StepLabel>{step.title}</StepLabel>
                    </Step>
                })}
            </Stepper>
            <div className={classes.contentContainer}
                 ref={this.ref}>
                <div className={classes.contentWrapper}
                     style={{transform: `translateX(-${activeStep * contentSize[0]}px)`}}>
                    {this.steps.map((step, stepId) => (
                      <div key={step.key}
                           className={classes.singleContent}
                           style={{width: contentSize[0], height: contentSize[1]}}>
                          {activeStep === stepId && step.component(activeStep === stepId)}
                      </div>
                    ))}
                </div>
            </div>
        </div>;
    }

    //endregion
}

export default withStyles(styles)(AutoDetection);

// edited by WuJiang5521 on 2021/10/14
