import React, {useCallback, useEffect, useRef} from 'react';
import {Button, Collapse, makeStyles} from "@material-ui/core";
import store from "../../../../Data/Store";
import RallyStruct from "./RallyStruct";
import useAutoScroll from "../../Utils/useAutoScroll";
import {useControllableValue} from "ahooks";
import interleave from "../../Utils/interleave";
import InsertionPlaceholder from "./InsertionPlaceholder";
import ErrorHinter from "./ErrorHinter";

const titleHeight = 36;

const useStyles = makeStyles(theme => ({
    rootGameStruct: {},
    title: {
        width: '100%',
        borderRadius: 0,
        height: titleHeight,
        lineHeight: `${titleHeight}px`,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    rallyList: {
        overflow: 'hidden auto',
        position: 'relative',
    },
}));

function GameStruct({
                        idx,
                        game,
                        focus,
                        onFocusGame,
                        onFocusRally,
                        maxHeight,
                        ...props
                    }) {
    const classes = useStyles();

    //region insertion
    const [selectedInsertion, setInsertion] = useControllableValue(props, {
        valuePropName: 'insertion',
        trigger: 'onSetInsertion',
    });
    //endregion

    const handleFocusGame = useCallback(() => onFocusGame(idx), [onFocusGame, idx]);
    const handleFocusRally = (rId) => {
        setInsertion(-1);
        onFocusRally(rId);
    };

    //region auto scroll
    const listRef = useRef(null);
    const scrollTo = useAutoScroll(listRef, {numNeighborVisible: 4});
    useEffect(() => scrollTo(focus.rId * 2 + 1), [focus]);
    //endregion

    return <div className={classes.rootGameStruct}>
        <ErrorHinter errInfo={game.errInfo}>
            <Button className={classes.title}
                    variant={'contained'}
                    color={game.errInfo.length === 0 ? 'primary' : 'secondary'}
                    onClick={handleFocusGame}>
                {store.languageSwitch("GameNum")(idx + 1)}
            </Button>
        </ErrorHinter>
        <Collapse in={focus.gId === idx} timeout='auto' unmountOnExit>
            <div className={classes.rallyList}
                 ref={listRef}
                 style={{maxHeight: maxHeight ? maxHeight(titleHeight) : undefined}}>
                {interleave(
                  game.list.map((rally, rId) => (
                    <RallyStruct key={`rally-${rId}`}
                                 idx={rId}
                                 rally={rally}
                                 focus={focus}
                                 onFocusRally={handleFocusRally}/>
                  )),
                  (rId) => (
                    <InsertionPlaceholder key={`ins-${rId}`}
                                          idx={rId}
                                          activate={selectedInsertion === rId}
                                          onSelect={setInsertion}/>
                  )
                )}
            </div>
        </Collapse>
    </div>
}

export default GameStruct;
