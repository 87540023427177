import React, {Component} from 'react';
import {Button, Input, Typography, withStyles} from '@material-ui/core';
import store from '../../../Data/Store';
import {fade} from "@material-ui/core/styles/colorManipulator";
import clsx from 'clsx';
import {Videocam, Close, ArrowUpward, ArrowDownward} from "@material-ui/icons";

const styles = theme => ({
    root: {
        margin: `${theme.spacing(1)}px 0`,

        borderWidth: '2px',
        borderRadius: '0.3vw',
        borderStyle: 'solid',
        borderColor: fade(theme.palette.primary.light, 0.25),

        height: '120px',

        display: 'flex',
        alignItems: 'center',
    },
    content: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        height: '100%',
    },
    justifyIndex: {
        width: 40,
        height: '100%',
    },
    up: {
        width: 40,
        height: 40,
        minWidth: 0,
        minHeight: 0,
    },
    down: {
        width: 40,
        height: 40,
        minWidth: 0,
        minHeight: 0,
    },
    index: {
        width: 40,
        height: 40,
        minWidth: 0,
        minHeight: 0,
    },
    infoArea: {
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    indexArea: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '20px',
        height: 20,
        lineHeight: '20px',
        display: 'flex',
        marginTop: theme.spacing(1),
    },
    indexText: {
        textAlign: 'right',
        flex: 1,
        margin: `0 ${theme.spacing(1)}px`,
        '& ~ p': {
            textAlign: 'left',
        }
    },
    ballArea: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '20px',
        width: '20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "center",
        overflow: 'hidden',
    },
    ball: {
        borderRadius: '50%',
        paddingTop: '70%',
        width: '70%',
        margin: '15%',
    },
    showBall: {
        backgroundColor: theme.palette.primary.light,
    },
    nameArea: {
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        overflow: 'hidden',
    },
    sideArea: {
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: '50%',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    name: {
        height: '50%',
        width: '100%',
    },
    alignLeft: {
        textAlign: 'left',
    },
    alignRight: {
        textAlign: 'right',
    },
    nameText: {
        overflow: 'hidden',
        maxWidth: '80px',
        textAlign: 'center',
    },
    nameWin: {
        fontWeight: 'bold'
    },
    scoreArea: {
        flexGrow: 0,
        flexShrink: 0,
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "flex-end",
        marginBottom: theme.spacing(1),
    },
    rallyScore: {
        fontSize: '1.3rem',
        margin: `0 ${theme.spacing(1)}px`,
        textAlign: 'center',
    },
    gameScore: {
        fontSize: '0.8rem',
        margin: `0 ${theme.spacing(1)}px`,
        textAlign: 'center',
    },
    centerDivider: {
        width: 'auto',
        flexGrow: 0,
        flexShrink: 0,
        margin: `0 ${theme.spacing(1)}px`,
        fontSize: '1rem',
    },
    action: {
        width: 40,
        flex: '0 0 40px',
    },
    playButton: {
        width: 40,
        height: 40,
        minWidth: 0,
        minHeight: 0,
        flex: '0 0 40px',
    },
    delButton: {
        width: 40,
        height: 40,
        minWidth: 0,
        minHeight: 0,
        flex: '0 0 40px',
    }
});

class VideoRecord extends Component {
    state = {
        editIndex: false,
        index: '0',
    };

    onPre = () => {
        const {rId} = this.props;
        this.onIdx(rId - 1);
    };

    onNxt = () => {
        const {rId} = this.props;
        this.onIdx(rId + 1);
    };

    onIdx = to => {
        const {rId} = this.props;
        store.handleChange('VideoIndex')({from: rId, to});
    };

    startEdit = () => this.setState({editIndex: true, index: '0'});

    handleEdit = e => {
        const val = parseInt(e.target.value, 10);
        if (isNaN(val)) this.setState({index: '0'});
        else this.setState({index: val.toString()});
    };

    handleKey = e => {
        if (e.key === 'Enter') {
            this.submitEdit();
            this.endEdit();
        }
    };

    submitEdit = () => {
        const val = parseInt(this.state.index, 10);
        if (!isNaN(val))
            this.onIdx(parseInt(this.state.index, 10) - 1);
    };

    endEdit = () => this.setState({editIndex: false});

    renderName(name, sId, win) {
        const {classes} = this.props;
        return <div key={name + sId}
                    className={clsx(classes.name, sId === 0 ? classes.alignRight : classes.alignLeft)}>
            <Typography noWrap className={clsx(classes.nameText, {[classes.nameWin]: win})}>{name}</Typography>
        </div>
    }

    renderBall = (show) => {
        const {classes} = this.props;
        return <div className={clsx(classes.ball, {
            [classes.showBall]: show
        })}/>
    };

    render() {
        const {classes, rId, redirect} = this.props;
        const rallyInfo = store.getData('RallyInfo')(redirect) || {
            startSide: 0,
            winSide: 0,
            name: [[''], ['']],
            gameScore: [0, 0],
            rallyScore: [0, 0],
        };

        return <div className={classes.root}>
            <div className={classes.justifyIndex}>
                <Button className={classes.up} onClick={this.onPre}>
                    <ArrowUpward/>
                </Button>
                {
                    this.state.editIndex &&
                    <Input
                      autoFocus
                      className={classes.index}
                      value={this.state.index}
                      onChange={this.handleEdit}
                      onKeyPress={this.handleKey}
                      onBlur={this.endEdit}
                    />
                }
                {
                    !this.state.editIndex &&
                    <Button className={classes.index} onClick={this.startEdit}>
                        {rId + 1}
                    </Button>
                }
                <Button className={classes.down} onClick={this.onNxt}>
                    <ArrowDownward/>
                </Button>
            </div>
            <div className={classes.content}>
                <div className={classes.infoArea}>
                    <div className={classes.indexArea}>
                        <Typography className={classes.indexText}>
                            {store.languageSwitch('GameNum')(redirect[0] + 1)}
                        </Typography>
                        <Typography className={classes.indexText}>
                            {store.languageSwitch('RallyNum')(redirect[1] + 1)}
                        </Typography>
                    </div>
                    <div className={classes.nameArea}>
                        <div className={classes.ballArea}>
                            {this.renderBall(rallyInfo.startSide === 0)}
                        </div>
                        <div className={classes.sideArea}>
                            {rallyInfo.name[0].map(name => this.renderName(name, 0, rallyInfo.winSide === 0))}
                        </div>
                        <div className={classes.centerDivider}>
                            vs
                        </div>
                        <div className={classes.sideArea}>
                            {rallyInfo.name[1].map(name => this.renderName(name, 1, rallyInfo.winSide === 1))}
                        </div>
                        <div className={classes.ballArea}>
                            {this.renderBall(rallyInfo.startSide === 1)}
                        </div>
                    </div>
                    <div className={classes.scoreArea}>
                        <div className={classes.rallyScore}>
                            {rallyInfo.rallyScore[0]}
                        </div>
                        <div className={classes.gameScore}>
                            {rallyInfo.gameScore[0]}
                        </div>
                        <div className={classes.centerDivider}>
                            :
                        </div>
                        <div className={classes.gameScore}>
                            {rallyInfo.gameScore[1]}
                        </div>
                        <div className={classes.rallyScore}>
                            {rallyInfo.rallyScore[1]}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.action}>
                <Button color={"secondary"} className={classes.delButton}
                        onClick={() => store.handleChange('DelExportVideo')(rId)}>
                    <Close/>
                </Button>
                <Button className={classes.playButton}
                        onClick={() => store.handleChange('PlayExportVideo')(rId)}>
                    <Videocam/>
                </Button>
            </div>
        </div>;
    }
}

export default withStyles(styles)(VideoRecord);

// edited by WuJiang5521 on 2019/9/3
