import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import registerServiceWorker from './registerServiceWorker';
import Utils from "./Utils";

Utils.init();
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
