import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import store from '../../Data/Store';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import clsx from 'clsx';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Button} from "@material-ui/core";
import {fade} from "@material-ui/core/styles/colorManipulator";

const style = store.pageStyle;

const styles = theme => ({
    root: {
        minWidth: '40%',
        width: `auto`,
        height: `calc(50% - ${theme.spacing(4) + 6}px)`,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
    },
    primaryBlock: {
        borderWidth: 2,
        borderRadius: '0.3vw',
        borderStyle: 'solid',
        borderColor: fade(theme.palette.primary.light, 0.25),
    },
    secondaryBlock: {
        borderWidth: 2,
        borderRadius: '0.3vw',
        borderStyle: 'solid',
        borderColor: fade(theme.palette.secondary.light, 0.25),
    },
    content: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    playerId: {
        position: 'absolute',
        color: theme.palette.secondary.main,
        fontSize: '1.3rem'
    },
    record: {
        width: '60%',
        height: '50px',
        margin: '3px 20%',
        textAlign: 'center',
        display: 'flex',
    },
    textField: {
        height: '100%',
        margin: `0 ${theme.spacing(1)}px`,
        flex: 1,
        flexBasis: 200,
        '& div': {
            color: style.text,
            '& div': {
                '& svg': {color: style.text}
            }
        }
    },
    cssRoot: {
        color: style.text,
        '&$cssFocused': {color: theme.palette.primary.main},
    },
    cssFilled: {color: theme.palette.primary.main},
    cssFocused: {},
    cssUnderline: {
        '&:before': {borderBottom: `1px solid ${style.text}`},
        '&:after': {borderBottomColor: theme.palette.primary.main},
    },
    colorSwitchBase: {
        color: theme.palette.primary.main,
        '& + $colorBar': {backgroundColor: style.text},
        '&$colorChecked': {
            color: theme.palette.primary.main,
            '& + $colorBar': {backgroundColor: style.text},
        },
    },
    colorBar: {},
    colorChecked: {},
    switchButton: {
        lineHeight: '50px',
    },
    switchChoice: {
        flex: 1,
        lineHeight: '32px',
        height: 32,
        minHeight: 0,
        padding: 0,
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.getContrastText(theme.palette.primary.contrastText),
        textAlign: 'left',
        border: `1px solid ${fade(theme.palette.primary.light, 0.25)}`,
        margin: theme.spacing(1),
    },
    currentSwitchChoice: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
});

class PlayerInfo extends Component {
    render() {
        const {classes, playerId} = this.props;
        const playerInfo = store.getData(`Player${playerId}`);

        return <div
            className={clsx(classes.root, classes.primaryBlock)}>
            <div className={classes.content}>
                <Typography className={classes.playerId}>
                    {`${playerId[0] === '0' ? 'A' : "B"}${+playerId[1] + 1}`}
                </Typography>
                <div className={classes.record}>
                    <TextField
                        className={classes.textField}
                        label={store.languageSwitch('playerName')}
                        value={playerInfo.name}
                        InputLabelProps={{
                            classes: {
                                root: classes.cssRoot,
                                filled: classes.cssFilled,
                                focused: classes.cssFocused,
                            }
                        }}
                        InputProps={{
                            classes: {
                                underline: classes.cssUnderline,
                            }
                        }}
                        onChange={store.handleChange(`Player${playerId}Name`)}
                    />
                </div>
                <div className={classes.record}>
                    <TextField
                        className={classes.textField}
                        label={store.languageSwitch('playerCountry')}
                        value={playerInfo.country}
                        InputLabelProps={{
                            classes: {
                                root: classes.cssRoot,
                                filled: classes.cssFilled,
                                focused: classes.cssFocused,
                            }
                        }}
                        InputProps={{
                            classes: {
                                underline: classes.cssUnderline,
                            }
                        }}
                        onChange={store.handleChange(`Player${playerId}Country`)}
                    />
                </div>
                <div className={classes.record}>
                    <TextField
                        className={classes.textField}
                        label={store.languageSwitch('playerRank')}
                        value={playerInfo.rank}
                        InputLabelProps={{
                            classes: {
                                root: classes.cssRoot,
                                filled: classes.cssFilled,
                                focused: classes.cssFocused,
                            }
                        }}
                        InputProps={{
                            classes: {
                                underline: classes.cssUnderline,
                            }
                        }}
                        onChange={store.handleChange(`Player${playerId}Rank`)}
                    />
                </div>
                <div className={classes.record}>
                    <Button className={clsx({
                        [classes.switchChoice]: true,
                        [classes.currentSwitchChoice]: !playerInfo.rightHand,
                    })} onClick={() => store.handleChange(`Player${playerId}Hand`)({target: {checked: false}})}>
                        {store.languageSwitch('playerLeftHand')}
                    </Button>
                    <Button className={clsx({
                        [classes.switchChoice]: true,
                        [classes.currentSwitchChoice]: playerInfo.rightHand,
                    })} onClick={() => store.handleChange(`Player${playerId}Hand`)({target: {checked: true}})}>
                        {store.languageSwitch('playerRightHand')}
                    </Button>
                </div>
                <div className={classes.record}>
                    <Button className={clsx({
                        [classes.switchChoice]: true,
                        [classes.currentSwitchChoice]: !playerInfo.isStraight,
                    })} onClick={() => store.handleChange(`Player${playerId}Pad`)({target: {checked: false}})}>
                        {store.languageSwitch('playerHorizontalPat')}
                    </Button>
                    <Button className={clsx({
                        [classes.switchChoice]: true,
                        [classes.currentSwitchChoice]: playerInfo.isStraight,
                    })} onClick={() => store.handleChange(`Player${playerId}Pad`)({target: {checked: true}})}>
                        {store.languageSwitch('playerStraightPat')}
                    </Button>
                </div>
                <div className={classes.record}>
                    <TextField
                        select
                        className={classes.textField}
                        label={store.languageSwitch('playerBackFace')}
                        value={playerInfo.face[0]}
                        InputLabelProps={{
                            classes: {
                                root: classes.cssRoot,
                                filled: classes.cssFilled,
                                focused: classes.cssFocused,
                            }
                        }}
                        InputProps={{
                            classes: {
                                underline: classes.cssUnderline,
                            }
                        }}
                        onChange={store.handleChange(`Player${playerId}BackFace`)}
                    >
                        {store.getData("FaceTypes").map((option, i) => {
                            const label = store.languageSwitch(option);
                            if (label === option) return null;
                            else return <MenuItem key={option} value={i}>
                                {label}
                            </MenuItem>
                        })}
                    </TextField>
                    <TextField
                        select
                        className={classes.textField}
                        label={store.languageSwitch('playerForeFace')}
                        value={playerInfo.face[1]}
                        InputLabelProps={{
                            classes: {
                                root: classes.cssRoot,
                                filled: classes.cssFilled,
                                focused: classes.cssFocused,
                            }
                        }}
                        InputProps={{
                            classes: {
                                underline: classes.cssUnderline,
                            }
                        }}
                        onChange={store.handleChange(`Player${playerId}ForeFace`)}
                    >
                        {store.getData("FaceTypes").map((option, i) => {
                            const label = store.languageSwitch(option);
                            if (label === option) return null;
                            else return <MenuItem key={option} value={i}>
                                {label}
                            </MenuItem>
                        })}
                    </TextField>
                </div>
            </div>
        </div>;
    }
}

export default withStyles(styles)(PlayerInfo);
