import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MatchTree from "../Common/MatchTree";
import Video from "../Common/Video/Video";
import StrikeList from "./StrikeList/StrikeList";
import TermEditor from "./TermEditor/TermEditor";
import {fade} from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.primary.contrastText,
  },
  block: {
    borderWidth: '2px',
    borderRadius: '8px',
    borderStyle: 'solid',
    borderColor: fade(theme.palette.primary.light, 0.25),
  },
  matchTree: {
    position: 'absolute',
    top: '10px',
    bottom: '226px',
    left: '30px',
    width: '240px',
  },
  termArea: {
    position: 'absolute',
    top: '10px',
    bottom: '10px',
    right: '30px',
    width: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2))',
  },
  strikeList: {
    position: 'absolute',
    bottom: '10px',
    height: '200px',
    left: '30px',
    right: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2) + 46px)',
  },
  videoPlayer: {
    position: 'absolute',
    top: '10px',
    bottom: '226px',
    left: '286px',
    right: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2) + 46px)',
  },
});

class Record extends Component {
  render() {
    const {classes} = this.props;

    return <div className={classes.root}>
      <div className={clsx(classes.matchTree, classes.block)}>
        <MatchTree />
      </div>
      <div className={clsx(classes.videoPlayer, classes.block)}>
        <Video plugins={['ReferenceTable']}/>
      </div>
      <div className={clsx(classes.termArea, classes.block)}>
        <TermEditor />
      </div>
      <div className={clsx(classes.strikeList, classes.block)}>
        <StrikeList />
      </div>
    </div>;
  }
}

export default withStyles(styles)(Record);
