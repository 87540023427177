import React, {Component} from 'react';
import {withStyles, Typography} from '@material-ui/core';

const styles = theme => ({
    root: {
        height: '30vh',
        position: 'relative',
    },
    svg: {
        height: '100%',
    },

    rootDiv: {
        height: 20,
        // width:  80,
        margin: 10,
        // border: '2px solid blue',
        display: 'flex',
        // flexwrap:'nowrap',
    },
    legendRect: {
        height: 20,
        width: 40,
        margin: '0px 5px'
    },

    backSvgArea: {
        position: 'absolute',
        zIndex: 0,
        top: 40,
        bottom: 50,
        left: 0,
        right: 0,
        overflow: 'hidden',
    },
    frontSvgArea: {
        position: 'relative',
        height: 'calc(100% - 80px)',
        left: 'calc(6vh - 16px)',
        width: 'calc(100% - 6vh + 16px)',
        marginBottom: 0,
        zIndex: 1,
        overflowX: 'scroll',
        overflowY: 'hidden',
    },
});

function LegendComp(props) {
    return <div className={props.classes.rootDiv}>
        <div className={props.classes.legendRect} style={{backgroundColor: props.fill}} onClick={props.handleClick}/>
        <Typography>{props.name}</Typography>
    </div>
}

const Legend = withStyles(styles)(LegendComp);

// const data = [[0, 1], [1, 1], [2, 1], [3, 1], [3, 2], [3, 3], [4, 3], [5, 3],
//     [6, 3], [6, 4], [6, 5], [7, 5], [8, 5], [9, 5], [9, 6], [9, 7], [9, 8], [9, 9], [10, 9], [11, 9]];

class ScoreStepGraph extends Component {
    state = {
        x: 0,
        y: 0,
        rectX: -1,
        rectY: -1,
        redSwitch: true,
        blueSwitch: true,
    };

    transXY = (oriX, oriY) => {
        const {data} = this.props;
        const box = document.getElementById('box').getBoundingClientRect();
        const ratio = (15 * data.length) / box.width;
        return {
            x: (oriX - box.x) * ratio,
            y: (oriY - box.y) * ratio,
        }
    };

    move = e => {
        const {x, y} = this.transXY(e.clientX, e.clientY);
        this.setState({x: x, y: y})
    };

    handleClick = idx => {
        const {handleClick} = this.props;
        let count = 0;
        handleClick('add-list', () => (count++ === idx))
    };

    moveRect = e => {
        const {x, y} = this.transXY(e.clientX, e.clientY);
        this.setState({rectX: x, rectY: y})
    };

    leaveRect = () => {
        this.setState({rectX: -1, rectY: -1})
    };

    RedClick = () => {
        this.setState({redSwitch: !this.state.redSwitch})
    };

    BlueClick = () => {
        this.setState({blueSwitch: !this.state.blueSwitch})
    };

    render() {
        const {classes, theme, data, time, player} = this.props;
        // 写明x轴y轴长度
        const padding = 0;
        const backPadding = 50;
        const bottomPadding = 2;
        const topPadding = 10;
        // const xLen = 500;
        const yLen = 200;
        // 坐标线长度
        const spline = 5;
        //文本宽度
        const textPadding = 5;
        //圆与x轴间距
        const circlePadding = 12;
        //矩形与x轴间距,矩形高度
        const rectHeight = 25;
        const redFill = theme.palette.primary.main;
        const blueFill = theme.palette.secondary.main;
        //数据
        // const data = [[0, 1], [1, 1], [2, 1], [3, 1], [3, 2], [3, 3], [4, 3], [5, 3],
        //     [6, 3], [6, 4], [6, 5], [7, 5], [8, 5], [9, 5], [9, 6], [9, 7], [9, 8], [9, 9], [10, 9], [11, 9],[0, 1], [1, 1], [2, 1], [3, 1], [3, 2], [3, 3], [4, 3], [5, 3],
        //     [6, 3], [6, 4], [6, 5], [7, 5], [8, 5], [9, 5], [9, 6], [9, 7], [9, 8], [9, 9], [10, 9], [11, 9],[0, 1], [1, 1], [2, 1], [3, 1], [3, 2], [3, 3], [4, 3], [5, 3],
        //     [6, 3], [6, 4], [6, 5], [7, 5], [8, 5], [9, 5], [9, 6], [9, 7], [9, 8], [9, 9], [10, 9], [11, 9],[0, 1], [1, 1], [2, 1], [3, 1], [3, 2], [3, 3], [4, 3], [5, 3],
        //     [6, 3], [6, 4], [6, 5], [7, 5], [8, 5], [9, 5], [9, 6], [9, 7], [9, 8], [9, 9], [10, 9], [11, 9],[0, 1], [1, 1], [2, 1], [3, 1], [3, 2], [3, 3], [4, 3], [5, 3],
        //     [6, 3], [6, 4], [6, 5], [7, 5], [8, 5], [9, 5], [9, 6], [9, 7], [9, 8], [9, 9], [10, 9], [11, 9],];
        const len = data.length;

        const maxScore = data => {
            let max = 11;
            for (let i = 0; i < data.length; i++) {
                if (data[i][0] > max)
                    max = data[i][0];
                if (data[i][1] > max)
                    max = data[i][1];
            }
            return max + 1;
        };

        // const xSplitLen = data.length !== 0 ? xLen / data.length : 0;
        const xSplitLen = 15;
        const ySplitLen = maxScore(data) !== 0 ? yLen / maxScore(data) : 0;
        const xLen = len * xSplitLen;
        // 以下处理矩形object元素
        // 队员1
        const rectList1 = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {};
            if (this.state.blueSwitch && data[i][0] === data[i][1]) {
                obj['width'] = xSplitLen * 0.95;
                obj['height'] = ySplitLen * 0.15;
                obj['x'] = i * xSplitLen + padding;
                obj['y'] = topPadding + ySplitLen * (maxScore(data) - data[i][0] - 1 + 0.85 - 0.06);
            } else {
                obj['width'] = xSplitLen * 0.95;
                obj['height'] = ySplitLen * 0.2;
                obj['x'] = i * xSplitLen + padding;
                obj['y'] = topPadding + ySplitLen * (maxScore(data) - data[i][0] - 1 + 0.85);
            }

            obj['key'] = 'RectOne' + i;
            obj['fill'] = redFill;
            if (this.state.redSwitch) {
                obj['opacity'] = 1;
            } else {
                obj['opacity'] = 0;
            }
            rectList1.push(obj);
        }
        // 队员2
        const rectList2 = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {};
            if (this.state.redSwitch && data[i][0] === data[i][1]) {
                obj['width'] = xSplitLen * 0.95;
                obj['height'] = ySplitLen * 0.15;
                obj['x'] = i * xSplitLen + padding;
                obj['y'] = topPadding + ySplitLen * (maxScore(data) - data[i][1] - 1 + 0.85 + 0.07);
            } else {
                obj['width'] = xSplitLen * 0.95;
                obj['height'] = ySplitLen * 0.2;
                obj['x'] = i * xSplitLen + padding;
                obj['y'] = topPadding + ySplitLen * (maxScore(data) - data[i][1] - 1 + 0.85);
            }
            obj['fill'] = blueFill;
            obj['key'] = 'RectTwo' + i;
            if (this.state.blueSwitch) {
                obj['opacity'] = 1;
            } else {
                obj['opacity'] = 0;
            }
            rectList2.push(obj);
        }

        // 矩形map
        const rect1 = rectList1.map((d) => {
              return <rect key={d.key} width={d.width} height={d.height} x={d.x}
                           y={d.y} fill={d.fill} opacity={d.opacity}/>
          }
        );
        const rect2 = rectList2.map((d) => {
              return <rect key={d.key} width={d.width} height={d.height} x={d.x}
                           y={d.y} fill={d.fill} opacity={d.opacity}/>
          }
        );
        //坐标轴轴线刻画
        //y轴刻画
        const yLineList = [];
        for (let i = 0; i <= maxScore(data); i += 2) {
            let obj = {};
            // 这里magic number 600,300分别为svg画布的宽，高
            obj.key = i.toString();
            obj['x1'] = backPadding;
            obj['y1'] = topPadding + (maxScore(data) - i) * ySplitLen;
            if (i % 4 === 0) {
                obj['x2'] = 2500;
                obj['strokeDasharray'] = '3,2';
            } else {
                obj['x2'] = backPadding + spline;
            }
            obj['y2'] = topPadding + (maxScore(data) - i) * ySplitLen;
            obj['strokeWidth'] = 1;
            obj['stroke'] = 'black';
            obj['x'] = backPadding - textPadding;
            obj['y'] = topPadding + (maxScore(data) - i) * ySplitLen + 7;
            obj['fill'] = 'black';
            obj['text'] = i;
            yLineList.push(obj);
        }
        const yLine = yLineList.map((d) => {
            return <line key={d.key}
                         x1={d.x1} y1={d.y1} x2={d.x2} y2={d.y2}
                         strokeWidth={d.strokeWidth} stroke={d.stroke} strokeDasharray={d.strokeDasharray}/>
        });
        const yText = yLineList.map((d) => {
            return <text key={d.key} x={d.x} y={d.y} fill={d.fill} textAnchor={'end'} fontSize={15}>{d.text}</text>
        });

        //刻画圆
        const circleList = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {key: i.toString()};
            //300表示svg高度
            obj['cx'] = padding + (i + 0.5) * xSplitLen;
            obj['cy'] = 250 - bottomPadding - circlePadding;
            //确定半径
            if (time[i] === 0) {
                obj['r'] = 0;
            }
            if (time[i] >= 1 && time[i] <= 3) {
                obj['r'] = xSplitLen * 0.1;
            }
            if (time[i] > 3 && time[i] <= 5) {
                obj['r'] = xSplitLen * 0.2;
            }
            if (time[i] > 5 && time[i] <= 7) {
                obj['r'] = xSplitLen * 0.25;
            }
            if (time[i] > 7 && time[i] <= 10) {
                obj['r'] = xSplitLen * 0.3;
            }
            if (time[i] > 10) {
                obj['r'] = xSplitLen * 0.35;
            }
            //确定fill
            if (data[i][0] === 0 || data[i][1] === 0) {
                if (data[i][0] !== 0) {
                    obj['fill'] = redFill;
                }
                if (data[i][1] !== 0) {
                    obj['fill'] = blueFill;
                }
            } else {
                if (data[i][0] > data[i - 1][0]) {
                    obj['fill'] = redFill;
                }
                if (data[i][1] > data[i - 1][1]) {
                    obj['fill'] = blueFill;
                }
            }
            circleList.push(obj);
        }
        const circle = circleList.map((d) => {
            return <circle key={d.key}
                           cx={d.cx} cy={d.cy} r={d.r} fill={d.fill}
                           pointerEvents={'none'}/>
        });

        //刻画矩形
        const rectList = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {key: i.toString()};
            //300表示svg高度
            obj['x'] = padding + i * xSplitLen;
            obj['y'] = 250 - bottomPadding - rectHeight;
            obj['width'] = xSplitLen * 1.05;
            obj['height'] = rectHeight;
            if (data[i][0] >= 0 && data[i][0] <= 4 && data[i][1] >= 0 && data[i][1] <= 4) {
                obj['fill'] = 'lightgray';
            }
            if ((data[i][0] > 4 && data[i][0] <= 8) || (data[i][1] > 4 && data[i][1] <= 8)) {
                obj['fill'] = 'darkgray';
            }
            if (data[i][0] > 8 || data[i][1] > 8) {
                obj['fill'] = 'gray';
            }
            rectList.push(obj);
        }
        const rect = rectList.map((d) => {
            return <rect key={d.key}
                         width={d.width} height={d.height} x={d.x}
                         y={d.y} fill={d.fill} onMouseMove={this.moveRect} onMouseLeave={this.leaveRect}/>
        });
        //处理鼠标悬停

        const rectHighList = [];
        for (let i = 0; i < data.length; i++) {
            if ((this.state.x >= padding + i * xSplitLen && this.state.x < padding + (i + 1) * xSplitLen) && this.state.y >= topPadding && this.state.y <= 250 - 10 - bottomPadding - rectHeight) {
                let obj = {
                    x: padding + i * xSplitLen,
                    y: topPadding,
                    width: xSplitLen * 0.95,
                    height: yLen,
                    fill: 'lightgray',
                    idx: i,
                    key: i.toString(),
                };
                rectHighList.push(obj);
            }
        }
        const rectHigh = rectHighList.map((d) => {
              return <rect key={d.key}
                           width={d.width} height={d.height} x={d.x}
                           y={d.y} fill={d.fill} onClick={() => this.handleClick(d.idx)}/>
          }
        );

        //处理圆悬停
        const rectTextList = [];
        for (let i = 0; i < data.length; i++) {
            if (this.state.rectX >= padding + i * xSplitLen && this.state.rectX < padding + (i + 1) * xSplitLen) {
                let obj = {
                    index: i,
                    x: padding + (i + 0.5) * xSplitLen - 20,
                    y: 250 - bottomPadding - rectHeight - 23,
                    width: 40,
                    height: 20,
                    fill: 'white',
                    opacity: 0.8,
                    strokeWidth: 1,
                    stroke: 'gray',
                    key: i.toString(),
                };
                rectTextList.push(obj);
            }
        }
        const rectText = rectTextList.map((d) => {
              return <g key={d.key}>
                  <rect width={d.width} height={d.height} x={d.x}
                        y={d.y} fill={d.fill} opacity={d.opacity}
                        strokeWidth={d.strokeWidth} stroke={d.stroke} pointerEvents={'none'}/>
                  <text x={d.x + 20} y={d.y + 15} fill={'black'}
                        fontSize={15} textAnchor={'middle'}>{time[d.index]} </text>
              </g>
          }
        );
        return <div className={classes.root}>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}/>
                <Legend name={player[0]} fill={this.state.redSwitch ? redFill : 'lightgray'}
                        handleClick={this.RedClick}/>
                <Legend name={player[1]} fill={this.state.blueSwitch ? blueFill : 'lightgray'}
                        handleClick={this.BlueClick}/>
                <div style={{flex: 1}}/>
            </div>
            <div className={classes.backSvgArea}>
                <svg className={classes.svg}
                     viewBox={'0,0,' + (xLen + padding * 2 + backPadding) + ',250'}>
                    {yLine}
                    {yText}
                    <line x1={49} y1={210} x2={2500} y2={210} stroke={'black'} strokeWidth={2}/>
                    <line x1={50} y1={10} x2={50} y2={210} stroke={'black'} strokeWidth={2}/>
                </svg>
            </div>
            <div className={classes.frontSvgArea}>
                <svg id={'box'} onMouseMove={this.move} className={classes.svg}
                     viewBox={'0,0,' + (xLen + padding * 2) + ',250'}>
                    {rectHigh}
                    {rect1}
                    {rect2}
                    <g transform={`translate(0, -10)`}>
                        {rect}
                        {circle}
                        {rectText}
                    </g>
                </svg>
            </div>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}/>
                <Legend name={'开局'} fill={'lightgray'}/>
                <Legend name={'中局'} fill={'darkgray'}/>
                <Legend name={'终局'} fill={'gray'}/>
                <div style={{flex: 1}}/>

            </div>
        </div>;
    }

}

export default withStyles(styles, {withTheme: true})(ScoreStepGraph);
