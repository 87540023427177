import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Video from "../Common/Video/Video";
import MatchTree from "../Common/MatchTree";
import RallyInfo from './RallyInfo';
import {fade} from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.primary.contrastText,
  },
  block: {
    borderWidth: 2,
    borderRadius: '0.3vw',
    borderStyle: 'solid',
    borderColor: fade(theme.palette.primary.light, 0.25),
  },
  matchTree: {
    position: 'absolute',
    top: '10px',
    bottom: '10px',
    left: '30px',
    width: '240px',
  },
  videoPlayer: {
    position: 'absolute',
    top: '10px',
    bottom: '326px',
    left: '286px',
    right: '30px',
  },
  rallyInfo: {
    position: 'absolute',
    bottom: '10px',
    height: '300px',
    left: '286px',
    right: '30px',
  }
});

class Locate extends Component {
  render() {
    const {classes} = this.props;

    return <div className={classes.root}>
      <div className={clsx(classes.matchTree, classes.block)}>
        <MatchTree editable/>
      </div>
      <div className={clsx(classes.videoPlayer, classes.block)}>
        <Video />
      </div>
      <div className={clsx(classes.rallyInfo, classes.block)}>
        <RallyInfo />
      </div>
    </div>;
  }
}

export default withStyles(styles)(Locate);
