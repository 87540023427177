import React, {Component} from 'react';
import {createMuiTheme, darken, lighten, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import store from '../Data/Store';
import Welcome from './Welcome/Welcome';
import MatchInfo from './MatchInfo/MatchInfo';
import Locate from './Locate/Locate';
import Record from './Record/Record';
import Export from './Export/Export';
import MatchList from "./Welcome/MatchList/MatchList";
import TabNavigator from "./Common/TabNavigator";
import {fade} from "@material-ui/core/styles/colorManipulator";
import pages from "../Data/Pages";
import AutoDetection from "./AutoDetection";
import {CssBaseline} from "@material-ui/core";

const styles = (theme) => ({
    background: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    content: {
        width: '98vw',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.primary.contrastText,
    },
    fileInput: {
        display: "none",
    },
});

const scrollColor = 'rgb(36, 40, 41)';
class App extends Component {
    state = {
        needRefresh: false,
        page: 0,
        lastPage: 0,
        lockedPage: null,
    };

    UNSAFE_componentWillMount() {
        store.register({app: this});
    }

    componentWillUnmount() {
        store.unregister({app: this});
    }

    render() {
        const {classes} = this.props;
        const theme = createMuiTheme({
            palette: store.theme,
            typography: {
                useNextVariants: true,
            },
            spacing: 10,
            overrides: {
                MuiCssBaseline: {
                    '@global': {
                        '*::-webkit-scrollbar': {
                            width: 10,
                            height: 10,
                        },
                        '*::-webkit-scrollbar-track': {
                            display: 'none',
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: fade(scrollColor, 0.1),
                            borderRadius: 5,
                        },
                        '*:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: lighten(scrollColor, 0.2),
                        },
                        '*::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: scrollColor,
                        },
                        '*::-webkit-scrollbar-thumb:active': {
                            backgroundColor: darken(scrollColor, 0.2),
                        },
                    },
                },
            },
        });
        // if (store.show)
        return <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <div className={classes.background}>
                <input id={"file-importer"} type={"file"} className={classes.fileInput}/>
                <div className={classes.content}>
                    {this.state.page === pages.WELCOME && <Welcome/>}
                    {this.state.page === pages.INFO && <MatchInfo/>}
                    {this.state.page === pages.AUTO_DETECTION && <AutoDetection/>}
                    {this.state.page === pages.LOCATE && <Locate/>}
                    {this.state.page === pages.RECORD && <Record/>}
                    {this.state.page === pages.EXPORT && <Export/>}
                </div>
                <TabNavigator page={this.state.page} onChange={this.handleSwitch}/>
                <MatchList/>
                <svg style={{display: 'none'}}>
                    <defs>
                        <marker id="triangleMarker" viewBox="0 0 10 10"
                                refX="1" refY="5"
                                markerUnits="strokeWidth"
                                markerWidth="5" markerHeight="5"
                                orient="auto">
                            <path d="M 0 0 L 10 5 L 0 10 z" fill="#f00"/>
                        </marker>
                    </defs>
                </svg>
            </div>
        </MuiThemeProvider>;
        // else
        //     return <div>本地服务未开启！</div>
    }

    handleSwitch = page => {
        if (this.state.lockedPage) {
            window.alert(`暂时无法切换页面：${this.state.lockedPage}`);
            return;
        }
        this.setState({
            page,
            lastPage: (this.state.page === pages.AUTO_DETECTION) ? this.state.lastPage : this.state.page,
            switching: false,
            switchingLanguage: false,
        });
    };
}

export default withStyles(styles)(App);
