import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import {Circle, Layer, Line, Rect, Stage} from "react-konva";
import {fade} from "@material-ui/core/styles/colorManipulator";

const anchorSize = 10;
const styles = theme => ({
    drawer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
});

//region utils
const vectorDirection = (p0, p1) => {
    const dx = p1[0] - p0[0], dy = p1[1] - p0[1];
    if (dx === 0 && dy === 0) return 0;
    const d = Math.sqrt(dx * dx + dy * dy);
    const ux = dx / d, uy = dy / d;
    if (uy < 0) {
        if (ux < 0) return -Math.PI + Math.asin(-uy);
        else return -Math.PI / 2 + Math.asin(ux);
    } else {
        if (ux > 0) return Math.asin(uy);
        else return Math.PI / 2 + Math.asin(-ux);
    }
}
const resortPoints = (points) => {
    if (points.length < 3) return points;
    const resortedPoints = points.slice(0, 4);
    const center = [0, 1].map(xyIdx => {
        return resortedPoints.reduce((p, point) => p + point[xyIdx], 0) / 4;
    });
    return resortedPoints
      .map(p => [vectorDirection(center, p), p])
      .sort((a, b) => a[0] - b[0])
      .map(p => p[1]);
}
//endregion

class TableDrawboard extends Component {
    state = {
        drawPoints: [],
        cursor: null,
        dragPolyStart: null,
        dragPolyCursor: null,
        dragPoint: [-1, null],
    }

    //region data interface
    setTablePos = points => {
        const {size} = this.props;
        this.props.onSetTable(resortPoints(points).map(p => [p[0] / size[0], p[1] / size[1]]).flat())
    }
    getPolyProps = () => {
        const {table, size} = this.props;
        if (table === null) return null;
        return {
            points: [
                [
                    table[0] * size[0],
                    table[1] * size[1]
                ],
                [
                    table[2] * size[0],
                    table[3] * size[1]
                ],
                [
                    table[4] * size[0],
                    table[5] * size[1]
                ],
                [
                    table[6] * size[0],
                    table[7] * size[1]
                ],
            ]
        }
    }
    handleClear = () => this.props.onSetTable(null);
    //endregion

    //region drag polygon
    getPolyPos = e => {
        return [e.target.x(), e.target.y()];
    }
    handleDragPolyStart = e => this.setState({
        dragPolyStart: this.getPolyPos(e),
        dragPolyCursor: this.getPolyPos(e),
    });
    handleDragPolyMove = e => this.setState({
        dragPolyCursor: this.getPolyPos(e),
    })
    handleDragPolyEnd = e => {
        const cursor = this.getPolyPos(e);
        const {dragPolyStart} = this.state;
        const dx = cursor[0] - dragPolyStart[0], dy = cursor[1] - dragPolyStart[1];

        const points = this.getPolyProps().points;
        this.setTablePos(points.map(p => [p[0] + dx, p[1] + dy]));

        e.target.x(dragPolyStart[0]);
        e.target.y(dragPolyStart[1]);

        this.setState({
            dragPolyStart: null,
            dragPolyCursor: null,
        })
    }
    //endregion

    //region drag point
    getAnchorPos = e => {
        return [e.target.x() + anchorSize / 2, e.target.y() + anchorSize / 2];
    }
    handleDragPoint = pid => e => {
        const pos = this.getAnchorPos(e);
        this.setState({
            dragPoint: [pid, pos],
        })
    }
    handleDragPointEnd = pid => e => {
        const {points} = this.getPolyProps();
        points[pid] = this.getAnchorPos(e);
        this.setTablePos(points);
        this.setState({
            dragPoint: [-1, null],
        })
    }
    //endregion

    //region draw
    getDrawProps = () => {
        const {drawPoints, cursor} = this.state;
        if (drawPoints.length === 0 && cursor === null) return null;
        const points = (cursor === null) ? [...drawPoints] : [...drawPoints, cursor];
        return {
            points: resortPoints(points),
        }
    }
    getPos = e => [e.evt.offsetX, e.evt.offsetY];
    handleMouseDown = e => {
        if (e.evt.button !== 0) return;
        if (this.props.table !== null) return;
        const pos = this.getPos(e);
        this.setState({
            drawStart: [],
            cursor: pos,
        })
    }
    handleMouseMove = e => {
        if (this.state.cursor === null) return;
        this.setState({cursor: this.getPos(e)});
    }
    handleMouseUp = e => {
        if (this.state.cursor === null) return;
        const pos = this.getPos(e);
        const drawPoints = [...this.state.drawPoints, pos];
        if (drawPoints.length === 4) {
            this.setTablePos(drawPoints);
            this.setState({drawPoints: [], cursor: null});
        } else this.setState({drawPoints, cursor: null});
    }

    //endregion

    render() {
        const {classes, theme, size} = this.props;

        const drawProps = this.getDrawProps();
        const drawStyle = {
            fill: "transparent",
            strokeWidth: 4,
            stroke: theme.palette.primary.dark,
        }
        const pointStyle = {
            fill: 'white',
            strokeWidth: 1,
            stroke: theme.palette.primary.dark,
            radius: anchorSize / 2,
        }

        const {dragPoint} = this.state;
        const anchorStyle = {
            fill: 'white',
            strokeWidth: 1,
            stroke: theme.palette.primary.dark,
            width: anchorSize,
            height: anchorSize,
        }

        const polyProps = this.getPolyProps();
        if (dragPoint[0] !== -1) polyProps.points[dragPoint[0]] = dragPoint[1];
        const polyStyle = {
            fill: fade(theme.palette.primary.main, 0.3),
            strokeWidth: 2,
            stroke: theme.palette.primary.dark,
        }
        const {dragPolyStart, dragPolyCursor} = this.state;
        const dx = (dragPolyStart === null) ? 0 : dragPolyCursor[0] - dragPolyStart[0];
        const dy = (dragPolyStart === null) ? 0 : dragPolyCursor[1] - dragPolyStart[1];

        return <Stage className={classes.drawer} width={size[0]} height={size[1]}
                      onContextMenu={this.handleClear}
                      onMouseDown={this.handleMouseDown}
                      onMouseMove={this.handleMouseMove}
                      onMouseUp={this.handleMouseUp}>
            <Layer>
                {drawProps !== null && <Line closed
                                             points={drawProps.points.flat()}
                                             {...drawStyle}/>}

                {drawProps !== null && drawProps.points.map(p => <Circle x={p[0]} y={p[1]}
                                                                         {...pointStyle}/>)}

                {polyProps !== null && <Line draggable
                                             onDragStart={this.handleDragPolyStart}
                                             onDragMove={this.handleDragPolyMove}
                                             onDragEnd={this.handleDragPolyEnd}
                                             closed
                                             points={polyProps.points.flat()}
                                             {...polyStyle}/>}

                {polyProps !== null && polyProps.points.map((p, pid) => (
                  <Rect key={pid}
                        draggable
                        onDragMove={this.handleDragPoint(pid)}
                        onDragEnd={this.handleDragPointEnd(pid)}
                        x={p[0] - anchorSize / 2 + dx}
                        y={p[1] - anchorSize / 2 + dy}
                        {...anchorStyle}/>
                ))}
            </Layer>
        </Stage>;
    }
}

export default withStyles(styles, {withTheme: true})(TableDrawboard);

// edited by WuJiang5521 on 2021/5/24
