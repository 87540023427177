import React, {Component} from 'react';
import {Button, Dialog, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import store from "../../Data/Store";

const styles = theme => ({
    btn: {
        margin: theme.spacing(2),
        boxShadow: 'none',

        borderColor: theme.palette.primary.light,
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'solid',

        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.dark,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
        }
    },
});

class NewOption extends Component {
    render() {
        const {classes, open, onClose, newOnline, newLocal, joinOnline} = this.props;

        return <Dialog open={open} onClose={onClose}>
            <DialogTitle>{store.languageSwitch('New')}</DialogTitle>
            <DialogContent>
                <Button className={classes.btn} onClick={newLocal}>{store.languageSwitch('NewLocal')}</Button>
                <Button className={classes.btn} onClick={newOnline}>{store.languageSwitch('NewOnline')}</Button>
                <Button className={classes.btn} onClick={joinOnline}>{store.languageSwitch('JoinOnline')}</Button>
            </DialogContent>
        </Dialog>;
    }
}

export default withStyles(styles)(NewOption);

// edited by WuJiang5521 on 2021/1/17
