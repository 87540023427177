import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import store from '../../../Data/Store';

const styles = theme => ({
    root: {
        margin: 25,
        border: `2px solid ${theme.palette.primary.light}`,
        borderCollapse: 'collapse',
        fontWeight: 'normal',
        '& td': {
            padding: 5,
            border: `2px solid ${theme.palette.primary.light}`,
            textAlign: 'center',
        },
        '& th': {
            padding: 5,
            fontWeight: 'normal',
            border: `2px solid ${theme.palette.primary.light}`,
            minWidth: 70,
        }
    },
    selectable: {
        cursor: 'pointer',
        '&:hover': {
            border: `3px solid ${theme.palette.primary.dark}`,
        },
    },
    highlight: {
        backgroundColor: theme.palette.secondary.light,
    },
    emphasize: {
        fontWeight: 'bold',
    }
});

class ThreeStageTable extends Component {
    handleClick = (winLose, index) => {
        const {clickCell, data: {name}} = this.props;
        if (clickCell instanceof Function)
            clickCell('add-list', (game, rally) => {
                if (winLose === 'win') {
                    if ((index === 2 || index === 0) && rally.rallyLength === 1)
                        return store.getData(`Player${rally.list[0].HitPlayer}`).name !== name;
                    return store.getData(`Player${rally.winSide}0`).name === name && (
                      index === 0 ? true : (
                        index === 5 ?
                          rally.rallyLength > 5 :
                          index === rally.rallyLength - 1
                      )
                    );
                } else {
                    return store.getData(`Player${1 - rally.winSide}0`).name === name && (
                      index === 0 ? true : (
                        index === 5 ?
                          rally.rallyLength >= 5 :
                          index === rally.rallyLength
                      )
                    );
                }
            });
    };

    parseName(name) {
        const nameList = name.split(', ');
        if (nameList.length > 1) return `对手`;
        else return nameList[0];
    }

    renderScore(winLose, index) {
        const {classes, data} = this.props;
        const d = data[winLose][index];
        return <td className={clsx({
            [classes.selectable]: d > 0,
            [classes.emphasize]: true,
        })} onClick={() => this.handleClick(winLose, index)}>{d === 0 ? '-' : d}</td>;
    }

    renderRate(rate, color, colSpan = 1) {
        const {classes} = this.props;

        return <td colSpan={colSpan} className={clsx({
            [classes.highlight]: color,
            [classes.emphasize]: true,
        })}>{rate}%</td>
    }

    render() {
        const {classes, data: {name, scoreRate, colorScore, useRate}} = this.props;

        return <table className={classes.root}>
            <thead>
            <tr>
                <td rowSpan={2} title={name} className={classes.emphasize}>{this.parseName(name)}</td>
                <th colSpan={2}>发球抢攻段</th>
                <th colSpan={2}>接发球抢攻段</th>
                <th rowSpan={2}>相持段</th>
                <th rowSpan={2}>总计</th>
            </tr>
            <tr>
                <th>发球</th>
                <th>第三拍</th>
                <th>接发球</th>
                <th>第四拍</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>得分数</th>
                {this.renderScore('win', 1)}
                {this.renderScore('win', 3)}
                {this.renderScore('win', 2)}
                {this.renderScore('win', 4)}
                {this.renderScore('win', 5)}
                {this.renderScore('win', 0)}
            </tr>
            <tr>
                <th>失分数</th>
                {this.renderScore('lose', 1)}
                {this.renderScore('lose', 3)}
                {this.renderScore('lose', 2)}
                {this.renderScore('lose', 4)}
                {this.renderScore('lose', 5)}
                {this.renderScore('lose', 0)}
            </tr>
            <tr>
                <th>得分率</th>
                {this.renderRate(scoreRate[1], colorScore[1], 2)}
                {this.renderRate(scoreRate[2], colorScore[2], 2)}
                {this.renderRate(scoreRate[3], colorScore[3])}
                {this.renderRate(scoreRate[0], colorScore[0])}
            </tr>
            <tr>
                <th>使用率</th>
                {this.renderRate(useRate[1], false, 2)}
                {this.renderRate(useRate[2], false, 2)}
                {this.renderRate(useRate[3], false)}
                {this.renderRate(useRate[0], false)}
            </tr>
            </tbody>
        </table>;
    }
}

export default withStyles(styles)(ThreeStageTable);

// edited by WuJiang5521 on 2019/5/28
