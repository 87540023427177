import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import store from '../../Data/Store';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Video from '../Common/Video/Video';
import PlayerInfo from './PlayerInfo';
import {Typography} from "@material-ui/core";
import {fade} from "@material-ui/core/styles/colorManipulator";

const style = store.pageStyle;
const styles = theme => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.primary.contrastText,
    },
    block: {
        borderWidth: 2,
        borderRadius: '0.3vw',
        borderStyle: 'solid',
        borderColor: fade(theme.palette.primary.light, 0.25),
    },
    matchInfos: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: `calc(12.5vh - ${theme.spacing(2)}px)`,
        bottom: `calc(12.5vh - ${theme.spacing(2)}px)`,
        left: '30px',
        width: 'calc(50% - 50px)',
    },
    matchInfoTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        flexGrow: 0,
        flexShrink: 0,
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    matchInfo: {
        width: '100%',
        height: (50 + theme.spacing(4)) * 2,
        flexGrow: 0,
        flexShrink: 0,
        display: 'flex',
        flexWrap: 'wrap',
        margin: `${theme.spacing(5)}px 0`,
    },
    matchInfoItem: {
        width: `calc(50% - ${theme.spacing(2)}px)`,
        height: `calc(50% - ${theme.spacing(2)}px)`,
        padding: theme.spacing(1),
        margin: `${theme.spacing(1)}px 0`,
        display: 'flex',
    },
    textField: {
        flex: 1,
        flexBasis: 200,
        margin: `0 ${theme.spacing(2)}px`,
        '& div': {
            color: style.text,
            '& div': {
                '& svg': {color: style.text}
            }
        }
    },
    videoArea: {
        flexGrow: 1,
        margin: theme.spacing(3),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    videoTitle: {
        flexGrow: 0,
        flexShrink: 0,
        margin: theme.spacing(1),
        fontSize: '1.3rem',
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
    video: {
        height: 'calc(100% - 40px - 2.6rem)',
        flexGrow: 1,
        flexBasis: 'calc(100% - 40px - 2.6rem)',
        margin: theme.spacing(1),
        position: 'relative',
    },
    videoButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        minWidth: '100%',
    },
    cssRoot: {
        color: style.text,
        '&$cssFocused': {color: theme.palette.primary.main},
    },
    cssFilled: {color: theme.palette.primary.main},
    cssFocused: {},
    cssUnderline: {
        '&:before': {borderBottom: `1px solid ${style.text}`},
        '&:after': {borderBottomColor: theme.palette.primary.main},
    },
    players: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: `calc(12.5vh - ${theme.spacing(2)}px)`,
        bottom: `calc(12.5vh - ${theme.spacing(2)}px)`,
        width: 'calc(50% - 50px)',
        right: '30px',
    },
    playerInfoTitle: {
        width: '100%',
        textAlign: 'center',
        fontSize: '1.5rem',
        flexGrow: 0,
        flexShrink: 0,
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    playerInfoPanels: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',
    },
    player: {
        minWidth: '40%',
        width: `auto`,
        height: `calc(50% - ${theme.spacing(4) + 6}px)`,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
    }
});

class MatchInfo extends Component {
    state = {
        needRefresh: false,
    };

    UNSAFE_componentWillMount() {
        store.register({matchInfo: this});
    }

    componentWillUnmount() {
        store.unregister({matchInfo: this});
    }

    render() {
        const {classes} = this.props;

        return <div className={classes.root}>
            {/* MatchInfo */}
            <div className={clsx(classes.block, classes.matchInfos)}>
                <Typography className={classes.matchInfoTitle}>
                    {store.languageSwitch('matchInfo')}
                </Typography>
                <div className={classes.matchInfo}>
                    <div className={classes.matchInfoItem}>
                        <TextField
                          className={classes.textField}
                          label={store.languageSwitch('matchYear')}
                          value={store.data.match.time[0]}
                          InputLabelProps={{
                              classes: {
                                  root: classes.cssRoot,
                                  filled: classes.cssFilled,
                                  focused: classes.cssFocused,
                              }
                          }}
                          InputProps={{
                              classes: {
                                  underline: classes.cssUnderline,
                              }
                          }}
                          onChange={store.handleChange("MatchYear")}
                        />
                        <TextField
                          select
                          className={classes.textField}
                          label={store.languageSwitch('matchMonth')}
                          value={store.data.match.time[1]}
                          InputLabelProps={{
                              classes: {
                                  root: classes.cssRoot,
                                  filled: classes.cssFilled,
                                  focused: classes.cssFocused,
                              }
                          }}
                          InputProps={{
                              classes: {
                                  underline: classes.cssUnderline,
                              }
                          }}
                          onChange={store.handleChange("MatchMonth")}
                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(option => (
                              <MenuItem key={option} value={option}>
                                  {option}
                              </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className={classes.matchInfoItem}>
                        <TextField
                          className={classes.textField}
                          label={store.languageSwitch('matchLocation')}
                          value={store.data.match.location}
                          InputLabelProps={{
                              classes: {
                                  root: classes.cssRoot,
                                  filled: classes.cssFilled,
                                  focused: classes.cssFocused,
                              }
                          }}
                          InputProps={{
                              classes: {
                                  underline: classes.cssUnderline,
                              }
                          }}
                          onChange={store.handleChange("MatchLocation")}
                        />
                        <TextField
                          select
                          className={classes.textField}
                          label={store.languageSwitch('matchType')}
                          value={store.data.match.type}
                          InputLabelProps={{
                              classes: {
                                  root: classes.cssRoot,
                                  filled: classes.cssFilled,
                                  focused: classes.cssFocused,
                              }
                          }}
                          InputProps={{
                              classes: {
                                  underline: classes.cssUnderline,
                              }
                          }}
                          onChange={store.handleChange("MatchType")}
                        >
                            {store.getData("MatchTypes").map((option, i) => {
                                let label = store.languageSwitch(option);
                                if (label === option) return null;
                                else return <MenuItem key={option} value={option}>
                                    {label}
                                </MenuItem>
                            })}
                        </TextField>
                    </div>
                    <div className={classes.matchInfoItem}>
                        <TextField
                          select
                          className={classes.textField}
                          label={store.languageSwitch('matchCompetition')}
                          value={store.data.match.competition}
                          InputLabelProps={{
                              classes: {
                                  root: classes.cssRoot,
                                  filled: classes.cssFilled,
                                  focused: classes.cssFocused,
                              }
                          }}
                          InputProps={{
                              classes: {
                                  underline: classes.cssUnderline,
                              }
                          }}
                          onChange={store.handleChange("MatchCompetition")}
                        >
                            {store.getData("MatchCompetitions").map((option, i) => (
                              <MenuItem key={option} value={option}>
                                  {store.languageSwitch(option)}
                              </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className={classes.matchInfoItem}>
                        <TextField
                          select
                          className={classes.textField}
                          label={store.languageSwitch('matchRound')}
                          value={store.data.match.round}
                          InputLabelProps={{
                              classes: {
                                  root: classes.cssRoot,
                                  filled: classes.cssFilled,
                                  focused: classes.cssFocused,
                              }
                          }}
                          InputProps={{
                              classes: {
                                  underline: classes.cssUnderline,
                              }
                          }}
                          onChange={store.handleChange("MatchRound")}
                        >
                            {store.getData("MatchRounds").map((option, i) => (
                              <MenuItem key={option} value={option}>
                                  {store.languageSwitch(option)}
                              </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
                <div className={classes.videoArea}>
                    <Button className={clsx(classes.videoButton, classes.block)}
                            onDrop={e => store.dropVideo(e)}
                            onClick={() => store.chooseVideo()}>
                        {""}
                    </Button>
                    <Typography className={classes.videoTitle}>
                        {store.languageSwitch('ChooseVideo')}<br/>
                        {store.languageSwitch('DragVideo')}<br/>
                    </Typography>
                    <div className={classes.video}>
                        <Video/>
                    </div>
                </div>
            </div>
            {/* Players */}
            <div className={clsx(classes.players, classes.block)}>
                <Typography className={classes.playerInfoTitle}>
                    {store.languageSwitch('playerInfo')}
                </Typography>
                <div className={classes.playerInfoPanels}>
                    {
                        store.data.player[0].length > 0 &&
                        <PlayerInfo playerId={'00'}/>
                    }
                    {
                        store.data.player[1].length > 0 &&
                        <PlayerInfo playerId={'10'}/>
                    }
                    {
                        store.data.player[0].length > 1 &&
                        <PlayerInfo playerId={'01'}/>
                    }
                    {
                        store.data.player[1].length > 1 &&
                        <PlayerInfo playerId={'11'}/>
                    }
                </div>
            </div>
        </div>;
    }
}

export default withStyles(styles)(MatchInfo);
