import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import store from '../../../Data/Store';
import Button from "@material-ui/core/Button/Button";
import hotkey from 'react-shortcut-key'
import {fade} from "@material-ui/core/styles/colorManipulator";

const style = store.pageStyle;
const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex',
    },
    strike: {
        minWidth: '150px',
        width: '150px',
        maxWidth: '150px',
        margin: 0,
        padding: 0,

        height: '100%',

        backgroundColor: theme.palette.primary.contrastText,
        borderRight: `2px solid ${fade(theme.palette.primary.light, 0.25)}`,

        display: 'flex',
        flexDirection: 'column',
    },
    textCompleteRoot: {
        color: style.text,
        backgroundColor: theme.palette.primary.contrastText,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: style.text,
        },
    },
    textWarningRoot: {
        color: style.warning,
        backgroundColor: theme.palette.primary.contrastText,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: style.warning,
        },
    },
    textErrorRoot: {
        color: style.error,
        backgroundColor: theme.palette.primary.contrastText,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: style.error,
        },
    },
    outlinedCompleteRoot: {
        color: style.text,
        backgroundColor: theme.palette.primary.contrastText,
        border: `1px solid ${style.text}`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: style.text,
            border: `1px solid ${style.text}`,
        },
    },
    outlinedWarningRoot: {
        color: style.warning,
        backgroundColor: theme.palette.primary.contrastText,
        border: `1px solid ${style.warning}`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: style.warning,
            border: `1px solid ${style.warning}`,
        },
    },
    outlinedErrorRoot: {
        color: style.error,
        border: `1px solid ${style.error}`,
        backgroundColor: theme.palette.primary.contrastText,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: style.error,
            border: `1px solid ${style.error}`,
        },
    },
    strikeIndex: {
        minHeight: 0,
        height: '40px',
        flex: '1 1 40px',
    },
    term: {
        width: '100%',
        textAlign: 'center',
        height: '26px',
        lineHeight: '26px',
        flex: '0 0 26px',
        fontSize: '18px',
    },
    ErrorTerm: {
        color: style.error,
    },
    WarningTerm: {
        color: style.warning,
    },
    CompleteTerm: {
        color: style.text,
    },
});

const componentOnBack = e => {
    e.preventDefault();
    store.handleChange("StrikeBack")();
};
const componentOnNext = e => {
    e.preventDefault();
    store.handleChange("StrikeNext")();
};
const keymap = {
    'n': componentOnNext,
    'b': componentOnBack,
};

const getTime = strike => {
    if (strike.PatTime === -1) return "";
    else {
        const time = parseInt(strike.PatTime.toString(), 10);
        const sec = time % 60;
        const min = parseInt((time / 60).toString(), 10);
        return `(${min}: ${sec.toString().padStart(2, '0')})`
    }
};

class StrikeList extends Component {
    state = {
        needRefresh: false,
    };

    UNSAFE_componentWillMount() {
        store.register({rallyInfo: this});
    }

    componentWillUnmount() {
        store.unregister({rallyInfo: this});
    }

    render() {
        const {classes} = this.props;
        const data = store.getData("StrikeList");

        return <div className={classes.root}>
            {
                data !== null &&
                data.map((strike, i) => {
                    return <div key={i} className={classes.strike}>
                        <Button fullWidth
                                onClick={store.handleChange(`FocusStrike${i}`)}
                                onDoubleClick={store.handleChange('VideoPlayStrike')}
                                className={clsx(classes.strikeIndex, classes[`${i === store.focus.strike ? "outlined" : "text"}${strike.termLevel}Root`])}>
                            {`${i + 1}　${strike.HitPlayer.label}${getTime(strike)}`}
                        </Button>
                        {
                            ["BallPosition", "StrikePosition", "StrikeTech", "GameAction", "StrikeEffect", "SpinKind"].map(key => {
                                const item = strike[key];
                                return <div className={clsx(classes.term, classes[`${item.level}Term`])}
                                            key={key}>
                                    {item.label}
                                </div>;
                            })
                        }
                    </div>
                })
            }
        </div>;
    }
}

export default hotkey(keymap)(withStyles(styles)(StrikeList));
