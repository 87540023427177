import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import {Layer, Line, Rect, Stage} from "react-konva";
import {fade} from "@material-ui/core/styles/colorManipulator";

const anchorSize = 10;

const styles = theme => ({
    drawer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
});

//region utils
const resortPoints = points => {
    return points.slice(0, 2).sort((p0, p1) => p0[0] - p1[0]);
}

//endregion

class NetDrawboard extends Component {
    state = {
        drawStart: [],
        cursor: null,
        dragPoint: [-1, null],
    }

    //region data interface
    setNetPos = points => {
        const {size} = this.props;
        this.props.onSetNet(resortPoints(points).map(p => [p[0] / size[0], p[1] / size[1]]).flat())
    }
    getLineProps = () => {
        const {net, size} = this.props;
        if (net === null) return null;
        return {
            points: [
                [
                    net[0] * size[0],
                    net[1] * size[1]
                ],
                [
                    net[2] * size[0],
                    net[3] * size[1]
                ],
            ]
        }
    }
    handleClear = () => this.props.onSetNet(null);
    //endregion

    //region drag point
    getAnchorPos = e => {
        return [e.target.x() + anchorSize / 2, e.target.y() + anchorSize / 2];
    }
    handleDragPoint = pid => e => {
        const pos = this.getAnchorPos(e);
        this.setState({
            dragPoint: [pid, pos],
        })
    }
    handleDragPointEnd = pid => e => {
        const {points} = this.getLineProps();
        points[pid] = this.getAnchorPos(e);
        this.setNetPos(points);
        this.setState({
            dragPoint: [-1, null],
        })
    }
    //endregion

    //region draw
    getDrawProps = () => {
        const {drawStart, cursor} = this.state;
        if (drawStart === null) return null;
        return {
            points: [drawStart, cursor],
        }
    }
    getPos = e => [e.evt.offsetX, e.evt.offsetY];
    handleMouseDown = e => {
        if (e.evt.button !== 0) return;
        if (this.props.net !== null) return;
        const pos = this.getPos(e);
        this.setState({
            drawStart: pos,
            cursor: pos,
        })
    }
    handleMouseMove = e => {
        if (this.state.cursor === null) return;
        this.setState({cursor: this.getPos(e)});
    }
    handleMouseUp = e => {
        if (this.state.cursor === null) return;
        const pos = this.getPos(e);
        const drawPoints = [this.state.drawStart, pos];
        this.setNetPos(drawPoints);
        this.setState({drawStart: null, cursor: null});
    }

    //endregion

    render() {
        const {classes, theme, size} = this.props;

        const drawProps = this.getDrawProps();
        const drawStyle = {
            fill: "transparent",
            strokeWidth: 4,
            stroke: theme.palette.primary.dark,
        }

        const polyProps = this.getLineProps();
        const polyStyle = {
            fill: fade(theme.palette.primary.main, 0.3),
            strokeWidth: 2,
            stroke: theme.palette.primary.dark,
        }

        const {dragPoint} = this.state;
        if (dragPoint[0] !== -1) polyProps.points[dragPoint[0]] = dragPoint[1];
        const anchorStyle = {
            fill: 'white',
            strokeWidth: 1,
            stroke: theme.palette.primary.dark,
            width: anchorSize,
            height: anchorSize,
        }

        return <Stage className={classes.drawer} width={size[0]} height={size[1]}
                      onContextMenu={this.handleClear}
                      onMouseDown={this.handleMouseDown}
                      onMouseMove={this.handleMouseMove}
                      onMouseUp={this.handleMouseUp}>
            <Layer>
                {drawProps !== null && <Line points={drawProps.points.flat()}
                                             {...drawStyle}/>}

                {polyProps !== null && <Line points={polyProps.points.flat()}
                                             {...polyStyle}/>}

                {polyProps !== null && polyProps.points.map((p, pid) => (
                  <Rect key={pid}
                        draggable
                        onDragMove={this.handleDragPoint(pid)}
                        onDragEnd={this.handleDragPointEnd(pid)}
                        x={p[0] - anchorSize / 2}
                        y={p[1] - anchorSize / 2}
                        {...anchorStyle}/>
                ))}
            </Layer>
        </Stage>;
    }
}

export default withStyles(styles, {withTheme: true})(NetDrawboard);

// edited by WuJiang5521 on 2021/5/24
