import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import store from "../../../Data/Store";

const styles = theme => ({
    root: {
        margin: 25,
        border: `2px solid ${theme.palette.primary.light}`,
        borderCollapse: 'collapse',
        '& td': {
            padding: 5,
            border: `2px solid ${theme.palette.primary.light}`,
            textAlign: 'center',
        },
        '& th': {
            padding: 5,
            fontWeight: 'normal',
            border: `2px solid ${theme.palette.primary.light}`,
            minWidth: 70,
        }
    },
    disabledTableCell: {
        backgroundImage: 'linear-gradient(to bottom right, transparent calc(50% - 1px), rgba(0,0,0,0.5), transparent calc(50% + 1px))',
    },
    selectable: {
        cursor: 'pointer',
        '&:hover': {
            border: `2px solid ${theme.palette.primary.dark}`,
        },
    },
    highlight: {
        backgroundColor: theme.palette.secondary.light
    },
    emphasize: {
        fontWeight: 'bold',
    }
});

class FourStageTable extends Component {
    handleClick = (winLose, index) => {
        const {clickCell, data: {name}} = this.props;
        if (clickCell instanceof Function)
            clickCell('add-list', (game, rally) => {
                if (winLose === 'win') {
                    if ((index === 2 || index === 0) && rally.rallyLength === 1)
                        return store.getData(`Player${1 - rally.winSide}0`).name !== name;
                    return store.getData(`Player${rally.winSide}0`).name === name && (
                      index === 0 ? true : (
                        index === 7 ? (rally.rallyLength > 7  && rally.rallyLength % 2 === 0) : (
                          index === 6 ? (rally.rallyLength > 6 && rally.rallyLength % 2 === 1) :
                            index === rally.rallyLength - 1
                        )
                      )
                    );
                } else {
                    return store.getData(`Player${1 - rally.winSide}0`).name === name && (
                      index === 0 ? true : (
                        index === 7 ? (rally.rallyLength >= 7  && rally.rallyLength % 2 === 1) : (
                          index === 6 ? (rally.rallyLength >= 6 && rally.rallyLength % 2 === 0) :
                            index === rally.rallyLength
                        )
                      )
                    );
                }
            });
    };

    parseName(name) {
        const nameList = name.split(', ');
        if (nameList.length > 1) return `对手`;
        else return nameList[0];
    }

    renderScore(winLose, index) {
        const {classes, data} = this.props;
        const d = data[winLose][index];
        return <td className={clsx(classes.emphasize, {[classes.selectable]: d > 0})}
                   onClick={() => this.handleClick(winLose, index)}>{d === 0 ? '-' : d}</td>;
    }

    renderRate(rate, color, colSpan = 1) {
        const {classes} = this.props;

        return <td colSpan={colSpan} className={clsx(classes.emphasize, {
            [classes.highlight]: color,
        })}>{rate}%</td>
    }

    render() {
        const {classes, data, clickCell, ...others} = this.props;
        const {scoreRate, colorScore, useRate} = data;

        return <table {...others}
                      className={classes.root}>
            <thead>
            <tr>
                <td rowSpan={2} title={data.name} className={classes.emphasize}>{this.parseName(data.name)}</td>
                <th colSpan={3}>发球抢攻段</th>
                <th colSpan={2}>发球相持段</th>
                <th colSpan={2}>接发球抢攻段</th>
                <th>接发球相持段</th>
                <th rowSpan={2}>总计</th>
            </tr>
            <tr>
                <th>发球</th>
                <th>第三拍</th>
                <th>第五拍</th>
                <th>第五拍</th>
                <th>相持I</th>
                <th>接发球</th>
                <th>第四拍</th>
                <th>相持II</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>得分</th>
                {this.renderScore('win', 1)}
                {this.renderScore('win', 3)}
                <td className={classes.disabledTableCell}/>
                {this.renderScore('win', 5)}
                {this.renderScore('win', 7)}
                {this.renderScore('win', 2)}
                {this.renderScore('win', 4)}
                {this.renderScore('win', 6)}
                {this.renderScore('win', 0)}
            </tr>
            <tr>
                <th>失分</th>
                {this.renderScore('lose', 1)}
                {this.renderScore('lose', 3)}
                {this.renderScore('lose', 5)}
                <td className={classes.disabledTableCell}/>
                {this.renderScore('lose', 7)}
                {this.renderScore('lose', 2)}
                {this.renderScore('lose', 4)}
                {this.renderScore('lose', 6)}
                {this.renderScore('lose', 0)}
            </tr>
            <tr>
                <th>得分率</th>
                {this.renderRate(scoreRate[1], colorScore[1], 3)}
                {this.renderRate(scoreRate[2], colorScore[2], 2)}
                {this.renderRate(scoreRate[3], colorScore[3], 2)}
                {this.renderRate(scoreRate[4], colorScore[4])}
                {this.renderRate(scoreRate[0], colorScore[0])}
            </tr>
            <tr>
                <th>使用率</th>
                {this.renderRate(useRate[1], false, 3)}
                {this.renderRate(useRate[2], false, 2)}
                {this.renderRate(useRate[3], false, 2)}
                {this.renderRate(useRate[4], false)}
                {this.renderRate(useRate[0], false)}
            </tr>
            </tbody>
        </table>;
    }
}

export default withStyles(styles)(FourStageTable);

// edited by WuJiang5521 on 2019/5/28
