import {useCallback, useEffect, useState} from "react";

const initConfig = {
    initState: "",
    initProgress: 0,
    errState: "error",
}

const useStateTransfer = (
  transferMatrix,
  activate,
  config = null,
  verbose = false,
) => {
    const {initState, initProgress, errState} = {...initConfig, ...config};

    const [state, setState] = useState(initState);
    const [progress, setProgress] = useState(initProgress);
    const handleRetry = useCallback(() => {
        if (!activate) return;
        setState(initState);
        setProgress(initProgress);
    }, [initState, initProgress, activate]);

    useEffect(() => {
        if (verbose) console.log(state, progress, activate);
        if (!activate) return;
        const stateConfig = transferMatrix[state];
        if (!stateConfig) return;
        if (progress === 0)
            setTimeout(() => stateConfig.onLoad({
                onProgress: setProgress,
                onFinish: () => setProgress(100),
                onError: errInfo => {
                    console.error(errInfo);
                    setState(stateConfig.errState || errState)
                }
            }), 100); // using timeout to avoid state override. For example, if users handleRetry in an effect after this one, the setProgress in this effect may be ignored.
        else if (progress === 100 && stateConfig.nxtState) {
            setState(stateConfig.nxtState);
            setProgress(0)
        }
    }, [state, progress, activate])

    return {
        state,
        progress, setProgress,
        handleRetry,
    }
}

export default useStateTransfer;
