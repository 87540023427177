import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import {Layer, Rect, Stage, Transformer} from "react-konva";
import {fade} from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
    drawer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
})

class ScoreboardDrawboard extends Component {
    state = {
        drawStart: null,
        cursor: null,
    }

    //region data interface
    setScoreboardPos = newParams => {
        const params = {...this.getRectProps(), ...newParams};
        const {size} = this.props;
        this.props.onSetScoreboard([
            params.x / size[0],
            params.y / size[1],
            (params.x + params.width) / size[0],
            (params.y + params.height) / size[1],
        ])
    }
    getRectProps = () => {
        const {size, scoreboard} = this.props;
        if (scoreboard === null) return null;
        else return {
            x: scoreboard[0] * size[0],
            y: scoreboard[1] * size[1],
            width: Math.abs(scoreboard[0] - scoreboard[2]) * size[0],
            height: Math.abs(scoreboard[1] - scoreboard[3]) * size[1],
        }
    }
    handleClear = () => this.props.onSetScoreboard(null);
    //endregion

    //region transform
    shapeRef = React.createRef();
    trRef = React.createRef();
    bindRef = ref => ele => {
        ref.current = ele;
        this.tryBindTransform();
    }
    tryBindTransform() {
        if (!this.props.scoreboard) return;
        if (!this.trRef.current) return;
        if (!this.shapeRef.current) return;
        this.trRef.current.nodes([this.shapeRef.current]);
        this.trRef.current.getLayer().batchDraw();
    }
    handleBoundBox = (oldBox, newBox) => {
        if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
        }
        return newBox;
    }
    handleTransform = e => {
        const node = this.shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);
        this.setScoreboardPos({
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
        });
    }
    //endregion

    //region drag
    handleDrag = e => this.setScoreboardPos({
        x: e.target.x(),
        y: e.target.y(),
    });
    //endregion

    //region draw
    getDrawProps = () => {
        const {drawStart, cursor} = this.state;
        if (drawStart === null) return null;
        else return {
            x: drawStart[0],
            y: drawStart[1],
            width: (cursor[0] - drawStart[0]),
            height: (cursor[1] - drawStart[1]),
        }
    }
    getPos = e => [e.evt.offsetX, e.evt.offsetY];
    handleMouseDown = e => {
        if (e.evt.button !== 0) return;
        if (this.props.scoreboard !== null) return;
        const pos = this.getPos(e);
        this.setState({
            drawStart: pos,
            cursor: pos,
        })
    }
    handleMouseMove = e => {
        if (this.state.drawStart === null) return;
        const pos = this.getPos(e);
        this.setState({
            cursor: pos,
        });
    }
    handleMouseUp = e => {
        const {drawStart} = this.state;
        if (drawStart === null) return;
        const pos = this.getPos(e);
        this.setScoreboardPos({
            x: Math.min(pos[0], drawStart[0]),
            y: Math.min(pos[1], drawStart[1]),
            width: Math.abs(pos[0] - drawStart[0]),
            height: Math.abs(pos[1] - drawStart[1]),
        })
        this.setState({
            drawStart: null,
            cursor: null,
        })
    }

    //endregion

    render() {
        const {classes, theme, size} = this.props;

        const drawProps = this.getDrawProps();
        const rectProps = this.getRectProps();
        const drawStyle = {
            fill: "transparent",
            strokeWidth: 2,
            stroke: theme.palette.primary.dark,
        }
        const rectStyle = {
            fill: fade(theme.palette.primary.main, 0.3),
            strokeWidth: 2,
            stroke: theme.palette.primary.dark,
        }

        return <Stage className={classes.drawer} width={size[0]} height={size[1]}
                      onContextMenu={this.handleClear}
                      onMouseDown={this.handleMouseDown}
                      onMouseMove={this.handleMouseMove}
                      onMouseUp={this.handleMouseUp}>
            <Layer>
                {drawProps !== null && <Rect {...drawProps} {...drawStyle}/>}
                {rectProps !== null && <Rect ref={this.bindRef(this.shapeRef)}
                                             draggable
                                             onDragEnd={this.handleDrag}
                                             onTransformEnd={this.handleTransform}
                                             {...rectProps}
                                             {...rectStyle}/>}
                {rectProps !== null && <Transformer ref={this.bindRef(this.trRef)}
                                                    keepRatio={false}
                                                    rotateEnabled={false}
                                                    boundBoxFunc={this.handleBoundBox}/>}
            </Layer>
        </Stage>;
    }
}

export default withStyles(styles, {withTheme: true})(ScoreboardDrawboard);

// edited by WuJiang5521 on 2021/5/24
