import {useCallback} from 'react';

function findChild(children, idx, offset = 0) {
  for (let i = idx + offset; i !== idx; i -= Math.sign(offset)) {
    if (Boolean(children[i]))
      return children[i];
  }
  return children[idx];
}

function ensureShow(curOffset, viewArea, childToShow, extraPadding) {
  let {left, top} = curOffset;
  const xRange = [left, left + viewArea.width],
    yRange = [top, top + viewArea.height];
  const xChild = [
      childToShow.offsetLeft - extraPadding,
      childToShow.offsetLeft + childToShow.clientWidth + extraPadding,
    ],
    yChild = [
      childToShow.offsetTop - extraPadding,
      childToShow.offsetTop + childToShow.clientHeight + extraPadding,
    ];

  if (xChild[0] < xRange[0]) left += (xChild[0] - xRange[0]);
  else if (xChild[1] > xRange[1]) left += (xChild[1] - xRange[1]);

  if (yChild[0] < yRange[0]) top += (yChild[0] - yRange[0]);
  else if (yChild[1] > yRange[1]) top += (yChild[1] - yRange[1]);

  return {left, top};
}

export default function useAutoScroll(
  listRef,
  option,
) {
  const {
    direction = 'y',
    numNeighborVisible = 1,
    centerFocus = false,
    extraPadding = 10,
  } = option;

  return useCallback((idxOfChild) => {
    if (listRef.current === null) return;
    if (!['relative', 'position', 'fixed', 'sticky'].includes(listRef.current.style.position))
      console.warn('When using `useAutoScroll` hooks, the HTMLElement regarded as list should have a position style that can provide relative position basis (i.g., relative, absolute, sticky, and fixed).');
    if (idxOfChild < 0) return;

    const current = listRef.current;
    const children = current.children;
    const childToFocus = findChild(children, idxOfChild);
    if (!childToFocus) return;

    let left = current.scrollLeft,
      top = current.scrollTop;

    if (centerFocus) {
      const focusChildLeft = childToFocus.offsetLeft,
        focusChildTop = childToFocus.offsetTop,
        focusChildWidth = childToFocus.clientWidth,
        focusChildHeight = childToFocus.clientHeight;
      left = focusChildLeft - focusChildWidth / 2;
      top = focusChildTop - focusChildHeight / 2;
    } else {
      const firstNeighbor = findChild(children, idxOfChild, -numNeighborVisible),
        lastNeighbor = findChild(children, idxOfChild, numNeighborVisible);
      const viewArea = {width: current.clientWidth, height: current.clientHeight};

      let curOffset = {left, top};
      curOffset = ensureShow(curOffset, viewArea, lastNeighbor, extraPadding);
      curOffset = ensureShow(curOffset, viewArea, firstNeighbor, extraPadding);
      curOffset = ensureShow(curOffset, viewArea, childToFocus, extraPadding);
      left = curOffset.left;
      top = curOffset.top;
    }

    if (direction === 'x')
      current.scrollTo({left, behavior: 'smooth'});
    else if (direction === 'y')
      current.scrollTo({top, behavior: 'smooth'});
    else
      current.scrollTo({top, left, behavior: 'smooth'});
  }, []);
}
