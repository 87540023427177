import React, {Component} from 'react';
import {
    RadioGroup,
    withStyles,
    FormControlLabel, Radio, FormControl, Typography, Divider, FormGroup, Checkbox
} from '@material-ui/core';
import ThreeStageTable from "./ThreeStageTable";
import FourStageTable from "./FourStageTable";
import Multi4Table from "./Multi4Table";
import Multi8Table from "./Multi8Table";
import ScoreTable from "./ScoreTable";
import store from '../../../Data/Store';
import {fade} from "@material-ui/core/styles/colorManipulator";
import ScoreStepGraph from "./ScoreStepGraph";
import ScoreLine from "./ScoreLine";
import VideoView from "./VideoView";

const drawerWidth = 150;

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    formControl: {
        margin: `0 0 ${theme.spacing(1)}px 0`,
    },
    title: {
        fontSize: '1.3rem',
        height: 40,
        lineHeight: '40px',
    },
    content: {
        position: "relative",
        flexGrow: 1,
        padding: theme.spacing(3),
        minWidth: 0,
        overflow: 'auto',
    },
    divider: {
        height: 2,
        backgroundColor: fade(theme.palette.primary.light, 0.25),
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        padding: theme.spacing(0, 1),
        flexShrink: 0,
        minWidth: 0,
        borderRight: `2px solid ${fade(theme.palette.primary.light, 0.25)}`,
    },
    scoreTable: {},
});

const div = (a, b) => {
    if (b === 0) return '0.00';
    else return `${(a * 100 / b).toFixed(2)}`;
};

class Statistics extends Component {
    state = {
        view: 'score-step',
        viewAttr: {games: [-1]},
        player: 0,
    };

    componentDidMount() {
        store.register({'statistics': this});
    }

    componentWillUnmount() {
        store.unregister({'statistics': this});
    }

    calDualGame = () => {
        const gameNumber = store.data.record.list.length;
        if (gameNumber % 2 === 0) return -1;
        if (gameNumber < 5) return -1;

        const lastGameScore = store.data.record.list[gameNumber - 1].score;
        if (lastGameScore[0] === lastGameScore[1]) return gameNumber;
        else return -1;
    };

    renderScore() {
        const {classes} = this.props;
        return <ScoreTable key={'score'} className={classes.scoreTable} data={{
            player: store.getData('PlayerNames').map(side => side.join(', ')),
            score: [store.getData('MatchResult')].concat(store.data.record.list.map(game => game.result)),
        }}/>;
    }

    handleClick = (key, filter) => {
        const rallyList = [];
        const dualGame = this.calDualGame();
        const rallyResult = store.getData('RallyResult');
        store.data.record.list.forEach((game, gId) => {
            if (!this.state.viewAttr.games) return;
            if (this.state.viewAttr.games.includes(-1) || this.state.viewAttr.games.includes(gId))
                game.list.forEach((rally, rId) => {
                    if (filter(game, rally, rallyResult[gId][rId]))
                        rallyList.push([gId, rId]);
                });
            else if ((gId === dualGame - 1) && (this.state.viewAttr.finalGame[0] || this.state.viewAttr.finalGame[1]))
                game.list.forEach((rally, rId) => {
                    if (!this.state.viewAttr.finalGame[(rally.score[0] < 5 && rally.score[1] < 5) ? 0 : 1]) return;
                    if (filter(game, rally, rallyResult[gId][rId]))
                        rallyList.push([gId, rId]);
                })
        });

        switch (key) {
            case "replace-list": {
                store.handleChange('VideoList')(rallyList);
                break;
            }
            case "add-list": {
                store.handleChange('VideoList')(store.videoList.concat(rallyList));
                break;
            }
            case "play-video": {
                this.setVideoList(rallyList);
                break;
            }
            default:
        }
    };

    renderGameFilter(rallyResult, distinguishFinalRally = false) {
        const handleChange = gId => {
            const {viewAttr} = this.state;
            if (viewAttr.games.includes(-1)) {
                if (gId === -1) {
                    viewAttr.games = [];
                } else {
                    viewAttr.games = rallyResult.map((_, i) => i).filter(d => d !== gId);
                }
            } else if (viewAttr.games.includes(gId)) {
                if (gId === -1) {
                    viewAttr.games = [];
                } else {
                    viewAttr.games = viewAttr.games.filter(d => d !== -1 && d !== gId);
                }
            } else {
                if (gId === -1) {
                    viewAttr.games = [-1];
                } else {
                    const games = viewAttr.games;
                    games.push(gId);
                    if (games.length >= rallyResult.length) viewAttr.games = [-1];
                    else viewAttr.games = games;
                }
            }
            if (viewAttr.games.includes(-1) || viewAttr.games.includes(4))
                viewAttr.finalGame = [true, true];
            else viewAttr.finalGame = [false, false];
            this.setState({viewAttr});
        };
        const dualGame = this.calDualGame();
        const handleChangeFinal = half => {
            if (!distinguishFinalRally) return;
            const {viewAttr} = this.state;
            viewAttr.finalGame[half] = !viewAttr.finalGame[half];
            if (viewAttr.finalGame[0] && viewAttr.finalGame[1]) {
                if (!viewAttr.games.includes(dualGame - 1)) {
                    viewAttr.games.push(dualGame - 1);
                    if (viewAttr.games.length >= rallyResult.length) viewAttr.games = [-1];
                }
            } else {
                if (viewAttr.games.includes(-1)) {
                    viewAttr.games = rallyResult.map((_, i) => i).filter(d => d !== dualGame - 1);
                } else if (viewAttr.games.includes(dualGame - 1))
                    viewAttr.games.splice(viewAttr.games.indexOf(dualGame - 1), 1);
            }
            this.setState({viewAttr});
        };

        return <FormGroup row>
            <FormControlLabel
                key={-1}
                control={
                    <Checkbox
                        checked={this.state.viewAttr.games.includes(-1)}
                        onChange={() => handleChange(-1)}
                    />
                }
                label={store.languageSwitch('all')}/>
            {
                rallyResult.map((g, gId) => <FormControlLabel
                    key={gId}
                    control={
                        <Checkbox
                            checked={this.state.viewAttr.games.includes(gId) || this.state.viewAttr.games.includes(-1)}
                            onChange={() => handleChange(gId)}
                        />
                    }
                    label={store.languageSwitch('GameNum')(gId + 1)}/>)
            }
            {
                distinguishFinalRally && dualGame !== -1 && <React.Fragment>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.viewAttr.finalGame[0]}
                                onChange={() => handleChangeFinal(0)}
                            />
                        }
                        label={store.languageSwitch('FrontHalfFinalGame')}/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.viewAttr.finalGame[1]}
                                onChange={() => handleChangeFinal(1)}
                            />
                        }
                        label={store.languageSwitch('BackHalfFinalGame')}/>
                </React.Fragment>
            }
        </FormGroup>;
    }

    renderTable() {
        const {view, player} = this.state;
        const playerNames = store
            .getData('PlayerNames')
            .map(side => side.join(', '));
        const rallyResult = store.getData('RallyResult');

        switch (view) {
            case "single-three-stage": {
                const win = [[0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0]],
                    lose = [[0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0]];
                const len = pos => {
                    if (pos === 0) return 2;
                    else if (pos < 6) return pos;
                    else return 5;
                };
                //region Statistics
                rallyResult.forEach((game, gId) => {
                    if (this.state.viewAttr.games.includes(gId) || this.state.viewAttr.games.includes(-1))
                        game.forEach(rally => {
                            if (!rally.win) return;
                            if (+rally.win[0] === player) {
                                win[0][len(rally.length - 1)]++;
                                win[0][0]++;
                                lose[1][len(rally.length)]++;
                                lose[1][0]++;
                            } else {
                                lose[0][len(rally.length)]++;
                                lose[0][0]++;
                                win[1][len(rally.length - 1)]++;
                                win[1][0]++;
                            }
                        });
                });
                const scoreRate = [], useRate = [];
                [0, 1].forEach(idx => {
                    const data = {
                        win: win[idx],
                        lose: lose[idx],
                    };
                    const countWin = data.win.reduce((p, c, i) => (p + (i === 0 ? 0 : c)), 0);
                    const countLose = data.lose.reduce((p, c, i) => (p + (i === 0 ? 0 : c)), 0);
                    const total = countWin + countLose;
                    scoreRate.push([
                        div(countWin, total),
                        div(data.win[1] + data.win[3], data.win[1] + data.win[3] + data.lose[1] + data.lose[3]),
                        div(data.win[2] + data.win[4], data.win[2] + data.win[4] + data.lose[2] + data.lose[4]),
                        div(data.win[5], data.win[5] + data.lose[5]),
                    ]);
                    useRate.push([
                        div(total, total),
                        div(data.win[1] + data.win[3] + data.lose[1] + data.lose[3], total),
                        div(data.win[2] + data.win[4] + data.lose[2] + data.lose[4], total),
                        div(data.win[5] + data.lose[5], total),
                    ]);
                });
                //endregion
                return <React.Fragment>
                    {this.renderScore()}
                    <ThreeStageTable data={{
                        name: playerNames[player],
                        win: win[0],
                        lose: lose[0],
                        scoreRate: scoreRate[0],
                        useRate: useRate[0],
                        colorScore: [0, 1, 2, 3].map(idx => scoreRate[0][idx] > scoreRate[1][idx]),
                    }} key={0} clickCell={this.handleClick}/>
                    <ThreeStageTable data={{
                        name: playerNames[1 - player],
                        win: win[1],
                        lose: lose[1],
                        scoreRate: scoreRate[1],
                        useRate: useRate[1],
                        colorScore: [0, 1, 2, 3].map(idx => scoreRate[1][idx] > scoreRate[0][idx]),
                    }} key={1} clickCell={this.handleClick}/>
                    {this.renderGameFilter(rallyResult)}
                </React.Fragment>;
            }
            case "single-four-stage": {
                const win = [[0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0]],
                    lose = [[0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0]];
                const len = pos => {
                    if (pos === 0) return 2;
                    else if (pos < 6) return pos;
                    else return pos % 2 + 6;
                };
                //region Statistics
                rallyResult.forEach((game, gId) => {
                    if (this.state.viewAttr.games.includes(gId) || this.state.viewAttr.games.includes(-1))
                        game.forEach(rally => {
                            if (!rally.win) return;
                            if (+rally.win[0] === player) {
                                win[0][len(rally.length - 1)]++;
                                win[0][0]++;
                                lose[1][len(rally.length)]++;
                                lose[1][0]++;
                            } else {
                                lose[0][len(rally.length)]++;
                                lose[0][0]++;
                                win[1][len(rally.length - 1)]++;
                                win[1][0]++;
                            }
                        });
                });
                const scoreRate = [], useRate = [];
                [0, 1].forEach(idx => {
                    const data = {win: win[idx], lose: lose[idx]};
                    const countWin = data.win.reduce((p, c, i) => (p + (i === 0 ? 0 : c)), 0);
                    const countLose = data.lose.reduce((p, c, i) => (p + (i === 0 ? 0 : c)), 0);
                    const total = countWin + countLose;
                    scoreRate.push([
                        div(countWin, total),
                        div(data.win[1] + data.win[3], data.win[1] + data.win[3] + data.lose[1] + data.lose[3] + data.lose[5]),
                        div(data.win[5] + data.win[7], data.win[5] + data.win[7] + data.lose[7]),
                        div(data.win[2] + data.win[4], data.win[2] + data.win[4] + data.lose[2] + data.lose[4]),
                        div(data.win[6], data.win[6] + data.lose[6]),
                    ]);
                    useRate.push([
                        div(total, total),
                        div(data.win[1] + data.win[3] + data.lose[1] + data.lose[3] + data.lose[5], total),
                        div(data.win[5] + data.win[7] + data.lose[7], total),
                        div(data.win[2] + data.win[4] + data.lose[2] + data.lose[4], total),
                        div(data.win[6] + data.lose[6], total),
                    ]);
                });
                //endregion
                return <React.Fragment>
                    {this.renderScore()}
                    <FourStageTable data={{
                        name: playerNames[player],
                        win: win[0],
                        lose: lose[0],
                        scoreRate: scoreRate[0],
                        useRate: useRate[0],
                        colorScore: [0, 1, 2, 3, 4].map(idx => scoreRate[0][idx] > scoreRate[1][idx]),
                    }} key={0} clickCell={this.handleClick}/>
                    <FourStageTable data={{
                        name: playerNames[1 - player],
                        win: win[1],
                        lose: lose[1],
                        scoreRate: scoreRate[1],
                        useRate: useRate[1],
                        colorScore: [0, 1, 2, 3, 4].map(idx => scoreRate[0][idx] < scoreRate[1][idx]),
                    }} key={1} clickCell={this.handleClick}/>
                    {this.renderGameFilter(rallyResult)}
                </React.Fragment>;
            }
            case "multi4": {
                const players = playerNames[this.state.player].split(', ');
                if (players.length !== 2) {
                    players[0] = 'A';
                    players[1] = 'B';
                }

                const win = [[0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0]],
                    lose = [[0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0]];
                const len = pos => {
                    if (pos < 5) return pos;
                    else return (pos - 1) % 4 + 5;
                };
                const dualGame = this.calDualGame();

                //region Statistics
                rallyResult
                    .map((game, gId) => game.filter(rally => {
                        if (gId !== dualGame - 1) return true;
                        return this.state.viewAttr.finalGame[(rally.score[0] < 5 && rally.score[1] < 5) ? 0 : 1];
                    }))
                    .forEach((game, gId) => {
                        if ((this.state.viewAttr.games.includes(gId) || this.state.viewAttr.games.includes(-1)) ||
                            (gId === dualGame - 1 && (this.state.viewAttr.finalGame[0] || this.state.viewAttr.finalGame[1])))
                            game.forEach(rally => {
                                if (!rally.win) return;
                                const losePlayer = rally.lose;
                                if (+losePlayer[0] !== +this.state.player) {
                                    if (rally.length > 1) {
                                        const winPlayer = rally.win;
                                        win[+winPlayer[1]][len(rally.length - 1)]++;
                                    } else {
                                        const winPlayer = rally.win;
                                        win[+winPlayer[1]][2]++;
                                    }
                                } else {
                                    lose[+losePlayer[1]][len(rally.length)]++;
                                }
                            });
                    });
                //endregion
                return <React.Fragment>
                    {this.renderScore()}
                    <Multi4Table data={{
                        players: [{
                            name: players[0],
                            win: win[0],
                            lose: lose[0],
                        }, {
                            name: players[1],
                            win: win[1],
                            lose: lose[1],
                        }],
                    }} clickCell={this.handleClick}/>
                    {this.renderGameFilter(rallyResult, true)}
                </React.Fragment>;
            }
            case "multi8": {
                const dualGame = this.calDualGame();
                const rallies = rallyResult
                    .map((game, gId) => game.filter(rally => {
                        if (gId !== dualGame - 1) return true;
                        return this.state.viewAttr.finalGame[(rally.score[0] < 5 && rally.score[1] < 5) ? 0 : 1];
                    }))
                    .filter((game, gId) => {
                        if (this.state.viewAttr.games.includes(gId) || this.state.viewAttr.games.includes(-1)) return true;
                        if (gId !== dualGame - 1) return false;
                        return this.state.viewAttr.finalGame[0] || this.state.viewAttr.finalGame[1]
                    }).flat();

                return <React.Fragment>
                    {this.renderScore()}
                    <Multi8Table rallies={rallies} player={player} clickCell={this.handleClick}/>
                    {this.renderGameFilter(rallyResult, true)}
                </React.Fragment>
            }
            case "score-step": {
                const data = store.data.record.list.map(game => game.list.map(rally => {
                    const score = JSON.parse(JSON.stringify(rally.score));
                    score[rally.winSide]++;
                    return score;
                })).flat();
                const player = store.data.player.map(side => side.map(p => p.name).join(', '));
                const time = store.data.record.list.map(game => game.list.map(rally => rally.rallyLength)).flat();
                return <ScoreStepGraph data={data} player={player} time={time} handleClick={this.handleClick}/>
            }
            case "score-line": {
                //region Init
                const players = store.getData('PlayerNames');
                const score = {
                    name: players,
                    data: players.map(s => s.map(_ => [])),
                };
                const losePoint = {
                    name: players,
                    data: players.map(s => s.map(_ => [])),
                };
                //endregion
                //region Statistics
                let max = 1;
                rallyResult.forEach((game, gId) => {
                    if (this.state.viewAttr.games.includes(gId) || this.state.viewAttr.games.includes(-1))
                        game.forEach((rally, rId) => {
                            const {length, win, lose} = rally;
                            if (!!win) {
                                if (length === 1) {
                                    if (isNaN(score.data[win[0]][win[1]][1]))
                                        score.data[win[0]][win[1]][1] = 0;
                                    max = Math.max(max, 2);
                                    score.data[win[0]][win[1]][1]++;
                                } else {
                                    if (isNaN(score.data[win[0]][win[1]][length - 2]))
                                        score.data[win[0]][win[1]][length - 2] = 0;
                                    max = Math.max(max, length - 1);
                                    score.data[win[0]][win[1]][length - 2]++;
                                }
                            }
                            if (!!lose) {
                                if (isNaN(losePoint.data[lose[0]][lose[1]][length - 1]))
                                    losePoint.data[lose[0]][lose[1]][length - 1] = 0;
                                max = Math.max(max, length);
                                losePoint.data[lose[0]][lose[1]][length - 1]++;
                            }
                        });
                });
                //endregion
                return <React.Fragment>
                    <ScoreLine title={'scoreLineGraph'} data={score} max={max} value={'win'}
                               handleClick={this.handleClick}/>
                    <ScoreLine title={'loseLineGraph'} data={losePoint} max={max} value={'lose'}
                               handleClick={this.handleClick}/>
                    {this.renderGameFilter(rallyResult)}
                </React.Fragment>;
            }
            default:
                return <div/>;
        }
    }

    initAttr = view => {
        switch (view) {
            case "single-three-stage":
                return {games: [-1]};
            case "single-four-stage":
                return {games: [-1]};
            case "multi4":
                return {games: [-1], finalGame: [true, true]};
            case "multi8":
                return {games: [-1], finalGame: [true, true]};
            case "score-step":
                return {games: [-1]};
            case "score-line":
                return {games: [-1]};
            default:
                return {};
        }
    };

    handleChangeView = e => {
        this.setState({
            view: e.target.value,
            viewAttr: this.initAttr(e.target.value)
        });
    };

    handleChangePlayer = e => {
        this.setState({player: parseInt(e.target.value, 10)});
    };

    render() {
        const {classes} = this.props;

        return <div className={classes.root}>
            <div className={classes.drawerPaper}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <Typography color={"primary"}
                                className={classes.title}>{store.languageSwitch('viewList')}</Typography>
                    <RadioGroup
                        value={this.state.view}
                        onChange={this.handleChangeView}
                    >
                        {[
                            {
                                value: 'score-step',
                                disabled: false,
                                label: store.languageSwitch('viewStep'),
                            },
                            {
                                value: 'score-line',
                                disabled: false,
                                label: store.languageSwitch('viewScoreLine'),
                            },
                            {
                                value: 'single-three-stage',
                                disabled: store.getData('PlayerNames')[0].length !== 1,
                                label: store.languageSwitch('viewSingle3'),
                            },
                            {
                                value: 'single-four-stage',
                                disabled: store.getData('PlayerNames')[0].length !== 1,
                                label: store.languageSwitch('viewSingle4'),
                            },
                            {
                                value: 'multi4',
                                disabled: store.getData('PlayerNames')[0].length !== 2,
                                label: store.languageSwitch('viewMulti4'),
                            },
                            {
                                value: 'multi8',
                                disabled: store.getData('PlayerNames')[0].length !== 2,
                                label: store.languageSwitch('viewMulti8'),
                            },
                        ].map(d => {
                            return <FormControlLabel key={d.value} value={d.value}
                                                     control={<Radio disabled={d.disabled}/>}
                                                     label={d.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
                <Divider className={classes.divider}/>
                {
                    ['single-three-stage', 'single-four-stage', 'multi4', 'multi8'].includes(this.state.view) &&
                    <FormControl component="fieldset" className={classes.formControl}>
                        <Typography color={"primary"}
                                    className={classes.title}>{store.languageSwitch('playerList')}</Typography>
                        <RadioGroup
                            value={`${this.state.player}`}
                            onChange={this.handleChangePlayer}
                        >
                            {
                                store.getData('PlayerNames').map((side, sId) => {
                                    const player = side.join(', ');
                                    return <FormControlLabel value={`${sId}`} control={<Radio/>} label={player}
                                                             key={sId}/>;
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                }
            </div>
            <main className={classes.content}>
                {this.renderTable()}
                {
                    store.statisticVideoList.length > 0 &&
                    <VideoView srcInfo={store.statisticVideoList[0]}
                               handleClose={() => this.setVideoList([])}
                               onEnd={() => this.setVideoList(store.statisticVideoList.slice(1))}/>
                }
            </main>
        </div>;
    }

    setVideoList = list => {
        store.handleChange('StatisticsVideoList')(list);
    };
}

export default withStyles(styles)(Statistics);

// edited by WuJiang5521 on 2019/5/28
