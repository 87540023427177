import React, {Component} from 'react';
import {Typography, withStyles} from '@material-ui/core';
import store from '../../../Data/Store';
import ReactEcharts from "echarts-for-react";

const styles = () => ({
    root: {},
    title: {
        fontSize: '1.3rem',
    }
});

class ScoreLine extends Component {
    state = {
        color: [],
        symbol: [['emptyCircle', 'emptyDiamond'], ['emptyRect', 'emptyTriangle']],
    };

    componentDidMount() {
        const {theme} = this.props;
        this.setState({
            color: [
                [theme.palette.primary.light, theme.palette.primary.dark],
                [theme.palette.secondary.light, theme.palette.secondary.dark]
            ],
        })
    }

    getOptions = () => {
        const {data: {name, data}, max} = this.props;
        return {
            color: this.state.color.map((s, sId) => s.slice(0, name[sId].length)).flat(),
            xAxis: {
                type: 'category',
                data: [...new Array(max)].map((d, i) => i + 1),
                triggerEvent: true,
                axisLabel: {
                    fontSize: 18,
                }
            },
            yAxis: {
                type: 'value',
                textStyle: {
                    fontSize: 18,
                }
            },
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: name.flat(),
                textStyle: {
                    fontSize: 18,
                }
            },
            series: data.map((s, sId) => {
                return s.map((p, pId) => ({
                    name: name[sId][pId],
                    data: [...new Array(max)].map((d, i) => !p[i] ? 0 : p[i]),
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 4,
                    },
                    // symbol: this.state.symbol[sId][pId],
                    // symbolSize: 8,
                }));
            }).flat(),
        };
    };

    handleEvents = e => {
        this.props.handleClick('add-list', (game, rally, rallyResult) => {
            if (this.props.value === 'win') {
                const {win, length} = rallyResult;
                if (!win) return false;
                if (e.componentType === 'series') {
                    if (e.seriesName !== store.getData(`Player${win}`).name)
                        return false;
                    return e.dataIndex === (length === 1 ? 1 : length - 2);
                } else return parseInt(e.value, 10) === (length === 1 ? 2 : length - 1);
            } else {
                let loseStrike = rally.list[rally.list.length - 1];
                if (!loseStrike) {
                    if (store.getData("PlayerNum") !== 1) return false;
                    loseStrike = {HitPlayer: `${1 - rally.winSide}0`};
                }
                if (e.componentType === 'series') {
                    if (e.seriesName !== store.getData(`Player${loseStrike.HitPlayer}`).name)
                        return false;
                    return e.dataIndex === rally.rallyLength - 1;
                } else return parseInt(e.value, 10) === rally.rallyLength;
            }
        });
    };

    render() {
        const {classes, title} = this.props;
        return <div className={classes.root}>
            <Typography className={classes.title} color={"primary"}>
                {store.languageSwitch(title)}
            </Typography>
            <ReactEcharts option={this.getOptions()} onEvents={{
                click: this.handleEvents
            }}/>
        </div>;
    }
}

export default withStyles(styles, {withTheme: true})(ScoreLine);

// edited by WuJiang5521 on 2019/9/3
