import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import store from '../../../Data/Store';
import hotkey from 'react-shortcut-key';
import Pause from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Replay from '@material-ui/icons/Replay';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import VolumeOff from '@material-ui/icons/VolumeOff';
import VolumeMute from '@material-ui/icons/VolumeMute';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import {fade} from '@material-ui/core/styles/colorManipulator';

const componentOnSpace = e => {
    e.preventDefault();
    store.handleChange("VideoPlayPause")();
};
const componentOnLeft = e => {
    e.preventDefault();
    store.handleChange("VideoPlayBack")();
};
const componentOnRight = e => {
    e.preventDefault();
    store.handleChange("VideoPlayFront")();
};
const componentOnPre = e => {
    e.preventDefault();
    store.handleChange("VideoPlayPreFrame")();
};
const componentOnNext = e => {
    e.preventDefault();
    store.handleChange("VideoPlayNextFrame")();
};
const componentOnUp = e => {
    e.preventDefault();
    store.handleChange("VideoSpeedUp")();
};
const componentOnDown = e => {
    e.preventDefault();
    store.handleChange("VideoSpeedDown")();
};
const componentOnReplay = e => {
    e.preventDefault();
    store.handleChange("VideoReplay")();
};
const keymap = {
    'space': componentOnSpace,
    'left': componentOnLeft,
    'right': componentOnRight,
    'c': componentOnPre,
    'v': componentOnNext,
    'up': componentOnUp,
    'down': componentOnDown,
    'r': componentOnReplay,
};

const style = store.pageStyle;
const styles = theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '20%',
        minHeight: '150px',
    },
    controlPanel: {
        position: 'absolute',
        bottom: 0,
        height: '48px',
        left: 0,
        right: 0,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        display: 'flex',
    },
    timelineAll: {
        flex: 5,
        position: 'relative',
        height: '100%',
    },
    timelineRally: {
        flex: 2,
        position: 'relative',
        height: '100%',
    },
    progress: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    playedPart: {
        position: 'absolute',
        left: '10px',
        top: '10px',
        height: '10px',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
    },
    toPlayPart: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        height: '10px',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        backgroundColor: fade(theme.palette.primary.main, 0),
    },
    playedText: {
        position: 'absolute',
        left: '10px',
        top: '29px',
        bottom: '3px',
        lineHeight: '16px',
        fontSize: '14px',
        color: style.text,
    },
    toPlayText: {
        position: 'absolute',
        right: '10px',
        top: '29px',
        bottom: '3px',
        lineHeight: '16px',
        fontSize: '14px',
        color: style.text,
    },
    currentPot: {
        position: 'absolute',
        top: '5px',
        width: '20px',
        height: '20px',
        borderRadius: '10px',
        border: `2px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.main,
    },
    volumePanel: {
        position: 'absolute',
        bottom: '48px',
        height: '100px',
        left: '12px',
        width: '24px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        cursor: 'pointer',
    },
    baseVolume: {
        position: 'absolute',
        bottom: '5px',
        left: '8px',
        width: '6px',
        borderBottomLeftRadius: '3px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: '3px',
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
    },
    potentialVolume: {
        position: 'absolute',
        top: '5px',
        left: '8px',
        width: '6px',
        borderBottomRightRadius: 0,
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
        borderBottomLeftRadius: 0,
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        backgroundColor: fade(theme.palette.primary.main, 0),
    },
    currentVolume: {
        position: 'absolute',
        left: '6px',
        width: '12px',
        height: '12px',
        borderRadius: '6px',
        border: `2px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.main,
    },
});

class VideoController extends Component {
    state = {
        hover: false,
        play: false,
        rate: 1,
        volume: 1,
        showVolume: false,
        mute: false,
        progress: 0,
    };

    slideOnControl = '';
    cmpPosition = 0;
    totalLength = 1;
    startTime = 0;
    endTime = 1;

    UNSAFE_componentWillMount() {
        store.register({video: this});
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let isSame = false;
        if (nextProps.location === null && this.props.location === null) isSame = true;
        if (!!nextProps.location && !!this.props.location &&
          nextProps.location[0] === this.props.location[0] && nextProps.location[1] === this.props.location[1])
            isSame = true;
        if (!isSame)
            this.calcDuration(nextProps);
        return true;
    }

    calcDuration(props = this.props) {
        const {location} = props;
        const bufferTime = props.bufferTime || 0;
        const rally = !location ? store.getData('FocusRally') : store.getData('RallyInfo')(location);
        if (rally === null) {
            this.startTime = 0;
            this.endTime = 1;
        } else {
            this.startTime = Math.max(rally.startTime - bufferTime, 0);
            this.endTime = rally.endTime + bufferTime;
        }
        if (!!location) {
            this.video.currentTime = this.startTime;
        }
    }

    componentWillUnmount() {
        this.video.ontimeupdate = null;
        store.unregister({video: this});
    }

    componentDidMount() {
        this.video = document.getElementById("video-player");
        this.video.ontimeupdate = this.handleTimeChange;
        this.calcDuration();
    }

    //region Timer
    handleTimeChange = () => {
        this.setState({
            progress: this.video.currentTime
        });
        if (this.props.onRallyEnd instanceof Function)
            if (this.video.currentTime > this.endTime)
                this.props.onRallyEnd();
    };
    //endregion

    //region Interactive
    handleMouseOver = () => {
        this.setState({
            hover: true,
        });
    };

    handleMouseOut = () => {
        this.setState({
            hover: false,
        });
    };

    handleMouseDown = key => e => {
        this.slideOnControl = key;
        const dom = document.getElementById(`${key}Slide`);
        const rect = dom.getBoundingClientRect();
        switch (key) {
            case 'total':
            case 'rally':
                this.cmpPosition = rect.left;
                this.totalLength = dom.clientWidth - 32;
                break;
            case 'volume':
                this.cmpPosition = rect.bottom;
                this.totalLength = dom.clientHeight - 18;
                break;
            default:
                this.cmpPosition = 0;
                this.totalLength = 1;
        }
        this.handleMouseMove(e);
    };

    handleMouseUp = e => {
        this.slideOnControl = '';
        this.cmpPosition = 0;
        this.totalLength = 1;
        this.handleMouseMove(e);
    };

    handleMouseMove = e => {
        if (this.slideOnControl !== '') {
            switch (this.slideOnControl) {
                case 'total': {
                    let offset = e.clientX - this.cmpPosition - 16;
                    if (offset < 0) offset = 0;
                    if (offset > this.totalLength) offset = this.totalLength;
                    const rate = offset / this.totalLength;
                    this.video.currentTime = this.video.duration * rate;
                    this.setState({
                        progress: this.video.duration * rate,
                    });
                }
                    break;
                case 'rally': {
                    let offset = e.clientX - this.cmpPosition - 16;
                    if (offset < 0) offset = 0;
                    if (offset > this.totalLength) offset = this.totalLength;
                    const rate = offset / this.totalLength;
                    this.video.currentTime = this.startTime + rate * (this.endTime - this.startTime);
                    this.setState({
                        progress: this.startTime + rate * (this.endTime - this.startTime),
                    });
                }
                    break;
                case 'volume': {
                    let offset = this.cmpPosition - e.clientY - 9;
                    if (offset < 0) offset = 0;
                    if (offset > this.totalLength) offset = this.totalLength;
                    const rate = offset / this.totalLength;
                    this.video.volume = rate;
                    this.setState({
                        volume: rate,
                    });
                }
                    break;
                default:
                    break;
            }
        }
        if (!this.state.hover) this.setState({
            hover: true
        });
    };

    handleShowVolume = () => {
        this.setState({
            showVolume: true,
        });
    };

    handleHideVolume = () => {
        this.setState({
            showVolume: false,
        });
    };

    handleMute = () => {
        const mute = this.state.mute;
        this.setState({
            mute: !mute,
        });
        this.video.muted = !mute;
    };
    //endregion

    //region Render
    renderVolume() {
        const {classes} = this.props;
        const button = this.state.mute ? <VolumeOff/> :
          (this.state.volume < 0.3 ? <VolumeMute/> :
            (this.state.volume < 0.7 ? <VolumeDown/> : <VolumeUp/>));
        return <div onMouseEnter={this.handleShowVolume} onMouseLeave={this.handleHideVolume}
                    style={{position: 'relative'}}>
            <IconButton onClick={this.handleMute}>{button}</IconButton>
            {
                this.state.showVolume &&
                <div className={classes.volumePanel} onMouseDown={this.handleMouseDown('volume')} id={'volumeSlide'}>
                    <div className={classes.baseVolume} style={{height: `${82 * this.state.volume + 3}px`}}/>
                    <div className={classes.potentialVolume} style={{height: `${82 * (1 - this.state.volume) + 3}px`}}/>
                    <div className={classes.currentVolume} style={{bottom: `${3 + 82 * this.state.volume}px`}}/>
                </div>
            }
        </div>
    }

    renderProgress(start, end, key) {
        const {classes} = this.props;
        if (isNaN(start) || isNaN(end) || end <= start) return <div/>;
        const progress = this.state.progress < start ? start : (this.state.progress > end ? end : this.state.progress);
        const playedLength = progress - start, toPlayLength = end - progress;
        const Num2Time = num => {
            let txt = '';
            if (num < 0) {
                txt = '-';
                num = -num;
            }
            num = parseInt(num, 10);
            const min = parseInt(num / 60, 10), sec = num % 60;
            txt += `${min} : ${sec < 10 ? '0' : ''}${sec}`;
            return txt;
        };
        const playedText = Num2Time(this.state.progress - start), toPlayText = Num2Time(end - this.state.progress);
        const playedRate = playedLength / (end - start), toPlayRate = toPlayLength / (end - start);
        return <div id={key + 'Slide'} className={classes.progress} onMouseDown={this.handleMouseDown(key)}>
            <div className={classes.playedPart}
                 style={{width: `calc(${100 * playedRate}% - ${32 * playedRate - 5}px)`}}/>
            <div className={classes.toPlayPart}
                 style={{width: `calc(${100 * toPlayRate}% - ${32 * toPlayRate - 5}px)`}}/>
            <div className={classes.currentPot}
                 style={{left: `calc(${100 * playedRate}% - ${32 * playedRate - 4}px)`}}/>
            <div className={classes.playedText}>{playedText}</div>
            <div className={classes.toPlayText}>{toPlayText}</div>
        </div>
    }

    render() {
        const {classes} = this.props;

        return <div className={classes.root} onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseOut}
                    onMouseUp={this.handleMouseUp} onMouseMove={this.handleMouseMove}>
            {
                this.state.hover && this.props.activate &&
                <div className={classes.controlPanel}>
                    <IconButton onClick={store.handleChange("VideoPlayPause")}>{this.state.play ? <Pause/> :
                      <PlayArrow/>}</IconButton>
                    <IconButton onClick={store.handleChange("VideoReplay")}><Replay/></IconButton>
                    <div className={classes.timelineAll}>
                        {this.renderProgress(0, this.video.duration, 'total')}
                    </div>
                    <div className={classes.timelineRally}>
                        {this.renderProgress(this.startTime, this.endTime, 'rally')}
                    </div>
                    {this.renderVolume()}
                    <Button onClick={store.handleChange("VideoSpeedUp")} onContextMenu={e => {
                        e.preventDefault();
                        store.handleChange("VideoSpeedDown")();
                        return false;
                    }}>{`x${this.state.rate}`}</Button>
                </div>
            }
        </div>;
    }

    //endregion
}

export default hotkey(keymap)(withStyles(styles)(VideoController));
