import React, {Component} from 'react';
import {IconButton, Typography, withStyles} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import store from '../../../Data/Store';
import Video from "../../Common/Video/Video";
import {fade} from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
    root: {
        position: 'absolute',
        height: '75%',
        zIndex: 100,
        left: theme.spacing(3),
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: fade(theme.palette.primary.contrastText, 0.8),
        padding: theme.spacing(3),
        borderRadius: '0.3vw',
        boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative,'
    },
    titleText: {
        fontSize: '1.5rem',
        color: theme.palette.primary.main,
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        fontSize: '1.5rem'
    },
    videoArea: {
        height: 'calc(100% - 1.5rem - 40px)',
        flexGrow: 1,
        position: 'relative',
    }
});

class VideoView extends Component {
    render() {
        const {classes, srcInfo, handleClose, onEnd} = this.props;


        return <div className={classes.root}>
            <div className={classes.title}>
                <Typography className={classes.titleText}>
                    {store.languageSwitch('GameNum')(srcInfo[0] + 1)}
                </Typography>
                <Typography className={classes.titleText}>
                    {store.languageSwitch('RallyNum')(srcInfo[1] + 1)}
                </Typography>
                <IconButton color={"secondary"} className={classes.closeButton} onClick={handleClose}>
                    <Close/>
                </IconButton>
            </div>
            <div className={classes.videoArea}>
                <Video onRallyEnd={onEnd} autoPlay location={srcInfo} bufferTime={3}/>
            </div>
        </div>;
    }
}

export default withStyles(styles)(VideoView);

// edited by WuJiang5521 on 2019/9/5
