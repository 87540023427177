import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import VideoAnnotator from "../Components/Annotators/VideoAnnotator";
import ScoreDrawboard from "../Components/Annotators/ScoreboardDrawboard";
import store from "../../../Data/Store";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        width: '100%',
        height: 30,
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'center',
    }
}));

function ScoreboardAnnotator({
                            scoreboard,
                            onSetScoreboard,
                            onPre,
                            onNext,
                        }) {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.content}>
            <VideoAnnotator src={store.getData('Video')}>
                {videoSize => <ScoreDrawboard size={videoSize}
                                              scoreboard={scoreboard}
                                              onSetScoreboard={onSetScoreboard}/>}
            </VideoAnnotator>
        </div>
        <div className={classes.actions}>
            <Button size={"small"}
                    color={"primary"}
                    onClick={onPre}>{store.languageSwitch("preStep")}</Button>
            <Button size={"small"}
                    color={"primary"}
                    variant={"contained"}
                    disabled={scoreboard === null}
                    onClick={onNext}>{store.languageSwitch("nxtStep")}</Button>
        </div>
    </div>
}

export default ScoreboardAnnotator;
