import React, {useCallback, useState} from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
    row: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0)
    },
    btn: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        },
        marginRight: theme.spacing(1),
    }
}));

const useProjectLoader = () => {
    const [videoSrc, setVideoSrc] = useState('');
    const [projName, setProjName] = useState('');
    const [projData, setProjData] = useState(null);

    const setVideo = useCallback((video) => {
        if (!video) setVideoSrc('');
        else setVideoSrc(video);
    }, []);
    const setProj = useCallback(proj => {
        if (!proj) {
            setProjName('');
            setProjData(null);
        } else {
            const reader = new FileReader();
            reader.onloadend = ev => {
                if (!ev.target) return;
                if (ev.target.readyState === FileReader.DONE) {
                    const result = JSON.parse(ev.target.result);

                    setProjName(proj.name);
                    setProjData(result);
                }
            };
            reader.readAsText(proj);
        }
    }, [])

    return {videoSrc, projData, projName, setVideo, setProj}
}

const ImportProject = ({open, onClose, onSubmit}) => {
    const {videoSrc, projData, projName, setVideo, setProj} = useProjectLoader();

    const handleClose = onClose;
    const handleCancel = useCallback(() => {
        setVideo(null);
        setProj(null);
        handleClose();
    }, [handleClose, setProj, setVideo]);
    const handleConfirm = useCallback(() => {
        if (videoSrc === '' || projName === '') {
            window.alert("请选择要导入的视频与工程文件！");
            return;
        }
        onSubmit(videoSrc, projData, projName.substr(0, projName.length - 5))
        handleCancel();
    }, [onSubmit, handleCancel, projData, projName, videoSrc]);
    const handleSelVideo = useCallback(() => {
        setVideo(prompt("输入视频地址："))
    }, [setVideo]);
    const handleSelProj = useCallback(() => {
        const importer = document.createElement('input');
        importer.type = 'file';
        importer.accept = '.json'
        importer.onchange = () => {
            const files = importer.files;
            if (!files) return;
            const project = files[0];
            setProj(project);
        };
        importer.click();
    }, [setProj]);

    const classes = useStyles();
    return <Dialog open={open}
                   maxWidth={'lg'}
                   onClose={handleClose}>
        <DialogTitle>打开项目</DialogTitle>
        <DialogContent>
            <div className={classes.row}>
                <Button className={classes.btn} onClick={handleSelVideo}>选择视频</Button>
                <Typography>{videoSrc === '' ? "未选择视频" : videoSrc}</Typography>
            </div>
            <div className={classes.row}>
                <Button className={classes.btn} onClick={handleSelProj}>选择工程</Button>
                <Typography>{projName === '' ? "未选择工程" : projName}</Typography>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleConfirm}>确认</Button>
            <Button onClick={handleCancel}>取消</Button>
        </DialogActions>
    </Dialog>
};

export default ImportProject;
