import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        margin: 25,
        border: `2px solid ${theme.palette.primary.light}`,
        borderCollapse: 'collapse',
        '& td': {
            padding: 5,
            border: `2px solid ${theme.palette.primary.light}`,
            textAlign: 'center',
        },
        '& th': {
            padding: 5,
            border: `2px solid ${theme.palette.primary.light}`,
            minWidth: 70,
        }
    },
    disabled: {
        backgroundImage: 'linear-gradient(to bottom right, transparent calc(50% - 1px), rgba(0,0,0,0.5), transparent calc(50% + 1px))',
    },
    winScore: {
        color: 'red',
    }
});

class ScoreTable extends Component {
    renderTitle(index) {
        const d2ch = i => {
            switch (i) {
                case 1:
                    return '一';
                case 2:
                    return '二';
                case 3:
                    return '三';
                case 4:
                    return '四';
                case 5:
                    return '五';
                case 6:
                    return '六';
                case 7:
                    return '七';
                default:
                    return '　';
            }
        };
        return <th key={index}>{`第${d2ch(index)}局`}</th>;
    }

    renderScore(index) {
        const {classes, data} = this.props;
        if (index >= data.score.length) return <td key={index} className={classes.disabled}/>;
        else {
            const s = data.score[index];
            return <td key={index}>
                <span className={clsx({
                    [classes.winScore]: s[0] > s[1]
                })}>{s[0]}</span>
                :
                <span className={clsx({
                    [classes.winScore]: s[1] > s[0]
                })}>{s[1]}</span>
            </td>
        }
    }

    render() {
        const {classes, data, className, ...others} = this.props;
        let len = (data.score[0][0] > data.score[0][1] ? data.score[0][0] : data.score[0][1]) * 2 - 1;
        if (len < 0) len = 0;

        return <table className={clsx(classes.root, className)} {...others}>
            <tbody>
            <tr>
                <th>{data.player[0]} VS {data.player[1]}</th>
                {Array(len).fill(0).map((d, i) => this.renderTitle(i + 1))}
            </tr>
            <tr>
                {this.renderScore(0)}
                {Array(len).fill(0).map((d, i) => this.renderScore(i + 1))}
            </tr>
            </tbody>
        </table>;
    }
}

export default withStyles(styles)(ScoreTable);

// edited by WuJiang5521 on 2019/5/29
