const wordDict = {
    //system
    FailOpenVideo: {cn: '无法读取视频！', en: 'Failed to Open Video!'},
    FailOpenProject: {cn: '无法读取工程！', en: 'Failed to Open Project!'},
    // page
    pageHome: {cn: '主页', en: 'Home'},
    pageInfo: {cn: '视频', en: 'Video'},
    pageAutoDetection: {cn: '智能', en: 'AI'},
    pageLocate: {cn: '清洗', en: 'Parse'},
    pageRecord: {cn: '采集', en: 'Collect'},
    pageExport: {cn: '分析', en: 'Analyze'},
    // language
    cn: {cn: '简体中文', en: '简体中文'},
    en: {cn: 'English', en: 'English'},
    // type
    WTTNormal: {cn: 'WTT常规挑战赛', en: 'WTT Contender'},
    WTTStar: {cn: 'WTT球星挑战赛', en: 'WTT Star Contender'},
    WTTFeeder: {cn: 'WTT支线赛', en: 'WTT Feeder'},
    WTTChampions: {cn: 'WTT冠军赛', en: 'WTT Champions'},
    WTTSmash: {cn: 'WTT大满贯赛', en: 'WTT Smash'},
    WTTWorldCupFinal: {cn: 'WTT世界杯总决赛', en: 'WTT World Cup Finals'},
    WTTYouthNormal: {cn: 'WTT青少年常规挑战赛', en: 'WTT Youth Contender'},
    WTTYouthStar: {cn: 'WTT青少年球星挑战赛', en: 'WTT Youth Star Contender'},
    Top32: {cn: '32强赛', en: 'Top 32'},
    WorldYouthChampion: {cn: '世青赛', en: 'World Youth Championships'},
    AsianYouthChampion: {cn: '亚青赛', en: 'Asian Junior and Cadet Championships'},
    Tryouts: {cn: '选拔赛', en: 'Tryouts'},
    Passes: {cn: '直通赛', en: 'Passes'},
    EuroChampion: {cn: '欧锦赛', en: 'European Championships'},
    WorldTeamChampion: {cn: '世乒赛团体赛', en: 'World Team Championships Finals'},
    WorldTour: {cn: '公开赛', en: 'World Tour'},
    WorldFinal: {cn: '国际乒联总决赛', en: 'World Tour Final'},
    WorldChampion: {cn: '世乒赛', en: 'World Championships'},
    WorldCup: {cn: '世界杯', en: 'World Cup'},
    Olympic: {cn: '奥运会', en: 'Olympic Games'},
    ChineseSuper: {cn: '乒超联赛', en: 'Chinese Super League'},
    AsianGame: {cn: '亚运会', en: 'Asian Games'},
    AsianChampion: {cn: '亚锦赛', en: 'Asian Championships'},
    AsianCup: {cn: '亚洲杯', en: 'Asian Cup'},
    OlympicChoose: {cn: '奥运选拔赛'},
    InGroup: {cn: '队内赛'},
    AntiEuroAsia: {cn: '欧亚对抗赛'},
    OtherType: {cn: '其他', en: 'Others'},
    // competition
    MenT: {cn: '男团', en: 'Men\'s Team'},
    MenTS: {cn: '男团单打', en: 'Men\'s Team (Singles)'},
    MenTD: {cn: '男团双打', en: 'Men\'s Team (Doubles)'},
    MenS: {cn: '男单', en: 'Men\'s Singles'},
    MenD: {cn: '男双', en: 'Men\'s Doubles'},
    WomenT: {cn: '女团', en: 'Women\'s Team'},
    WomenTS: {cn: '女团单打', en: 'Women\'s Team (Singles)'},
    WomenTD: {cn: '女团双打', en: 'Women\'s Team (Doubles)'},
    WomenS: {cn: '女单', en: 'Women\'s Singles'},
    WomenD: {cn: '女双', en: 'Women\'s Doubles'},
    MixedS: {cn: '混单', en: 'Mixed Singles'},
    MixedD: {cn: '混双', en: 'Mixed Doubles'},
    // round
    Final: {cn: '决赛', en: 'Final'},
    SemiFinal: {cn: '半决赛', en: 'Semi-Finals'},
    QuarterFinal: {cn: '四分之一决赛', en: 'Quarter Finals'},
    Round16: {cn: '八分之一决赛', en: 'Round of 16'},
    Round32: {cn: '十六分之一决赛', en: 'Round of 32'},
    Round64: {cn: '三十二分之一决赛', en: 'Round of 64'},
    Round128: {cn: '六十四分之一决赛', en: 'Round of 128'},
    Groups1: {cn: '小组赛第一轮', en: 'Groups R1'},
    Groups2: {cn: '小组赛第二轮', en: 'Groups R2'},
    Groups3: {cn: '小组赛第三轮', en: 'Groups R3'},
    Formal1: {cn: '正赛第一轮', en: 'Formal R1'},
    Formal2: {cn: '正赛第二轮', en: 'Formal R2'},
    Formal3: {cn: '正赛第三轮', en: 'Formal R3'},
    Qualify1: {cn: '资格赛第一轮', en: 'Qualifying R1'},
    Qualify2: {cn: '资格赛第二轮', en: 'Qualifying R2'},
    Qualify3: {cn: '资格赛第三轮', en: 'Qualifying R3'},
    Qualify4: {cn: '资格赛第四轮', en: 'Qualifying R4'},
    OtherRound: {cn: '其他', en: 'Others'},
    // face
    faceZheng: {cn: '正胶', en: 'Long Pimpled Rubber'},
    faceIn: {cn: '反胶', en: 'In Pimpled Rubber'},
    faceOut: {cn: '生胶', en: 'Out Pimpled Rubber'},
    faceLong: {cn: '长胶', en: 'Long Pimpled Rubber'},
    // term
    BallPosition: {en: 'Stroke Placement', cn: '落点'},
    StrikePosition: {en: 'Stroke Position', cn: '身位'},
    StrikeTech: {en: 'Stroke Technique', cn: '技术'},
    GameAction: {en: 'Game Action', cn: '势态'},
    StrikeEffect: {en: 'Strike Effect', cn: '效果'},
    SpinKind: {en: 'Stroke Spin', cn: '旋转'},
    // value
    S1: {en: 'Serve 1', cn: '反手区发球'},
    S2: {en: 'Serve 2', cn: '正手区发球'},
    SP: {en: 'Special', cn: '意外球'},
    FL: {en: 'Long Forehand', cn: '正手长球'},
    FH: {en: 'Half-long Forehand', cn: '正手半长球'},
    FS: {en: 'Short Forehand', cn: '正手短球'},
    ML: {en: 'Long Middle', cn: '中路长球'},
    MH: {en: 'Half-long Middle', cn: '中路半长球'},
    MS: {en: 'Short Middle', cn: '中路短球'},
    BL: {en: 'Long Backhand', cn: '反手长球'},
    BH: {en: 'Half-long Backhand', cn: '反手半长球'},
    BS: {en: 'Short Backhand', cn: '反手短球'},
    F: {en: 'Forehand', cn: '正手位'},
    B: {en: 'Backhand', cn: '反手位'},
    T: {en: 'Back Turn', cn: '反侧身'},
    P: {en: 'Pivot', cn: '侧身位'},
    Pendulum: {en: 'Pendulum', cn: '钟摆式发球'},
    Reverse: {en: 'Reverse', cn: '逆旋转发球'},
    Tomahawk: {en: 'Tomahawk', cn: '砍式发球'},
    Topspin: {en: 'Topspin', cn: '弧圈球'},
    Attack: {en: 'Attack', cn: '快攻'},
    Smash: {en: 'Smash', cn: '扣杀'},
    Flick: {en: 'Flick', cn: '挑打'},
    Twist: {en: 'Twist', cn: '拧'},
    Push: {en: 'Push', cn: '搓球'},
    Short: {en: 'T Short', cn: '摆短'},
    Slide: {en: 'Slide', cn: '撇'},
    Block: {en: 'Block', cn: '挡球'},
    Lob: {en: 'Lob', cn: '放高球'},
    Chopping: {en: 'Chopping', cn: '削球'},
    PimpleTech: {en: 'P Tech', cn: '颗粒胶技术'},
    Others: {en: 'Others', cn: '其他技术'},
    Serve: {en: 'Serve', cn: '发球'},
    Receive: {en: 'Receive', cn: '接发球'},
    Stalemate: {en: 'Stalemate', cn: '相持'},
    Offensive: {en: 'Offensive', cn: '进攻'},
    Defensive: {en: 'Defensive', cn: '防御'},
    Controlled: {en: 'Controlled', cn: '控制'},
    L: {en: '', cn: ''},
    L1: {en: 'Level 1', cn: '等级1'},
    L2: {en: 'Level 2', cn: '等级2'},
    L3: {en: 'Level 3', cn: '等级3'},
    L4: {en: 'Level 4', cn: '等级4'},
    L5: {en: 'Level 5', cn: '等级5'},
    ST: {en: 'Strong Topspin', cn: '强上旋'},
    NT: {en: 'Normal Topspin', cn: '中上旋'},
    NS: {en: 'No Spin', cn: '不旋转'},
    ND: {en: 'Normal Downspin', cn: '中下旋'},
    SD: {en: 'Strong Downspin', cn: '强下旋'},
    SK: {en: 'Sink', cn: '下沉'},
    WT: {en: 'No Touch', cn: '未触球'},
    // welcome
    SystemName: {cn: '乒乓数据采集系统', en: 'Table Tennis Data Collector'},
    New: {cn: '新建', en: 'New'},
    NewLocal: {cn: '本地采集', en: 'Local'},
    NewOnline: {cn: '新建合作', en: 'New Collaboration'},
    JoinOnline: {cn: '加入合作', en: 'Join Collaboration'},
    Open: {cn: '打开', en: 'Open'},
    Save: {cn: '保存', en: 'Save'},
    Updates: {cn: '更新日志', en: 'Updates Log'},
    // match info
    matchInfo: {cn: '比赛信息', en: 'Match Info'},
    ChooseVideo: {cn: '选择比赛视频', en: 'Choose the video'},
    DragVideo: {cn: '（或将视频拖至此处）', en: '(or drag the video file here)'},
    matchYear: {cn: '年', en: 'Year'},
    matchMonth: {cn: '月', en: 'Month'},
    matchLocation: {cn: '比赛地点', en: 'Country/City'},
    matchType: {cn: '比赛类型', en: 'Type'},
    matchCompetition: {cn: '比赛项目', en: 'Competition'},
    matchRound: {cn: '比赛轮次', en: 'Round'},
    // player info
    playerInfo: {cn: '球员信息', en: 'Player Info'},
    playerName: {cn: '姓名', en: 'Name'},
    playerCountry: {cn: '国家', en: 'Country'},
    playerRank: {cn: '排名', en: 'Rank'},
    playerLeftHand: {cn: '惯用左手', en: 'Left-handed'},
    playerRightHand: {cn: '惯用右手', en: 'Right-handed'},
    playerHorizontalPat: {cn: '横拍', en: 'Shake-hand'},
    playerStraightPat: {cn: '直拍', en: 'Penhold'},
    playerBackFace: {cn: '反面胶型', en: 'Back-face type'},
    playerForeFace: {cn: '正面胶型', en: 'Fore-face type'},
    // locate
    RallyLength: {cn: '拍数', en: 'Number of strikes'},
    RallyStarterA: {cn: 'A方发球', en: 'A serves'},
    RallyStarterB: {cn: 'B方发球', en: 'B serves'},
    RallyWinnerA: {cn: 'A方胜出', en: 'A wins'},
    RallyWinnerB: {cn: 'B方胜出', en: 'B wins'},
    timeLock: {cn: "时间同步调整", en: "Synchronized Time Adjusting"},
    timeLockHelp: {
        cn: "勾选后，后续回合的时间将同步调整，保证回合间隔时间不变。",
        en: "After checking, the time of subsequent rounds will be adjusted synchronously to ensure that the round interval time remains unchanged."
    },
    // match tree
    Match: {cn: '比赛', en: 'Match'},
    GameNum: {cn: i => `第${i}局`, en: i => `Game${i}`},
    RallyNum: {cn: i => `第${i}回合`, en: i => `Rally${i}`},
    StrikeNum: {cn: i => `第${i}拍`, en: i => `Strike${i}`},
    // export
    fileList: {cn: '文件列表', en: 'File List'},
    csvFile: {cn: '.csv文件', en: '.csv file'},
    jsonFile: {cn: '.json文件', en: '.json file'},
    exportFile: {cn: '导出文件', en: 'Export'},
    exportVideo: {cn: '导出视频', en: 'Export'},
    checkFile: {cn: '请核对信息', en: 'Please check the information'},
    videoList: {cn: '视频列表', en: 'Video List'},
    videoCount: {cn: i => `共 ${i} 个片段`, en: i => `Number of Videos: ${i}`},
    // analyze
    viewList: {cn: '选择视图', en: 'View List'},
    playerList: {cn: '选择球员', en: 'Player List'},
    viewVideo: {cn: '视频', en: 'Video'},
    viewSingle3: {cn: '单打三段法', en: 'Single Three-stage'},
    viewSingle4: {cn: '单打四段法', en: 'Single Four-stage'},
    viewMulti4: {cn: '双打四轮表', en: 'Multi 4-round'},
    viewMulti8: {cn: '双打八轮表', en: 'Multi 8-round'},
    viewStep: {cn: '比分图', en: 'Score Step'},
    viewScoreLine: {cn: '得失分折线', en: 'Score Line'},
    // line chart
    scoreLineGraph: {cn: '得分-拍数折线图', en: 'Score-StrokeNum Line'},
    loseLineGraph: {cn: '失分-拍数折线图', en: 'Score-StrokeNum Line'},
    all: {cn: '全部', en: 'All'},
    // multi8
    win: {cn: '得分', en: 'Win'},
    lose: {cn: '失分', en: 'Lose'},
    total: {cn: '合计', en: 'Total'},
    FrontHalfFinalGame: {cn: '决胜局前段', en: 'Final game before 5 points'},
    BackHalfFinalGame: {cn: '决胜局后段', en: 'Final game after 5 points'},
    // auto detection
    dataPreprocess: {cn: "视频预处理", en: ""},
    autoDetection: {cn: "智能预采集", en: "AI Pre-collect"},
    annotateScore: {cn: '框选比分牌', en: "Annotate the score board"},
    annotateTable: {cn: '标记桌子四角', en: "Annotate the four corners of the table"},
    annotateNet: {cn: '标记球网上沿', en: "Annotate the top edge of the net"},
    backendProcess: {cn: '视频切分', en: "Segment the match"},
    segmentGame: {cn: '回合切分', en: "Segment the match"},
    getSegmentResult: {cn: '获取回合切分结果', en: "Get the result of segmenting"},
    calPat: {cn: '计算拍数', en: "Calculate the number of strokes"},
    preStep: {cn: '上一步', en: "Previous"},
    nxtStep: {cn: '下一步', en: "Next"},
    calMD5: {cn: "提取视频关键信息", en: "Extract Video Information"},
    checkVideoExistence: {cn: "智能识别信息", en: ""},
    uploadMP4: {cn: "上传视频文件", en: ""},
    uploadAnnotation: {cn: "上传用户标注", en: ""},
    uploadTable: {cn: "上传球桌定位", en: ""},
    uploadNet: {cn: "上传球网定位", en: ""},
    dataCheck: {cn: "数据检查", en: ""},
    StartTime: {cn: "开始时间"},
    EndTime: {cn: "结束时间"},
    ReverseScore: {cn: "调换分数"},
    InputGameScore: {cn: name => `请输入此时${name}的大比分：`},
    InputRallyScore: {cn: name => `请输入此时${name}的小比分：`},
    processFinish: {cn: "处理完成", en: ""},
    processError: {cn: "处理失败", en: ""},
    errorHelp: {cn: "请按下F12按钮，切换至控制台标签页，并截图联系管理员。", en: ""},
    EmptyGame: {cn: "局内不存在回合记录"},
    EmptyRally: {cn: "回合未初始化"},
    DuplicateGameInfo: {cn: "存在重复的局信息"},
    DuplicateRallyInfo: {cn: "存在重复的回合信息"},
    GameNotEnd: {cn: "局未结束"},
    MatchNotEnd: {cn: "比赛未结束"},
    HasErrorRally: {cn: rId => `局内第${rId + 1}回合存在错误`},
    WrongRallyTime: {cn: "回合时间存在错误"},
    WrongRallyLength: {cn: "回合长度存在错误"},
    WrongRallyScore: {cn: "回合比分存在错误"},
    // dialog
    cancel: {cn: '取消', en: 'Cancel'},
    confirm: {cn: '确认', en: "Confirm"},
    retry: {cn: '重试', en: "Retry"},
};

export default wordDict;
