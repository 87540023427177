import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import store from '../../../Data/Store';

const styles = theme => ({
    root: {
        margin: 25,
        border: `2px solid ${theme.palette.primary.light}`,
        borderCollapse: 'collapse',
        '& td': {
            padding: 5,
            border: `2px solid ${theme.palette.primary.light}`,
            textAlign: 'center',
            '&$missVBorder': {
                borderTop: 'hidden',
                borderBottom: 'hidden',
                borderLeft: `2px solid ${theme.palette.primary.light}`,
                borderRight: `2px solid ${theme.palette.primary.light}`,
            },
            '&$missHBorder': {
                borderLeft: 'hidden',
                borderRight: 'hidden',
                borderTop: `2px solid ${theme.palette.primary.light}`,
                borderBottom: `2px solid ${theme.palette.primary.light}`,
            },
            '&$missBorder': {
                borderLeft: 'hidden',
                borderRight: 'hidden',
                borderTop: 'hidden',
                borderBottom: 'hidden',
            },
        },
        '& th': {
            padding: 5,
            fontWeight: 'normal',
            border: `2px solid ${theme.palette.primary.light}`,
            minWidth: 70,
        }
    },
    missBorder: {},
    missVBorder: {},
    missHBorder: {},
    selectable: {
        cursor: 'pointer',
        '&:hover': {
            border: `3px solid ${theme.palette.primary.dark}`,
        },
    },
    emphasize: {
        fontWeight: 'bold',
    }
});

class Multi4Table extends Component {
    renderCell(val) {
        const {classes} = this.props;

        return <td className={classes.emphasize}>{val}</td>;
    }

    handleClick = (arr) => {
        const {clickCell, data} = this.props;
        if (clickCell instanceof Function)
            clickCell('add-list', (game, rally, rallyResult) => {
                for (let [player, winLose, index] of arr) {
                    if (store.getData(`Player${winLose === 'win' ? rallyResult.win : rallyResult.lose}`).name !== data.players[player].name)
                        continue;

                    let len = rally.rallyLength;
                    if (winLose === 'win') {
                        if (len === 1) len = 2;
                        else len -= 1;
                    }
                    if (len < 5) {
                        if (index === len) return true;
                    } else {
                        if (index === (len - 1) % 4 + 5) return true;
                    }
                }
            });
    };

    renderScore(arr) {
        const {classes, data} = this.props;
        let val = 0;
        arr.forEach(idx => val += data.players[idx[0]][idx[1]][idx[2]]);

        return <td className={clsx(classes.emphasize, {[classes.selectable]: val !== 0})}
                   onClick={() => this.handleClick(arr)}>
            {val === 0 ? '-' : val}
        </td>
    }

    render() {
        const {classes, data} = this.props;
        const [a, b] = data.players;

        return <table className={classes.root}>
            <tbody>
            <tr>
                <th rowSpan={2}/>
                <th colSpan={2}>发球/第三拍</th>
                <th colSpan={2}>相持</th>
                <th colSpan={2}>合计</th>
                <td rowSpan={5} className={classes.missVBorder}/>
                <th rowSpan={2}/>
                <th colSpan={2}>接发球/第四拍</th>
                <th colSpan={2}>相持</th>
                <th colSpan={2}>合计</th>
            </tr>
            <tr>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
            </tr>
            <tr>
                {this.renderCell(a.name)}
                {this.renderScore([[0, 'win', 1]])}
                {this.renderScore([[0, 'lose', 1]])}
                {this.renderScore([[0, 'win', 5]])}
                {this.renderScore([[0, 'lose', 5]])}
                {this.renderScore([[0, 'win', 1], [0, 'win', 5]])}
                {this.renderScore([[0, 'lose', 1], [0, 'lose', 5]])}

                {this.renderCell(b.name)}
                {this.renderScore([[1, 'win', 2]])}
                {this.renderScore([[1, 'lose', 2]])}
                {this.renderScore([[1, 'win', 6]])}
                {this.renderScore([[1, 'lose', 6]])}
                {this.renderScore([[1, 'win', 2], [1, 'win', 6]])}
                {this.renderScore([[1, 'lose', 2], [1, 'lose', 6]])}
            </tr>
            <tr>
                {this.renderCell(b.name)}
                {this.renderScore([[1, 'win', 3]])}
                {this.renderScore([[1, 'lose', 3]])}
                {this.renderScore([[1, 'win', 7]])}
                {this.renderScore([[1, 'lose', 7]])}
                {this.renderScore([[1, 'win', 3], [1, 'win', 7]])}
                {this.renderScore([[1, 'lose', 3], [1, 'lose', 7]])}

                {this.renderCell(a.name)}
                {this.renderScore([[0, 'win', 4]])}
                {this.renderScore([[0, 'lose', 4]])}
                {this.renderScore([[0, 'win', 8]])}
                {this.renderScore([[0, 'lose', 8]])}
                {this.renderScore([[0, 'win', 4], [0, 'win', 8]])}
                {this.renderScore([[0, 'lose', 4], [0, 'lose', 8]])}
            </tr>
            <tr>
                <th>合计</th>
                {this.renderScore([[0, 'win', 1], [1, 'win', 3]])}
                {this.renderScore([[0, 'lose', 1], [1, 'lose', 3]])}
                {this.renderScore([[0, 'win', 5], [1, 'win', 7]])}
                {this.renderScore([[0, 'lose', 5], [1, 'lose', 7]])}
                {this.renderScore([[0, 'win', 1], [0, 'win', 5], [1, 'win', 3], [1, 'win', 7]])}
                {this.renderScore([[0, 'lose', 1], [0, 'lose', 5], [1, 'lose', 3], [1, 'lose', 7]])}

                <th>合计</th>
                {this.renderScore([[1, 'win', 2], [0, 'win', 4]])}
                {this.renderScore([[1, 'lose', 2], [0, 'lose', 4]])}
                {this.renderScore([[1, 'win', 6], [0, 'win', 8]])}
                {this.renderScore([[1, 'lose', 6], [0, 'lose', 8]])}
                {this.renderScore([[1, 'win', 2], [1, 'win', 6], [0, 'win', 4], [0, 'win', 8]])}
                {this.renderScore([[1, 'lose', 2], [1, 'lose', 6],[0, 'lose', 4], [0, 'lose', 8] ])}
            </tr>
            <tr>
                <td colSpan={7} className={classes.missHBorder}/>
                <td className={classes.missBorder}/>
                <td colSpan={7} className={classes.missHBorder}/>
            </tr>
            <tr>
                <th rowSpan={2}/>
                <th colSpan={2}>发球/第三拍</th>
                <th colSpan={2}>相持</th>
                <th colSpan={2}>合计</th>
                <td rowSpan={5} className={classes.missVBorder}/>
                <th rowSpan={2}/>
                <th colSpan={2}>接发球/第四拍</th>
                <th colSpan={2}>相持</th>
                <th colSpan={2}>合计</th>
            </tr>
            <tr>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
                <th>得分</th>
                <th>失分</th>
            </tr>
            <tr>
                {this.renderCell(b.name)}
                {this.renderScore([[1, 'win', 1]])}
                {this.renderScore([[1, 'lose', 1]])}
                {this.renderScore([[1, 'win', 5]])}
                {this.renderScore([[1, 'lose', 5]])}
                {this.renderScore([[1, 'win', 1], [1, 'win', 5]])}
                {this.renderScore([[1, 'lose', 1], [1, 'lose', 5]])}

                {this.renderCell(a.name)}
                {this.renderScore([[0, 'win', 2]])}
                {this.renderScore([[0, 'lose', 2]])}
                {this.renderScore([[0, 'win', 6]])}
                {this.renderScore([[0, 'lose', 6]])}
                {this.renderScore([[0, 'win', 2], [0, 'win', 6]])}
                {this.renderScore([[0, 'lose', 2], [0, 'lose', 6]])}
            </tr>
            <tr>
                {this.renderCell(a.name)}
                {this.renderScore([[0, 'win', 3]])}
                {this.renderScore([[0, 'lose', 3]])}
                {this.renderScore([[0, 'win', 7]])}
                {this.renderScore([[0, 'lose', 7]])}
                {this.renderScore([[0, 'win', 3], [0, 'win', 7]])}
                {this.renderScore([[0, 'lose', 3], [0, 'lose', 7]])}

                {this.renderCell(b.name)}
                {this.renderScore([[1, 'win', 4]])}
                {this.renderScore([[1, 'lose', 4]])}
                {this.renderScore([[1, 'win', 8]])}
                {this.renderScore([[1, 'lose', 8]])}
                {this.renderScore([[1, 'win', 4], [1, 'win', 8]])}
                {this.renderScore([[1, 'lose', 4], [1, 'lose', 8]])}
            </tr>
            <tr>
                <th>合计</th>
                {this.renderScore([[1, 'win', 1], [0, 'win', 3]])}
                {this.renderScore([[1, 'lose', 1], [0, 'lose', 3]])}
                {this.renderScore([[1, 'win', 5], [0, 'win', 7]])}
                {this.renderScore([[1, 'lose', 5], [0, 'lose', 7]])}
                {this.renderScore([[1, 'win', 1], [1, 'win', 5], [0, 'win', 3], [0, 'win', 7]])}
                {this.renderScore([[1, 'lose', 1], [1, 'lose', 5], [0, 'lose', 3], [0, 'lose', 7]])}

                <th>合计</th>
                {this.renderScore([[0, 'win', 2], [1, 'win', 4]])}
                {this.renderScore([[0, 'lose', 2], [1, 'lose', 4]])}
                {this.renderScore([[0, 'win', 6], [1, 'win', 8]])}
                {this.renderScore([[0, 'lose', 6], [1, 'lose', 8]])}
                {this.renderScore([[0, 'win', 2], [0, 'win', 6], [1, 'win', 4], [1, 'win', 8]])}
                {this.renderScore([[0, 'lose', 2], [0, 'lose', 6], [1, 'lose', 4], [1, 'lose', 8]])}
            </tr>
            </tbody>
        </table>;
    }
}

export default withStyles(styles)(Multi4Table);

// edited by WuJiang5521 on 2019/5/29
