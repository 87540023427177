export const oldMatchTypes = [
    "WorldTour",
    "WorldChampion",
    "WorldCup",
    "Olympic",
    "ChineseSuper",
    "AsianGame",
    "OlympicChoose",
    "AsianChampion",
    "AsianCup",
    "InGroup",
    "AntiEuroAsia",
    'WorldFinal',
    "OtherType"
]

export const allMatchTypes = [
    'WTTNormal',
    'WTTStar',
    'WTTFeeder',
    'WTTChampions',
    'WTTSmash',
    'WTTWorldCupFinal',
    'WTTYouthNormal',
    'WTTYouthStar',
    'Top32',
    'WorldYouthChampion',
    'AsianYouthChampion',
    'Tryouts',
    'Passes',
    'EuroChampion',
    'WorldTeamChampion',
    "WorldTour",
    "WorldFinal",
    "WorldChampion",
    "WorldCup",
    "Olympic",
    "ChineseSuper",
    "AsianGame",
    "OlympicChoose",
    "AsianChampion",
    "AsianCup",
    "InGroup",
    "AntiEuroAsia",
    "OtherType"
];

export const oldMatchCompetitions = [
    "MenT",
    "MenS",
    "MenD",
    "WomenT",
    "WomenS",
    "WomenD",
    "MixedD"
];

export const allMatchCompetitions = [
    "MenT",
    "MenTS",
    "MenTD",
    "MenS",
    "MenD",
    "WomenT",
    "WomenTS",
    "WomenTD",
    "WomenS",
    "WomenD",
    "MixedS",
    "MixedD"
];

export const oldMatchRounds = [
    "Final",
    "SemiFinal",
    "QuarterFinal",
    "Round16",
    "Round32",
    "Groups1",
    "Groups2",
    "Groups3",
    "OtherRound",
];

export const allMatchRounds = [
    "Final",
    "SemiFinal",
    "QuarterFinal",
    "Round16",
    "Round32",
    "Round64",
    "Round128",
    "Groups1",
    "Groups2",
    "Groups3",
    "Formal1",
    "Formal2",
    "Formal3",
    "Qualify1",
    "Qualify2",
    "Qualify3",
    "Qualify4",
    "OtherRound",
];

export const allFaceTypes = [
    "faceZheng",
    "faceIn",
    "faceOut",
    "faceLong"
];
