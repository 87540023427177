import React from 'react';
import {Divider, List, ListItem, ListItemText, makeStyles, Popover} from "@material-ui/core";
import interleave from "../../Utils/interleave";

const useStyles = makeStyles(theme => ({
    root: {},
    popover: {
        pointerEvents: 'none'
    }
}));

function ErrorHinter({errInfo, children}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <>
        <div className={classes.root}
             onMouseEnter={handlePopoverOpen}
             onMouseLeave={handlePopoverClose}>
            {children}
        </div>
        {errInfo && errInfo.length > 0 && <Popover className={classes.popover}
                                                   open={open}
                                                   anchorEl={anchorEl}
                                                   anchorOrigin={{
                                                       vertical: 'center',
                                                       horizontal: 'right',
                                                   }}
                                                   transformOrigin={{
                                                       vertical: 'center',
                                                       horizontal: 'left',
                                                   }}
                                                   onClose={handlePopoverClose}
                                                   disableRestoreFocus>
            <List>
                {interleave(
                  errInfo.map((err, eId) => <ListItem diablePadding key={`err${eId}`}>
                      <ListItemText>{err.type}</ListItemText>
                  </ListItem>),
                  i => i > 0 && i < errInfo.length && <Divider key={`divider${i}`}/>
                )}
            </List>
        </Popover>}
    </>
}

export default ErrorHinter;
