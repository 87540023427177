export default function interleave(
  list,
  insertion,
) {
  const l = [];
  list.forEach((item, i) => {
    l.push(insertion(i));
    l.push(item);
  });
  l.push(insertion(list.length));
  return l;
}
