import React, {Component} from 'react';
import clsx from 'clsx';
import store from '../../Data/Store';
import hotkey from 'react-shortcut-key';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";

const style = store.pageStyle;
const styles = theme => ({
    root: {
        width: '100%',
        height: '300px',
        overflow: 'hidden',
    },
    score: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '150px',
        overflow: 'hidden',
    },
    bigScoreCard: {
        height: '150px',
        width: '100px',
        fontSize: '80px',
        lineHeight: '180px',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    smallScoreCard: {
        height: '75px',
        width: '50px',
        fontSize: '40px',
        lineHeight: '90px',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    playerName: {
        flex: 1,
        height: '150px',
        fontSize: '50px',
        lineHeight: '180px',
        textAlign: 'center',
        color: style.text
    },
    scorePadding: {
        width: '20px',
    },
    info: {
        width: '100%',
        height: '150px',
        overflow: 'hidden',
        display: 'flex'
    },
    placeholder: {
        height: '100%',
        flex: 1,
    },
    sideSelect: {
        height: '100%',
        flex: 1,
        display: 'flex',
    },
    timeSelect: {
        height: '100%',
        flex: 1,
        textAlign: 'center',
    },
    timeButton: {
        margin: '50px 0',
        height: '50px',
        lineHeight: '50px',
        color: style.text,
        backgroundColor: theme.palette.primary.contrastText,
        '&:hover': {
            color: style.text,
            backgroundColor: theme.palette.primary.contrastText,
            border: `1px solid ${style.text}`,
        },
    },
    rallyLength: {
        height: '100%',
        textAlign: 'center',
        flex: 1,
    },
    timeLock: {
        height: '100%',
        flex: 1,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    colorSwitchBase: {
        color: theme.palette.primary.main,
        '& + $colorBar': {backgroundColor: style.text},
        '&$colorChecked': {
            color: theme.palette.primary.main,
            '& + $colorBar': {backgroundColor: style.text},
        },
    },
    colorBar: {},
    colorChecked: {},
    switchButton: {
        margin: '50px 0',
        lineHeight: '50px',
    },
    switchChoice: {
        margin: '50px 0',
        flex: 1,
        lineHeight: '50px',
        textAlign: 'center',
        color: style.text,
    },
    textField: {
        margin: '50px 0',
        height: '50px',
        lineHeight: '50px',
        flex: 1,
        flexBasis: 200,
        '& div': {
            color: style.text,
            '& div': {
                '& svg': {color: style.text}
            }
        }
    },
    cssRoot: {
        color: style.text,
        '&$cssFocused': {color: theme.palette.primary.main},
    },
    cssFilled: {color: theme.palette.primary.main},
    cssFocused: {},
    cssUnderline: {
        '&:before': {borderBottom: `1px solid ${style.text}`},
        '&:after': {borderBottomColor: theme.palette.primary.main},
    },
});

let enableShortcut = true;
const componentOnNumber = num => e => {
    if (!enableShortcut) return;
    e.preventDefault();
    store.handleChange("RallyLengthAdd")({target: {value: num}});
};
const componentOnStart = e => {
    e.preventDefault();
    store.handleChange("RallyStart")();
};
const componentOnEnd = e => {
    e.preventDefault();
    store.handleChange("RallyEnd")();
};
const componentOnBackspace = e => {
    if (!enableShortcut) return;
    e.preventDefault();
    store.handleChange("RallyLengthBackspace")();
};
const componentOnEnter = e => {
    e.preventDefault();
    store.handleChange("NextRally")();
};
const keymap = {
    '0': componentOnNumber(0),
    '1': componentOnNumber(1),
    '2': componentOnNumber(2),
    '3': componentOnNumber(3),
    '4': componentOnNumber(4),
    '5': componentOnNumber(5),
    '6': componentOnNumber(6),
    '7': componentOnNumber(7),
    '8': componentOnNumber(8),
    '9': componentOnNumber(9),
    'numpad 0': componentOnNumber(0),
    'numpad 1': componentOnNumber(1),
    'numpad 2': componentOnNumber(2),
    'numpad 3': componentOnNumber(3),
    'numpad 4': componentOnNumber(4),
    'numpad 5': componentOnNumber(5),
    'numpad 6': componentOnNumber(6),
    'numpad 7': componentOnNumber(7),
    'numpad 8': componentOnNumber(8),
    'numpad 9': componentOnNumber(9),
    's': componentOnStart,
    'd': componentOnEnd,
    'backspace': componentOnBackspace,
    'enter': componentOnEnter,
};

class RallyInfo extends Component {
    state = {
        needRefresh: false
    };

    UNSAFE_componentWillMount() {
        store.register({rallyInfo: this});
    }

    componentWillUnmount() {
        store.unregister({rallyInfo: this});
    }

    enableShort = en => enableShortcut = en;

    render() {
        const {classes} = this.props;
        const item = store.getData('FocusRally');
        if (item === null) return <div/>;
        const timeFormat = second => {
            second = parseInt(second, 10);
            const hour = parseInt(second / 3600, 10);
            const min = parseInt((second % 3600) / 60, 10);
            const min0 = min < 10;
            const sec = second % 60;
            const sec0 = sec < 10;

            return `${hour} : ${min0 ? "0" : ""}${min} : ${sec0 ? "0" : ""}${sec}`;
        };

        return <div className={classes.root}>
            <div className={classes.score}>
                <div className={classes.playerName}>
                    {item.player[0]}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.bigScoreCard}>
                    {parseInt(item.rallyScore[0] / 10, 10)}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.bigScoreCard}>
                    {item.rallyScore[0] % 10}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.smallScoreCard}>
                    {item.gameScore[0]}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.smallScoreCard}>
                    {item.gameScore[1]}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.bigScoreCard}>
                    {parseInt(item.rallyScore[1] / 10, 10)}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.bigScoreCard}>
                    {item.rallyScore[1] % 10}
                </div>
                <div className={classes.scorePadding}/>
                <div className={classes.scorePadding}/>
                <div className={classes.playerName}>
                    {item.player[1]}
                </div>
            </div>
            <div className={classes.info}>
                <div className={classes.placeholder}/>
                <div className={classes.sideSelect}>
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyStarterA')}</Typography>
                    <Switch
                        checked={item.startSide === 1}
                        className={classes.switchButton}
                        classes={{
                            switchBase: classes.colorSwitchBase,
                            checked: classes.colorChecked,
                            track: classes.colorBar,
                        }}
                        onClick={store.handleChange("RallyStarter")}
                    />
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyStarterB')}</Typography>
                </div>
                <div className={classes.timeSelect}>
                    <Button className={clsx(classes.margin, classes.timeButton)}
                            onClick={store.handleChange("RallyStart")}>
                        {timeFormat(item.startTime)}
                    </Button>
                </div>
                <div className={classes.rallyLength}>
                    <TextField
                        className={classes.textField}
                        label={store.languageSwitch('RallyLength')}
                        value={item.rallyLength}
                        InputLabelProps={{
                            classes: {
                                root: classes.cssRoot,
                                filled: classes.cssFilled,
                                focused: classes.cssFocused,
                            }
                        }}
                        InputProps={{
                            classes: {
                                underline: classes.cssUnderline,
                            }
                        }}
                        onBlur={() => this.enableShort(true)}
                        onFocus={() => this.enableShort(false)}
                        onChange={store.handleChange("RallyLength")}
                    />
                </div>
                <div className={classes.timeSelect}>
                    <Button className={clsx(classes.margin, classes.timeButton)}
                            onClick={store.handleChange("RallyEnd")}>
                        {timeFormat(item.endTime)}
                    </Button>
                </div>
                <div className={classes.sideSelect}>
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyWinnerA')}</Typography>
                    <Switch
                      checked={item.winSide === 1}
                      className={classes.switchButton}
                      classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          track: classes.colorBar,
                      }}
                    />
                    <Typography className={classes.switchChoice}>{store.languageSwitch('RallyWinnerB')}</Typography>
                </div>
                <div className={classes.timeLock}>
                    <FormControlLabel label={store.languageSwitch("timeLock")}
                                      control={<Tooltip title={store.languageSwitch("timeLockHelp")}>
                                          <Checkbox color={"primary"}
                                                    checked={store.timeLock}
                                                    onChange={((event, checked) => store.handleChange("TimeLock")(checked))}/>
                                      </Tooltip>}/>
                </div>
                <div className={classes.placeholder}/>
            </div>
        </div>;
    }
}

export default hotkey(keymap)(withStyles(styles)(RallyInfo));
