const createURL = (protocol, ip, port, uri = "/", params = null,) => {
    return `${protocol}://${ip}:${port}${uri}${params === null ? "" : "?" + new URLSearchParams(params)}`;
}

const url = {
    local: (uri, params) => createURL("http", "10.76.2.49", "5000", uri, params),
    cvBackend: (uri, params) => createURL("https", "video-clip.projects.zjuidg.org", "443", uri, params),
    // cvBackend: (uri, params) => createURL("http", "10.76.2.58", "8080", uri, params),
}

const DEBUG = false;

window.on = e => {
    e.preventDefault();
    console.error(e);
}

const api = !DEBUG ? {
    checkVideoExistence: (video_name, md5) => new Promise((resolve, reject) => {
        fetch(url.cvBackend(
          '/video_existence',
          {video_name, md5}
        )).catch(reject)
          .then(res => res.json())
          .catch(reject)
          .then(resolve);
    }),
    uploadVideo: (fileObj, filename, md5, onProgress, cb, onError) => {
        let oldLoaded, oldTime;

        const form = new FormData();
        form.append("file", fileObj);
        form.append('video_name', filename);
        form.append('md5', md5);

        const xhr = new XMLHttpRequest();
        xhr.open("post", url.cvBackend('/video/upload'), true);
        xhr.onload = e => cb(JSON.parse(xhr.response));
        xhr.onerror = e => {
            console.error(e);
            onError && onError(e);
        }
        xhr.upload.onprogress = e => {
            const currentLoaded = e.loaded, currentTime = new Date().getTime();
            const difLoaded = currentLoaded - oldLoaded, difTime = (currentTime - oldTime) / 1000;

            let speed = difLoaded / difTime;
            let units = 'b/s';
            let leftTime = ((e.total - e.loaded) / speed).toFixed(0);
            if (speed > 1024) {
                speed /= 1024;
                units = 'kb/s';
            }
            if (speed > 1024) {
                speed /= 1024;
                units = 'M/s';
            }
            if (speed > 1024) {
                speed /= 1024;
                units = 'G/s';
            }

            const sec = leftTime % 60;
            const min = (leftTime - sec) / 60 % 60;
            const hr = ((leftTime - sec) / 60 - min) / 60;


            onProgress((e.loaded / e.total * 100).toFixed(0),
              `${speed.toFixed(2)}${units}`,
              `${hr > 0 ? (hr + "h ") : ""}${min > 0 ? (min + "min ") : ""}${sec + "s"}`);
        };
        xhr.onloadstart = () => {
            oldTime = new Date().getTime();
            oldLoaded = 0;
        };
        xhr.send(form);
    },
    uploadVideoConfig: (video_name, md5, net, table, scoreboard) => new Promise((resolve, reject) => {
        try {
            fetch(url.cvBackend(
              "/video_config",
            ), {
                method: 'POST',
                body: JSON.stringify({video_name, md5, net, table, scoreboard})
            }).then(res => res.json())
              .then(resolve);
        } catch (e) {
            reject(e);
        }
    }),
    segmentGameStructure: (video_name, md5) => new Promise((resolve, reject) => {
        try {
            fetch(url.cvBackend("/game_structure/segment"), {
                method: "POST",
                body: JSON.stringify({video_name, md5})
            }).then(res => res.json())
              .then(resolve);
        } catch (e) {
            reject(e);
        }
    }),
    getGameStructure: (video_name, md5) => new Promise((resolve, reject) => {
        try {
            fetch(url.cvBackend("/game_structure", {video_name, md5}))
              .then(res => res.json())
              .then(resolve);
        } catch (e) {
            reject(e);
        }
    }),
    calculateRallyInfo: (video_name, md5, score) => new Promise((resolve, reject) => {
        try {
            fetch(url.cvBackend("/rally_info/calculation"), {
                method: "POST",
                body: JSON.stringify({video_name, md5, score}),
            }).then(res => res.json())
              .then(resolve);
        } catch (e) {
            reject(e);
        }
    }),
    getRallyInfo: (video_name, md5, score) => new Promise((resolve, reject) => {
        try {
            fetch(url.cvBackend("/rally_info", {video_name, md5, score}))
              .then(res => res.json())
              .then(resolve);
        } catch (e) {
            reject(e);
        }
    }),
    getTaskProgress: taskId => new Promise((resolve, reject) => {
        try {
            fetch(url.cvBackend(`/${taskId}/progress`))
              .then(res => res.json())
              .then(resolve);
        } catch (e) {
            reject(e);
        }
    })
} : {
    checkVideoExistence: (video_name, md5) => {
        return new Promise(resolve => resolve({
            "status": "succeed",
            "video_existed": true,
            "video_segmented": true,
            "scoreboard": [0.22039473684210525, 0.8245614035087719, 0.28399122807017546, 0.9200779727095516],
            "table": [0.3991228070175439, 0.47173489278752434, 0.5778508771929824, 0.4678362573099415, 0.5888157894736842, 0.6900584795321637, 0.39364035087719296, 0.6920077972709552],
            "net": [0.3793859649122807, 0.5399610136452242, 0.6041666666666666, 0.543859649122807]
        }))
    },
    getGameStructure: (video_name, md5) => new Promise((resolve, reject) => resolve({
        "status": "succeed",
        "fps": 24.0,
        "game_structure": [
            {
                "frame": [7200, 7656],
                "score": "0_0_00_01",
                "stroke_detected": true,
                "stroke_num": 8
            }, {
                "frame": [7656, 8040],
                "score": "0_0_01_01",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [8040, 8376],
                "score": "0_0_02_01",
                "stroke_detected": true,
                "stroke_num": 8
            }, {
                "frame": [8376, 8808],
                "score": "0_0_03_01",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [8808, 9336],
                "score": "0_0_04_01",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [9336, 9768],
                "score": "0_0_04_02",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [9768, 10128],
                "score": "0_0_05_02",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [10128, 10560],
                "score": "0_0_06_02",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [10560, 11136],
                "score": "0_0_07_02",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [11136, 11448],
                "score": "0_0_07_03",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [11448, 11808],
                "score": "0_0_07_04",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [11808, 12456],
                "score": "0_0_07_05",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [12456, 13032],
                "score": "0_0_07_06",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [13032, 13512],
                "score": "0_0_08_06",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [13512, 13824],
                "score": "0_0_09_06",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [13824, 14208],
                "score": "0_0_10_06",
                "stroke_detected": true,
                "stroke_num": 8
            }, {
                "frame": [14208, 14592],
                "score": "0_0_10_07",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [14592, 14688],
                "score": "0_0_11_07",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [16896, 17256],
                "score": "1_0_00_01",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [17256, 17616],
                "score": "1_0_01_01",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [17880, 18048],
                "score": "1_0_02_01",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [18048, 18480],
                "score": "1_0_02_02",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [18648, 18768],
                "score": "1_0_03_02",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [18768, 19320],
                "score": "1_0_03_03",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [19320, 19800],
                "score": "1_0_04_03",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [19800, 20184],
                "score": "1_0_04_04",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [20184, 20664],
                "score": "1_0_04_05",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [20664, 21192],
                "score": "1_0_05_05",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [21192, 21720],
                "score": "1_0_05_06",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [21720, 22128],
                "score": "1_0_05_07",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [22128, 22536],
                "score": "1_0_06_07",
                "stroke_detected": true,
                "stroke_num": 0
            }, {
                "frame": [22536, 23088],
                "score": "1_0_07_07",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [23424, 23736],
                "score": "1_0_08_07",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [23736, 24288],
                "score": "1_0_08_08",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [24288, 24768],
                "score": "1_0_08_09",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [25176, 25920],
                "score": "1_0_09_09",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [25920, 26568],
                "score": "1_0_09_10",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [26568, 26640],
                "score": "1_0_09_11",
                "stroke_detected": true,
                "stroke_num": 0
            }, {
                "frame": [29040, 29376],
                "score": "1_1_01_00",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [29376, 29808],
                "score": "1_1_02_00",
                "stroke_detected": true,
                "stroke_num": 9
            }, {
                "frame": [29808, 30168],
                "score": "1_1_02_01",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [30168, 30672],
                "score": "1_1_02_02",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [30672, 31104],
                "score": "1_1_03_02",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [31296, 31584],
                "score": "1_1_04_02",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [31584, 32040],
                "score": "1_1_04_03",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [32040, 32496],
                "score": "1_1_04_04",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [32496, 32904],
                "score": "1_1_05_04",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [32904, 33384],
                "score": "1_1_05_05",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [33384, 33720],
                "score": "1_1_05_06",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [35808, 36288],
                "score": "1_1_05_07",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [36288, 37080],
                "score": "1_1_06_07",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [37080, 37512],
                "score": "1_1_07_07",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [37512, 37920],
                "score": "1_1_08_07",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [37920, 38664],
                "score": "1_1_08_08",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [38904, 39432],
                "score": "1_1_08_09",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [39432, 40008],
                "score": "1_1_08_10",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [40200, 40512],
                "score": "1_1_09_10",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [40512, 41256],
                "score": "1_1_10_10",
                "stroke_detected": true,
                "stroke_num": 0
            }, {
                "frame": [41256, 41760],
                "score": "1_1_11_10",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [41760, 42504],
                "score": "1_1_11_11",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [42504, 43104],
                "score": "1_1_11_12",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [43104, 43872],
                "score": "1_1_12_12",
                "stroke_detected": true,
                "stroke_num": 0
            }, {
                "frame": [43872, 44448],
                "score": "1_1_12_13",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [44448, 44496],
                "score": "1_1_12_14",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [46992, 47400],
                "score": "1_2_01_00",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [47400, 47760],
                "score": "1_2_02_00",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [47760, 48192],
                "score": "1_2_02_01",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [48192, 48792],
                "score": "1_2_02_02",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [48792, 49128],
                "score": "1_2_03_02",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [49128, 49632],
                "score": "1_2_03_03",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [49632, 50016],
                "score": "1_2_03_04",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [50016, 50784],
                "score": "1_2_03_05",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [50976, 51216],
                "score": "1_2_03_06",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [51216, 51792],
                "score": "1_2_04_06",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [51792, 52200],
                "score": "1_2_04_07",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [52200, 52680],
                "score": "1_2_04_08",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [52680, 53040],
                "score": "1_2_05_08",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [53208, 53448],
                "score": "1_2_05_09",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [53448, 53952],
                "score": "1_2_06_09",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [53952, 54288],
                "score": "1_2_06_10",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [54288, 54336],
                "score": "1_2_06_11",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [56640, 56952],
                "score": "1_3_00_01",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [57168, 57360],
                "score": "1_3_01_01",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [57600, 57768],
                "score": "1_3_02_01",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [57768, 58176],
                "score": "1_3_03_01",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [58176, 58680],
                "score": "1_3_04_01",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [58872, 59304],
                "score": "1_3_05_01",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [59304, 59616],
                "score": "1_3_05_02",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [59616, 59976],
                "score": "1_3_06_02",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [59976, 60432],
                "score": "1_3_06_03",
                "stroke_detected": true,
                "stroke_num": 0
            }, {
                "frame": [60432, 60816],
                "score": "1_3_07_03",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [60816, 61224],
                "score": "1_3_07_04",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [61224, 61608],
                "score": "1_3_08_04",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [61608, 62016],
                "score": "1_3_09_04",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [62016, 62400],
                "score": "1_3_10_04",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [62400, 62736],
                "score": "1_3_10_05",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [62736, 63384],
                "score": "1_3_10_06",
                "stroke_detected": true,
                "stroke_num": 5
            }, {
                "frame": [63384, 63432],
                "score": "1_3_11_06",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [65520, 66024],
                "score": "2_3_00_01",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [66192, 66456],
                "score": "2_3_00_02",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [66456, 66912],
                "score": "2_3_00_03",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [66912, 67392],
                "score": "2_3_01_03",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [67392, 67776],
                "score": "2_3_02_03",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [67776, 68352],
                "score": "2_3_02_04",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [68352, 68688],
                "score": "2_3_02_05",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [68688, 69120],
                "score": "2_3_03_05",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [69120, 69600],
                "score": "2_3_04_05",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [69816, 70176],
                "score": "2_3_05_05",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [72168, 72648],
                "score": "2_3_06_05",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [72648, 73104],
                "score": "2_3_06_06",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [73104, 73536],
                "score": "2_3_06_07",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [73536, 74040],
                "score": "2_3_07_07",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [74040, 74640],
                "score": "2_3_08_07",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [74640, 75240],
                "score": "2_3_08_08",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [75240, 75696],
                "score": "2_3_09_08",
                "stroke_detected": true,
                "stroke_num": 8
            }, {
                "frame": [75696, 76560],
                "score": "2_3_09_09",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [76560, 77184],
                "score": "2_3_10_09",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [77184, 77904],
                "score": "2_3_10_10",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [77904, 78528],
                "score": "2_3_11_10",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [78528, 78576],
                "score": "2_3_12_10",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [80904, 81360],
                "score": "3_3_00_01",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [81360, 81744],
                "score": "3_3_00_02",
                "stroke_detected": true,
                "stroke_num": 6
            }, {
                "frame": [81744, 82056],
                "score": "3_3_01_02",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [82056, 82512],
                "score": "3_3_02_02",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [82512, 83040],
                "score": "3_3_02_03",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [83424, 83856],
                "score": "3_3_03_03",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [83856, 84504],
                "score": "3_3_04_03",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [84504, 84984],
                "score": "3_3_04_04",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [84984, 85680],
                "score": "3_3_05_04",
                "stroke_detected": true,
                "stroke_num": 1
            }, {
                "frame": [85680, 86112],
                "score": "3_3_05_05",
                "stroke_detected": true,
                "stroke_num": 0
            }, {
                "frame": [86112, 86568],
                "score": "3_3_06_05",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [86568, 87072],
                "score": "3_3_07_05",
                "stroke_detected": true,
                "stroke_num": 4
            }, {
                "frame": [87384, 87672],
                "score": "3_3_08_05",
                "stroke_detected": true,
                "stroke_num": 7
            }, {
                "frame": [87672, 88008],
                "score": "3_3_09_05",
                "stroke_detected": true,
                "stroke_num": 3
            }, {
                "frame": [88008, 88009],
                "score": "3_3_10_05",
                "stroke_detected": true,
                "stroke_num": 2
            }, {
                "frame": [88009, 88010],
                "score": "3_3_11_05",
                "stroke_detected": true,
                "stroke_num": 2
            }]
    })),
    getTaskProgress: taskId => new Promise((resolve, reject) => resolve({
        status: 'succeed',
        progress: 100,
    })),
};

export default api;
