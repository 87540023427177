import React, {useEffect, useRef} from 'react';
import {Button, makeStyles} from "@material-ui/core";
import store from "../../../Data/Store";
import CircularProgressStatus from "../Components/CircularProgressStatus";
import useStateTransfer from "../Utils/useStateTransfer";
import api from "../../../Data/API";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
    },
    progressContainer: {
        width: '100%',
        flex: '0 0 100%',
        height: '70%',
    },
    actions: {
        width: '100%',
        height: 30,
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'center',
    }
}));

const Status = Object.freeze({
    uploadMP4: store.languageSwitch("uploadMP4"),
    uploadAnnotation: store.languageSwitch("uploadAnnotation"),
    segmentGame: store.languageSwitch("segmentGame"),
    getSegmentResult: store.languageSwitch("getSegmentResult"),
    calPat: store.languageSwitch("calPat"),
    finish: store.languageSwitch("processFinish"),
    error: store.languageSwitch("processError"),
});

const getTaskProgress = (task_id, onProgress, onFinish, onError) => {
    const interval = setInterval(() => {
        api.getTaskProgress(task_id)
          .then(res => {
              const {status, progress} = res;

              if (status === 'failed') {
                  onError(`Fail to get information about task ${task_id}`);
                  clearInterval(interval);
              } else if (progress !== 1) onProgress && onProgress((progress * 100).toFixed(0));
              else {
                  onFinish && onFinish();
                  clearInterval(interval);
              }
          })
    }, 1000);
}

const uploadMP4 = (config, onProgress, onFinish, onError) => {
    const {canSkipUploadVideo} = config;
    if (canSkipUploadVideo) {
        onFinish();
        return;
    }

    const {videoFile, videoName, md5} = config;
    api.uploadVideo(
      videoFile,
      videoName,
      md5,
      onProgress,
      onFinish,
      onError,
    )
}

const uploadAnnotation = (config, onFinish, onError) => {
    const {canSkipUploadAnnotation} = config;
    if (canSkipUploadAnnotation) {
        onFinish();
        return;
    }

    const {videoName, md5, net, table, scoreboard} = config;
    api.uploadVideoConfig(
      videoName,
      md5,
      net,
      table,
      scoreboard,
    ).then(onFinish)
      .catch(onError);
}

const segmentGame = (config, onProgress, onFinish, onError) => {
    const {canSkipSegment} = config;
    if (canSkipSegment) {
        onFinish();
        return;
    }

    const {videoName, md5} = config;
    api.segmentGameStructure(videoName, md5)
      .then(res => {
          const {task_id} = res;

          getTaskProgress(
            task_id,
            onProgress,
            onFinish,
            onError,
          )
      })
      .catch(onError);
}

const getSegmentResult = (config, onFinish, onError) => {
    const {videoName, md5} = config;
    api.getGameStructure(videoName, md5)
      .then(res => {
          if (res.status === 'failed') onError("Fail to get segment data");
          else onFinish({fps: res.fps, segmentData: res['game_structure']});
      })
      .catch(onError)
}

const calPat = (config, onProgress, onFinish, onError) => {
    const {segmentData, videoName, md5} = config;
    const segProgress = 100 / (segmentData.length + 1);
    const calPat = i => {
        if (i >= segmentData.length) {
            onFinish();
            return;
        }
        const next = () => {
            onProgress((i + 1) * segProgress);
            calPat(i + 1);
        }

        const segment = segmentData[i];
        if (segment['stroke_detected']) {
            next();
            return;
        }

        const segInfo = [videoName, md5, segment['score']];

        api.calculateRallyInfo(...segInfo)
          .then(res => {
              if (res.status === "failed") {
                  onError("Fail to calculate rally info.");
                  return;
              }

              const {task_id} = res;

              getTaskProgress(
                task_id,
                progress => onProgress(i * segProgress + progress / 100 * segProgress),
                () => {
                    api.getRallyInfo(...segInfo)
                      .then(res => {
                          if (res.status === "failed") {
                              onError("Fail to calculate rally info.");
                              return;
                          }

                          segment['stroke_num'] = res['stroke_num'];
                          next();
                      })
                },
                onError
              )
          })
          .catch(onError)
    }
    calPat(0);
}

function BackendProcessor({
                              activate,
                              videoFile,
                              videoName,
                              md5,
                              canSkipUploadVideo,
                              scoreboard,
                              table,
                              net,
                              canSkipUploadAnnotation,
                              onProcessEnd,
                              canSkipSegment,
                              onPre,
                              onNext,
                              onStartBackendProcess,
                              onEndBackendProcess,
                          }) {
    const classes = useStyles();
    const data = useRef({
        fps: 24,
        segmentData: [],
    });

    const transferMatrix = {
        "": {
            onLoad: ({onFinish}) => onFinish(),
            nxtState: Status.uploadMP4,
        },
        [Status.uploadMP4]: {
            onLoad: ({onProgress, onFinish, onError}) => {
                onStartBackendProcess()
                uploadMP4(
                  {videoFile, videoName, md5, canSkipUploadVideo},
                  onProgress,
                  (...props) => {
                      onFinish(...props);
                      onEndBackendProcess();
                  },
                  onError
                )
            },
            nxtState: Status.uploadAnnotation,
        },
        [Status.uploadAnnotation]: {
            onLoad: ({onFinish, onError}) => {
                onStartBackendProcess()
                uploadAnnotation(
                  {scoreboard, table, net, canSkipUploadAnnotation, videoName, md5},
                  (...props) => {
                      onFinish(...props);
                      onEndBackendProcess();
                  },
                  onError,
                )
            },
            nxtState: Status.segmentGame,
        },
        [Status.segmentGame]: {
            onLoad: ({onProgress, onFinish, onError}) => {
                onStartBackendProcess()
                segmentGame(
                  {videoName, md5, canSkipSegment},
                  onProgress,
                  (...props) => {
                      onFinish(...props);
                      onEndBackendProcess();
                  },
                  onError
                )
            },
            nxtState: Status.getSegmentResult,
        },
        [Status.getSegmentResult]: {
            onLoad: ({onFinish, onError}) => {
                onStartBackendProcess()
                getSegmentResult(
                  {videoName, md5},
                  res => {
                      data.current = res;
                      onFinish();
                      onEndBackendProcess();
                  },
                  onError
                )
            },
            // nxtState: Status.calPat,
            nxtState: Status.finish,
        },
        [Status.calPat]: {
            onLoad: ({onProgress, onFinish, onError}) => {
                onStartBackendProcess()
                calPat(
                  {segmentData: data.current.segmentData, videoName, md5},
                  onProgress,
                  (...props) => {
                      onFinish(...props);
                      onEndBackendProcess();
                  },
                  onError
                )
            },
            nxtState: Status.finish,
        },
        [Status.finish]: {
            onLoad: ({onProgress}) => {
                onProgress(100);
                onProcessEnd && onProcessEnd(data.current);
            },
        },
        [Status.error]: {
            onLoad: ({onProgress}) => onProgress(0),
        },
    }
    const {state, progress, handleRetry} = useStateTransfer(
      transferMatrix,
      activate,
      {errState: Status.error},
    );
    useEffect(handleRetry, [md5, scoreboard, table, net]);

    return <div className={classes.root}>
        <div className={classes.content}>
            <div className={classes.progressContainer}>
                <CircularProgressStatus error={state === Status.error}
                                        statusText={state}
                                        progress={progress}/>
            </div>
        </div>
        <div className={classes.actions}>
            <Button size={"small"}
                    color={'primary'}
                    onClick={onPre}>{store.languageSwitch("preStep")}</Button>
            {state === Status.error ?
              <Button size={"small"}
                      color={'secondary'} variant={"contained"}
                      onClick={handleRetry}>{store.languageSwitch("retry")}</Button> :
              <Button size={"small"}
                      color={"primary"} variant={"contained"}
                      disabled={state !== Status.finish}
                      onClick={onNext}>{store.languageSwitch("nxtStep")}</Button>
            }
        </div>
    </div>
}

export default BackendProcessor;
