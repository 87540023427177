import React, {Component, createElement} from 'react';
import {Dialog, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import store from '../../Data/Store';
import ReactMarkdown from "react-markdown";

const styles = theme => ({
    content: {
        width: '100%'
    },
    title: {
        color: theme.palette.primary.main,
    },
});

class VersionLog extends Component {
    render() {
        const {classes, theme, ...others} = this.props;

        return <Dialog maxWidth={'md'} fullWidth {...others}>
            <DialogTitle>{store.languageSwitch('Updates')}</DialogTitle>
            <DialogContent>
                <ReactMarkdown source={store.getData('Updates')}
                               className={classes.content}
                               renderers={{
                                   heading: props => createElement(
                                     `h${props.level}`,
                                     {className: classes.title},
                                     props.children
                                   )
                               }}/>
            </DialogContent>
        </Dialog>;
    }
}

export default withStyles(styles, {withTheme: true})(VersionLog);

// edited by WuJiang5521 on 2019/11/6
