import React, {Component} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress, TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import {PlayArrow, Delete, FormatListNumbered} from "@material-ui/icons";
import store from '../../../Data/Store';
import VideoRecord from "./VideoRecord";

const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        margin: theme.spacing(1),
        flex: '0 0 auto',
        height: 40,
        lineHeight: '40px',
        display: 'flex',
    },
    title: {
        fontSize: '1.3rem',
        lineHeight: '40px',
    },
    content: {
        margin: theme.spacing(1),
        marginRight: 0,
        overflowX: 'hidden',
        overflowY: 'scroll',
        flex: '1 1 0',
    },
    action: {
        margin: theme.spacing(1),
        flex: '0 0 auto',
        height: 40,
        lineHeight: '40px',
        display: 'flex',
    },
    videoListPreview: {
        width: 40,
        minWidth: 0,
        flex: `0 0 40px`,
        borderRadius: 0,
    },
    videoListResort: {
        width: 40,
        minWidth: 0,
        flex: `0 0 40px`,
        borderRadius: 0,
    },
    videoListExport: {
        flex: 1,
        minWidth: 0,
        marginRight: theme.spacing(1),
        boxShadow: 'none',
    },
    videoListClear: {
        width: 40,
        minWidth: 0,
        flex: `0 0 40px`,
        borderRadius: 0,
        marginRight: 1,
    },
    buttonRoot: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
});

class VideoList extends Component {
    state = {
        openProgress: false,
        progressStatus: null,
        progressInfo: null,
        name: store.data.record.MatchNo,
        buffer_time: 2,
        exportConfirmOpen: false,
    };

    handleOpenProgress = () => {
        this.setState({
            openProgress: true,
        });
    };

    handleCloseProgress = () => {
        this.setState({
            openProgress: false,
        });
    };

    componentDidMount() {
        store.register({'videoList': this});
    }

    componentWillUnmount() {
        store.unregister({'videoList': this});
    }

    handleExport = () => {
        this.handleOpenProgress();
        store.handleChange('ExportVideos')({
            name: this.state.name,
            buffer_time: this.state.buffer_time,
            callback: res => {
                this.setState({
                    progressStatus: res.status,
                    progressInfo: res.info,
                });
            }
        })
    };

    closeExport = () => this.setState({exportConfirmOpen: false});
    openExport = () => this.setState({exportConfirmOpen: true});

    render() {
        const {classes} = this.props;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography color={"primary"} className={classes.title}>{store.languageSwitch('videoList')}</Typography>
                <div style={{flex: 1}}/>
                {store.languageSwitch('videoCount')(store.videoList.length)}
            </div>
            <div className={classes.content}>
                {store.videoList.map((redirect, rId) => <VideoRecord redirect={redirect} rId={rId} key={rId}/>)}
            </div>
            <div className={classes.action}>
                <Button className={classes.videoListExport}
                        disabled={store.videoList.length === 0}
                        variant={"contained"} color={"primary"}
                        onClick={this.openExport}>
                    {store.languageSwitch('exportVideo')}
                </Button>
                <Button className={classes.videoListResort}
                        disabled={store.videoList.length === 0}
                        onClick={store.handleChange('ResortExportVideos')}>
                    <FormatListNumbered/>
                </Button>
                <Button className={classes.videoListPreview}
                        disabled={store.videoList.length === 0}
                        onClick={store.handleChange('PlayAllExportVideos')}>
                    <PlayArrow/>
                </Button>
                <Button color={"secondary"} className={classes.videoListClear}
                        disabled={store.videoList.length === 0}
                        onClick={store.handleChange('ClearExportVideos')}>
                    <Delete/>
                </Button>
            </div>
            <Dialog open={this.state.exportConfirmOpen}>
                <DialogTitle>导出参数设置</DialogTitle>
                <DialogContent>
                    <TextField fullWidth label="比赛名称" value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
                    <TextField fullWidth label="缓冲时间" value={this.state.buffer_time.toString()} onChange={e => this.setState({buffer_time: parseFloat(e.target.value)})} />
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={() => {
                        this.handleExport();
                        this.closeExport();
                    }}>确认导出</Button>
                    <Button onClick={this.closeExport}>取消</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.openProgress}
                    fullWidth maxWidth={'md'}>
                <DialogTitle>
                    {this.state.progressStatus === 'failed' && '导出失败'}
                    {this.state.progressStatus === 'succeed' && '导出成功'}
                    {this.state.progressStatus === 'processing' && '正在处理'}
                    {this.state.progressStatus === null && '导出进度'}
                </DialogTitle>
                <DialogContent>
                    {
                        ['failed', 'succeed'].includes(this.state.progressStatus) &&
                        <DialogContentText>{this.state.progressInfo}</DialogContentText>
                    }
                    {
                        this.state.progressStatus === 'processing' &&
                        <DialogContentText>{this.state.progressInfo.toFixed(2)}%</DialogContentText>
                    }
                    {
                        this.state.progressStatus === 'processing' &&
                        <LinearProgress color="secondary" variant="buffer"
                                        value={this.state.progressInfo}
                                        valueBuffer={this.state.progressInfo}/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.progressStatus === 'processing'}
                            onClick={this.handleCloseProgress}>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

export default withStyles(styles)(VideoList);

// edited by WuJiang5521 on 2019/9/3
