import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import store from '../../../Data/Store';
import Button from '@material-ui/core/Button';
import {fade} from "@material-ui/core/styles/colorManipulator";

const style = store.pageStyle;
const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTech: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
  techClass: {
    overflow: 'hidden',
    flex: 1,
    height: '100%',
    textAlign: 'center',
  },
  title: {
    width: `calc(100% - ${theme.spacing(1)}px)`,
    height: '36px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0.5),
    textAlign: 'center',
    lineHeight: '36px',
  },
  othersTech: {
    height: `44px`,
    display: 'flex',
  },
  button: {
    width: `calc(100% - ${theme.spacing(1)}px)`,
    flex: 1,
    maxHeight: '36px',
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  chosenPossible: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  chosenImpossible: {
    color: style.disabled,
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${style.disabled}`,
    '&:hover': {
      color: style.disabled,
      backgroundColor: theme.palette.primary.contrastText,
      border: `1px solid ${style.disabled}`,
    },
    cursor: 'not-allowed',
  },
  unchosenPossible: {
    color: theme.palette.primary.dark,
    backgroundColor: fade(theme.palette.primary.light, 0.25),
    border: `1px solid ${fade(theme.palette.primary.light, 0.25)}`,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    }
  },
  unchosenImpossible: {
    color: style.disabled,
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${style.disabled}`,
    '&:hover': {
      color: style.disabled,
      backgroundColor: theme.palette.primary.contrastText,
      border: `1px solid ${style.disabled}`,
    },
    cursor: 'not-allowed',
  },
});

class StrikeTech extends Component {
  state = {
    needRefresh: false,
  };

  UNSAFE_componentWillMount() {
    store.register({strikeTech: this});
  }

  componentWillUnmount() {
    store.unregister({strikeTech: this});
  }

  render() {
    const {classes} = this.props;
    const impossible = store.getData("ImpossibleStrikeTech");
    const cur = store.getData('CurrentStrikeTech');

    return <div className={classes.root}>
      <div className={classes.mainTech}>
        <div className={classes.techClass}>
          <div className={classes.title}>{store.languageSwitch('Serve')}</div>
          {
            ["Pendulum", "Reverse", "Tomahawk"].map(((value, i) => {
              const status = `${cur === value ? 'chosen' : 'unchosen'}${impossible.includes(value) ? 'Impossible' : 'Possible'}`;
              return <Button
                className={clsx(classes.button, classes[status])}
                key={i} onClick={() => {
                if (!impossible.includes(value)) store.handleChange("StrikeTech")(value);
              }
              }>{store.languageSwitch(value)}
              </Button>;
            }))
          }
        </div>
        <div className={classes.techClass}>
          <div className={classes.title}>{store.languageSwitch('Offensive')}</div>
          {
            ["Topspin", "Attack", "Smash", "Flick", "Twist"].map(((value, i) => {
              const status = `${cur === value ? 'chosen' : 'unchosen'}${impossible.includes(value) ? 'Impossible' : 'Possible'}`;
              return <Button
                className={clsx(classes.button, classes[status])}
                fullWidth key={i} onClick={() => {
                if (!impossible.includes(value)) store.handleChange("StrikeTech")(value);
              }
              }>{store.languageSwitch(value)}
              </Button>
            }))
          }
        </div>
        <div className={classes.techClass}>
          <div className={classes.title}>{store.languageSwitch('Controlled')}</div>
          {
            ["Push", "Short", "Slide"].map(((value, i) => {
              const status = `${cur === value ? 'chosen' : 'unchosen'}${impossible.includes(value) ? 'Impossible' : 'Possible'}`;
              return <Button
                className={clsx(classes.button, classes[status])}
                fullWidth key={i} onClick={() => {
                if (!impossible.includes(value)) store.handleChange("StrikeTech")(value);
              }
              }>{store.languageSwitch(value)}
              </Button>
            }))
          }
        </div>
        <div className={classes.techClass}>
          <div className={classes.title}>{store.languageSwitch('Defensive')}</div>
          {
            ["Block", "Lob", "Chopping"].map(((value, i) => {
              const status = `${cur === value ? 'chosen' : 'unchosen'}${impossible.includes(value) ? 'Impossible' : 'Possible'}`;
              return <Button
                className={clsx(classes.button, classes[status])}
                fullWidth key={i} onClick={() => {
                if (!impossible.includes(value)) store.handleChange("StrikeTech")(value);
              }
              }>{store.languageSwitch(value)}
              </Button>
            }))
          }
        </div>
      </div>
      <div className={classes.othersTech}>
        {
          ["PimpleTech", "Others"].map(((value, i) => {
            const status = `${cur === value ? 'chosen' : 'unchosen'}${impossible.includes(value) ? 'Impossible' : 'Possible'}`;
            return <Button
              className={clsx(classes.button, classes[status])}
              fullWidth key={i} onClick={() => {
              if (!impossible.includes(value)) store.handleChange("StrikeTech")(value);
            }
            }>{store.languageSwitch(value)}
            </Button>
          }))
        }
      </div>
    </div>;
  }
}

export default withStyles(styles)(StrikeTech);
