import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import VideoAnnotator from "../Components/Annotators/VideoAnnotator";
import store from "../../../Data/Store";
import NetDrawboard from "../Components/Annotators/NetDrawboard";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        width: '100%',
        height: 30,
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'center',
    }
}));

function NetAnnotator({
                          net,
                          onSetNet,
                          onPre,
                          onNext,
                      }) {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.content}>
            <VideoAnnotator src={store.getData('Video')}>
                {videoSize => <NetDrawboard size={videoSize}
                                            net={net}
                                            onSetNet={onSetNet}/>}
            </VideoAnnotator>
        </div>
        <div className={classes.actions}>
            <Button size={"small"}
                    color={"primary"}
                    onClick={onPre}>{store.languageSwitch("preStep")}</Button>
            <Button size={"small"}
                    color={"primary"}
                    variant={"contained"}
                    disabled={net === null}
                    onClick={onNext}>{store.languageSwitch("nxtStep")}</Button>
        </div>
    </div>
}

export default NetAnnotator;
