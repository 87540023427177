const rules = {
  BallPosition: [
    {
      match: {
        cur: {
          Index: [0],
        },
      },
      possible: ['S1', 'S2'],
      default: 'S1',
    }, // 发球落点必须是S1, S2，默认S1
    {
      match: {
        cur: {
          PlayerNum: [2],
          Index: [0],
        },
      },
      possible: ['S2']
    }, // 双打发球必须在正手区
    {
      match: {
        cur: {
          PlayerNum: [2],
          Index: [1],
        }
      },
      impossible: ['BS', 'BH', 'BL']
    }, // 双打接发球落点不可能是反手区
    {
      match: {
        cur: {
          notIndex: [0],
        },
      },
      impossible: ['S1', 'S2'],
    }, // 非发球落点不能是S1, S2
    {
      match: {
        pre: {
          BallPosition: ['BH', 'MH', 'FH'],
          notStrikeTech: ['Short', 'Lob', 'Chopping', 'PimpleTech', 'Others'],
        },
      },
      impossible: ['BS', 'MS', 'FS'],
    }, // 当一拍技术不是摆短、放高球、削球、颗粒胶技术、其他技术时，若接的落点是半长球，那么打出去的落点不能是短球
    {
      match: {
        pre: {
          BallPosition: ['BL', 'ML', 'FL'],
          notStrikeTech: ['Short', 'Lob', 'Chopping', 'PimpleTech', 'Others'],
        },
      },
      impossible: ['BS', 'MS', 'FS', 'BH', 'MH', 'FH'],
    }, // 当一拍技术不是摆短、放高球、削球、颗粒胶技术、其他技术时，若接的落点是长球，那么打出去的落点不能是短球、半长球
    {
      match: {
        pre: {
          StrikeTech: ['Topspin', 'Attack', 'Smash', 'Flick', 'Twist', 'Block'],
        }
      },
      impossible: ['BS', 'MS', 'FS', 'BH', 'MH', 'FH'],
    }, // 使用护圈、快攻、扣杀、挑打、拧、挡球打出去的落点，不能是短球、半长球
    {
      match: {
        pre: {
          StrikeTech: ['Short'],
        },
      },
      impossible: ['BL', 'ML', 'FL'],
    }, // 使用摆短打出去的落点，不能是长球
    {
      match: {
        pre: {
          StrikeTech: ['Push', 'Slide'],
        },
      },
      impossible: ['BS', 'MS', 'FS'],
    }, // 使用搓球、撇打出去的落点，不能是短球
  ],
  StrikePosition: [
    {
      match: {
        cur: {
          Index: [0],
        },
      },
      possible: ['S1', 'S2'],
      default: 'S1',
    }, // 发球身位必须是S1, S2，默认S1
    {
      match: {
        cur: {
          PlayerNum: [2],
          Index: [0],
        },
      },
      possible: ['S2']
    }, // 双打发球必须在正手区
    {
      match: {
        cur: {
          PlayerNum: [2],
          Index: [1],
        }
      },
      impossible: ['B', 'P']
    }, // 双打接发球落点不可能是反手区
    {
      match: {
        cur: {
          notIndex: [0],
        },
      },
      impossible: ['S1', 'S2'],
    }, // 非发球身位不能是S1, S2
    {
      match: {
        cur: {
          BallPosition: ['FS', 'FH', 'FL'],
        },
      },
      impossible: ['P', 'B'],
    }, // 接落点在正手区的球时，身位不能是侧身、反手
    {
      match: {
        cur: {
          BallPosition: ['BS', 'BH', 'BL'],
        },
      },
      impossible: ['F', 'T'],
    }, // 接落点在反手区的球时，身位不能是正手、反侧身
    {
      match: {
        cur: {
          BallPosition: ['FL', 'FH', 'FS'],
        },
      },
      default: 'F',
    }, // 接正手球时，身位默认正手
    {
      match: {
        cur: {
          BallPosition: ['BL', 'BH', 'BS'],
        },
      },
      default: 'B',
    }, // 接反手球时，身位默认反手
  ],
  StrikeTech: [
    {
      match: {
        cur: {
          Index: [0]
        },
      },
      possible: ['Pendulum', 'Reverse', 'Tomahawk'],
    }, // 发球时，只能使用发球技术
    {
      match: {
        cur: {
          notIndex: [0]
        },
      },
      impossible: ['Pendulum', 'Reverse', 'Tomahawk'],
    }, // 非发球时，不能使用发球技术
    {
      match: {
        cur: {
          BallPosition: ["BS", "MS", "FS"],
        }
      },
      impossible: ['Topspin', 'Attack', 'Smash', 'Chopping', 'Block'],
    }, // 接短球，能用挑打、拧、搓球、摆短、撇、放高球、颗粒胶技术、其他技术，不能用弧圈、快攻、扣杀、挡球、削
    {
      match: {
        cur: {
          BallPosition: ["BH", "MH", "FH"],
        }
      },
      impossible: ['Block'],
    }, // 接半长球，能用弧圈、快攻、扣杀、挑打、拧、搓球、摆短、撇、放高球、削、颗粒胶技术、其他技术，不能用挡球
    {
      match: {
        cur: {
          BallPosition: ["BL", "ML", "FL"],
        }
      },
      impossible: ['Flick', 'Twist', 'Short'],
    }, // 接长球，能用弧圈、快攻、扣杀、削、搓球、撇、挡球、放高球、颗粒胶技术、其他技术，不能用挑打、拧、摆短
    {
      match: {
        pre: {
          StrikeTech: ['Push', 'Short', 'Slide', 'Chopping']
        }
      },
      impossible: ['Block', 'Lob'],
    }, // 不能用挡球、放高球接搓球、摆短、撇、削球
    {
      match: {
        pre: {
          StrikeTech: ['Topspin', 'Attack', 'Smash', 'Flick', 'Twist']
        }
      },
      impossible: ['Push', 'Short', 'Slide'],
    }, // 不能用搓球、摆短、撇接弧圈、快攻、扣杀、挑打、拧
    {
      match: {
        pre: {
          StrikeTech: ['Block']
        }
      },
      impossible: ['Push', 'Short', 'Slide', 'Block', 'Lob'],
    }, // 不能用搓球、摆短、撇、挡球、放高球接挡球
    {
      match: {
        pre: {
          StrikeTech: ['Lob']
        }
      },
      impossible: ['Push', 'Short', 'Slide', 'Block', 'Lob', 'Chopping'],
    }, // 不能用搓球、摆短、撇、挡球、放高球、削球接放高球
    {
      match: {
        cur: {
          FaceType: [1],
        },
      },
      impossible: ['PimpleTech']
    }, // 反胶不能用颗粒胶技术
    {
      match: {
        pre: {
          StrikeTech: ['Topspin', 'Attack', 'Chopping'],
        },
        cur: {
          notIndex: [0, 1, 2, 3],
          FaceType: [0, 2]
        }
      },
      default: 'Attack'
    }, // 四拍后，接弧圈、快攻或削球，正胶、生胶的默认技术是快攻
    {
      match: {
        pre: {
          StrikeTech: ['Topspin', 'Attack', 'Chopping'],
        },
        cur: {
          notIndex: [0, 1, 2, 3],
          FaceType: [1]
        }
      },
      default: 'Topspin'
    }, // 四拍后，接弧圈、快攻或削球，反胶的默认技术是弧圈
    {
      match: {
        pre: {
          StrikeTech: ['Topspin', 'Attack', 'Chopping'],
        },
        cur: {
          notIndex: [0, 1, 2, 3],
          FaceType: [3]
        }
      },
      default: 'Chopping'
    } // 四拍后，接弧圈、快攻或削球，长胶的默认技术是削球
  ],
  GameAction: [
    {
      match: {
        cur: {
          Index: [0]
        },
      },
      possible: ['Serve']
    }, // 发球势态是发球
    {
      match: {
        cur: {
          Index: [1]
        },
      },
      possible: ['Receive']
    }, // 接发球势态是接发球
    {
      match: {
        cur: {
          notIndex: [0, 1]
        },
      },
      possible: ['Stalemate', 'Offensive', 'Defensive', 'Controlled']
    }, // 除了发球、接发球，势态只能是相持、进攻、防守、控制之一(之后没提到的都需要在四种中手选)
    {
      match: {
        pre: {
          TechType: ['Attack'],
        },
        cur: {
          TechType: ['Attack'],
        }
      },
      possible: ['Stalemate'],
    }, // A进攻，B进攻，B的势态是相持
    {
      match: {
        pre: {
          TechType: ['Attack'],
        },
        cur: {
          TechType: ['Defend', 'Chopping'],
        }
      },
      possible: ['Defensive'],
    }, // A进攻，B防守或削球，B的势态是防守
    {
      match: {
        pre: {
          TechType: ['Attack'],
        },
        cur: {
          TechType: ['Others'],
        }
      },
      possible: ['Defensive', 'Stalemate'],
    }, // A进攻，B其他，B的势态是相持或防守，不能是进攻或控制
    {
      match: {
        pre: {
          TechType: ['Defend'],
        },
        cur: {
          TechType: ['Attack'],
        }
      },
      possible: ['Offensive'],
    }, // A防守，B进攻，B的势态是进攻
    {
      match: {
        pre: {
          TechType: ['Defend'],
        },
        cur: {
          TechType: ['Chopping'],
        }
      },
      possible: ['Controlled'],
    }, // A防守，B削球，B的势态是控制
    {
      match: {
        pre: {
          TechType: ['Defend'],
        },
        cur: {
          TechType: ['Others'],
        }
      },
      possible: ['Offensive', 'Controlled'],
    }, // A防守，B其他，B的势态是进攻或控制，不能是相持或防守
    {
      match: {
        pre: {
          TechType: ['Control'],
        },
        cur: {
          TechType: ['Attack'],
        }
      },
      possible: ['Offensive'],
    }, // A控制，B进攻，B的势态是进攻
    {
      match: {
        pre: {
          TechType: ['Control'],
        },
        cur: {
          TechType: ['Control', 'Chopping'],
        }
      },
      possible: ['Controlled'],
    }, // A控制，B控制或削球，B的势态是控制
    {
      match: {
        pre: {
          TechType: ['Control'],
        },
        cur: {
          TechType: ['Others'],
        }
      },
      possible: ['Offensive', 'Controlled'],
    }, // A控制，B其他，B的势态是进攻或控制，不能是相持或防守
    {
      match: {
        pre: {
          TechType: ['Chopping'],
        },
        cur: {
          TechType: ['Attack'],
        }
      },
      possible: ['Offensive'],
    }, // A削球，B进攻，B的势态是进攻
    {
      match: {
        pre: {
          TechType: ['Chopping'],
        },
        cur: {
          TechType: ['Control', 'Chopping'],
        }
      },
      possible: ['Controlled'],
    }, // A削球，B控制或削球，B的势态是控制
    {
      match: {
        pre: {
          TechType: ['Chopping'],
        },
        cur: {
          TechType: ['Others'],
        }
      },
      possible: ['Offensive', 'Controlled'],
    }, // A削球，B其他，B的势态是进攻或控制，不能是相持或防守
    {
      match: {
        pre: {
          TechType: ['Others'],
        },
        cur: {
          TechType: ['Attack'],
        }
      },
      possible: ['Offensive', 'Stalemate'],
    }, // A其他，B进攻，B的势态是进攻或相持
    {
      match: {
        pre: {
          TechType: ['Others'],
        },
        cur: {
          TechType: ['Defend'],
        }
      },
      possible: ['Defensive'],
    }, // A其他，B防守，B的势态是防守
    {
      match: {
        pre: {
          TechType: ['Others'],
        },
        cur: {
          TechType: ['Control'],
        }
      },
      possible: ['Controlled'],
    }, // A其他，B控制，B的势态是控制
    {
      match: {
        pre: {
          TechType: ['Others'],
        },
        cur: {
          TechType: ['Chopping'],
        }
      },
      possible: ['Defensive', 'Controlled'],
    }, // A其他，B削球，B的势态是防守或控制
  ],
  StrikeEffect: [
    {
      match: {
        cur: {
          Index: [-1]
        },
      },
      possible: [5],
    }, // 失分拍，效果为5
    {
      match: {
        cur: {
          Index: [-2]
        }
      },
      possible: [1],
    }, // 得分拍，效果为1
    {
      match: {
        cur: {
          notIndex: [-1, -2],
        }
      },
      possible: [2, 3, 4],
    }, // 其他拍效果只可能为2，3，4，若无特殊说明，需要用户从其中选择一项
    {
      match: {
        cur: {
          TechType: ['Serve', 'Attack']
        },
        nxt: {
          TechType: ['Attack']
        },
        nnt: {
          TechType: ['Attack']
        }
      },
      possible: [3]
    }, // A发球或进攻，B进攻，A进攻，A的效果是3
    {
      match: {
        cur: {
          TechType: ['Serve', 'Attack']
        },
        nxt: {
          TechType: ['Attack']
        },
        nnt: {
          TechType: ['Defend', 'Chopping']
        }
      },
      possible: [4]
    }, // A发球或进攻，B进攻，A防守或削球，A的效果是4
    {
      match: {
        cur: {
          TechType: ['Serve', 'Attack']
        },
        nxt: {
          TechType: ['Attack']
        },
        nnt: {
          TechType: ['PimpleTech', 'Others']
        }
      },
      possible: [3, 4]
    }, // A发球或进攻，B进攻，A颗粒胶技术或其他技术，A的效果是3或4
    {
      match: {
        cur: {
          TechType: ['Serve', 'Attack']
        },
        nxt: {
          TechType: ['Defend', 'Control', 'Chopping']
        },
        nnt: {
          TechType: ['Attack']
        }
      },
      possible: [2]
    }, // A发球或进攻，B防守、控制或削球，A进攻，A的效果是2
    {
      match: {
        cur: {
          TechType: ['Serve', 'Attack']
        },
        nxt: {
          TechType: ['Defend', 'Control', 'Chopping']
        },
        nnt: {
          TechType: ['Control', 'Chopping']
        }
      },
      possible: [2]
    }, // A发球或进攻，B防守、控制或削球，A控制或削球，A的效果是3
    {
      match: {
        cur: {
          TechType: ['Serve', 'Attack']
        },
        nxt: {
          TechType: ['Defend', 'Control', 'Chopping']
        },
        nnt: {
          TechType: ['PimpleTech', 'Others']
        }
      },
      possible: [2, 3]
    }, // A发球或进攻，B防守、控制或削球，A颗粒胶技术或其他技术，A的效果是2或3
    {
      match: {
        cur: {
          notIndex: [-2],
          TechType: ['Control', 'Defend', 'Chopping']
        },
        nxt: {
          TechType: ['Attack']
        }
      },
      possible: [4]
    }, // A控制、防守或削球，B进攻，A的效果是4
    {
      match: {
        cur: {
          TechType: ['Control', 'Defend', 'Chopping']
        },
        nxt: {
          TechType: ['Control', 'Chopping']
        },
        nnt: {
          TechType: ['Attack']
        }
      },
      possible: [2]
    }, // A控制、防守或削球，B控制或削球，A进攻，A的效果是2
    {
      match: {
        cur: {
          TechType: ['Control', 'Defend', 'Chopping']
        },
        nxt: {
          TechType: ['Control', 'Chopping']
        },
        nnt: {
          TechType: ['Control', 'Chopping']
        }
      },
      possible: [3]
    }, // A控制、防守或削球，B控制或削球，A控制或削球，A的效果是3
    {
      match: {
        cur: {
          TechType: ['Control', 'Defend', 'Chopping']
        },
        nxt: {
          TechType: ['Control', 'Chopping']
        },
        nnt: {
          TechType: ['PimpleTech', 'Others']
        }
      },
      possible: [2, 3]
    }, // A控制、防守或削球，B控制或削球，A颗粒胶技术或其他技术，A的效果是2或3
  ],
  SpinKind: [
    {
      match: {
        cur: {
          TechType: ['Serve'],
        },
      },
      possible: ['NT', 'ND'],
    }, // 发球旋转是中上旋或中下旋，无默认项
    {
      match: {
        cur: {
          StrikeTech: ['PimpleTech'],
        },
      },
      possible: ['NS', 'SD', 'ND', 'SK'],
    }, // 颗粒胶技术旋转是不转、强下旋、中下旋或下沉，无默认项
    {
      match: {
        cur: {
          FaceType: [0, 2, 3],
          StrikeTech: ['Chopping'],
        },
      },
      possible: ['NS', 'SD', 'ND', 'SK'],
    }, // 正、生、长胶削球的旋转是不转、强下旋、中下旋或下沉，无默认项
    {
      match: {
        cur: {
          FaceType: [1],
          StrikeTech: ['Chopping'],
        },
      },
      possible: ['NS', 'SD', 'ND'],
    }, // 反胶削球的旋转是不转、强下旋或中下旋，无默认项
    {
      match: {
        cur: {
          FaceType: [0, 1, 2],
          StrikeTech: ['Topspin'],
        },
      },
      possible: ['ST', 'NT'],
    }, // 正、反、生胶的弧圈的旋转是强上旋或中上旋，无默认项
    {
      match: {
        pre: {
          StrikeTech: ['Push', 'Short']
        },
        cur: {
          FaceType: [0, 1, 2],
          StrikeTech: ['Topspin'],
        },
      },
      default: 'ST',
    }, // 如果倒数第二拍是搓球或者摆短，那最后一拍弧圈默认强上旋
    {
      match: {
        pre: {
          notStrikeTech: ['Push', 'Short']
        },
        cur: {
          FaceType: [0, 1, 2],
          StrikeTech: ['Topspin'],
        },
      },
      default: 'NT',
    }, // 如果倒数第二拍不是搓球或者摆短，那最后一拍弧圈默认中上旋
    {
      match: {
        cur: {
          FaceType: [0, 2],
          StrikeTech: ['Attack', 'Smash', 'Flick', 'Twist', 'Block'],
        },
      },
      possible: ['SK'],
    }, // 正、生胶的快攻、扣杀、挑打、拧、挡球的旋转是下沉，默认下沉
    {
      match: {
        cur: {
          FaceType: [0, 2],
          StrikeTech: ['Push', 'Short', 'Slide'],
        },
      },
      possible: ['ND', 'SD', 'SK'],
      default: 'SK'
    }, // 正、生胶的搓球、摆短、撇的旋转是中下旋、强下旋或下沉，默认下沉
    {
      match: {
        cur: {
          FaceType: [0],
          StrikeTech: ['Lob'],
        },
      },
      possible: ['ST', 'NT', 'NS'],
      default: 'NT',
    }, // 正胶的放高球的旋转是强上旋、中上旋或不旋转，默认中上旋
    {
      match: {
        cur: {
          FaceType: [0],
          StrikeTech: ['Lob'],
        },
      },
      possible: ['NT', 'NS'],
      default: 'NT',
    }, // 生胶的放高球的旋转是中上旋或不旋转，默认中上旋
    {
      match: {
        cur: {
          FaceType: [1],
          StrikeTech: ['Attack', 'Smash', 'Flick'],
        },
      },
      possible: ['ST', 'NT'],
      default: 'NT',
    }, // 反胶的快攻、扣杀或挑打的旋转是强上旋或中上旋，默认中上旋
    {
      match: {
        cur: {
          FaceType: [1],
          StrikeTech: ['Twist'],
        },
      },
      possible: ['ST', 'NT', 'ND'],
      default: 'NT',
    }, // 反胶的拧的旋转是强上旋、中上旋或中下旋，默认中上旋
    {
      match: {
        cur: {
          FaceType: [1],
          StrikeTech: ['Push', 'Short', 'Slide'],
        },
      },
      possible: ['ND', 'SD'],
      default: 'ND'
    }, // 反胶的搓球、摆短、撇的旋转是中下旋或强下旋，默认中下旋
    {
      match: {
        cur: {
          FaceType: [1],
          StrikeTech: ['Block'],
        },
      },
      possible: ['NT', 'NS'],
      default: 'NT'
    }, // 反胶的挡球的旋转是中上旋或不旋转，默认中上旋
    {
      match: {
        cur: {
          FaceType: [1],
          StrikeTech: ['Lob'],
        },
      },
      possible: ['ST', 'NT', 'NS'],
      default: 'NT'
    }, // 反胶的放高球的旋转是强上旋、中上旋或不旋转，默认中上旋
    {
      match: {
        pre: {
          TechType: ['Defend', 'Attack'],
        },
        cur: {
          FaceType: [1],
          StrikeTech: ['Topspin', 'Attack', 'Smash'],
        },
      },
      possible: ['NT'],
    }, // 当对方使用防御性技术和进攻性技术时，反胶下一拍的弧圈球、快攻和扣杀为中上旋；
    {
      match: {
        pre: {
          StrikeTech: ['Short', 'Push'],
        },
        cur: {
          StrikeTech: ['Topspin'],
        },
      },
      possible: ['ST'],
    }, // 当对方使用摆短和搓球时，本方下一拍的弧圈球为强上旋；
    {
      match: {
        pre: {
          TechType: ['Serve'],
          SpinKind: ['NT'],
        },
        cur: {
          FaceType: [1],
          StrikeTech: ['Topspin', 'Attach', 'Smash', 'Flick'],
        },
      },
      possible: ['NT'],
    }, // 发球如果是中上旋，反胶下一拍的弧圈球、快攻、扣杀、挑打也是中上旋；
    {
      match: {
        pre: {
          TechType: ['Serve'],
          SpinKind: ['ND'],
        },
        cur: {
          StrikeTech: ['Topspin'],
        },
      },
      possible: ['ST'],
    }, // 发球如果是中下旋，下一拍的弧圈球是强上旋；
    {
      match: {
        pre: {
          TechType: ['Serve'],
          SpinKind: ['ND'],
        },
        cur: {
          FaceType: [1],
          StrikeTech: ['Attack', 'Smash', 'Flick'],
        },
      },
      possible: ['NT'],
    }, // 发球如果是中下旋，反胶下一拍的快攻、扣杀、挑打是中上旋；
    {
      match: {
        cur: {
          PlayerNum: [2],
          Index: [0],
        },
      },
      addPossible: ['NS'],
    }, // 双打发球可以不转
    {
      match: {
        cur: {
          Index: [-1]
        }
      },
      addPossible: ['WT'],
    }, // 最后一球可以未触碰
  ],
  PlayerPositionAl0: [],
  PlayerPositionAl1: [],
  PlayerPositionOp0: [],
  PlayerPositionOp1: [],
};

export default rules;
