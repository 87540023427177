export const players = [{
    "name": "上田仁",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "刘丁硕",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "方博",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "张继科",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "丁宁",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a09"
}, {
    "name": "平野美宇",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a0a"
}, {
    "name": "朱雨玲",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a0b"
}, {
    "name": "陈梦",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a0c"
}, {
    "name": "王曼昱",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a0d"
}, {
    "name": "王艺迪",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a0e"
}, {
    "name": "陈可",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a0f"
}, {
    "name": "伊藤美诚",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a10"
}, {
    "name": "早田希娜",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a11"
}, {
    "name": "许昕",
    "country": "中国",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a12"
}, {
    "name": "张本智和",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a13"
}, {
    "name": "刘诗雯",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a14"
}, {
    "name": "陈幸同",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a15"
}, {
    "name": "石川佳纯",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a16"
}, {
    "name": "樊振东",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a17"
}, {
    "name": "文佳",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a18"
}, {
    "name": "孙颖莎",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a19"
}, {
    "name": "林高远",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a1a"
}, {
    "name": "李尚洙",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a1b"
}, {
    "name": "田志希",
    "country": "韩国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a1c"
}, {
    "name": "森园政崇",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a1d"
}, {
    "name": "马龙",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a1e"
}, {
    "name": "陈建安",
    "country": "中国台湾",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a1f"
}, {
    "name": "郑怡静",
    "country": "中国台湾",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a20"
}, {
    "name": "刘高阳",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a21"
}, {
    "name": "弗朗西斯卡",
    "country": "德国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a22"
}, {
    "name": "索尔佳",
    "country": "德国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a23"
}, {
    "name": "法尔克",
    "country": "瑞典",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a24"
}, {
    "name": "埃克霍姆",
    "country": "瑞典",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a25"
}, {
    "name": "王楚钦",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a26"
}, {
    "name": "雨果",
    "country": "巴西",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a27"
}, {
    "name": "林昀儒",
    "country": "中国台湾",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a28"
}, {
    "name": "什巴耶夫",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a29"
}, {
    "name": "米哈伊洛娃",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a2a"
}, {
    "name": "任浩",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a2b"
}, {
    "name": "朱霖峰",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a2c"
}, {
    "name": "钱天一",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a2d"
}, {
    "name": "Sidorenko",
    "country": "俄罗斯",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a2e"
}, {
    "name": "Tailakova",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a2f"
}, {
    "name": "Floer",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a30"
}, {
    "name": "Gasnier",
    "country": "法国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a31"
}, {
    "name": "张宇镇",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "张禹珍",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a32"
}, {
    "name": "崔孝珠",
    "country": "韩国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a33"
}, {
    "name": "何卓佳",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a34"
}, {
    "name": "梁靖崑",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a35"
}, {
    "name": "丹羽孝希",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a36"
}, {
    "name": "佐藤瞳",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a37"
}, {
    "name": "杜凯琹",
    "country": "中国香港",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b86caa054794919a38"
}, {
    "name": "水谷隼",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a39"
}, {
    "name": "波尔",
    "country": "德国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a3a"
}, {
    "name": "加藤美优",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a3b"
}, {
    "name": "吉村真晴",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a3c"
}, {
    "name": "桥本帆乃香",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a3d"
}, {
    "name": "冯天薇",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a3e"
}, {
    "name": "A迪亚兹",
    "country": "波多黎各",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a3f"
}, {
    "name": "波尔卡诺娃",
    "country": "奥地利",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a40"
}, {
    "name": "徐孝元",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a41"
}, {
    "name": "P索尔佳",
    "country": "德国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a42"
}, {
    "name": "韩莹",
    "country": "德国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a43"
}, {
    "name": "陈思羽",
    "country": "中国台湾",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a44"
}, {
    "name": "斯佐克斯",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a45"
}, {
    "name": "约内斯库",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a79cfa729be156f842ed66"
}, {
    "name": "伊尔兰德",
    "country": "荷兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a46"
}, {
    "name": "苏慧音",
    "country": "中国香港",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a47"
}, {
    "name": "张安",
    "country": "美国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a48"
}, {
    "name": "萨马拉",
    "country": "罗马尼亚",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a49"
}, {
    "name": "吴玥",
    "country": "美国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a4a"
}, {
    "name": "帕瑟特斯卡",
    "country": "乌克兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a4b"
}, {
    "name": "李倩",
    "country": "波兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a4c"
}, {
    "name": "梅谢里夫",
    "country": "埃及",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a4d"
}, {
    "name": "芝田沙季",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a4e"
}, {
    "name": "张墨",
    "country": "加拿大",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a4f"
}, {
    "name": "米特海姆",
    "country": "德国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a50"
}, {
    "name": "素塔西尼",
    "country": "泰国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a51"
}, {
    "name": "单晓娜",
    "country": "德国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 0,
    "_id": "60a228b96caa054794919a52"
}, {
    "name": "顾玉婷",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a53"
}, {
    "name": "倪夏莲",
    "country": "卢森堡",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 0,
    "_id": "60a228b96caa054794919a54"
}, {
    "name": "杨晓欣",
    "country": "摩纳哥",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a55"
}, {
    "name": "李皓晴",
    "country": "中国香港",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a56"
}, {
    "name": "于梦雨",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a57"
}, {
    "name": "布鲁纳",
    "country": "巴西",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a58"
}, {
    "name": "木原美优",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a59"
}, {
    "name": "马特洛娃",
    "country": "捷克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a5a"
}, {
    "name": "金宋依",
    "country": "朝鲜",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a5b"
}, {
    "name": "波塔",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a5c"
}, {
    "name": "巴拉佐娃",
    "country": "斯洛伐克",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a5d"
}, {
    "name": "付玉",
    "country": "葡萄牙",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a5e"
}, {
    "name": "长崎美柚",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a5f"
}, {
    "name": "郑先知",
    "country": "中国台湾",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a60"
}, {
    "name": "李洁",
    "country": "荷兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a61"
}, {
    "name": "诺斯科娃",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a62"
}, {
    "name": "林叶",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228b96caa054794919a63"
}, {
    "name": "张瑞",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a64"
}, {
    "name": "芭特拉",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a65"
}, {
    "name": "邵洁妮",
    "country": "葡萄牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a66"
}, {
    "name": "森樱花",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a68"
}, {
    "name": "马达拉兹",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a69"
}, {
    "name": "加普诺娃",
    "country": "乌克兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a6a"
}, {
    "name": "刘斐",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a6b"
}, {
    "name": "M迪亚兹",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a6c"
}, {
    "name": "车孝心",
    "country": "朝鲜",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a6d"
}, {
    "name": "维瓦雷利",
    "country": "意大利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a6e"
}, {
    "name": "玛利亚肖",
    "country": "西班牙",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a6f"
}, {
    "name": "曾尖",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a70"
}, {
    "name": "维加",
    "country": "智利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a71"
}, {
    "name": "德努特",
    "country": "卢森堡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a72"
}, {
    "name": "佩格尔",
    "country": "匈牙利",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a73"
}, {
    "name": "德沃夏克",
    "country": "西班牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a74"
}, {
    "name": "梁夏银",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a75"
}, {
    "name": "A索尔佳",
    "country": "奥地利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a76"
}, {
    "name": "帕蒂卡",
    "country": "波兰",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a77"
}, {
    "name": "贝格斯特隆",
    "country": "瑞典",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a78"
}, {
    "name": "刘昱昕",
    "country": "中国台湾",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a79"
}, {
    "name": "安藤美奈美",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a7a"
}, {
    "name": "孙铭阳",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a7b"
}, {
    "name": "申裕斌",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a7c"
}, {
    "name": "加斯尼尔",
    "country": "法国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a7d"
}, {
    "name": "莫蕾特",
    "country": "瑞士",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a7e"
}, {
    "name": "奥拉万",
    "country": "泰国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a7f"
}, {
    "name": "小塩遥菜",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a80"
}, {
    "name": "朱成竹",
    "country": "中国香港",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a81"
}, {
    "name": "塩见真希",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a82"
}, {
    "name": "何天天",
    "country": "英国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a83"
}, {
    "name": "袁嘉楠",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a84"
}, {
    "name": "大藤沙月",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a85"
}, {
    "name": "卢布勒斯库",
    "country": "塞尔维亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a86"
}, {
    "name": "穆赫基",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a87"
}, {
    "name": "乔巴努",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a88"
}, {
    "name": "金南海",
    "country": "朝鲜",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a89"
}, {
    "name": "刘炜珊",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a8a"
}, {
    "name": "巴格拉诺娃",
    "country": "白俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a8b"
}, {
    "name": "刘佳",
    "country": "奥地利",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a8c"
}, {
    "name": "张蔷",
    "country": "中国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a8d"
}, {
    "name": "埃德姆",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a8e"
}, {
    "name": "汉弗莎拉",
    "country": "喀麦隆",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a8f"
}, {
    "name": "张萱",
    "country": "西班牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a90"
}, {
    "name": "浜本由惟",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a91"
}, {
    "name": "王艾米",
    "country": "美国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a92"
}, {
    "name": "高王",
    "country": "泰国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a93"
}, {
    "name": "范思琦",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a94"
}, {
    "name": "库库尔科娃",
    "country": "斯洛伐克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a95"
}, {
    "name": "冯亚兰",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a96"
}, {
    "name": "木子",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 2,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a97"
}, {
    "name": "武杨",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a98"
}, {
    "name": "莎萨瓦利",
    "country": "伊朗",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a99"
}, {
    "name": "郑丽贤",
    "country": "马来西亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a9a"
}, {
    "name": "何英",
    "country": "马来西亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a9b"
}, {
    "name": "可人",
    "country": "马来西亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a9c"
}, {
    "name": "阿希塔利",
    "country": "伊朗",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a9d"
}, {
    "name": "沙美",
    "country": "伊朗",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a9e"
}, {
    "name": "黄翠枝",
    "country": "中国澳门",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919a9f"
}, {
    "name": "鍾穎儀",
    "country": "中国澳门",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919aa0"
}, {
    "name": "塔莫拉万",
    "country": "泰国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228ba6caa054794919aa1"
}, {
    "name": "M法尔克",
    "country": "瑞典",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 0,
    "_id": "60a228bb6caa054794919aa2"
}, {
    "name": "奥恰洛夫",
    "country": "德国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa3"
}, {
    "name": "郑荣植",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa4"
}, {
    "name": "皮切福德",
    "country": "英国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa5"
}, {
    "name": "西蒙高茨",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa6"
}, {
    "name": "黄镇廷",
    "country": "中国香港",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa7"
}, {
    "name": "赵子豪",
    "country": "中国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa8"
}, {
    "name": "加尔多斯",
    "country": "奥地利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aa9"
}, {
    "name": "弗雷塔斯",
    "country": "葡萄牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aaa"
}, {
    "name": "庄智渊",
    "country": "中国台湾",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aab"
}, {
    "name": "达科",
    "country": "斯洛文尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aac"
}, {
    "name": "萨姆索诺夫",
    "country": "白俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aad"
}, {
    "name": "K卡尔松",
    "country": "瑞典",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aae"
}, {
    "name": "卡奈克",
    "country": "美国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aaf"
}, {
    "name": "格罗斯",
    "country": "丹麦",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab0"
}, {
    "name": "阿昌塔",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab1"
}, {
    "name": "普卡尔",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab2"
}, {
    "name": "汪洋",
    "country": "斯洛伐克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab3"
}, {
    "name": "菲鲁斯",
    "country": "德国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab4"
}, {
    "name": "宇田幸矢",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab5"
}, {
    "name": "古斯塔沃",
    "country": "巴西",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab6"
}, {
    "name": "加纳纳塞卡然",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab7"
}, {
    "name": "安宰贤",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab8"
}, {
    "name": "莱贝松",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ab9"
}, {
    "name": "杜达",
    "country": "德国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aba"
}, {
    "name": "奥马尔",
    "country": "埃及",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919abb"
}, {
    "name": "J佩尔森",
    "country": "瑞典",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919abc"
}, {
    "name": "哈贝松",
    "country": "奥地利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919abd"
}, {
    "name": "神巧也",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919abe"
}, {
    "name": "基里尔",
    "country": "哈萨克斯坦",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919abf"
}, {
    "name": "森园政崇",
    "country": "日本",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac0"
}, {
    "name": "加奇尼",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac1"
}, {
    "name": "吉奥尼斯",
    "country": "希腊",
    "isRight": true,
    "isStraight": false,
    "faceType0": 3,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac2"
}, {
    "name": "艾哈迈德",
    "country": "埃及",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac3"
}, {
    "name": "帕维尔",
    "country": "捷克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac4"
}, {
    "name": "邱党",
    "country": "德国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac5"
}, {
    "name": "卡尔伯格",
    "country": "瑞典",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac6"
}, {
    "name": "吉村和弘",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac7"
}, {
    "name": "斯卡奇科夫",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac8"
}, {
    "name": "寇磊",
    "country": "乌克兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ac9"
}, {
    "name": "金科霍尔",
    "country": "英国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919aca"
}, {
    "name": "阿波罗尼亚",
    "country": "葡萄牙",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919acb"
}, {
    "name": "艾什",
    "country": "巴西",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919acc"
}, {
    "name": "罗伯斯",
    "country": "西班牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919acd"
}, {
    "name": "及川瑞基",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ace"
}, {
    "name": "迪亚斯",
    "country": "波兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919acf"
}, {
    "name": "托基奇",
    "country": "斯洛文尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bb6caa054794919ad0"
}, {
    "name": "吉村真晴",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad1"
}, {
    "name": "皮斯特耶",
    "country": "斯洛伐克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad2"
}, {
    "name": "努伊廷克",
    "country": "比利时",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad3"
}, {
    "name": "陈建安",
    "country": "中国台湾",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad4"
}, {
    "name": "林钟勋",
    "country": "韩国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad5"
}, {
    "name": "蒙泰罗",
    "country": "葡萄牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad6"
}, {
    "name": "赵胜敏",
    "country": "韩国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad7"
}, {
    "name": "阿库祖",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad8"
}, {
    "name": "什巴耶夫",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ad9"
}, {
    "name": "T蒙泰罗",
    "country": "巴西",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ada"
}, {
    "name": "费格尔",
    "country": "奥地利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919adb"
}, {
    "name": "沃尔特",
    "country": "德国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919adc"
}, {
    "name": "徐晨皓",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919add"
}, {
    "name": "孙闻",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ade"
}, {
    "name": "闫安",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919adf"
}, {
    "name": "向鹏",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae0"
}, {
    "name": "徐英彬",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae1"
}, {
    "name": "斯祖迪",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae2"
}, {
    "name": "弗洛雷",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae3"
}, {
    "name": "廖振珽",
    "country": "中国台湾",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae4"
}, {
    "name": "徐海东",
    "country": "中国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae5"
}, {
    "name": "周启豪",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae6"
}, {
    "name": "K阿萨尔",
    "country": "埃及",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae7"
}, {
    "name": "斯佐克斯",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae8"
}, {
    "name": "于子洋",
    "country": "中国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919ae9"
}, {
    "name": "乔纳森",
    "country": "丹麦",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919aea"
}, {
    "name": "扎扎亨德",
    "country": "叙利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919aeb"
}, {
    "name": "埃德姆奥菲昂",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919aec"
}, {
    "name": "奥斯霍纳科",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919aed"
}, {
    "name": "加西法德瓦",
    "country": "突尼斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919aee"
}, {
    "name": "阿纳斯塔西娅",
    "country": "哈萨克斯坦",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919aef"
}, {
    "name": "丹妮拉",
    "country": "古巴",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919af0"
}, {
    "name": "帕维德",
    "country": "法国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bc6caa054794919af1"
}, {
    "name": "玛丽亚肖",
    "country": "西班牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1,
    "_id": "60a228bd6caa054794919af2"
}, {
    "name": "科祖尔",
    "country": "斯洛文尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "弗朗尼",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡拉卡塞维奇",
    "country": "塞尔维亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "佩托",
    "country": "塞尔维亚",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "林兆恒",
    "country": "中国香港",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "何钧杰",
    "country": "中国香港",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡辛",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "鲍威尔",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "胡海明",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "西鲁塞克",
    "country": "捷克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "西弗恩特斯",
    "country": "阿根廷",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿法纳多尔",
    "country": "波多黎各",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "周哲宇",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿拉米安",
    "country": "伊朗",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "斯卡契柯夫",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "马约罗斯",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "鲍约尔",
    "country": "波兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡罗琳",
    "country": "巴西",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿卜杜勒",
    "country": "埃及",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "梅丽莎",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "米舍克",
    "country": "奥地利",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "杜迪安",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "切尔茜",
    "country": "圭亚那",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "井纳兹",
    "country": "瑞典",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "洪剑芳",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 0
}, {
    "name": "刘娟",
    "country": "美国",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "特里弗诺娃",
    "country": "保加利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "萨利", "country": "斐济", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "格蕾丝",
    "country": "斐济",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "拉瓦洛娃",
    "country": "哈萨克斯坦",
    "isRight": true,
    "isStraight": true,
    "faceType0": 2,
    "faceType1": 1
}, {
    "name": "浜本由惟",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "茱莉娅", "country": "巴西", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "妮侬",
    "country": "委内瑞拉",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "王晨", "country": "美国", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "王欣阳",
    "country": "加拿大",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "洁西卡", "country": "巴西", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "王臻",
    "country": "加拿大",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "吕", "country": "加拿大", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "高舍",
    "country": "加拿大",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "王慧静", "country": "美国", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "迈克",
    "country": "美国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "布罗姆利",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "刘园",
    "country": "奥地利",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "格里格洛娃",
    "country": "斯洛伐克",
    "isRight": true,
    "isStraight": true,
    "faceType0": 0,
    "faceType1": 1
}, {
    "name": "玛丽安娜",
    "country": "黎巴嫩",
    "isRight": true,
    "isStraight": true,
    "faceType0": 2,
    "faceType1": 1
}, {
    "name": "堪珀斯",
    "country": "古巴",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "拉马德里",
    "country": "智利",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "维加",
    "country": "智利",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "希尔娃",
    "country": "墨西哥",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "哈辛",
    "country": "美国",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "麦子泳",
    "country": "中国香港",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "杰妮芙", "country": "瑞典", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "刘曦",
    "country": "中国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿达马科娃",
    "country": "捷克",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "穆哈默德",
    "country": "沙特阿拉伯",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "滨本由惟",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "赫尔米",
    "country": "埃及",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "肖遥茜",
    "country": "西班牙",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "刘馨尹",
    "country": "中国台湾",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿尔滕卡亚迪娜",
    "country": "土耳其",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "松平志穗",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "赵大成",
    "country": "韩国",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "吴颖岚",
    "country": "中国香港",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "李时温",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "金娜英", "country": "韩国", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "达斯",
    "country": "印度",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "贵霖", "country": "巴西", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "金河英",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "罗西克希娜",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "李佳燚",
    "country": "中国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "洛伊莱特",
    "country": "法国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿鲁纳",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "M卡尔松",
    "country": "瑞典",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "安藤南",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "金智浩",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "迪亚斯",
    "country": "波兰",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "纽丁克",
    "country": "比利时",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡茨曼",
    "country": "俄罗斯",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "格雷布涅夫",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "吉田雅己",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卢博米尔",
    "country": "捷克",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "赵承敏",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "朱俊熙",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "托多偌维奇",
    "country": "塞尔维亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "加西纳",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "朴圭贤",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "金克霍尔",
    "country": "英国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "迪亚科努",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "朴姜贤",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "碧莲科",
    "country": "乌克兰",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "金民赫",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "金大宇",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "温特萨宾",
    "country": "德国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "户上隼辅",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "朴景泰",
    "country": "韩国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "拉波索娃",
    "country": "斯洛伐克",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "铃木飒",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "松岛辉空",
    "country": "日本",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "斯托亚诺夫",
    "country": "意大利",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "莫雷高德",
    "country": "瑞典",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "莎士莲",
    "country": "法国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "斯佐科斯",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "库尔茨基",
    "country": "波兰",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "伊根勒",
    "country": "土耳其",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "艾克塞克依",
    "country": "匈牙利",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "西多连科",
    "country": "俄罗斯",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "库比克",
    "country": "波兰",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "杰里米",
    "country": "加拿大",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "易卜拉欣迪奥",
    "country": "塞内加尔",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "闫鑫",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿里",
    "country": "沙特阿拉伯",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "哈马姆",
    "country": "突尼斯",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "盛约书亚",
    "country": "瓦努阿图",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "范尼", "country": "多哥", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "布里亚",
    "country": "阿尔及利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "莱瓦亚茨",
    "country": "塞尔维亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "扬卡里克",
    "country": "捷克",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "巴特蒙赫",
    "country": "蒙古",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "加斯顿",
    "country": "阿根廷",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿尔伯特",
    "country": "厄瓜多尔",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "奥拉基德",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "库马尔",
    "country": "美国",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "恩赫巴特",
    "country": "蒙古",
    "isRight": false,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "科伊奇",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "何钧杰",
    "country": "中国香港",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡辛",
    "country": "法国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "伊希维托",
    "country": "巴西",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "耶夫托维奇",
    "country": "塞尔维亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "法泽卡斯",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "佩吉尔",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "周鑫",
    "country": "美国",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿比奥顿",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "B",
    "country": "Unknown",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "戈麦兹",
    "country": "智利",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "奥莫塔约",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "马德里",
    "country": "墨西哥",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "其他",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "赫鲁夫",
    "country": "阿尔及利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "贡杜兹",
    "country": "土耳其",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "徐乔伊斯",
    "country": "加拿大",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "A", "country": "未知", "isRight": true, "isStraight": true, "faceType0": 1, "faceType1": 1}, {
    "name": "C",
    "country": "未知",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "什莉丝塔",
    "country": "尼泊尔",
    "isRight": true,
    "isStraight": true,
    "faceType0": 0,
    "faceType1": 1
}, {
    "name": "阿克潘",
    "country": "尼日利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "玛哈詹",
    "country": "尼泊尔",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "恩里克斯",
    "country": "危地马拉",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "奥尔特加",
    "country": "智利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "塔卡",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "魏睿萱",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "耶格尔",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "杨倩",
    "country": "澳大利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "洛赫莱比",
    "country": "阿尔及利亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "马尔瓦",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "吉内尔",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "扎里夫",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 0
}, {
    "name": "李昱谆",
    "country": "中国台北",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿布拉米安",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "玛琳妮娜",
    "country": "俄罗斯",
    "isRight": false,
    "isStraight": false,
    "faceType0": 0,
    "faceType1": 1
}, {
    "name": "沃罗贝娃",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "哈特布里奇",
    "country": "匈牙利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "哈宁",
    "country": "白俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "万媛",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿库拉",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "泰拉科娃",
    "country": "俄罗斯",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡玛斯",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "泽林卡",
    "country": "斯洛伐克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "特里格罗斯",
    "country": "白俄罗斯",
    "isRight": true,
    "isStraight": true,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "帕夫洛维奇",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "皮科林",
    "country": "意大利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "伊姆蕾",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "J马丁",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "科布拉斯科娃",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "戈罗德",
    "country": "卢森堡",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "哈瑞克",
    "country": "土耳其",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "伊玛兹",
    "country": "土耳其",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "文格任",
    "country": "波兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "凯莉夏洛特",
    "country": "瓦里斯",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "科蒂娜",
    "country": "阿根廷",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "洛维拉",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "伊凡娜",
    "country": "克罗地亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "艾卡特里妮",
    "country": "希腊",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "横井咲樱",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "艾希卡",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 0,
    "faceType1": 1
}, {
    "name": "张璇",
    "country": "西班牙",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿圭列斯",
    "country": "阿根廷",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "阿卡希娃",
    "country": "哈萨克斯坦",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "德拉格曼",
    "country": "罗马尼亚",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "曼茨",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "苏里扬",
    "country": "塞尔维亚",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "劳伦蒂",
    "country": "意大利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 0,
    "faceType1": 1
}, {
    "name": "德格拉夫",
    "country": "比利时",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "里奥斯",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "梅村优香",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "波拉特亚科",
    "country": "乌克兰",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "帕达萨克",
    "country": "泰国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "冈萨雷斯",
    "country": "波多黎各",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "金妮帕",
    "country": "泰国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "帕雷德斯",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "奥利维拉",
    "country": "葡萄牙",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "马尔凯蒂",
    "country": "比利时",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "玛丽亚姆",
    "country": "比利时",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡蒂娅",
    "country": "阿尔及利亚 ",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "奥库薇薇安",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "穆克吉",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "智恩彩",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "巴多夫斯基",
    "country": "波兰",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "帕特卡",
    "country": "印度",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "相马梦乃",
    "country": "日本",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "周雨",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "波兰斯基",
    "country": "捷克",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "王歆茹",
    "country": "新加坡",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "莫拉莱斯",
    "country": "智利",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "龙丽莎",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "杨蕙菁",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "吴洋晨",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "米戈特",
    "country": "法国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "张缤月",
    "country": "未知",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "卡特莉娜",
    "country": "捷克",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "瑞秋",
    "country": "美国",
    "isRight": false,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "乔安娜",
    "country": "美国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "郭雨涵",
    "country": "中国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {
    "name": "朴申赫",
    "country": "韩国",
    "isRight": true,
    "isStraight": false,
    "faceType0": 1,
    "faceType1": 1
}, {"name": "李美京", "country": "韩国", "isRight": true, "isStraight": false, "faceType0": 1, "faceType1": 1}];
export default name => {
    for (const player of players)
        if (player.name === name)
            return {
                country: player.country,
                rightHand: player.isRight,
                isStraight: player.isStraight,
                face: [player.faceType0, player.faceType1],
            }
};
