import React, {useRef} from 'react';
import {CircularProgress, Grid, makeStyles, Tooltip, Typography} from "@material-ui/core";
import store from "../../../Data/Store";
import {InfoOutlined} from "@material-ui/icons";
import {useSize} from "ahooks";

const useStyles = makeStyles(theme => ({
    circularProgressStatus: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    status: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}));

function CircularProgressStatus({error, statusText, progress}) {
    const classes = useStyles();
    const container = useRef(null);
    const {width, height} = useSize(container);
    const size = Math.min(width, height);

    return <div ref={container} className={classes.circularProgressStatus}>
        <CircularProgress className={classes.progress}
                          style={{
                              width: size,
                              height: size,
                              transform: 'translate(-50%, -50%) rotate(-90deg)',
                          }}
                          variant={"determinate"} value={progress}/>
        <div className={classes.status}>
            <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                    <Typography color={error ? "error" : "textPrimary"}>
                        {statusText}
                    </Typography>
                </Grid>
                <Grid item>
                    {error && <Tooltip title={store.languageSwitch("errorHelp")}>
                        <span><InfoOutlined color="error"/></span>
                    </Tooltip>}
                </Grid>
            </Grid>
        </div>
    </div>
}

export default CircularProgressStatus;
