import store from "../../../Data/Store";

const ErrorInfo = Object.freeze({
    EmptyGame: store.languageSwitch("EmptyGame"),
    EmptyRally: store.languageSwitch("EmptyRally"),
    DuplicateGameInfo: store.languageSwitch("DuplicateGameInfo"),
    DuplicateRallyInfo: store.languageSwitch("DuplicateRallyInfo"),
    GameNotEnd: store.languageSwitch("GameNotEnd"),
    MatchNotEnd: store.languageSwitch("MatchNotEnd"),
    HasErrorRally: store.languageSwitch("HasErrorRally"),
    WrongRallyTime: store.languageSwitch("WrongRallyTime"),
    WrongRallyLength: store.languageSwitch("WrongRallyLength"),
    WrongRallyScore: store.languageSwitch("WrongRallyScore"),
})

const newGame = () => ({
    score: [0, 0],
    result: [0, 0],
    startSide: 0,
    list: [],

    errInfo: [{type: ErrorInfo.EmptyGame}],
})

const newRally = () => ({
    score: [0, 0],
    result: [0, 0],
    startSide: 0,
    winSide: 0,
    rallyLength: 0,
    startTime: 0,
    endTime: 0,

    errInfo: [{type: ErrorInfo.EmptyRally}],
})

const calErrorInfo = data => {
    const calRallyErrorInfo = (game, gId, gameList) => (rally, rId, rallyList) => {
        // init data
        if (rId === 0) rally.startSide = game.startSide;
        else if (rId === 1) rally.startSide = rallyList[0].startSide;
        else if (rId >= 2 && rId < 20) rally.startSide = 1 - rallyList[rId - 2].startSide;
        else rally.startSide = 1 - rallyList[rId - 1].startSide;

        if (rId < rallyList.length - 1) rally.result = [...rallyList[rId + 1].score];
        else {
            const winSide = (rally.rallyLength + rally.startSide) % 2;
            rally.result = [
                rally.score[0] + 1 - winSide,
                rally.score[1] + winSide,
            ]
        }

        rally.winSide = (rally.result[0] > rally.score[0]) ? 0 : 1;

        rally.errInfo = [];

        // wrong score
        if (rId > 0) {
            const preRally = rallyList[rId - 1] || null;
            const win0 = rally.score[0] - preRally.score[0],
              win1 = rally.score[1] - preRally.score[1];
            if (![0, 1].includes(win0) || ![0, 1].includes(win1) || win0 === win1)
                rally.errInfo.push({type: ErrorInfo.WrongRallyScore})
        }

        // wrong rally length
        if (rally.rallyLength % 2 !== Math.abs(rally.winSide - rally.startSide))
            rally.errInfo.push({type: ErrorInfo.WrongRallyLength});

        // wrong time
        if (rally.startTime >= rally.endTime) rally.errInfo.push({type: ErrorInfo.WrongRallyTime});
    }
    const calGameErrorInfo = (game, gId, gameList) => {
        // init data
        if (gId !== 0) game.startSide = 1 - gameList[gId - 1].startSide;
        game.errInfo = [];

        // compute rally errors
        game.list.forEach(calRallyErrorInfo(game, gId, gameList));
        if (game.list.length > 0)
            game.result = game.list[game.list.length - 1].result;

        // empty game
        if (game.list.length === 0)
            game.errInfo.push({type: ErrorInfo.EmptyGame})

        // game not end
        if (!canGameEnd(game, false)) game.errInfo.push({type: ErrorInfo.GameNotEnd})

        // match not end
        if (gId === gameList.length - 1 && canGameEnd(game)) {
            const gameWinner = (game.result[0] > game.result[1]) ? 0 : 1;
            if (game.score[gameWinner] + 1 === game.score[1 - gameWinner])
                game.errInfo.push({type: ErrorInfo.MatchNotEnd})
        }

        // collect errors
        game.list.forEach((rally, rId) => {
            if (rally.errInfo.length > 0)
                game.errInfo.push({
                    type: ErrorInfo.HasErrorRally(rId),
                    payload: {rId}
                })
        })
    }
    data.forEach(calGameErrorInfo)
    return data;
}

const backendData2tempData = (segmentData, fps = 24) => {
    const data = [];

    const setData = (gId, rId, rallyInfo) => {
        while (data.length <= gId) data.push(newGame());
        const game = data[gId];

        while (game.list.length <= rId) game.list.push(newRally());
        const rally = game.list[rId];

        // init game score
        if (!game.initialized) {
            game.score = rallyInfo.gameScore;
            game.initialized = true;
        } else {
            // game score mismatch
            if (game.score[0] !== rallyInfo.gameScore[0] || game.score[1] !== rallyInfo.gameScore[1])
                rally.errInfo.push({
                    type: ErrorInfo.DuplicateGameInfo,
                    payload: rallyInfo.gameScore,
                });
        }

        if (!rally.initialized) {
            rally.rallyLength = rallyInfo.rallyLength;
            rally.result = rallyInfo.rallyScore;
            rally.startTime = rallyInfo.startTime;
            rally.endTime = rallyInfo.endTime;
            rally.initialized = false;
        } else {
            rally.errInfo.push({
                type: ErrorInfo.DuplicateRallyInfo,
                payload: rallyInfo,
            })
        }
    }

    segmentData.forEach(segment => {
        const {frame, score, stroke_num} = segment;
        const startTime = frame[0] / fps, endTime = frame[1] / fps;
        const [gameScore0, gameScore1, rallyScore0, rallyScore1] = score.split('_').map(num => +num);
        setData(
          gameScore0 + gameScore1,
          rallyScore0 + rallyScore1 - 1,
          {
              startTime,
              endTime,
              rallyLength: stroke_num,
              gameScore: [gameScore0, gameScore1],
              rallyScore: [rallyScore0, rallyScore1]
          }
        )
    })

    data.forEach(game => {
        game.list.forEach((rally, rId) => {
            if (rId === 0) rally.score = [0, 0];
            else rally.score = JSON.parse(JSON.stringify(game.list[rId - 1].result));
        })
    })

    calErrorInfo(data);

    return data;
}

const tempData2systemData = data => JSON.parse(JSON.stringify(data.map(tempGame => ({
    result: tempGame.result,
    score: tempGame.score,
    structureLevel: 0,
    termLevel: 2,
    list: tempGame.list.map(({
                                 startTime,
                                 endTime,
                                 rallyLength,
                                 startSide,
                                 winSide,
                                 score,
                             }) => ({
        startTime,
        endTime,
        rallyLength,
        startSide,
        winSide,
        score,
        structureLevel: 0,
        termLevel: 2,
        list: [],
    }))
}))))

const tempData2backendData = (data, fps) => {
    const backendData = [];
    data.forEach(game => {
        game.list.forEach(rally => {
            backendData.push({
                frame: [Math.round(rally.startTime * fps), Math.round(rally.endTime * fps)],
                score: [game.score[0], game.score[1], rally.result[0], rally.result[1]].join("_"),
                stroke_num: rally.rallyLength,
            })
        })
    })
    return backendData;
}

const isDataComplete = data => data.every(game => game.errInfo.length === 0);

const canGameEnd = (game, useCache = true) => useCache ?
  game.errInfo.findIndex(e => e.type === ErrorInfo.GameNotEnd) === -1 :
  ((Math.max(...game.result) === 11 && Math.min(...game.result) < 10) ||
    (Math.max(...game.result) > 11 && Math.abs(game.result[0] - game.result[1]) === 2));

export {
    ErrorInfo,
    newGame,
    newRally,
    calErrorInfo,
    backendData2tempData,
    tempData2systemData,
    tempData2backendData,
    isDataComplete,
    canGameEnd,
}
