class Utils {
    static init() {
        Array.prototype.insert = function (idx, item) {
            this.splice(idx, 0, item);
            return this;
        }
    }
}

export default Utils;
