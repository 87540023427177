import {calErrorInfo, newGame, newRally} from "./dataTransfer";

const dataActions = Object.freeze({
    AddGame: "AddGame",
    RemoveGame: "RemoveGame",
    SetGameInfo: "SetGameInfo",
    ReverseScore: "ReverseScore",

    AddRally: "AddRally",
    RemoveRally: "RemoveRally",
    SetRallyInfo: "SetRallyInfo",
})

const reducer = (data, {type, payload}) => {
    return calErrorInfo((() => {
        switch (type) {
            case dataActions.AddGame: {
                const {gId} = payload;
                return data.insert(gId, newGame());
            }
            case dataActions.RemoveGame: {
                const {gId} = payload;
                return data.filter((_, i) => i !== gId);
            }
            case dataActions.SetGameInfo: {
                const {gId, gameInfo} = payload;
                return data.map((g, i) => (i !== gId) ?
                  g :
                  {
                      ...g,
                      ...gameInfo,
                  }
                )
            }
            case dataActions.ReverseScore: {
                return data.map(g => ({
                    ...g,
                    score: [g.score[1], g.score[0]],
                    list: g.list.map(r => ({
                        ...r,
                        score: [r.score[1], r.score[0]],
                        result: [r.result[1], r.result[0]],
                    }))
                }))
            }
            case dataActions.AddRally: {
                const {gId, rId} = payload;
                if (gId >= data.length)
                    data.insert(gId, newGame());
                return data.map((g, i) => (i !== gId) ?
                  g :
                  {
                      ...g,
                      list: g.list.insert(rId, newRally())
                        .map((r, j, l) => {
                            if (rId === 0 || j !== rId) return r;
                            const preRally = l[j - 1];
                            r.score = JSON.parse(JSON.stringify(preRally.result));
                            return r;
                        }),
                  }
                );
            }
            case dataActions.RemoveRally: {
                const {gId, rId} = payload;
                return data.map((g, i) => (i !== gId) ?
                  g :
                  {
                      ...g,
                      list: g.list.filter((_, j) => j !== rId),
                  }
                );
            }
            case dataActions.SetRallyInfo: {
                const {gId, rId, rallyInfo} = payload;
                console.log(gId, rId, rallyInfo);
                return data.map((g, i) => (i !== gId) ?
                  g :
                  {
                      ...g,
                      list: g.list.map((r, j) => (j !== rId) ?
                        r :
                        {
                            ...r,
                            ...rallyInfo,
                        }
                      )
                  }
                )
            }
            default:
                throw Error("Wrong action type!");
        }
    })());
}

export {
    dataActions,
    reducer,
};
