import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import store from '../../Data/Store';
import Button from '@material-ui/core/Button';
import {fade} from "@material-ui/core/styles/colorManipulator";
import VersionLog from "./VersionLog";
import NewOption from "./NewOption";

const styles = theme => ({
    // position
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    buttonList: {
        width: "100%",
        height: '100%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.contrastText,
    },
    button: {
        width: '30vh',
        height: '30vh',
        flex: '0 0 30vh',
        boxShadow: 'none',
        margin: theme.spacing(5),
        fontSize: '5vh',

        borderColor: fade(theme.palette.primary.light, 0.25),
        borderWidth: 4,
        borderRadius: '0.3vw',
        borderStyle: 'solid',

        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.dark,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
        }
    },
    updates: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%)',
        bottom: '10vh',
        boxShadow: 'none',
        fontSize: '1vh',

        borderColor: fade(theme.palette.primary.light, 0),
        borderWidth: 4,
        borderRadius: '0.3vw',
        borderStyle: 'solid',

        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: fade(theme.palette.primary.light, 0.25),
            backgroundColor: fade(theme.palette.primary.light, 0.25),
            color: theme.palette.primary.main,
        }
    },
});

class Welcome extends Component {
    state = {
        openUpdates: false,
        openNewOption: false,
    };

    handleOpenUpdates = () => this.setState({openUpdates: true});

    handleCloseUpdates = () => this.setState({openUpdates: false});

    handleOpenNewOption = () => this.setState({openNewOption: true});

    handleCloseNewOption = () => this.setState({openNewOption: false});

    handleOpen = () => store.LoadTemp();

    handleSave = () => store.SaveTemp();

    handleNewLocal = () => store.NewMatch();

    handleNewOnline = () => {
        alert("您没有新建合作的权限！");
    };

    handleJoinOnline = () => {
        prompt('请输入合作编码：');
    };

    render() {
        const {classes} = this.props;

        return <div className={classes.root}>
            <div className={classes.buttonList}>
                <Button className={classes.button} onClick={this.handleOpenNewOption}>
                    {store.languageSwitch('New')}
                </Button>
                <Button className={classes.button} onClick={this.handleOpen}>
                    {store.languageSwitch('Open')}
                </Button>
                <Button className={classes.button} onClick={this.handleSave}>
                    {store.languageSwitch('Save')}
                </Button>
            </div>
            <Button className={classes.updates} onClick={this.handleOpenUpdates}>
                {store.languageSwitch('Updates')}
            </Button>
            <NewOption open={this.state.openNewOption} onClose={this.handleCloseNewOption}
                       newLocal={this.handleNewLocal}
                       newOnline={this.handleNewOnline}
                       joinOnline={this.handleJoinOnline}/>
            <VersionLog open={this.state.openUpdates} onClose={this.handleCloseUpdates}/>
        </div>;
    }
}

export default withStyles(styles)(Welcome);
