import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import StrikeTech from "./StrikeTech";
import PositionCollector from "./PositionCollector";
import AdditionalTerm from "./AdditionalTerm";
import {fade} from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  positionCollector: {
    position: 'absolute',
    width: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2))',
    top: 0,
    height: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2) * 7 / 5)',
  },
  strikeTech: {
    position: 'absolute',
    width: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2))',
    bottom: '53px',
    height: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2) * 5 / 4 / 2 + 72px)',
    borderTop: `2px solid ${fade(theme.palette.primary.light, 0.25)}`,
  },
  additionalTerm: {
    position: 'absolute',
    width: 'calc((100vh - 20px - 12px - 72px - 50px) / (7 / 5 + 5 / 4 / 2))',
    bottom: 0,
    height: '50px',
    borderTop: `2px solid ${fade(theme.palette.primary.light, 0.25)}`,
  }
});

class TermEditor extends Component {
  render() {
    const {classes} = this.props;

    return <div>
      <div className={classes.positionCollector}>
        <PositionCollector />
      </div>
      <div className={classes.strikeTech}>
        <StrikeTech />
      </div>
      <div className={classes.additionalTerm}>
        <AdditionalTerm />
      </div>
    </div>;
  }
}

export default withStyles(styles)(TermEditor);