import React from 'react';
import {Button, makeStyles, Typography} from "@material-ui/core";
import store from "../../../../Data/Store";
import ErrorHinter from "./ErrorHinter";

const useStyles = makeStyles(theme => ({
    rootRallyStruct: {
        height: 36,
        width: `calc(100% - ${theme.spacing(2)}px)`,
        margin: theme.spacing(0, 1),
        padding: '5px 7px',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    rallyIndex: {
        flex: `0 0 50%`,
    },
    rallyScore: {
        flex: 1,
    },
    versus: {
        flex: 0,
    },
}));

function RallyStruct({
                         idx,
                         rally,
                         focus,
                         onFocusRally,
                     }) {
    const classes = useStyles();
    const handleFocusRally = () => onFocusRally(idx);

    return <ErrorHinter errInfo={rally.errInfo}>
        <Button className={classes.rootRallyStruct}
                variant={focus.rId === idx ? 'contained' : 'outlined'}
                color={rally.errInfo.length === 0 ? 'primary' : 'secondary'}
                fullWidth
                onClick={handleFocusRally}>
            <Typography className={classes.rallyIndex}>
                {store.languageSwitch("RallyNum")((idx + 1).toString().padStart(2, ' '))}
            </Typography>
            <Typography className={classes.rallyScore}>{rally.score[0]}</Typography>
            <Typography className={classes.versus}>:</Typography>
            <Typography className={classes.rallyScore}>{rally.score[1]}</Typography>
        </Button>
    </ErrorHinter>
}

export default RallyStruct;
