import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import SimpleVideoPlayer from "../SimpleVideoPlayer";
import {useSize} from "ahooks";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    container: {
        position: 'absolute',
        top: 'calc(50% - 25px)',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

function VideoAnnotator({children, src}) {
    const containerRef = useRef(null);
    const {width: cW, height: cH} = useSize(containerRef);
    const [[vW, vH], setVideoSize] = useState([1, 1]);

    const scale = Math.min(cW / vW, (cH - 50) / vH);
    const size = !scale ? [0, 0] : [vW * scale, vH * scale];

    const classes = useStyles();

    return <div className={classes.root} ref={containerRef}>
        <div className={classes.container}
             style={{width: size[0], height: size[1]}}>
            <SimpleVideoPlayer src={src} onLoadedMetaData={e => {
                setVideoSize([e.target.videoWidth, e.target.videoHeight]);
            }}/>

            {children(size)}
        </div>
    </div>;
}

export default VideoAnnotator;

// edited by WuJiang5521 on 2021/5/25
