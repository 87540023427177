import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import clsx from 'clsx';
import store from "../../Data/Store";
import {fade} from "@material-ui/core/styles/colorManipulator";
import pages from "../../Data/Pages";

const styles = theme => ({
    tabArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '2vw',
        height: '100%',
        backgroundColor: theme.palette.primary.contrastText,
    },
    switchTab: {
        minWidth: 0,
        width: 'calc(100% - 2px)',
        margin: `${theme.spacing(0.5)}px 0`,
        height: '15vh',
        padding: 0,
        borderWidth: 2,
        borderLeftWidth: 0,
        borderStyle: 'solid',
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        borderColor: fade(theme.palette.primary.light, 0.25),
        borderTopRightRadius: '0.3vw',
        borderBottomRightRadius: '0.3vw',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.light, 0.25),
            color: theme.palette.primary.main,
        }
    },
    currentTab: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        }
    },
    tabTitle: {
        writingMode: 'vertical-lr',
        fontSize: '1.5rem',
    },
});

class TabNavigator extends Component {
    handleWheel = e => {
        const curIdx = pages.sort.indexOf(this.props.page);
        const nxtIdx = curIdx + Math.sign(e.deltaY);

        if (curIdx !== nxtIdx && pages.sort[nxtIdx] !== undefined)
            this.props.onChange(pages.sort[nxtIdx]);
    };

    render() {
        const {classes} = this.props;

        return <div className={classes.tabArea} onWheel={this.handleWheel}>
            {
                pages.sort.map(page => {
                    return <Button key={page} onClick={() => this.props.onChange(page)} fullWidth
                                   className={clsx({
                                       [classes.switchTab]: true,
                                       [classes.currentTab]: page === this.props.page,
                                   })}
                                   classes={{label: classes.tabTitle}}>
                        {store.languageSwitch(this.getPageName(page))}
                    </Button>
                })
            }
        </div>;
    }

    getPageName(page) {
        switch (page) {
            case pages.WELCOME:
                return "pageHome";
            case pages.INFO:
                return "pageInfo";
            case pages.AUTO_DETECTION:
                return "pageAutoDetection";
            case pages.LOCATE:
                return "pageLocate";
            case pages.RECORD:
                return "pageRecord";
            case pages.EXPORT:
                return "pageExport";
            default:
                return "";
        }
    }
}

export default withStyles(styles)(TabNavigator);

// edited by WuJiang5521 on 2019/9/2
