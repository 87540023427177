import React, {useEffect, useRef} from 'react';
import {Button, makeStyles} from "@material-ui/core";
import store from "../../../Data/Store";
import CircularProgressStatus from "../Components/CircularProgressStatus";
import SparkMD5 from "spark-md5";
import api from "../../../Data/API";
import useStateTransfer from "../Utils/useStateTransfer";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
    },
    progressContainer: {
        width: '100%',
        flex: '0 0 100%',
        height: '70%',
    },
    actions: {
        width: '100%',
        height: 30,
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'center',
    }
}));

const Status = Object.freeze({
    calMD5: store.languageSwitch("calMD5"),
    checkVideoExistence: store.languageSwitch("checkVideoExistence"),
    finish: store.languageSwitch("processFinish"),
    error: store.languageSwitch("processError"),
})

const calMd5 = (videoFile, onProgress, onFinish, onError) => {
    if (videoFile === null) return;
    const videoURL = URL.createObjectURL(videoFile);
    fetch(videoURL)
      .then(r => r.blob())
      .then(blob => {
          const fileReader = new FileReader();

          const chunkSize = 2097152;
          const chunks = Math.min(20, Math.ceil(blob.size / chunkSize));
          const spark = new SparkMD5.ArrayBuffer();
          let currentChunk = 0;

          const loadNext = () => {
              console.log('calMD5', currentChunk / chunks * 100);
              onProgress(currentChunk / chunks * 100);
              const start = currentChunk * chunkSize,
                end = ((start + chunkSize) >= blob.size) ? blob.size : start + chunkSize;

              fileReader.readAsArrayBuffer(blob.slice(start, end))
          }
          fileReader.onload = (e) => {
              console.log(`read chunk nr ${currentChunk + 1} of ${chunks}`)
              spark.append(e.target.result);
              currentChunk++;
              if (currentChunk < chunks) loadNext();
              else {
                  console.log("finished loading");
                  const md5 = spark.end();
                  console.info('computed hash', md5);
                  onProgress(100);
                  onFinish(md5);
              }
          }
          fileReader.onerror = (err) => {
              console.warn(err);
              onError(err);
          }
          loadNext();
      });
};

const checkVideoExistence = (videoName, md5, onFinish, onError) => {
    api.checkVideoExistence(videoName, md5)
      .then(res => {
          if (res.status !== "succeed") onError(res.info);

          const hasAll = ["scoreboard", "table", "net"].every(key => res[key] !== null);

          const canSkipUploadVideo = res.video_existed;
          const canSkipUploadAnnotation = hasAll;
          const canSkipSegment = res.video_segmented;

          onFinish({
              scoreboard: res.scoreboard,
              table: res.table,
              net: res.net,
              canSkipUploadVideo,
              canSkipUploadAnnotation,
              canSkipSegment,
          });
      })
      .catch(err => {
          onError(err);
      })
}

function Preprocessor({
                          activate,
                          videoFile,
                          onProcessEnd,
                          onNext,
                      }) {
    const classes = useStyles();
    const md5 = useRef("");
    const videoConfig = useRef(null);
    const errInfo = useRef("");

    const transferMatrix = {
        "": {
            onLoad: ({onFinish}) => onFinish(),
            nxtState: Status.calMD5,
        },
        [Status.calMD5]: {
            onLoad: ({onFinish, onProgress, onError}) => calMd5(
              videoFile,
              onProgress,
              d => {
                  onFinish();
                  md5.current = d;
              },
              err => {
                  onError(Status.error);
                  errInfo.current = err;
              }
            ),
            nxtState: Status.checkVideoExistence,
        },
        [Status.checkVideoExistence]: {
            onLoad: ({onFinish, onError}) => checkVideoExistence(
              videoFile.name,
              md5.current,
              d => {
                  onFinish();
                  videoConfig.current = d;
              },
              err => {
                  onError(Status.error);
                  errInfo.current = err;
              }
            ),
            nxtState: Status.finish,
        },
        [Status.finish]: {
            onLoad: ({onProgress}) => {
                onProgress(100);
                onProcessEnd && onProcessEnd(md5.current, videoConfig.current);
            },
        },
        [Status.error]: {
            onLoad: ({onProgress}) => onProgress(0),
        },
    }
    const {state, progress, handleRetry} = useStateTransfer(
      transferMatrix,
      activate,
      {errState: Status.error},
      true,
    );
    useEffect(handleRetry, [videoFile]);

    return <div className={classes.root}>
        <div className={classes.content}>
            <div className={classes.progressContainer}>
                <CircularProgressStatus error={state === Status.error}
                                        statusText={state}
                                        progress={progress}/>
            </div>
        </div>
        <div className={classes.actions}>

            {state === Status.error ?
              <Button size={"small"}
                      color={'secondary'} variant={"contained"}
                      onClick={handleRetry}>{store.languageSwitch("retry")}</Button> :
              <Button size={"small"}
                      disabled={state !== Status.finish}
                      color={"primary"} variant={"contained"}
                      onClick={onNext}>{store.languageSwitch("nxtStep")}</Button>
            }
        </div>
    </div>
}

export default Preprocessor;
