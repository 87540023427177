import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, makeStyles} from "@material-ui/core";
import store from "../../../Data/Store";
import {
    backendData2tempData,
    canGameEnd,
    isDataComplete,
    tempData2backendData,
    tempData2systemData
} from "../Utils/dataTransfer";
import SimpleVideoPlayer from "../Components/SimpleVideoPlayer";
import GameStructure from "../Components/GameStructure";
import RallyInfo from "../Components/RallyInfo";
import {useSize} from "ahooks";
import {dataActions, reducer} from "../Utils/dataActions";
import {useHotkeys} from "react-hotkeys-hook";
import useChangeData from "../Utils/useChangeData";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 50px)',
        position: 'relative',
    },
    containerGameStructure: {
        position: "absolute",
        left: theme.spacing(1),
        width: 250,
        top: theme.spacing(1),
        bottom: theme.spacing(1),
    },
    containerVideoPlayer: {
        position: "absolute",
        left: theme.spacing(2) + 250,
        right: theme.spacing(1),
        top: theme.spacing(1),
        bottom: theme.spacing(2) + 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
    },
    wrapperVideoPlayer: {
        position: 'relative'
    },
    containerRallyInfo: {
        position: "absolute",
        left: theme.spacing(2) + 250,
        right: theme.spacing(1),
        height: 250,
        bottom: theme.spacing(1),
    },
    actions: {
        width: '100%',
        height: 30,
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'center',
    }
}));

function DataChecker({
                         src,
                         fps,
                         segmentData,
                         onPre,
                         onProcessEnd,
                         onStartProcess,
                         onEndProcess,
                     }) {
    //region avoid page change
    useEffect(() => {
        onStartProcess();
        return onEndProcess;
    }, [])
    //endregion

    //region data process
    const [data, setData] = useState([]);
    useEffect(() => setData(backendData2tempData(segmentData, fps)), [segmentData, fps]);
    const handleChange = ({type, payload}) => setData(data => JSON.parse(JSON.stringify(reducer(data, {type, payload}))));

    const handleConfirm = () => {
        onProcessEnd(
          tempData2systemData(data),
          tempData2backendData(data, fps)
        );
        onEndProcess();
    }
    //endregion

    const [focus, setFocus] = useState({
        gId: -1,
        rId: -1,
    })

    //region video dom API
    const videoRef = useRef(null);
    const getCurrentTime = () => videoRef.current.currentTime;
    //endregion

    //region video size
    const container = useRef(null);
    const {width: cW, height: cH} = useSize(container);
    const [[vW, vH], setVideoSize] = useState([1, 1]);
    const [width, height] = useMemo(() => {
        const scale = Math.min(cW / vW, (cH - 50) / vH);
        return [vW * scale, vH * scale];
    }, [cW, cH, vW, vH]);
    //endregion

    //region hot keys
    const {
        handleChangeRallyLength,
        handleChangeStartTime,
        handleChangeEndTime,
    } = useChangeData(handleChange);
    //region video control
    useHotkeys('r', () => {
        const {gId, rId} = focus;
        const game = data[gId];
        if (!game) return;
        const rally = game.list[rId];
        if (!rally) return;

        const toTime = rally.startTime;
        console.log(videoRef.current, toTime);
        if (toTime > 0 && toTime < videoRef.current.duration)
            videoRef.current.currentTime = toTime;
    }, {}, [focus.gId, focus.rId])
    useHotkeys('left', () => {
        videoRef.current.currentTime -= 3;
    })
    useHotkeys('right', () => {
        videoRef.current.currentTime += 3;
    })
    useHotkeys('space', (e) => {
        e.preventDefault();
        if (videoRef.current.paused) videoRef.current.play();
        else videoRef.current.pause();
    })
    //endregion
    //region game control
    useHotkeys('enter', () => {
        let {gId, rId} = focus;
        let needGen = false;

        if (gId === -1) {
            gId = 0;
            rId = 0;
            needGen = data.length === 0 || data[0].list.length === 0;
        } else {
            const game = data[gId];
            if (rId < game.list.length - 1) rId++;
            else if (!canGameEnd(game)) {
                rId++;
                needGen = true;
            } else {
                gId++;
                rId = 0;
                needGen = gId === data.length || data[gId].list.length === 0;
            }
        }

        if (needGen) {
            handleChange({
                type: dataActions.AddRally,
                payload: {gId, rId}
            })
        }

        setFocus({gId, rId});
    }, {}, [focus.gId, focus.rId, data])
    //endregion
    //region time
    useHotkeys('s', () => {
        const {gId, rId} = focus;
        const startTime = videoRef.current.currentTime;
        handleChangeStartTime({gId, rId, startTime});
    }, {}, [focus.gId, focus.rId])
    useHotkeys('d', () => {
        const {gId, rId} = focus;
        const endTime = videoRef.current.currentTime;
        handleChangeEndTime({gId, rId, endTime});
    }, {}, [focus.gId, focus.rId])
    //endregion
    //region input number directly
    const deleteNumber = () => {
        const {gId, rId} = focus;
        const game = data[gId];
        if (!game) return;
        const rally = game.list[rId];
        if (!rally) return;

        const currentRallyLength = rally.rallyLength;
        const newRallyLength = parseInt(Math.floor(currentRallyLength / 10).toString(10));
        handleChangeRallyLength({gId, rId, rallyLength: newRallyLength})
    }
    const inputNumber = num => () => {
        const {gId, rId} = focus;
        const game = data[gId];
        if (!game) return;
        const rally = game.list[rId];
        if (!rally) return;
        const currentRallyLength = rally.rallyLength;
        const newRallyLength = currentRallyLength * 10 + num;
        handleChangeRallyLength({gId, rId, rallyLength: newRallyLength})
    }
    useHotkeys('0,num_0', inputNumber(0), {}, [inputNumber]);
    useHotkeys('1,num_1', inputNumber(1), {}, [inputNumber]);
    useHotkeys('2,num_2', inputNumber(2), {}, [inputNumber]);
    useHotkeys('3,num_3', inputNumber(3), {}, [inputNumber]);
    useHotkeys('4,num_4', inputNumber(4), {}, [inputNumber]);
    useHotkeys('5,num_5', inputNumber(5), {}, [inputNumber]);
    useHotkeys('6,num_6', inputNumber(6), {}, [inputNumber]);
    useHotkeys('7,num_7', inputNumber(7), {}, [inputNumber]);
    useHotkeys('8,num_8', inputNumber(8), {}, [inputNumber]);
    useHotkeys('9,num_9', inputNumber(9), {}, [inputNumber]);
    useHotkeys('backspace', deleteNumber, {}, [deleteNumber])
    //endregion
    //endregion


    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.content}>
            <div className={classes.containerGameStructure}>
                <GameStructure focus={focus} setFocus={setFocus} data={data} onChange={handleChange}/>
            </div>
            <div className={classes.containerVideoPlayer}
                 ref={container}>
                <div className={classes.wrapperVideoPlayer} style={{width, height}}>
                    <SimpleVideoPlayer src={src}
                                       videoRef={videoRef}
                                       onLoadedMetaData={e => setVideoSize([e.target.videoWidth, e.target.videoHeight])}/>
                </div>
            </div>
            <div className={classes.containerRallyInfo}>
                <RallyInfo focus={focus} data={data}
                           onChange={handleChange}
                           getCurrentTime={getCurrentTime}/>
            </div>
        </div>
        <div className={classes.actions}>
            <Button size={"small"}
                    color={'primary'}
                    onClick={onPre}>{store.languageSwitch("preStep")}</Button>
            <Button size={"small"}
                    color={"primary"}
                    variant={"contained"}
                    disabled={!isDataComplete(data)}
                    onClick={handleConfirm}>{store.languageSwitch("confirm")}</Button>
        </div>
    </div>
}

export default DataChecker;
