import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import store from '../../Data/Store';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Statistics from "./Statistics/Statistics";
import {fade} from "@material-ui/core/styles/colorManipulator";
import VideoList from "./VideoList/VideoList";
import {Typography} from "@material-ui/core";

const styles = theme => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.primary.contrastText,
    },
    block: {
        borderWidth: '2px',
        borderRadius: '0.3vw',
        borderStyle: 'solid',
        borderColor: fade(theme.palette.primary.light, 0.25),
    },
    statistics: {
        position: 'absolute',
        top: '10px',
        bottom: '10px',
        left: '30px',
        right: '386px',
    },
    settings: {
        position: 'absolute',
        top: '10px',
        height: '150px',
        right: '30px',
        width: '340px',
        display: 'block',
        textAlign: 'center',
    },
    title: {
        fontSize: '1.3rem',
        lineHeight: '40px',
        height: '40px',
        textAlign: 'left',
        marginLeft: theme.spacing(1),
    },
    checkboxRoot: {
        color: theme.palette.primary.main,
        '&$checkboxChecked': {
            color: theme.palette.primary.main,
        },
    },
    checkboxChecked: {},
    exportButton: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translate(-50%)',
        boxShadow: 'none',
    },
    videoList: {
        position: 'absolute',
        top: '180px',
        bottom: '10px',
        width: '340px',
        right: '30px',
    }
});

class Export extends Component {
    state = {
        exportCsv: true,
        exportJson: true,
    };

    render() {
        const {classes} = this.props;
        const check = store.exportCheck();

        return <div className={classes.root}>
            <div className={clsx(classes.settings, classes.block)}>
                <Typography color={"primary"} className={classes.title}>
                    {store.languageSwitch('fileList')}
                </Typography>
                <FormControlLabel
                  control={
                      <Checkbox
                        checked={this.state.exportCsv}
                        onChange={this.handleChange("exportCsv")}
                        classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checkboxChecked,
                        }}
                      />
                  }
                  label={store.languageSwitch('csvFile')}
                />
                <FormControlLabel
                  control={
                      <Checkbox
                        checked={this.state.exportJson}
                        onChange={this.handleChange("exportJson")}
                        classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checkboxChecked,
                        }}
                      />
                  }
                  label={store.languageSwitch('jsonFile')}
                />
                <Button className={classes.exportButton} onClick={this.handleExport}
                        variant={"contained"} color={"primary"}
                        disabled={!check}>{check ? store.languageSwitch('exportFile') : store.languageSwitch('checkFile')}</Button>
            </div>
            <div className={clsx(classes.statistics, classes.block)}>
                <Statistics/>
            </div>
            <div className={clsx(classes.videoList, classes.block)}>
                <VideoList/>
            </div>
        </div>;
    }

    handleChange = ext => e => {
        const state = this.state;
        state[ext] = e.target.checked;
        this.setState(state);
    };

    handleExport = () => {
        if (this.state.exportCsv) store.exportCsvData();
        if (this.state.exportJson) store.exportJsonData();
    };
}

export default withStyles(styles)(Export);
